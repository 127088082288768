import React, { useContext } from "react";

import { AppContext } from "../../context/context";
import { formatPrice, formatDate } from '../../utils/Common';
import { Link } from "react-router-dom";
const CustomerListItem = (props) => {
  const { config, state } = useContext(AppContext);
  const { BASE_URL } = config;

  const {
    customer
  } = props;
  return (
    <div>
      <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-5  items-start">
        <div className="text-[14px]">
          <h1 className="text-[#000232] font-[500]">{customer.id}</h1>
          <p className="text-[#87889E] font-[700]">{customer.name}</p>
        </div>

        <p className="text-[14px] text-[#000] font-[500] text-center">Premium</p>
        <p className="text-[14px] text-[#000] font-[500] text-center">{customer.purchase_number}</p>
        <p className="text-[14px] text-[#000] font-[500] text-center">{customer.purchase_amount}</p>
        {/* <div className="text-[14px] text-[#000] font-[500] text-center">{customer.due}</div> */}
        <div className="text-[12px] font-nunito font-[700] flex flex-col lg:flex-row gap-2 justify-end items-center">
          {/* <a className="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]" href={`/invoice/${customer.name}`}>VIEW</a>
          <a className="block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#407BFF] px-[12px] pt-[2px] pb-[2px]" href={`/invoice/${customer.name}`}>EDIT</a> */}
          <Link className="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]" to={`/customer-profile/${customer.id}`}>VIEW</Link>
        </div>
      </div>

    </div>
  );
};

export default CustomerListItem;