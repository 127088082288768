import html2pdf from "html2pdf.js";
import React, { useState, useRef } from "react";
//import QRCode from "react-qr-code";
import { numberToWords, formatPrice } from "../../utils/Common";
import PdfHtmlContent from "./PdfHtmlContent/PdfHtmlContent";
import PdfHtmlFor2mm from "./PdfHtmlContentFor2mm/PdfHtmlFor2mm";
import PdfHtmlFor3mm from "./PdfHtmlContentFor3mm/PdfHtmlContentFor3mm";
import Watermark from "./Watermark/Watermark";
import { FaChevronDown, FaCloudDownloadAlt } from "react-icons/fa";
import { IoPrint } from "react-icons/io5";
import { event } from "jquery";
import { useNavigate } from "react-router-dom";
import DeliveryInvoice from "./DeliveryInvoicePage/DeliveryInvoice";
import DeliveryInvoice3mm from "./DeliveryInvoicePage/DeliveryInvoice3mm";
import DeliveryInvoice2mm from "./DeliveryInvoicePage/DeliveryInvoice2mm";
import PdfHtmlContentA3 from "./PdfHtmlContentA3/PdfHtmlContentA3";
import DeliveryInvoiceA3 from "./DeliveryInvoicePage/DeliveryInvoiceA3";
import PdfHtmlContentA3Challan from "./PdfHtmlContentA3Challan/PdfHtmlContentA3Challan";
import DeliveryInvoiceA3Challan from "./DeliveryInvoicePage/DeliveryInvoiceA3Challan";
import { Modal } from "../../utils/Modal";
import styles from "./PdfHtmlContentFor3mm/PdfHtmlContentFor3mm.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatPhoneNumber, invoiceCalculate } from "../../utils/Common";
//import QRCode from 'qrcode';
import html2canvas from "html2canvas";

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const InvoiceDetail = (props) => {
  const [pageSelect, setpageSelect] = useState(null);
  const [downloadPdfView, setdownloadPdfView] = useState(false);
  const [dropdownPdf, setDropdownPdf] = useState(false);
  const [dropdownPrint, setDropdownPrint] = useState(false);
  const [printContentVisible, setPrintContentVisible] = useState(false);
  const originalContents = document.body.innerHTML;
  const navigate = useNavigate();

  const {
    invoice,
    invoiceCondition,
    paperSize,
    deliveryMode,
    invoiceImeis,
    inputs,
  } = props;

  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, "0");
  const monthName = invoiceDate.toLocaleString("en-US", { month: "long" });
  const day = String(invoiceDate.getDate()).padStart(2, "0");
  const hours = String(invoiceDate.getHours() % 12).padStart(2, "0");
  const minutes = String(invoiceDate.getMinutes()).padStart(2, "0");
  const ampm = invoiceDate.getHours() >= 12 ? "PM" : "AM";
  const [id, setId] = useState("");
  const [showPrintOptions, setShowPrintOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours}:${minutes}${ampm}`;
  const formattedDateTime = `${day} ${monthName} ${year} ( ${hours}:${minutes}${ampm} )`;

  const shop_logo = invoice?.user_info?.invoice_settings?.shop_logo
    ? invoice?.user_info?.invoice_settings?.shop_logo
    : `${BASE_URL}/layoutlogo.svg`;
  //const shop_logo = `${BASE_URL}/layoutlogo.svg`;
  let totalQty = 0;
  let totalDiscount = 0;

  // const handlePrint = (elementId) => {
  //   const printElement = document.getElementById(elementId);

  //   if (printElement) {
  //     setPrintContentVisible(true);
  //     const printContents = printElement.innerHTML;
  //     document.body.innerHTML = printContents;
  //     window.print();
  //   } else {
  //     console.error(`Element with id "${elementId}" not found.`);
  //   }
  // };

  const handlePrint = (elementId) => {
    const printElement = document.getElementById(elementId);

    if (printElement) {
      setPrintContentVisible(true);

      // Get the content of the element to print
      const printContents = printElement.innerHTML;

      // Initialize printable content
      let printableHTML = printContents;

      // Add specific styles only if the elementId is "pdfConentAreasPad"
      if (elementId === "pdfConentAreasPad") {
        const printStyles = `
                <style>
                    @media print {
                        body {
                            margin: 0;
                            padding: 0;
                        }
                        #${elementId} {
                            padding-top: 50px; /* Space at the top of each page */
                            padding-bottom: 30px; /* Space at the bottom of each page */
                            page-break-inside: avoid;
                        }
                        @page {
                            margin: 1.5in 0.5in 0.7in 0.5in; /* Top, right, bottom, left */
                        }
                    }
                </style>
            `;
        printableHTML = `
                ${printStyles}
                ${printContents}
            `;
      }

      // Set the body content and styles dynamically
      document.body.innerHTML = printableHTML;

      // Trigger print
      window.print();

      // Reload the page to restore original content
      window.location.reload();
    } else {
      console.error(`Element with id "${elementId}" not found.`);
    }
  };

//  const handlePrintA4Customize = (elementId) => {
//     const printContent = document.getElementById(elementId);
//     const windowPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

//     if (windowPrint) {
//       windowPrint.document.write(`
//         <!DOCTYPE html>
//         <html>
//           <head>
//             <title>Print Invoice</title>
//             <meta name="color-scheme" content="normal">
//             <meta name="color-rendering" content="optimizeQuality">
//             <style>
//               ${document.querySelector('style').innerHTML}

//               @media not print {
//                 body {
//                   background: white;
//                 }
//               }

//               @page {
//                 margin: 0;
//                 size: auto;
//               }
//             </style>
//           </head>
//           <body>
//             ${printContent.outerHTML}
//           </body>
//         </html>
//       `);

//       windowPrint.document.close();
//       windowPrint.focus();

//       // Wait for resources to load
//       setTimeout(() => {
//         windowPrint.print();
//         // windowPrint.close();
//       }, 250);
//     }
//   };

  window.onafterprint = () => {
    setPrintContentVisible(false);
    document.body.innerHTML = originalContents;
    // Restore original content
    window.location.reload();
  };

  const handleGeneratePdf = async (pageSize, pageSizeNumber) => {
    const element = document.getElementById("pdfConentAreas");
    const element2 = document.getElementById("pdfConentAreas3");
    const element3 = document.getElementById("pdfConentAreas2");
    const element4 = document.getElementById("pdfConentAreasA3");
    const element5 = document.getElementById("pdfConentAreasA3Challan");

    let selectedElement;
    setdownloadPdfView(true);
    if (pageSizeNumber === 1) {
      setId("pdfConentAreas");
      console.log("page size", pageSizeNumber);
      console.log(element);
      setpageSelect(pageSizeNumber);

      selectedElement = element;
    } else if (pageSizeNumber === 2) {
      setId("pdfConentAreas3");
      console.log("page size", pageSizeNumber);
      setpageSelect(pageSizeNumber);
      selectedElement = element2;
    } else if (pageSizeNumber === 3) {
      setId("pdfConentAreas2");
      console.log("page size", pageSizeNumber);
      setpageSelect(pageSizeNumber);
      selectedElement = element3;
    } else if (pageSizeNumber === 4) {
      setId("pdfConentAreasA3");
      console.log("page size", pageSizeNumber);
      setpageSelect(pageSizeNumber);
      selectedElement = element4;
    } else if (pageSizeNumber === 5) {
      setId("pdfConentAreasA3Challan");
      console.log("page size", pageSizeNumber);
      setpageSelect(pageSizeNumber);
      selectedElement = element5;
    }

    const formatOptions = {
      a4: "a4",
      "3mm": [80, 297],
      "2mm": [56, 297],
      a3: [117, 254],
    };
    const shopLogoUrlWithProxy = `<span class="math-inline">${API_BASE_URL}</span>${shop_logo}`; // Prepend proxy URL

    const opt = {
      margin: 0.1,
      filename: "myPdf.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        scale: 900 / 96,
        proxy: shopLogoUrlWithProxy,
        useCORS: true,
        allowTaint: false,
        logging: false,
      },
      jsPDF: {
        unit: "mm",
        format: formatOptions[pageSize],
        orientation: "portrait",
        marginLeft: 10,
        marginRight: 10,
        image: { type: "jpeg", quality: 1 },
        fontFaces: [{ family: "Arial", src: "path/to/arial.ttf" }],
      },
    };

    const generatePdf = () => {
      html2pdf()
        .set(opt)
        .from(selectedElement)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          pdf.save(`${invoice.invoice_id}_invoice_${pageSize}.pdf`);
        });
    };

    const images = selectedElement?.querySelectorAll("img");
    let loadedImages = 0;

    // const checkImagesLoaded = () => {
    //   loadedImages++;

    //   if (loadedImages === images?.length) {
    generatePdf();
    //   }
    // };

    // if (images != null) {
    //   images.forEach(img => {
    //     img.addEventListener('load', checkImagesLoaded);
    //   })
    // };

    // if (images != null) {
    //   images.forEach(img => {
    //     if (img.complete) {
    //       checkImagesLoaded();
    //     }
    //   })
    // };
  };

  const handleNavigate = () => {
    navigate("/invoice");
  };

  // const handlePrintInNewTab = (elementId) => {
  //   const printElement = document.getElementById(elementId);

  //   if (printElement) {
  //     const printContents = printElement.innerHTML;

  //     // Open a new tab
  //     const printWindow = window.open('', '_blank');

  //     // Write the HTML content into the new tab
  //     printWindow.document.open();
  //     printWindow.document.write(`
  //       <html>
  //         <head>
  //           <title>Print Invoice</title>
  //           <style>
  //             /* Add any custom styles here to match your styling */
  //             body { font-family: Arial, sans-serif; font-size: 14px; padding: 10px; margin: 0; }
  //           </style>
  //         </head>
  //         <body onload="window.print(); window.close();">
  //           ${printContents}
  //         </body>
  //       </html>
  //     `);
  //     printWindow.document.close();
  //   } else {
  //     console.error(`Element with id "${elementId}" not found.`);
  //   }
  // };

  // const openInvoiceInNewTab = () => {
  //   const newTab = window.open('', '_blank');
  //   const htmlContent = `
  //     <!DOCTYPE html>
  //     <html>
  //     <head>
  //       <title>Invoice</title>
  //       <style>
  //         ${styles}
  //       </style>
  //     </head>
  //     <body>
  //       ${document.getElementById('pdfConentAreas3').outerHTML}
  //     </body>
  //     </html>
  //   `;
  //   newTab.document.open();
  //   newTab.document.write(htmlContent);
  //   newTab.document.close();
  //   newTab.focus();
  // };

  // const openInvoiceInNewTab = () => {
  //   const newTab = window.open('', '_blank');
  //   const htmlContent = `
  //     <!DOCTYPE html>
  //     <html>
  //     <head>
  //       <title>Invoice</title>
  //       <style>
  //         .pdfConentAreas3 {
  //           font-family: Arial, Helvetica, sans-serif;
  //           font-weight: 800;
  //           color: rgb(0, 0, 0);
  //         }
  //         .headingTable { margin: 0 0 20px 0; }
  //         tbody, th { font-family: Arial, Helvetica, sans-serif; font-weight: 800; color: rgb(0, 0, 0); }
  //         .headingTable td { font-size: 12px; }
  //         .headingPDf { text-align: center; margin: 0 0 10px 0; font-size: 22px; }
  //         .invoice-customer { font-family: Arial, Helvetica, sans-serif; font-weight: 800; color: rgb(0, 0, 0); }
  //         img.logoImage { width: 120px; margin: 0 0 10px 0; }
  //         .pdfConentAreas3 table { width: 100%; border-spacing: 0; border-collapse: collapse; }
  //         .pdfConentAreas3 table td { margin: 0; }
  //       </style>
  //     </head>
  //     <body>
  //       ${document.getElementById('pdfConentAreas3').outerHTML}
  //     </body>
  //     </html>
  //   `;
  //   newTab.document.open();
  //   newTab.document.write(htmlContent);
  //   newTab.document.close();
  //   newTab.focus();
  // };

  // const invoiceRef = useRef(null);
  // //const logoRef = useRef(null);
  // let totalItems = 0;
  // const handleTotalQtyUpdate = (newTotalQty) => {
  //   console.log("Updated Total Quantity in Parent: ", newTotalQty);
  //   totalItems=newTotalQty;
  // };
  // const handlePdf = async () => {
  //   const invoicepdf = invoiceRef.current;
  //   const doc = new jsPDF();
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const contentWidth = pageWidth * 0.4; // Set desired content width, e.g., 70% of page width
  //   const centerX = (pageWidth - contentWidth) / 2; // Center alignment based on reduced width

  //   //header
  //   doc.setFontSize(8);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(`${invoice?.user_info?.outlet_name}`, pageWidth / 2, 20, { align: "center" });
  //   doc.text(`Address: ${invoice?.user_info?.address}`, pageWidth / 2, 25, { align: "center" });
  //   doc.text(`Mobile: ${invoice?.user_info?.invoice_settings?.mobile_number}`, pageWidth / 2, 30, { align: "center" });
  //   doc.text(`${invoice?.user_info?.invoice_settings?.social_link ? invoice?.user_info?.invoice_settings?.social_link : ("")}`, pageWidth / 2, 35, { align: "center" });

  //   doc.setFontSize(12);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(`Sales Receipt`, pageWidth / 2, 42, { align: "center" });

  //   doc.setFontSize(10);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(`Invoice: ${invoice?.invoice_id}`, pageWidth / 2, 47, { align: "center" });

  //   const margin = centerX;
  //   const lineYPosition = 50;
  //   doc.line(margin, lineYPosition, pageWidth - margin, lineYPosition);

  //   const leftMargin = centerX;
  //   doc.setFontSize(10);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(`Date: ${formattedDate}`, leftMargin, 55, );
  //   doc.text(`Time: ${formattedTime}`, leftMargin, 60, );

  //   let currentYPosition = 65;
  //   // Extract table content
  //   doc.autoTable({
  //     html : invoicepdf,
  //     // startY: 65,
  //     startY: currentYPosition,
  //     theme: 'plain',
  //     showHead : 'firstPage',
  //     margin: { left: centerX, right: centerX },
  //     headStyles: {
  //       fillColor: null,
  //       textColor: 'black',
  //       lineWidth: 0.5,
  //       lineColor: 'black',
  //     },
  //     bodyStyles: {
  //         fillColor: null,
  //         lineWidth: 0,
  //     },
  //     columnStyles: {
  //       1: { halign: 'center' },
  //       2: { halign: 'center' },
  //       3: { halign: 'right' }
  //     },
  //     pageBreak: 'auto',
  //   });

  //   const fontSize = 10;
  //   const lineHeight = 8;
  //   currentYPosition = doc.lastAutoTable.finalY + 5;

  //   const addSummaryRow = (label, value, yPosition) => {
  //   doc.setFontSize(fontSize);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(label, leftMargin, yPosition);
  //   const rightX = pageWidth - leftMargin ;  // Adjust 10px margin
  //   doc.text(String(value), rightX, yPosition, { align: 'right' });
  //   //doc.line(leftMargin, yPosition + 2, pageWidth - leftMargin, yPosition + 2);
  //   };

  //   // Add the summary below the table
  //   addSummaryRow('TOTAL ITEMS', totalItems, currentYPosition);
  //   currentYPosition += lineHeight;
  //   doc.line(leftMargin, currentYPosition + 2, pageWidth - leftMargin, currentYPosition + 2);

  //   addSummaryRow('SUB TOTAL', `${invoice?.sub_total} ${invoice?.user_info?.invoice_settings?.currency_info?.code || 'BDT'}`, currentYPosition);
  //   currentYPosition += lineHeight;
  //   doc.line(leftMargin, currentYPosition + 2, pageWidth - leftMargin, currentYPosition + 2);

  //   addSummaryRow('DISCOUNT', `${formatPrice(invoice?.discount || 0)} ${invoice?.user_info?.invoice_settings?.currency_info?.code || 'BDT'}`, currentYPosition);
  //   currentYPosition += lineHeight;
  //   doc.line(leftMargin, currentYPosition + 2, pageWidth - leftMargin, currentYPosition + 2);

  //   addSummaryRow('Vat', `${invoice?.vat ? formatPrice(invoice?.vat) : '0.00'} ${invoice?.user_info?.invoice_settings?.currency_info?.code || 'BDT'}`, currentYPosition);
  //   currentYPosition += lineHeight;
  //   doc.line(leftMargin, currentYPosition + 2, pageWidth - leftMargin, currentYPosition + 2);

  //   addSummaryRow('Service Charge', `${invoice?.tax ? formatPrice(invoice?.tax) : '0.00'} ${invoice?.user_info?.invoice_settings?.currency_info?.code || 'BDT'}`, currentYPosition);
  //   currentYPosition += lineHeight;
  //   //doc.line(leftMargin, currentYPosition + 2, pageWidth - leftMargin, currentYPosition + 2);

  //   addSummaryRow('Grand Total', `${formatPrice(invoiceCalculate(invoice).grandTotal)} ${invoice?.user_info?.invoice_settings?.currency_info?.code || 'BDT'}`, currentYPosition);
  //   currentYPosition += lineHeight;

  //   addSummaryRow('Paid Amount', `${formatPrice(invoice?.paid_amount || 0)} ${invoice?.user_info?.invoice_settings?.currency_info?.code || 'BDT'}`, currentYPosition);
  //   currentYPosition += lineHeight;

  //   // Add the Due Amount row with underline
  //   addSummaryRow('Due Amount', `${formatPrice(invoiceCalculate(invoice).dueAmount) } ${invoice?.user_info?.invoice_settings?.currency_info?.code || 'BDT'}`, currentYPosition);
  //   //currentYPosition += lineHeight;
  //   doc.line(leftMargin, currentYPosition + 2, pageWidth - leftMargin, currentYPosition + 2); // Underline for Due Amount only

  //   doc.setFontSize(8);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(`In word: ${numberToWords(formatPrice(invoice?.paid_amount))} taka only`, pageWidth / 2, currentYPosition + 8, { align: "center" });

  //   doc.setFontSize(8);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(`YOU SAVED ${formatPrice(invoice?.discount ? invoice?.discount : 0)}${invoice?.user_info?.invoice_settings?.currency_info?.code ? invoice?.user_info?.invoice_settings?.currency_info?.code : ' BDT'} TAKA IN THIS PURCHASE`, pageWidth / 2, currentYPosition + 16, { align: "center" });

  //   doc.setFontSize(8);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(`THANKS FOR PURCHASE`, pageWidth / 2, currentYPosition + 20, { align: "center" });

  //   const qrCodeUrl = await QRCode.toDataURL(`${BASE_URL}/invoice/${invoice?.invoice_id}`, { width: 60 });
  //   const qrCodeSize = 15; // Smaller QR code size
  //   const qrCodeX = (pageWidth - qrCodeSize) / 2; // Center align
  //   const qrCodeY = currentYPosition + 22; // Slightly below the "THANKS FOR PURCHASE" text
  //   doc.addImage(qrCodeUrl, 'PNG', qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);

  //   doc.setFontSize(7);
  //   doc.setFont("helvetica");
  //   doc.text(`A PRODUCT OF SQUAD INNOVATORS`, pageWidth / 2, currentYPosition + 40, { align: "center" });

  //   const linkYPosition = currentYPosition + 43; // Adjust position below QR code
  //   const websiteUrl = "https://www.outletexpense.com";
  //   doc.setFontSize(6);
  //   doc.textWithLink("www.outletexpense.com", pageWidth / 2, linkYPosition, { align: "center", url: websiteUrl });

  //   // addSummaryRow('Grand Totals', `${formatPrice(invoiceCalculate(invoice).grandTotal)} ${invoiceSetting?.currency_info?.code || 'BDT'}`, currentYPosition);
  //   doc.output('dataurlnewwindow')
  // }

  return (
    <>
      <div>
        {/* <div className={`${paperSize == 'A4' ? 'block' : 'hidden'}`} >
        <PdfHtmlContent
          shop_logo={shop_logo}
          invoice={invoice}
          invoiceSetting={invoice?.user_info?.invoice_settings}
        />
      </div> */}
        {String(invoice.delivery_method?.type_name).toLowerCase() ==
        "hand-to-hand" ? (
          <div>
            {console.log("invoice", invoice)}
            {paperSize === "A4" && (
              <PdfHtmlContent
                shop_logo={shop_logo}
                invoice={invoice}
                invoiceImeis={invoiceImeis}
                id={"pdfConentAreas"}
                invoiceSetting={invoice?.user_info?.invoice_settings}
                inputs={inputs}
              />
            )}
            {/* {paperSize === '3mm' && (
            <PdfHtmlFor3mm
              shop_logo={shop_logo}
              invoice={invoice}
              id={"pdfConentAreas3"}
              invoiceSetting={invoice?.user_info?.invoice_settings}
            />
          )} */}
            <div className="hidden">
              <PdfHtmlFor3mm
                shop_logo={shop_logo}
                invoice={invoice}
                id={"pdfConentAreas3"}
                invoiceSetting={invoice?.user_info?.invoice_settings}
                // invoiceRef={invoiceRef}
                // logoRef={logoRef}
                // updateTotalQty={handleTotalQtyUpdate}
              />
            </div>
            {/* {paperSize === '2mm' && (
            <PdfHtmlFor2mm
              shop_logo={shop_logo}
              invoice={invoice}
              id={"pdfConentAreas2"}
              invoiceSetting={invoice?.user_info?.invoice_settings}
            />
          )} */}
            <div className="hidden">
              <PdfHtmlFor2mm
                shop_logo={shop_logo}
                invoice={invoice}
                id={"pdfConentAreas2"}
                invoiceSetting={invoice?.user_info?.invoice_settings}
              />
            </div>
            <div className="hidden">
              <PdfHtmlContentA3
                shop_logo={shop_logo}
                invoice={invoice}
                id={"pdfConentAreasA3"}
                invoiceSetting={invoice?.user_info?.invoice_settings}
              />
            </div>
            <div className="hidden">
              <PdfHtmlContentA3Challan
                shop_logo={shop_logo}
                invoice={invoice}
                id={"pdfConentAreasA3Challan"}
                invoiceSetting={invoice?.user_info?.invoice_settings}
              />
            </div>
          </div>
        ) : (
          <div>
            <DeliveryInvoice
              shop_logo={shop_logo}
              invoice={invoice}
              invoiceSetting={invoice?.user_info?.invoice_settings}
              invoiceImeis={invoiceImeis}
              id={"pdfConentAreas"}
              inputs={inputs}
            />
            <div className="hidden">
              <DeliveryInvoice3mm
                shop_logo={shop_logo}
                invoice={invoice}
                id={"pdfConentAreas3"}
                invoiceSetting={invoice?.user_info?.invoice_settings}
              />
            </div>
            <div className="hidden">
              <DeliveryInvoice2mm
                shop_logo={shop_logo}
                invoice={invoice}
                id={"pdfConentAreas2"}
                invoiceSetting={invoice?.user_info?.invoice_settings}
              />
            </div>
            <div className="hidden">
              <DeliveryInvoiceA3
                shop_logo={shop_logo}
                invoice={invoice}
                id={"pdfConentAreasA3"}
                invoiceSetting={invoice?.user_info?.invoice_settings}
              />
            </div>
            <div className="hidden">
              <DeliveryInvoiceA3Challan
                shop_logo={shop_logo}
                invoice={invoice}
                id={"pdfConentAreasA3Challan"}
                invoiceSetting={invoice?.user_info?.invoice_settings}
              />
            </div>
          </div>
        )}

        {/* new pdf and print buttons */}
        <div className="flex gap-12  justify-between text-xs md:text-sm lg:text-base mt-6">
          {console.log(invoice)}
          {/* pdf buttons */}
          <div
            className={`flex-1 transform duration-300 ${
              dropdownPdf || dropdownPrint
                ? "!h-[270px] md:!h-[300px]"
                : "h-[90px]"
            } space-y-2 relative`}
          >
            {/* pdf drop down button */}
            <button
              onClick={() => setDropdownPdf(!dropdownPdf)}
              className=" flex justify-center gap-1 md:gap-3 items-center bg-[#f1effc] text-[#034AFF] absolute z-10 !px-1 py-3 w-full rounded-xl font-bold shadow-md"
            >
              <span>
                <FaCloudDownloadAlt size={22} />
              </span>{" "}
              Download PDF{" "}
              <div
                className={`transform duration-300 ${
                  dropdownPdf ? "-rotate-180" : ""
                }`}
              >
                {" "}
                <FaChevronDown size={18} />
              </div>
            </button>
            {/* child buttons */}
            <button
              onClick={() => handleGeneratePdf("a4", 1)}
              className={`text-[#034AFF] font-semibold flex gap-1 md:gap-3  items-center justify-center ${
                dropdownPdf
                  ? "translate-y-[3.7rem] "
                  : "-translate-y-[0.6rem] opacity-0"
              } hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}
            >
              <FaCloudDownloadAlt size={22} /> <span>Download A4 PDF</span>
            </button>
            <button
              onClick={() => handleGeneratePdf("3mm", 2)}
              className={`text-[#034AFF] font-semibold flex gap-1 md:gap-3 items-center justify-center ${
                dropdownPdf
                  ? "translate-y-[3.7rem] "
                  : "-translate-y-[4.8rem] opacity-0"
              }  hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}
            >
              <FaCloudDownloadAlt size={22} /> <span>Download 3mm PDF</span>
            </button>
            <button
              onClick={() => handleGeneratePdf("2mm", 3)}
              className={` text-[#034AFF] font-semibold flex gap-1 md:gap-3 items-center justify-center ${
                dropdownPdf
                  ? "translate-y-[3.7rem] "
                  : "-translate-y-[8.4rem] opacity-0"
              } border hover:bg-[#034AFF] hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}
            >
              <FaCloudDownloadAlt size={22} /> <span>Download 2mm PDF</span>
            </button>
          </div>
          {/* print buttons */}
          <div
            className={`flex-1 space-y-2 transform duration-300 ${
              dropdownPdf || dropdownPrint
                ? "!h-[270px] md:!h-[300px]"
                : "h-[90px]"
            } relative`}
          >
            {/* print drop down button */}
            <button
              onClick={() => setDropdownPrint(!dropdownPrint)}
              className=" bg-[#f1effc] flex justify-center items-center gap-1 md:gap-3 text-[#034AFF] font-bold shadow-md absolute z-10 !px-1 py-3 w-full rounded-xl"
            >
              <span>
                <IoPrint size={22} />
              </span>{" "}
              Print{" "}
              <div
                className={`transform duration-300 ${
                  dropdownPrint ? "-rotate-180" : ""
                }`}
              >
                {" "}
                <FaChevronDown size={18} />
              </div>
            </button>
            {/* child buttons */}
            <button
              onClick={() => {
                handlePrint("pdfConentAreas");
              }}
              className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${
                dropdownPrint
                  ? "translate-y-[3.7rem] "
                  : "-translate-y-[0.6rem] opacity-0"
              } hover:text-[#eaeffb] hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300  py-3 w-full  rounded-xl`}
            >
              <IoPrint size={22} />
              <span>Print A4</span>
            </button>
            {/* child buttons */}
            <button
              onClick={() => {
                handlePrint("pdfConentAreasPad");
              }}
              className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${
                dropdownPrint
                  ? "translate-y-[3.7rem] "
                  : "-translate-y-[0.6rem] opacity-0"
              } hover:text-[#eaeffb] hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300  py-3 w-full  rounded-xl`}
            >
              <IoPrint size={22} />
              <span>Print A4 (Pad)</span>
            </button>
            {/* child buttons */}
            <button
              onClick={() => {
                handlePrint("pdfConentAreasCustomize");
              }}
              className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${
                dropdownPrint
                  ? "translate-y-[3.7rem] "
                  : "-translate-y-[0.6rem] opacity-0"
              } hover:text-[#eaeffb] hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300  py-3 w-full  rounded-xl`}
            >
              <IoPrint size={22} />
              <span>Print A4 (Customize)</span>
            </button>
            {/* {(invoice.delivery_method?.type_name =='hand-to-hand') && */}
            <button
              onClick={() => {
                handlePrint("pdfConentAreas3");
              }}
              className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${
                dropdownPrint
                  ? "translate-y-[3.7rem] "
                  : "-translate-y-[4.8rem] opacity-0"
              }  hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}
            >
              <IoPrint size={22} /> <span>Print 3 inch</span>
            </button>
            {/* <button
              onClick={() => { openInvoiceInNewTab('pdfConentAreas3') }}
              className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold ${dropdownPrint ? "translate-y-[3.7rem] " : "-translate-y-[4.8rem] opacity-0"} hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full rounded-xl`}
            >
              <IoPrint size={22}/> <span>Print 3 inch</span>
            </button> */}
            {/* <button
              onClick={handlePdf}
              className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold ${dropdownPrint ? "translate-y-[3.7rem] " : "-translate-y-[4.8rem] opacity-0"} hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full rounded-xl`}
            >
              <IoPrint size={22}/> <span>Print 3 inch</span>
            </button> */}

            {/* } */}
            {/* {(invoice.delivery_method?.type_name =='hand-to-hand') && */}
            <button
              onClick={() => {
                handlePrint("pdfConentAreas2");
              }}
              className={` flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${
                dropdownPrint
                  ? "translate-y-[3.7rem] "
                  : "-translate-y-[8.4rem] opacity-0"
              } border hover:bg-[#034AFF] hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}
            >
              <IoPrint size={22} />
              <span>Print 2 inch</span>
            </button>
            {/* <button onClick={() => { handlePrint('pdfConentAreasA3') }} className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${dropdownPrint ? "translate-y-[3.7rem] " : "-translate-y-[0.6rem] opacity-0"} hover:text-[#eaeffb] hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300  py-3 w-full  rounded-xl`}>
              <IoPrint size={22}/><span>Print A3</span>
            </button>
            <button onClick={() => { handlePrint('pdfConentAreasA3Challan') }} className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${dropdownPrint ? "translate-y-[3.7rem] " : "-translate-y-[0.6rem] opacity-0"} hover:text-[#eaeffb] hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300  py-3 w-full  rounded-xl`}>
              <IoPrint size={22}/><span>Print A3 Challan</span>
            </button> */}
            <button
              htmlFor="choose_print_option"
              onClick={() => {
                setShowPrintOptions(true);
              }}
              className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${
                dropdownPrint
                  ? "translate-y-[3.7rem] "
                  : "-translate-y-[0.6rem] opacity-0"
              } hover:text-[#eaeffb] hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300  py-3 w-full  rounded-xl`}
            >
              <IoPrint size={22} />
              <span>Print A5</span>
            </button>
            {/* } */}
          </div>
        </div>
        {/* back to list button */}
        <button
          onClick={() => handleNavigate()}
          className=" bg-[#034AFF] py-3 w-1/2 mt-3 mx-auto flex justify-center text-white rounded-xl text-xs md:text-sm lg:text-base"
        >
          Back to list
        </button>
      </div>

      {showPrintOptions ? (
        <Modal
          loadingCallback={loadingCallback}
          navigate={navigate}
          isOpen={showPrintOptions}
          setOpen={setShowPrintOptions}
          title="Print Options"
          formId="choose_print_option"
          type="choosePrintOption"
          //selectType="designation"
          //refetch={()=>setRefetch(val=>!val)}
          //formData={formData}
          //setFormData={setFormData}
          //setWarningMessage={setWarningMessage}
          handlePrint={handlePrint}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default InvoiceDetail;
