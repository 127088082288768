// import React from "react";
// import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// // Define styles
// const styles = StyleSheet.create({
//   page: {
//     padding: 40,
//     position: "relative",
//   },
//   header: {
//     marginBottom: 10,
//     textAlign: "center",
//   },
//   title: {
//     fontSize: 16,
//     fontWeight: "bold",
//     marginBottom: 4,
//   },
//   shopInfo: {
//     fontSize: 10,
//     marginBottom: 2,
//   },
//   thickDivider: {
//     borderBottomWidth: 2,
//     borderBottomColor: "#000",
//     marginVertical: 1,
//     width: "100%",
//   },
//   reportTitle: {
//     fontSize: 12,
//     fontWeight: "bold",
//     marginTop: 2,
//     marginBottom: 3,
//   },
//   tableContainer: {
//     display: "flex",
//     flexDirection: "row",
//     marginBottom: 10,
//   },
//   tableWrapper: {
//     flex: 1,
//     borderWidth: 1,
//     borderColor: "#000",
//     marginRight: 5,
//     backgroundColor: "#fff",
//   },
//   tableTitle: {
//     fontSize: 10,
//     fontWeight: "bold",
//     marginVertical: 5,
//     textAlign: "center",
//     textDecoration: "underline"
//   },

//   tableHeader: {
//     flexDirection: "row",
//     backgroundColor: "#f2f2f2",
//     borderBottomWidth: 0.5,
//     borderColor: "#000",
//   },
//   tableHeaderCell: {
//     padding: 5,
//     fontSize: 10,
//     fontWeight: "bold",
//     flex: 1,
//     textAlign: "center",
//   },
//   tableRow: {
//     flexDirection: "row",
//   },
//   tableCell: {
//     padding: 5,
//     fontSize: 10,
//     flex: 1,
//     textAlign: "right",
//     borderBottomWidth: 0.5,
//     borderColor: "#000",
//   },
//   tableCellLeft: {
//     textAlign: "left",
//   },
//   tableCellCenter: {
//     textAlign: "center",
//   },
//   tableCellRight: {
//     textAlign: "right",
//   },
//   totalRow: {
//     backgroundColor: "#f2f2f2",
//     fontWeight: "bold",
//     flexDirection: "row",
//     borderTopWidth: 1,
//     borderColor: "#000",
//   },
//   totalCell: {
//     padding: 5,
//     fontSize: 10,
//     flex: 1,
//     textAlign: "right",
//   },
//   footer: {
//     position: "absolute",
//     bottom: 15,
//     left: 0,
//     right: 0,
//     flexDirection: "row",
//     justifyContent: "space-around",
//     fontSize: 10,
//   },
// });

// // PDF document component
// const MonthWiseReportPDFDocument = ({
//   reports,
//   totalSales,
//   totalExpenseDebit,
//   totalExpenseCredit,
//   totalPurchase,
//   totalPaymentDebit,
//   totalPaymentCredit,
//   user,
// }) => (
//   <Document>
//     <Page style={styles.page} size="A4" orientation="portrait">
//       {/* Header */}
//       <View style={styles.header}>
//         <Text style={styles.title}>
//           {user?.invoice_settings?.shop_name ||
//             "Bhai Bhai Sewing Corner (Mirpur)"}
//         </Text>
//         <Text style={styles.shopInfo}>
//           {user?.address ||
//             "Shop No-5 (2nd Floor) SN Mansion, Mirpur-1, Dhaka-1216."}
//         </Text>
//         <Text style={styles.shopInfo}>
//           Mobile: {user?.phone || "01819132220"}
//         </Text>
//         <View style={styles.thickDivider} />
//         <Text style={styles.reportTitle}>Month-Wise Report</Text>
//       </View>

//       {/* Sales and Purchases Table Side by Side */}
//       <View style={styles.tableContainer}>
//         {/* Sales Table */}
//         <View style={styles.tableWrapper}>
//         <Text style={styles.tableTitle}>Sales History</Text>
//           <View style={styles.tableHeader}>
//             <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>
//               Customer Name
//             </Text>
//             <Text style={[styles.tableHeaderCell, styles.tableCellRight]}>Credit (BDT)</Text>
//           </View>
//           {reports?.sales?.map((report, idx) => (
//             <View key={idx} style={styles.tableRow}>
//               <Text style={[styles.tableCell, styles.tableCellLeft]}>
//                 {report.customer_name}
//               </Text>
//               <Text style={styles.tableCell}>{report.total_credit_amount}</Text>
//             </View>
//           ))}
//           <View style={styles.totalRow}>
//             <Text style={[styles.totalCell, styles.tableCellLeft]}>
//               Total Sales:
//             </Text>
//             <Text style={styles.totalCell}>{totalSales || 0}</Text>
//           </View>
//         </View>

//         {/* Purchases Table */}
//         <View style={styles.tableWrapper}>
//         <Text style={styles.tableTitle}>Purchase History</Text>
//           <View style={styles.tableHeader}>
//             <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>
//               Vendor Name
//             </Text>
//             <Text style={[styles.tableHeaderCell, styles.tableCellRight]}>Debit (BDT)</Text>
//           </View>
//           {reports?.purchases?.map((purchase, idx) => (
//             <View key={idx} style={styles.tableRow}>
//               <Text style={[styles.tableCell, styles.tableCellLeft]}>
//                 {purchase.vendor_name || "N/A"}
//               </Text>
//               <Text style={styles.tableCell}>
//                 {purchase.total_debit_amount}
//               </Text>
//             </View>
//           ))}
//           <View style={styles.totalRow}>
//             <Text style={[styles.totalCell, styles.tableCellLeft]}>
//               Total Purchases:
//             </Text>
//             <Text style={styles.totalCell}>{totalPurchase || 0}</Text>
//           </View>
//         </View>
//       </View>

//       {/* Expenses and Payments Table Side by Side */}
//       <View style={styles.tableContainer}>
//         {/* Expenses Table */}
//         <View style={styles.tableWrapper}>
//         <Text style={styles.tableTitle}>Expenses Details</Text>
//           <View style={styles.tableHeader}>
//             <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>
//               Category Name
//             </Text>
//             <Text style={[styles.tableHeaderCell, styles.tableCellCenter]}>Debit (BDT)</Text>
//             <Text style={[styles.tableHeaderCell, styles.tableCellRight]}>Credit (BDT)</Text>
//           </View>
//           {reports?.expenses?.map((report, idx) => (
//             <View key={idx} style={styles.tableRow}>
//               <Text style={[styles.tableCell, styles.tableCellLeft]}>
//                 {report.catogory_name}
//               </Text>
//               <Text style={[styles.tableCell, styles.tableCellCenter]}>
//                 {report.expense_type === "Debit" ? report.amount : 0}
//               </Text>
//               <Text style={styles.tableCell}>
//                 {report.expense_type === "Credit" ? report.amount : 0}
//               </Text>
//             </View>
//           ))}
//           <View style={styles.totalRow}>
//             <Text style={[styles.totalCell, styles.tableCellLeft]}>
//               Total Expenses:
//             </Text>
//             <Text style={[styles.totalCell, styles.tableCellCenter]}>{totalExpenseDebit || 0}</Text>
//             <Text style={styles.totalCell}>{totalExpenseCredit || 0}</Text>
//           </View>
//         </View>

//         {/* Payments Table */}
//         <View style={styles.tableWrapper}>
//         <Text style={styles.tableTitle}>Payments History</Text>
//           <View style={styles.tableHeader}>
//             <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>
//               Payment Type
//             </Text>
//             <Text style={[styles.tableHeaderCell, styles.tableCellCenter]}>Debit (BDT)</Text>
//             <Text style={[styles.tableHeaderCell, styles.tableCellRight]}>Credit (BDT)</Text>
//           </View>
//           {reports?.payments?.map((payment, idx) => (
//             <View key={idx} style={styles.tableRow}>
//               <Text style={[styles.tableCell, styles.tableCellLeft]}>
//                 {payment.payment_type_name || "Cash"}
//               </Text>
//               <Text style={[styles.tableCell, styles.tableCellCenter]}>{payment.payment_debit || 0}</Text>
//               <Text style={styles.tableCell}>{payment.payment_credit || 0}</Text>
//             </View>
//           ))}
//           <View style={styles.totalRow}>
//             <Text style={[styles.totalCell, styles.tableCellLeft]}>
//               Total Payments:
//             </Text>
//             <Text style={[styles.totalCell, styles.tableCellCenter]}>{totalPaymentDebit || 0}</Text>
//             <Text style={styles.totalCell}>{totalPaymentCredit || 0}</Text>
//           </View>
//         </View>
//       </View>

//       {/* Footer */}
//       <View style={styles.footer}>
//         <Text>Prepared by</Text>
//         <Text>Checked/Recommended by</Text>
//         <Text>Authorized by</Text>
//       </View>
//     </Page>
//   </Document>
// );

// export default MonthWiseReportPDFDocument;





import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    position: "relative",
  },
  header: {
    marginBottom: 10,
    textAlign: "center",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  shopInfo: {
    fontSize: 10,
    marginBottom: 2,
  },
  thickDivider: {
    borderBottomWidth: 2,
    borderBottomColor: "#000",
    marginVertical: 1,
    width: "100%",
  },
  reportTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginTop: 2,
    marginBottom: 3,
  },
  tableContainer: {
    marginBottom: 20,
  },
  tableWrapper: {
    width: "100%",
    borderWidth: 1,
    borderColor: "#000",
    marginBottom: 10,
    backgroundColor: "#fff",
  },
  tableTitle: {
    fontSize: 10,
    fontWeight: "bold",
    marginVertical: 5,
    textAlign: "center",
    textDecoration: "underline",
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#f2f2f2",
    borderBottomWidth: 0.5,
    borderColor: "#000",
  },
  tableHeaderCell: {
    padding: 5,
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    flex: 1,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    padding: 5,
    fontSize: 10,
    textAlign: "right",
    borderBottomWidth: 0.5,
    borderColor: "#000",
    flex: 1,
  },
  tableCellLeft: {
    textAlign: "left",
  },
  tableCellCenter: {
    textAlign: "center",
  },
  tableCellRight: {
    textAlign: "right",
  },
  totalRow: {
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
    flexDirection: "row",
    borderTopWidth: 1,
    borderColor: "#000",
  },
  totalCell: {
    padding: 5,
    fontSize: 10,
    textAlign: "right",
    flex: 1,
  },
  footer: {
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: 10,
  },
});

// PDF document component
const MonthWiseReportPDFDocument = ({
  reports,
  totalSales,
  totalExpenseDebit,
  totalExpenseCredit,
  totalPurchase,
  totalPaymentDebit,
  totalPaymentCredit,
  user,
}) => (
  <Document>
    <Page style={styles.page} size="A4" orientation="portrait">
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>
          {user?.invoice_settings?.shop_name ||
            "Bhai Bhai Sewing Corner (Mirpur)"}
        </Text>
        <Text style={styles.shopInfo}>
          {user?.address ||
            "Shop No-5 (2nd Floor) SN Mansion, Mirpur-1, Dhaka-1216."}
        </Text>
        <Text style={styles.shopInfo}>
          Mobile: {user?.phone || "01819132220"}
        </Text>
        <View style={styles.thickDivider} />
        <Text style={styles.reportTitle}>Month-Wise Report</Text>
      </View>

      {/* Sales Table */}
      <View style={styles.tableWrapper}>
        {/* <Text style={styles.tableTitle}>Sales History</Text> */}
        <View style={styles.tableHeader}>
          <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>Sl.</Text>
          <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>Customer ID</Text>
          <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>Customer Name</Text>
          <Text style={[styles.tableHeaderCell, styles.tableCellRight]}>Credit (BDT)</Text>
        </View>
        {reports?.sales?.map((report, idx) => (
          <View key={idx} style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.tableCellLeft]}>{idx + 1}</Text>
            <Text style={[styles.tableCell, styles.tableCellLeft]}>{report.customer_id}</Text>
            <Text style={[styles.tableCell, styles.tableCellLeft]}>{report.customer_name}</Text>
            <Text style={styles.tableCell}>{report.total_credit_amount}</Text>
          </View>
        ))}
        <View style={styles.totalRow}>
          <Text style={[styles.totalCell, styles.tableCellLeft]}>Total Sales:</Text>
          <Text style={styles.totalCell}>{totalSales || 0}</Text>
        </View>
      </View>

      {/* Purchases Table */}
      <View style={styles.tableWrapper}>
        {/* <Text style={styles.tableTitle}>Purchase History</Text> */}
        <View style={styles.tableHeader}>
          <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>Sl.</Text>
          <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>Vendor Name</Text>
          <Text style={[styles.tableHeaderCell, styles.tableCellRight]}>Debit (BDT)</Text>
        </View>
        {reports?.purchases?.map((purchase, idx) => (
          <View key={idx} style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.tableCellLeft]}>{idx + 1}</Text>
            <Text style={[styles.tableCell, styles.tableCellLeft]}>
              {purchase.vendor_name || "N/A"}
            </Text>
            <Text style={styles.tableCell}>{purchase.total_debit_amount}</Text>
          </View>
        ))}
        <View style={styles.totalRow}>
          <Text style={[styles.totalCell, styles.tableCellLeft]}>Total Purchases:</Text>
          <Text style={styles.totalCell}>{totalPurchase || 0}</Text>
        </View>
      </View>

      {/* Expenses Table */}
      <View style={styles.tableWrapper}>
        {/* <Text style={styles.tableTitle}>Expenses Details</Text> */}
        <View style={styles.tableHeader}>
          <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>Sl.</Text>
          <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>Category Name</Text>
          <Text style={[styles.tableHeaderCell, styles.tableCellCenter]}>Debit (BDT)</Text>
          <Text style={[styles.tableHeaderCell, styles.tableCellRight]}>Credit (BDT)</Text>
        </View>
        {reports?.expenses?.map((report, idx) => (
          <View key={idx} style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.tableCellLeft]}>{idx + 1}</Text>
            <Text style={[styles.tableCell, styles.tableCellLeft]}>{report.catogory_name}</Text>
            <Text style={[styles.tableCell, styles.tableCellCenter]}>
              {report.expense_type === "Debit" ? report.amount : 0}
            </Text>
            <Text style={styles.tableCell}>
              {report.expense_type === "Credit" ? report.amount : 0}
            </Text>
          </View>
        ))}
        <View style={styles.totalRow}>
          <Text style={[styles.totalCell, styles.tableCellLeft]}>Total Expenses:</Text>
          <Text style={[styles.totalCell, styles.tableCellCenter]}>{totalExpenseDebit || 0}</Text>
          <Text style={styles.totalCell}>{totalExpenseCredit || 0}</Text>
        </View>
      </View>

      {/* Payments Table */}
      <View style={styles.tableWrapper}>
        {/* <Text style={styles.tableTitle}>Payments History</Text> */}
        <View style={styles.tableHeader}>
          <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>Sl.</Text>
          <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>Payment Type</Text>
          <Text style={[styles.tableHeaderCell, styles.tableCellCenter]}>Debit (BDT)</Text>
          <Text style={[styles.tableHeaderCell, styles.tableCellRight]}>Credit (BDT)</Text>
        </View>
        {reports?.payments?.map((payment, idx) => (
          <View key={idx} style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.tableCellLeft]}>{idx + 1}</Text>
            <Text style={[styles.tableCell, styles.tableCellLeft]}>{payment.payment_type_name || "Cash"}</Text>
            <Text style={[styles.tableCell, styles.tableCellCenter]}>{payment.payment_debit || 0}</Text>
            <Text style={styles.tableCell}>{payment.payment_credit || 0}</Text>
          </View>
        ))}
        <View style={styles.totalRow}>
          <Text style={[styles.totalCell, styles.tableCellLeft]}>Total Payments:</Text>
          <Text style={[styles.totalCell, styles.tableCellCenter]}>{totalPaymentDebit || 0}</Text>
          <Text style={styles.totalCell}>{totalPaymentCredit || 0}</Text>
        </View>
      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <Text>Prepared by</Text>
        <Text>Checked/Recommended by</Text>
        <Text>Authorized by</Text>
      </View>
    </Page>
  </Document>
);

export default MonthWiseReportPDFDocument;
