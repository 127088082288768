import React from "react";
import {
  formatPhoneNumber,
  numberToWords,
  formatPrice,
  invoiceCalculate,
} from "../../../utils/Common";
import QRCode from "react-qr-code";

const PdfHtmlContentCustomize = ({
  invoice,
  invoiceSetting,
  invoiceImeis,
  inputs,
  formattedDateTime,
  totalPricePrintCustomize,
  totalItemsPrintCustomize,
  styles,
  totalPrice,
  totalQty,
  BASE_URL,
}) => {
  console.log("PdfHtmlContentCustomize props:", {
    totalPricePrintCustomize,
    invoice,
    invoiceSetting,
  });

  return (
    <div className="hidden">
      <div
        id="pdfConentAreasCustomize"
        className="max-w-3xl mx-auto py-0 bg-white pdfConentAreasCustomize"
      >
        {/* Header */}
        <div className="relative">
          <div className="w-full bg-gray-800 relative h-28 overflow-hidden">
            <div className="relative z-10 flex justify-between items-start">
              {/* Logo Section */}
              <div
                // className="relative h-[28rem] border-orange-600 w-[26rem] bg-black transform p-4"
                className="relative h-[30rem] w-[26rem] transform"
                style={{
                  backgroundColor: invoiceSetting?.second_code || "black",
                  borderColor: invoiceSetting?.first_code || "orange",
                  clipPath: "polygon(100% 0px, 90% -2%, 0% 125%, 0px 0px)",
                  borderWidth: "1px", // Adjust if needed
                }}
              >
                <div className="text-white text-2xl font-bold tracking-wider p-2 flex items-center gap-2">
                  {/* {invoiceSetting?.shop_name || "YOUR LOGO"} */}
                  <img
                    src={invoiceSetting.shop_logo}
                    alt="Shop Logo"
                    className="w-[50px] sm:w-[70px] md:w-[90px] object-contain mt-1"
                  />
                  <p className="text-white text-sm tracking-widest mt-1 p-2 ">
                    {invoiceSetting?.slogan || ""}
                  </p>
                </div>
              </div>

              <div
                className=" absolute left-52 h-3 -rotate-[58deg] w-80 z-10"
                style={{
                  backgroundColor: invoiceSetting?.first_code || "orange",
                }}
              ></div>
              <div
                className="absolute top-[6.3rem] h-3 w-[19rem]"
                style={{
                  backgroundColor: invoiceSetting?.first_code || "orange",
                }}
              ></div>

              {/* Invoice Section */}
              <div className="text-right pt-4 pr-4 pb-2">
                <h2
                  className=" text-3xl"
                  style={{
                    color: invoiceSetting?.first_code || "orange",
                  }}
                >
                  INVOICE
                </h2>
                <p className="text-white text-sm mt-1">
                  ID NO : {invoice.invoice_id}
                </p>
                {/* <p className="text-white text-sm">Date : {formattedDateTime}</p> */}
              </div>
            </div>

            {/* Bottom Border */}
            <div className="absolute bottom-0 left-0 w-full h-4 bg-white" />
          </div>
          <div className="absolute bottom-0 right-0 w-1/3 h-full bg-orange-300 -z-10 transform skew-x-12 origin-bottom-right" />
        </div>

        {/* Invoice Details */}
        <div className="grid grid-cols-3 gap-4 mt-4">
          {/* Invoice To Section */}
          <div className="flex flex-col justify-start space-y-2">
            <h3
              className={`border-t-4 border-l-4 text-white px-4 py-1 inline-block ${
                invoiceSetting?.second_code ? "" : "bg-gray-900"
              }`}
              style={{
                backgroundColor: invoiceSetting?.second_code || undefined,
                borderColor: invoiceSetting?.first_code
                  ? invoiceSetting.first_code
                  : "orange",
              }}
            >
              Invoice To:
            </h3>
            <div className="mt-2">
              <p className="font-semibold">{invoice.customer_name}</p>
              <p className="text-gray-600">
                Phone: {invoice.country_code}
                {invoice.customer_phone}
              </p>
              <p className="text-gray-600">
                Customer ID: {invoice.customer_id}
              </p>
            </div>
          </div>

          {/* QR Code Section */}
          <div className="flex justify-center items-center pb-2">
            <div className="w-[45px] sm:w-[65px] md:w-[100px]">
              <QRCode
                className="w-full object-contain"
                value={BASE_URL + "/invoice-view/" + invoice?.invoice_id}
                size={100}
              />
            </div>
          </div>

          {/* Invoice From Section */}
          <div className="flex flex-col justify-between items-end space-y-2">
            <h3
              className={`border-t-4 border-l-4 text-white px-4 py-1 inline-block ${
                invoiceSetting?.second_code ? "" : "bg-gray-900"
              }`}
              style={{
                backgroundColor: invoiceSetting?.second_code || undefined,
                borderColor: invoiceSetting?.first_code
                  ? invoiceSetting.first_code
                  : "orange",
              }}
            >
              Invoice From:
            </h3>
            <div className="mt-2 text-right">
              <p className="font-semibold">{invoiceSetting?.shop_name}</p>
              <p className="text-gray-600">
                Phone: {invoice?.user_info?.phone}
              </p>
              <p className="text-gray-600">
                Address: {invoice.user_info.address}
              </p>
            </div>
          </div>
        </div>

        {/* Services Table */}
        <div className="mt-8 overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr
                style={{
                  backgroundColor: invoiceSetting?.first_code || "orange",
                }}
              >
                <th className="border-r-4 border-white text-center py-2 text-white">
                  #
                </th>
                <th className="border-r-4 border-white text-center py-2 text-white">
                  DESCRIPTION
                </th>
                <th className="border-r-4 border-white text-center py-2 text-white">
                  WARRANTY
                </th>
                <th className="border-r-4 border-white text-center py-2 text-white">
                  QTY
                </th>
                <th className="border-r-4 border-white text-center py-2 text-white">
                  PRICE
                </th>
                <th className="border-r-4 border-white text-center py-2 text-white">
                  TOTAL
                </th>
              </tr>
            </thead>
            <tbody className="divide-y-2 font-nunito font-semibold">
              {(() => {
                let rowCounter = 0;
                const processedProductIds = new Set();

                return invoice?.sales_details?.map((item) => {
                  const findImeis = invoiceImeis.filter(
                    (imei) => imei.product_id === item.product_id
                  );

                  if (processedProductIds.has(item.product_id)) {
                    return null;
                  }

                  processedProductIds.add(item.product_id);

                  let subtotalPrice = 0;
                  if (findImeis.length > 0) {
                    const qty = findImeis.length;
                    totalItemsPrintCustomize += qty;
                    subtotalPrice = findImeis.reduce(
                      (acc, prod) => acc + prod.sale_price,
                      0
                    );
                    totalPricePrintCustomize += subtotalPrice;
                  } else {
                    subtotalPrice = item.qty * item.price;
                    totalQty += Number(item.qty);
                    totalItemsPrintCustomize += 1;
                    totalPricePrintCustomize += Number(subtotalPrice);
                  }

                  return findImeis.length > 0 ? (
                    findImeis.map((imei, index) => (
                      <tr
                        key={imei.id}
                        className="text-sm  border-b-2 border-gray-200"
                      >
                        <td className="py-1 px-4 text-center">
                          {++rowCounter}
                        </td>
                        <td className="py-1 px-4">
                          {imei.product_name} {imei.optional_name}
                          <br />
                          <span className="text-[10px]">{imei.imei}</span>
                        </td>
                        <td className="py-1 px-4 text-center">
                          {invoice.defaultwarranties?.map(
                            (warrantyItem) =>
                              warrantyItem.invoice_id === invoice.invoice_id &&
                              warrantyItem.product_id === item.product_id &&
                              (warrantyItem.warranty_id == null ? (
                                <span key={warrantyItem.id}>
                                  {warrantyItem.default_warranties_count || ""}{" "}
                                  {item.product_info.warrenty || ""}
                                </span>
                              ) : (
                                <span key={warrantyItem.id}>
                                  {warrantyItem.warranty?.warranties_count ||
                                    ""}{" "}
                                  {warrantyItem.warranty?.name || ""}
                                </span>
                              ))
                          )}
                        </td>
                        <td className="py-1 px-4 text-center">1</td>
                        <td className="py-1 px-4 text-right">
                          {formatPrice(imei.sale_price)}
                        </td>
                        <td className="py-1 px-4 text-right">
                          {formatPrice(imei.sale_price)}
                          {invoiceSetting?.currency_info?.code || " BDT"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr key={item.id} className="text-sm">
                      <td className="py-1 px-4 text-center">{++rowCounter}</td>
                      <td className="py-1 px-4">
                        {item.product_info.name}
                        <br />
                        <span className="text-[10px]">
                          {item.product_info.serial}
                        </span>
                      </td>
                      <td className="py-1 px-4 text-center">
                        {invoice.defaultwarranties?.map(
                          (warrantyItem) =>
                            warrantyItem.invoice_id === invoice.invoice_id &&
                            warrantyItem.product_id === item.product_id &&
                            (warrantyItem.warranty_id == null ? (
                              <span key={warrantyItem.id}>
                                {warrantyItem.default_warranties_count || ""}{" "}
                                {item.product_info.warrenty || ""}
                              </span>
                            ) : (
                              <span key={warrantyItem.id}>
                                {warrantyItem.warranty?.warranties_count || ""}{" "}
                                {warrantyItem.warranty?.name || ""}
                              </span>
                            ))
                        )}
                      </td>
                      <td className="py-1 px-4 text-center">{item.qty}</td>
                      <td className="py-1 px-4 text-right">
                        {formatPrice(item.price)}
                      </td>
                      <td className="py-1  text-right">
                        {formatPrice(subtotalPrice)}{" "}
                        {invoiceSetting?.currency_info?.code || " BDT"}
                      </td>
                    </tr>
                  );
                });
              })()}
            </tbody>
          </table>
        </div>

        {/* Footer */}
        {/* Footer */}
        <div className="mt-8 flex justify-between gap-8">
          {/* Left: Paid By Section */}
          <div className="flex-1 relative">
            {invoice?.multiple_payment?.length > 0 ? (
              <div>
                {(() => {
                  const grandTotal = parseFloat(
                    (
                      Number(totalPrice) +
                      Number(invoice?.vat || 0) +
                      Number(invoice?.tax || 0) +
                      Number(invoice?.delivery_fee || 0)
                    ).toFixed(2)
                  );
                  const paidAmount = Number(
                    invoice.paid_amount && invoice.paid_amount !== 0
                      ? invoice.paid_amount
                      : 0
                  );
                  const dueAmount = grandTotal - paidAmount;

                  const isFullyPaid = dueAmount === 0;
                  const isFullyDue = paidAmount === 0;
                  const isPartiallyPaid = paidAmount > 0;

                  return (
                    <span
                      style={{
                        letterSpacing: "0.08em",
                        transform: "rotate(-45deg)",
                        display: "inline-block",
                        position: "absolute",
                        opacity: 1,
                        fontSize: "14px",
                        color: "gray",
                        top: "50%",
                        left: "50%",
                        transformOrigin: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {isFullyDue ? (
                        "The amount is fully due"
                      ) : (
                        <>
                          Paid by{" "}
                          {invoice?.multiple_payment
                            ?.map((payment) => payment.payment_type.type_name)
                            .join(", ")
                            .replace(/, ([^,]*)$/, " and $1")}{" "}
                          {isFullyPaid ? "" : "Partially"}
                        </>
                      )}
                    </span>
                  );
                })()}
              </div>
            ) : (
              <span>No payment information available</span>
            )}
          </div>

          {/* Right: Subtotal Section */}
          <div className="flex-1 text-right">
            <p className="flex justify-between">
              <span className="font-semibold font-nunito">Subtotal:</span>
              <span className="font-semibold font-nunito">
                {formatPrice(totalPricePrintCustomize)}{" "}
                {invoiceSetting?.currency_info?.code || " BDT"}
              </span>
            </p>
            <p className="flex justify-between ">
              <span className="font-semibold font-nunito">Tax:</span>
              <span className="font-semibold font-nunito">
                {formatPrice(invoice.tax || 0)}{" "}
                {invoiceSetting?.currency_info?.code || " BDT"}
              </span>
            </p>
            <p className="flex justify-between">
              <span className="font-semibold font-nunito">Discount:</span>
              <span className="font-semibold font-nunito">
                {formatPrice(invoice.discount || 0)}{" "}
                {invoiceSetting?.currency_info?.code || " BDT"}
              </span>
            </p>
            <div
              className="mt-4  text-white px-4 py-2 inline-block"
              style={{
                backgroundColor: invoiceSetting?.first_code || "orange",
              }}
            >
              <span className="font-bold">
                TOTAL:{" "}
                {formatPrice(
                  Number(totalPricePrintCustomize) +
                    Number(invoice?.vat || 0) +
                    Number(invoice?.tax || 0) +
                    Number(invoice?.delivery_fee || 0) -
                    Number(invoice?.discount || 0)
                )}{" "}
                {invoiceSetting?.currency_info?.code || " BDT"}
              </span>
            </div>
          </div>
        </div>

        {/* Amount in words */}
        {/* <p className="text-gray-900 mt-4 font-nunito text-sm font-semibold text-right ">
          <span className="text-gray-500">In Word:</span>{" "}
          {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))}{" "}
          taka only
        </p> */}

        {/* Savings Info */}
        {/* <div
          style={
            invoiceSetting?.dummy_text
              ? {
                  backgroundColor: invoiceSetting.dummy_text,
                  color: "white",
                  WebkitPrintColorAdjust: "exact",
                  padding: "10px",
                  textAlign: "center",
                  margin: "13px 0",
                }
              : {
                  backgroundColor: "#407BFF",
                  color: "white",
                  WebkitPrintColorAdjust: "exact",
                  padding: "10px",
                  textAlign: "center",
                  margin: "13px 0",
                }
          }
        >
          <p className="text-white font-nunito text-sm font-bold">
            You saved {formatPrice(invoice.discount || 0)}
            {invoiceSetting?.currency_info?.code || " BDT"} in this purchase
          </p>
        </div> */}

        {/* QR Code */}

        {/* Terms and Signature */}
        <div className="flex justify-between space-x-5 text-[10px] lg:text-sm mt-4">
          <div className="w-1/2">
            <h3 className="text-[10px] font-semibold text-gray-800 mb-2">
              Terms and Conditions
            </h3>
            <ul className="list-disc list-inside text-gray-700 text-[10px]">
              {inputs?.map((item, index) => (
                <li key={item.id || index}>{item.description}</li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col justify-end items-end ">
            <div className=" flex flex-col justify-end items-start ">
              <div className="flex flex-col items-center mt-4">
                <div className="border-t border-gray-400 ">
                  <p className="font-bold">Customer Signature</p>
                </div>
              </div>
              <div className="flex flex-col items-center mt-5">
                <div className="border-t border-gray-400">
                  <p className="font-bold">Authorized Signature</p>
                </div>
              </div>
            </div>
            <div className="flex justify-start mt-2">
              <QRCode
                className="w-[50px] object-contain"
                value={
                  invoice?.user_info?.web_address ||
                  invoice?.user_info?.invoice_settings?.web_address ||
                  "No Web Address set to the QR code"
                }
                size={50}
              />
            </div>
          </div>
        </div>

        {/* Thank You Message */}
        {/* <div className="mt-8 text-center">
          <h4 className="text-orange-500 text-xs font-semibold">
            Thanks for your business
          </h4>
          <p className="text-gray-600 mt-2 text-xs">
            {invoiceSetting?.footer_text ||
              "We appreciate your business. Have a great day!"}
          </p>
        </div> */}

        {/* Bottom Stripe */}
        <div className="bottom-0 left-0 right-0 p-4">
        <div className="relative h-16 mt-8">
          <div className="absolute bottom-0 left-0 w-full h-full bg-white"></div>
          <div
            className="absolute bottom-0 left-0 w-full h-4 "
            style={{
              backgroundColor: invoiceSetting?.first_code || "orange",
            }}
          ></div>
          <div
            className="absolute bottom-0 right-0 w-1/4 h-12  clip-custom"
            style={{
              backgroundColor: invoiceSetting?.second_code || "black",
            }}
          ></div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default PdfHtmlContentCustomize;
