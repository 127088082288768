import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Swal from "sweetalert2";
import FormPayment from "../../forms/FormPayment";
import BarcodeReader from "react-barcode-reader";
import { get } from "../../services/apiService";
import DatePicker from "react-datepicker";

import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import warrantyIcon from "./../../assets/images/warranty-icon.png";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineSearch,
} from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { Modal } from "../../utils/Modal";
import "../OrderProduct/OrderProduct.css";
//import categoryDymmyImaage from "../../assits/product1.png";
import { GrFormClose } from "react-icons/gr";
import Pagination from "../../utils/pagination"; // Adjust the path accordingly
import { useFlashMessage } from "../../context/FlashMessageContext";
import { AppContext } from "../../context/context.js";
import InvoiceCustomerForm from "../../forms/InvoiceCustomerForm.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import cardIcon from "../../assets/images/card.svg";
import moneyIcon from "../../assets/images/coin-hand.svg";
import qrIcon from "../../assets/images/qr_code.svg";
import wholeSale from "../../assets/images/wholesale.png";
import { formatPrice, eclipse, formatNumber } from "../../utils/Common";
import ItemSelectBox from "../../forms/ItemSelectBox.js";
import EditFormPayment from "../../forms/EditFormPayment.js";
import { useLocation } from "react-router-dom";
import CustomerInfoModal from "./CustomerInfoModal.js";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className="p-[6px] rounded-full absolute top-[40%] -right-[8px]"
      style={{ ...style, display: "inline-block", background: "#7F8098" }}
      onClick={onClick}
    >
      <AiOutlineArrowRight className="text-[#FFF] text-base font-bold"></AiOutlineArrowRight>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="p-[6px] rounded-full absolute top-[40%] -left-[9px] z-50"
      style={{ ...style, display: "inline-block", background: "#7F8098" }}
      onClick={onClick}
    >
      <AiOutlineArrowLeft className="text-[#FFF] text-base font-bold"></AiOutlineArrowLeft>
    </div>
  );
}
const BASE_URL = process.env.REACT_APP_ROOT_URL;
const shop_logo = `${BASE_URL}/layoutlogo.svg`;

const EditOrderProduct = (props) => {
  const {
    type,
    invoiceData,
    salesType,
    initialDiscount,
    setInitialDiscount,
    paidAmount,
    setPaidAmount,
  } = props;

  // console.log("Invoice Data: ", invoiceData);

  const [isShowModal,setIsShowModal] = useState(false);
  const location = useLocation();

  const { state, setPaperSize, setDeliveryMode, updateBrandList } =
    useContext(AppContext);

  const [paymentList, setPaymentList] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);
  const [deliveryAccountList, setDeliveryAccountList] = useState([]);
  const [isSave, setIsSave] = useState(1);
  const [selectedImei,setSelectedImei] = useState(null);
  const [status, setStatus] = useState(1);
  const [transactionDate, setTransactionDate] = useState(
    invoiceData.created_at
  );
  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage(); // Retrieve the dispatch function from your state management library

  const { currentUser, invoiceSetting } = state;
  const [category, setCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [filterItem, setFilterItem] = useState([]);
  const [invoice, setInvoice] = useState(invoiceData);
  const [customer, setCustomer] = useState(invoiceData?.customers);
  const [seller, setSeller] = useState(invoiceData?.saler);
  const [vendor, setVendor] = useState(invoiceData?.vendor);
  const [warningMessage, setWarningMessage] = useState(false);
  const [vat, setVat] = useState(
    invoiceSetting?.vat != "" ? Number(invoiceSetting?.vat) : 0
  );
  const [tax, setTax] = useState(
    invoiceSetting?.tax != "" ? Number(invoiceSetting?.tax) : 0
  );
  const [moodActive, setMoodActive] = useState(null);
  const [sizeActive, setSizeActive] = useState(null);
  const [sugarActive, setSugarActive] = useState(null);
  const [iceActive, setIceActive] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [selectedFood, setSelectedFood] = useState(null);
  // const [orderList, setOrderList] = useState(invoiceData ? invoiceData.sales_details : []);
  const [orderList, setOrderList] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [moreDiscount, setMoreDiscount] = useState(0);
  const [totalMoreDiscount, setTotalMoreDiscount] = useState(initialDiscount);
  const [selectedDiscountType, setSelectedDiscountType] = useState();
  const [grandTotal, setGrandTotal] = useState(
    invoiceData.sub_total + invoiceData.vat + invoiceData.tax
  );
  const [subTotalNew, setSubTotalNew] = useState(invoiceData.sub_total);
  const [grandTotalAfterDiscount, setGrandTotalDiscounted] = useState(
    invoiceData.sub_total +
      invoiceData.vat +
      invoiceData.tax -
      invoiceData.discount
  );
  const [deliveryAmount, setDeliveryAmount] = useState(
    invoiceData.delivery_fee ? invoiceData.delivery_fee : "0.00"
  );
  const [change, setChange] = useState(false);
  const [loader, setLoader] = useState(true);
  const [paymentAccountList, setPaymentAccountList] = useState([]);
  const [due, setDue] = useState(
    invoiceData.sub_total -
      invoiceData.discount +
      invoiceData.vat +
      invoiceData.tax -
      invoiceData.paid_amount
  );

  // console.log(filteredProducts)
  // const [paidAmount, setPaidAmount] = useState(invoiceData.paid_amount);
  // const [selectedProductWarrantyList, setSelectedProductWarrantyList] = useState([]);
  const [selectedProductWarrantyList, setSelectedProductWarrantyList] =
    useState([]);
  const [warrantyModal, setWarrantyModal] = useState(false);
  const [warrantyList, setWarrantyList] = useState([]);
  const [searchVarientItem, setSearchVarientItem] = useState("");

  const [selectedItem, setSelectedItem] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));
  const locationPurchase = useLocation();
  const typeUrl = locationPurchase.pathname.includes("purchase-invoice");

  const [selectedImeis, setSelectedImeis] = useState([]);
  const [imeis, setImeis] = useState([]);

  const handleSelectedItemQty129 = (item, field, e) => {
    // console.log(orderList);
    let qty = e.target.value;
    // console.log(qty);
    // if(isNaN(qty)){
    //   qty = 0
    // }

    // console.log(qty);
    const modifiedQty = qty.split("x")[1];
    const parsedQty = parseInt(modifiedQty, 10);
    const parsedPrice = parseInt(e.target.value);
    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.id === item.id
    );

    if (
      orderList[existingIndex] &&
      orderList[existingIndex].current_stock < parsedQty
    ) {
      showModalWarning("This product is stockout!", item);
      return;
    }

    if (field === "qty") {
      if (existingIndex !== -1) {
        const updatedOrderList = [...orderList];
        updatedOrderList[existingIndex].qty = parsedQty;
        setOrderList(updatedOrderList);
      }
    }

    if (field === "price") {
      if (existingIndex !== -1) {
        const updatedOrderList = [...orderList];
        if (type === "billing") {
          updatedOrderList[existingIndex].price = parsedPrice;
          updatedOrderList[existingIndex].product_info.retails_price =
            parsedPrice;
          setOrderList(updatedOrderList);
        }
        updatedOrderList[existingIndex].price = parsedPrice;
        setOrderList(updatedOrderList);
      }
    }
  };

  useEffect(() => {
  }, [type]);

  const handleCloseModal = () => setIsShowModal(false);

  // useEffect(()=>{console.log("selectedFood", selectedFood);},[selectedFood])

  useEffect(() => {
    const newData = location.pathname.startsWith("/purchase-invoice/edit")
      ? invoiceData.purchase_details
      : invoiceData.sales_details;
    console.log("newData ", newData);
    const newDataList = [];
    newData?.map((item) => {
      // const newItem = filteredProducts.filter( (t)=>t.id== item.product_id );
      newDataList.push({ ...item, id: item.product_id, product_id: item.id });
      // newDataList.push(newItem)
    });
    // console.log("mapping Data", newDataList);
    setOrderList(newDataList);
  }, []);

  useEffect(() => {
    const uniqueMap = new Set();
    const warrantyData = [];
    invoiceData?.defaultwarranties?.map((item) => {
      if (!uniqueMap.has(item?.product_id)) {
        uniqueMap.add(item?.product_id);
        const newWarranty = invoiceData.defaultwarranties
          .filter((t) => t.warranty && t.product_id === item.product_id)
          .map((t) => t.warranty);
        if (newWarranty !== "null") {
          warrantyData.push({ id: item.product_id, warranty: newWarranty });
        }
      }
    });
    setSelectedProductWarrantyList(warrantyData);
  }, [warrantyModal]);

  const [isMobile, setIsMobile] = useState(false);
  const MAX_MOBILE_SCREEN_WIDTH = 767;
  let subtotal = 0;
  // Calculate totals

  // useEffect(()=>{
  //   setDeliveryAmount(invoiceData.delivery_fee)
  // }, [type])

  useEffect(() => {
    if (orderList.length == 0) {
      setSubTotalNew(0);
      setGrandTotal(0);
      setGrandTotalDiscounted(0);
      setPaidAmount(0);
      setTotalMoreDiscount(0);
      setMoreDiscount(0);
      setDue(0);
      setMoney("");
      return;
    }
    // console.log("order List", invoiceData);/
    let discountTotal = 0;
    let subtotaln = 0;
    console.log("orderList ", orderList);
    orderList?.forEach((orderFood) => {
      let discount = 0;
      if (
        type == "purchase" ||
        (type == "billing" && salesType == "wholesale")
      ) {
        discount = 0;
      } else {
        if (
          (orderFood?.product_info?.discount_type === "Percentage" ||
            orderFood?.product_info?.discount_type === "2") &&
          orderFood?.product_info?.discount
        ) {
          // Discount is specified under product_info
          discount = formatPrice(
            orderFood?.product_info.retails_price *
              (orderFood?.product_info.discount / 100)
          );
        } else if (
          (orderFood?.discount_type === "Percentage" ||
            orderFood?.discount_type === "2") &&
          orderFood?.discount
        ) {
          // Discount is specified under orderFood
          discount = formatPrice(
            orderFood?.retails_price * (orderFood?.discount / 100)
          );
        } else {
          // No discount found
          discount =
            orderFood?.product_info?.discount || orderFood?.discount || 0;
        }
      }
      let subtotalPrice = 0;
      if (type == "purchase") {
        subtotalPrice = formatPrice(
          orderFood.qty * ((orderFood?.price || orderFood?.price) - discount)
        );
        subtotaln += Number(subtotalPrice);
      } else if (invoiceData.sales_type == "wholesale") {
        // subtotalPrice = formatPrice(
        //   orderFood.qty *
        //     ((orderFood?.product_info?.wholesale_price ||
        //       orderFood?.wholesale_price) -
        //       discount)
        // );
        subtotalPrice = formatPrice(
          orderFood.qty *
            (orderFood?.product_info?.wholesale_price ||
              orderFood?.wholesale_price)
        );
        subtotaln += Number(subtotalPrice);
      } else {
        // subtotalPrice = formatPrice(
        //   orderFood.qty *
        //     ((orderFood?.price ||
        //       orderFood?.price) -
        //       discount)
        // );

        subtotalPrice =
          user.type == 8
            ? formatPrice(orderFood.qty * orderFood?.price)
            : formatPrice(
                orderFood.qty *
                  ((orderFood?.price || orderFood?.retails_price) - discount)
              );
        subtotaln += Number(subtotalPrice);
      }
      //discountTotal += formatPrice(orderFood.current_stock * discount);
    });
    // console.log("billing", type, salesType);

    setSubTotalNew(subtotaln);
    const vats = vat && vat > 0 ? (vat / 100) * subtotaln : 0;
    const taxes = tax && tax > 0 ? (tax / 100) * subtotaln : 0;
    const grandTotalnew = formatPrice(subtotaln + Number(vats) + Number(taxes));
    setGrandTotal(grandTotalnew);
    setGrandTotalDiscounted(
      formatPrice(
        Number(grandTotalnew) + Number(deliveryAmount) - Number(initialDiscount)
      )
    );

    // setPaidAmount(formatPrice(paidAmount));
    setDue(
      Number(grandTotalnew) +
        Number(deliveryAmount) -
        Number(paidAmount) -
        Number(initialDiscount)
    );
  }, [orderList, initialDiscount]);

  useEffect(() => {
    setGrandTotalDiscounted(
      Number(grandTotalAfterDiscount) + Number(deliveryAmount)
    );
    setDue(Number(grandTotalAfterDiscount) - Number(paidAmount));
  }, [deliveryAmount]);

  // console.log("paid amount", paidAmount);

  useEffect(() => {
    setPaidAmount(paidAmount);
    setDue(Number(grandTotalAfterDiscount) - paidAmount);
  }, [paidAmount]);

  const notify = (msg, type) => {
    if (type == "success") {
      toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
    }
    if (type == "error") {
      toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
    }
  };

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH
    ) {
      setIsMobile(true);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const handleResize = () => {
    if (
      typeof window !== "undefined" &&
      document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleChangePaidAmount = (value) => {
    //if value is number then set the value
    if (!isNaN(value)) {
      // if (value <= Number(grandTotalAfterDiscount)) {
      console.log(value);
      if (value[0] == "0") {
        value = String(value).slice(1, value.length);
        setPaidAmount(value);
      } else {
        if (value > Number(grandTotalAfterDiscount)) {
          return;
        } else {
          setPaidAmount(value);
        }
      }
      setDue(Number(grandTotalAfterDiscount) - value);
      // }
      // else {
      //   return;
      // }
    } else {
      return;
    }
  };
  // useEffect(()=>{
  //   handleChangePaidAmount(paidAmount)
  // },[paidAmount])

  const handleChangeDiscount = (value) => {
    // if not integer then return
    if (isNaN(value)) {
      setMoreDiscount();
      setInitialDiscount();
      return;
    }
    if (selectedDiscountType === "Percentage" || selectedDiscountType === "2") {
      // Check if the discount percentage is 100%
      if (value > 100) {
        // Set the discount equal to the grand total
        setTotalMoreDiscount(0);
        setMoreDiscount(0);
        setInitialDiscount(0);
      } else {
        // Apply other percentage discount
        setTotalMoreDiscount(formatPrice(Number(grandTotal) * (value / 100)));
        setMoreDiscount(value);
        setInitialDiscount(value);
      }
    } else {
      // If discount type is not percentage, set the discount directly
      //but discount should not be more than grand total
      if (value > Number(grandTotal)) {
        setTotalMoreDiscount(0);
        setMoreDiscount(0);
        setInitialDiscount(0);
      } else {
        setTotalMoreDiscount(value);
        setMoreDiscount(value);
        setInitialDiscount(value);
      }
    }
  };

  const handleChangeDiscountType = (e) => {
    const { name, value } = e.target;
    setSelectedDiscountType(value);
  };
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const navigate = useNavigate();

  const [money, setMoney] = useState(invoiceData?.pay_mode);

  let vats = 0;
  let taxes = 0;
  let discountTotal = 0;
  //let grandTotal = 0;
  const discountType = ["Fixed", "Percentage"];
  const token = localStorage.getItem("token");

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedValue, setSelectedValue] = useState("A4");
  const [firstTime, setfirstTime] = useState(true);
  const perPage = 10;

  const [prevSearchKeyword, setPrevSearchKeyword] = useState("");

  const getTopSaleProductlist = (page, searchKeyword = null) => {
    if (searchKeyword) {
      //setCurrentPage(1);
      if (firstTime) {
        //loadingCallback(true);
        setfirstTime(false);
      }
      getFilteredProductlist(page, searchKeyword);
    } else {
      if (firstTime) {
        setLoader(true);
        //loadingCallback(true);
        setfirstTime(false);
      }
      axios
        .get(
          `${process.env.REACT_APP_API_URL}top-sales?page=${page}&limit=${perPage}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setLoader(false);
          if (response.data.success) {
            loadingCallback(false);
            setFilterItem(response.data.data.data);
            setFilteredProducts(response.data.data.data);
            setPagination(response.data.data);
            setSelectedFood(response.data.data.data[0]);
            if (response.data.data.data[0]?.image_path) {
              setSelectedFoodImg(response.data.data.data[0].image_path);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    }
  };
  useEffect(() => {
    // Check if the search keyword has changed
    const isSearchKeywordChanged = searchKeyword !== prevSearchKeyword;

    // Update the previous search keyword
    setPrevSearchKeyword(searchKeyword);

    // If it's a new search keyword, set the page to 1
    if (isSearchKeywordChanged) {
      setCurrentPage(1);
      setfirstTime(true);
    } else {
      // It's the same search keyword, proceed with your logic
      if (firstTime) {
        setfirstTime(false);
      }
      getTopSaleProductlist(currentPage, searchKeyword);
    }
  }, [currentPage, searchKeyword, prevSearchKeyword, firstTime]);

  useEffect(() => {
    // getProductData();
    const updateProduct = filterItem.filter((curElement) => {
      return curElement?.category_id === category;
    });
    //setFilteredProducts(updateProduct);
  }, [category, filterItem]);
  const [selectedPaymentAccountList, setSelectedPaymentAccountList] = useState(
    invoiceData?.multiple_payment
  );
  const [filteredAccountList, setFilteredAccountList] = useState({});

  const getPayment = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}payment-type-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setPaymentList(response.data.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };

  useEffect(() => {
    getPayment();
    getPaymentAccountList();
    getWarranty();
    getDelivery();
    getDeliveryAccountList();
  }, [currentPage]);

  const getPaymentAccountList = () => {
    const token = localStorage.getItem("token");
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}payment-type-category-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setPaymentAccountList(response.data.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };

  const handleSelectChange = (event) => {
    setChange(true);
    setSelectedValue(event.target.value);
    setPaperSize(event.target.value);
  };

  const getFilteredProductlist = (page, searchKeyword) => {
    //loadingCallback(true);
    const token = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}search-product?page=${page}&limit=${perPage}`,
        { keyword: searchKeyword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          //setCategoryData(response.data.data.data);
          setFilterItem(response.data.data.data);
          setFilteredProducts(response.data.data.data);
          setPagination(response.data.data);
          setSelectedFood(response.data.data.data[0]);
          if (response.data.data.data[0]?.image_path) {
            setSelectedFoodImg(response.data.data.data[0].image_path);
          }
        } else {
          console.log("Data Not Found!");
        }
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  const showModalWarning = (msg, data = null) => {
    Swal.fire({
      title: msg,
      text: "",
      icon: "warning",
    });
  };

  // const handleSelectedFood = (item, order = "increment") => {
  //   if (item?.product_type != 'service' && type != "purchase" && item.current_stock == 0) {
  //     showModalWarning("This product is stockout!", item);
  //     return;
  //   }

  //   const existingIndex = orderList.findIndex(
  //     (orderItem) => orderItem?.id === item?.id
  //   );
  //   if (order == "decrement") {
  //     // Item already exists, update its current_stock
  //     const updatedOrderList = [...orderList];
  //     if (updatedOrderList[existingIndex].qty == 1) {
  //       handleDelete(existingIndex);
  //     } else {
  //       updatedOrderList[existingIndex].qty -= 1;
  //       setOrderList(updatedOrderList);
  //     }
  //   } else {
  //     //const current_stock = item.current_stock;
  //     if (existingIndex !== -1) {
  //       // Item already exists, update its current_stock
  //       const updatedOrderList = [...orderList];

  //       if (
  //         item?.product_type != 'service' &&
  //         type != "purchase" &&
  //         item.current_stock < updatedOrderList[existingIndex].qty + 1
  //       ) {
  //         showModalWarning("This product is stockout!", item);
  //         return;
  //       } else {
  //         updatedOrderList[existingIndex].qty += 1;
  //         setOrderList(updatedOrderList);
  //       }
  //     } else {
  //       item.qty = 1;
  //       // Item doesn't exist, add it to the orderList with current_stock 1
  //       let newOrderArr = [...orderList, item];
  //       setOrderList(newOrderArr);

  //       const value = {
  //         id: item?.id,
  //         warranty: [
  //           {
  //             id: "",
  //             product_id: item?.id,
  //             warranty_id: "",
  //             warranties_count: item.warranties_count,
  //             name: item.warrenty,
  //           },
  //         ],
  //       };

  //       // setSelectedProductWarrantyList([...selectedProductWarrantyList, value])
  //       if (selectedProductWarrantyList && selectedProductWarrantyList.length) {
  //         const newWarrantyList = [...selectedProductWarrantyList, value];
  //         setSelectedProductWarrantyList(newWarrantyList);
  //       } else {
  //         setSelectedProductWarrantyList([value]);
  //       }
  //     }
  //     //item.current_stock -= 1;
  //     if(item?.product_info){
  //       const indexItem = filteredProducts.findIndex((t)=> t.id == item.id);
  //       const newItem = {...filteredProducts[indexItem]}
  //       setSelectedFood(newItem);
  //       console.log("newFoodItem", newItem);
  //       if (newItem?.image_path) {
  //         setSelectedFoodImg(newItem.image_path);
  //       }
  //     }
  //     else {
  //       setSelectedFood(item);
  //       if (item?.image_path) {
  //         setSelectedFoodImg(item.image_path);
  //       }
  //     }
  //   }
  // };

  // added by mushfique
  // const handleSelectedFood = (item, order = "increment") => {
  //   if (
  //     item?.product_type != "service" &&
  //     type != "purchase" &&
  //     item.current_stock == 0
  //   ) {
  //     showModalWarning("This product is stockout!", item);
  //     return;
  //   }

  //   const existingIndex = orderList.findIndex(
  //     (orderItem) => orderItem?.id === item?.id
  //   );
  //   if (order == "decrement") {
  //     // Item already exists, update its current_stock

  //     // late edit for specific user
  //     const updatedOrderList = [...orderList];
  //     // if (updatedOrderList[existingIndex].qty == 1) {
  //     //   handleDelete(existingIndex);
  //     // } else {
  //     //   updatedOrderList[existingIndex].qty -= 1;
  //     //   setOrderList(updatedOrderList);
  //     // }

  //     if (updatedOrderList[existingIndex].qty == 1) {
  //       handleDelete(existingIndex);
  //     } else {
  //       updatedOrderList[existingIndex].qty -= 1;
  //       setOrderList(updatedOrderList);
  //     }
  //   } else {
  //     //const current_stock = item.current_stock;
  //     if (existingIndex !== -1) {
  //       // Item already exists, update its current_stock
  //       const updatedOrderList = [...orderList];

  //       // if (
  //       //   item?.product_type != 'service' &&
  //       //   type != "purchase" &&
  //       //   item.current_stock < updatedOrderList[existingIndex].qty + 1
  //       // ) {
  //       //   showModalWarning("This product is stockout!", item);
  //       //   return;
  //       // } else {
  //       //   updatedOrderList[existingIndex].qty += 1;
  //       //   setOrderList(updatedOrderList);
  //       // }

  //       if (
  //         item?.product_type != "service" &&
  //         type != "purchase" &&
  //         item.current_stock < updatedOrderList[existingIndex].qty + 1
  //       ) {
  //         showModalWarning("This product is stockout!", item);
  //         return;
  //       } else {
  //         updatedOrderList[existingIndex].qty += 1;
  //         setOrderList(updatedOrderList);
  //       }
  //     } else {
  //       item.qty = 1;
  //       // Item doesn't exist, add it to the orderList with current_stock 1
  //       let newOrderArr = [...orderList, item];
  //       setOrderList(newOrderArr);

  //       const value = {
  //         id: item?.id,
  //         warranty: [
  //           {
  //             id: "",
  //             product_id: item?.id,
  //             warranty_id: "",
  //             warranties_count: item.warranties_count,
  //             name: item.warrenty,
  //           },
  //         ],
  //       };

  //       // setSelectedProductWarrantyList([...selectedProductWarrantyList, value])
  //       if (selectedProductWarrantyList && selectedProductWarrantyList.length) {
  //         const newWarrantyList = [...selectedProductWarrantyList, value];
  //         setSelectedProductWarrantyList(newWarrantyList);
  //       } else {
  //         setSelectedProductWarrantyList([value]);
  //       }
  //     }
  //     //item.current_stock -= 1;
  //     if (item?.product_info) {
  //       const indexItem = filteredProducts.findIndex((t) => t.id == item.id);
  //       const newItem = { ...filteredProducts[indexItem] };
  //       setSelectedFood(newItem);
  //       console.log("newFoodItem", newItem);
  //       if (newItem?.image_path) {
  //         setSelectedFoodImg(newItem.image_path);
  //       }
  //     } else {
  //       setSelectedFood(item);
  //       if (item?.image_path) {
  //         setSelectedFoodImg(item.image_path);
  //       }
  //     }
  //   }
  // };

  const handleSelectedFood = (item, order = "increment") => {
    if (
      item?.product_type != "service" &&
      type != "purchase" &&
      item.current_stock == 0
    ) {
      showModalWarning("This product is stockout!", item);
      return;
    }

    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.id === item?.id
    );

    console.log(item);
    console.log(orderList);

    // return

    if (order == "decrement") {
      // Item already exists, update its current_stock

      // late edit for specific user
      const updatedOrderList = [...orderList];
      console.log(updatedOrderList);
      // return
      // if (updatedOrderList[existingIndex].qty == 1) {
      //   handleDelete(existingIndex);
      // } else {
      //   updatedOrderList[existingIndex].qty -= 1;
      //   setOrderList(updatedOrderList);
      // }

      if (updatedOrderList[existingIndex].qty == 1) {
        handleDelete(existingIndex);
      } else {
        updatedOrderList[existingIndex].qty -= 1;
        setOrderList(updatedOrderList);
      }
    } else {
      //const current_stock = item.current_stock;
      if (parseFloat(existingIndex) !== -1) {
        // Item already exists, update its current_stock
        const updatedOrderList = [...orderList];

        // return

        // if (
        //   item?.product_type != 'service' &&
        //   type != "purchase" &&
        //   item.current_stock < updatedOrderList[existingIndex].qty + 1
        // ) {
        //   showModalWarning("This product is stockout!", item);
        //   return;
        // } else {
        //   updatedOrderList[existingIndex].qty += 1;
        //   setOrderList(updatedOrderList);
        // }

        if (
          item?.product_type != "service" &&
          type != "purchase" &&
          item.current_stock < updatedOrderList[existingIndex].qty + 1
        ) {
          showModalWarning("This product is stockout!", item);
          return;
        } else {
          updatedOrderList[existingIndex].qty += 1;
          setOrderList(updatedOrderList);
        }
      } else {
        console.log("I am here");
        item.qty = 1;
        // Item doesn't exist, add it to the orderList with current_stock 1
        const newItem = {
          id: item.id,
          sales_id: null,
          invoice_id: null,
          product_id: item.id,
          qty: item.qty,
          price: item.retails_price.toString(),
          purchase_price: item.purchase_price.toFixed(2),
          mode: null,
          size: null,
          created_at: item.created_at,
          updated_at: item.updated_at,
          remaining_qty: item.qty,
          product_info: {
            id: item.id,
            name: item.name,
            barcode: item.barcode,
            category_id: item.category_id,
            others: item.others,
            sub_category_id: item.sub_category_id,
            brand_id: item.brand_id,
            unit_id: item.unit_id,
            currency_id: item.currency_id,
            image_path: item.image_path,
            image_path1: item.image_path1,
            image_path2: item.image_path2,
            image_path3: item.image_path3,
            purchase_price: item.purchase_price,
            wholesale_price: item.wholesale_price,
            retails_price: item.retails_price,
            regular_price: item.regular_price,
            discount_type: item.discount_type,
            discount: item.discount,
            description: item.description,
            warrenty: item.warrenty,
            quantity: item.quantity,
            discount_id: item.discount_id,
            warranty_id: item.warranty_id,
            deleted: item.deleted,
            user_id: item.user_id,
            created_at: item.created_at,
            updated_at: item.updated_at,
            product_id: item.product_id,
            have_variant: item.have_variant,
            serial: item.serial,
            color: item.color,
            warranties_count: item.warranties_count,
            manufactory_date: item.manufactory_date,
            expiry_date: item.expiry_date,
            is_variable_weight: item.is_variable_weight,
            minimum_stock: item.minimum_stock,
            product_type: item.product_type,
            is_ecommerce: item.is_ecommerce,
            color_code: item.color_code,
            is_specification: item.is_specification,
            brands: {
              id: item.brands.id,
              name: item.brands.name,
              description: item.brands.description,
              image_path: item.brands.image_path,
              is_topbrand: item.brands.is_topbrand,
              user_id: item.brands.user_id,
              created_at: item.brands.created_at,
              updated_at: item.brands.updated_at,
            },
            category: {
              id: item.category.id,
              name: item.category.name,
              description: item.category.description,
              user_id: item.category.user_id,
              created_at: item.category.created_at,
              updated_at: item.category.updated_at,
            },
            unit: {
              id: item.unit.id,
              name: item.unit.name,
              description: item.unit.description,
              user_id: item.unit.user_id,
              created_at: item.unit.created_at,
              updated_at: item.unit.updated_at,
            },
          },
          product_imei: item.imeis || [],
        };
        let newOrderArr = [...orderList, newItem];
        console.log(newOrderArr);

        setOrderList(newOrderArr);
        // return

        const value = {
          id: item?.id,
          warranty: [
            {
              id: "",
              product_id: item?.id,
              warranty_id: "",
              warranties_count: item.warranties_count,
              name: item.warrenty,
            },
          ],
        };

        // setSelectedProductWarrantyList([...selectedProductWarrantyList, value])
        if (selectedProductWarrantyList && selectedProductWarrantyList.length) {
          const newWarrantyList = [...selectedProductWarrantyList, value];
          setSelectedProductWarrantyList(newWarrantyList);
        } else {
          setSelectedProductWarrantyList([value]);
        }
      }
      //item.current_stock -= 1;
      if (item?.product_info) {
        const indexItem = filteredProducts.findIndex((t) => t.id == item.id);
        const newItem = { ...filteredProducts[indexItem] };
        setSelectedFood(newItem);
        console.log("newFoodItem", newItem);
        if (newItem?.image_path) {
          setSelectedFoodImg(newItem.image_path);
        }
      } else {
        setSelectedFood(item);
        if (item?.image_path) {
          setSelectedFoodImg(item.image_path);
        }
      }
    }
  };

  // for imei selection

  const variationForm = {
    product_id: "",
    name: "",
    serial: "",
    purchase_price: 0,
    wholesale_price: 0,
    retails_price: 0,
    discount_type: 0,
    storage: "",
    ram: "",
    battery_life: "",
    quantity: 1,
  };

  const handleSelectedFoodForImei = (newItem, order = "increment") => {
    // console.log("selected Product", item);
    const item = { ...newItem };

    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.imei_id === item?.imei_id
    );
    // return

    // Need to start working from it ====================================
    // Need to start working from it ====================================
    // Need to start working from it ====================================

    if (order == "decrement") {
      // Item already exists, update its current_stock
      const updatedOrderList = [...orderList];
      const parseQty =
        user.type == 8
          ? parseFloat(updatedOrderList[existingIndex].product129Qty)
          : parseFloat(updatedOrderList[existingIndex].qty);
      if (parseQty <= 1) {
        handleDelete(existingIndex);
      } else {
        const initialVariationData = Array.from(
          { length: Number.parseInt(parseQty - 1) },
          () => ({
            ...variationForm,
            product_id: item.id,
            purchase_price: item.purchase_price,
            wholesale_price: item.wholesale_price,
            retails_price: item.retails_price,
            storage: item.storage,
            ram: item.ram,
            battery_life: item.battery_life,
          })
        );
        updatedOrderList[existingIndex].variantsSum = 0;
        updatedOrderList[existingIndex].variants = initialVariationData;
        updatedOrderList[existingIndex].qty = parseQty - 1;
        setOrderList(updatedOrderList);
      }
    } else {
      //const current_stock = item.current_stock;
      if (existingIndex !== -1) {
        // Item already exists, update its current_stock
        const updatedOrderList = [...orderList];
        const parseQty =
          user.type == 8
            ? parseFloat(updatedOrderList[existingIndex].product129Qty)
            : parseFloat(updatedOrderList[existingIndex].qty);
        if (
          item?.product_type != "service" &&
          type != "purchase" &&
          item.stock_unit_no < parseQty + 1
        ) {
          showModalWarning("This product is stockout!", item);
          return;
        } else {
          const initialVariationData = Array.from(
            { length: Number.parseInt(parseQty + 1) },
            () => ({
              ...variationForm,
              product_id: item.id,
              purchase_price: item.purchase_price,
              wholesale_price: item.wholesale_price,
              retails_price: item.retails_price,
              storage: item.storage,
              ram: item.ram,
              battery_life: item.battery_life,
            })
          );
          updatedOrderList[existingIndex].variantsSum = 0;
          updatedOrderList[existingIndex].variants = initialVariationData;
          if (user.type == 8) {
            updatedOrderList[existingIndex].product129Qty = parseQty + 1;
            console.log(updatedOrderList[existingIndex].product129Qty);
          } else {
            updatedOrderList[existingIndex].qty = parseQty + 1;
          }
          setOrderList(updatedOrderList);
        }
      } else {
        item.qty = 1;
        // Item doesn't exist, add it to the orderList with current_stock 1
        const initialVariationData = Array.from(
          { length: Number.parseInt(1) },
          () => ({
            ...variationForm,
            product_id: item.id,
            purchase_price: item.purchase_price,
            wholesale_price: item.wholesale_price,
            retails_price: item.retails_price,
          })
        );
        item.variants = initialVariationData;
        item.variantsSum = 0;
        let newOrderArr = [...orderList, item];
        setOrderList(newOrderArr);

        const value = {
          id: item?.id,
          warranty: [
            {
              id: "",
              product_id: item?.id,
              warranty_id: "",
              warranties_count: item.warranties_count
                ? item.warranties_count
                : "",
              name: item.warrenty ? item.warrenty : "",
            },
          ],
        };

        // if (item?.warranties_count !== null && item?.warrenty !== null) {
        if (
          selectedProductWarrantyList &&
          selectedProductWarrantyList?.length
        ) {
          const newWarrantyList = [...selectedProductWarrantyList, value];
          setSelectedProductWarrantyList(newWarrantyList);
        } else {
          setSelectedProductWarrantyList([value]);
        }
        //   console.log("warranty1", value);
        //   setSelectedProductWarrantyList([value]);
        // }
        // else{
        //   console.log("warranty2", value);
        // }
      }
      //item.current_stock -= 1;
      setSelectedFood(item);
      if (item?.image_path) {
        setSelectedFoodImg(item.image_path);
      }
    }
  };

  const handleImeisSelect = (imei, selectedFood) => {
    // console.log("imei: ", imei, " || ", "selectedFood: ", selectedFood )

    // console.log(imei.imei);
    // setBarcodeImei(imei.imei);
    const newItems = { ...selectedFood };

    console.log(imei)

    // return
    newItems.checked = 1;

    // console.log("selectPrime", newItems, imei);
    //newItems.retails_price = imei.sale_price;

    // if(type === 'wholesale'){
    //   newItems.retails_price = imei?.currency_rate !== null
    //   ? Number.parseFloat(
    //     imei?.wholesale_price
    //   ).toFixed(2)
    //   : Number.parseFloat(
    //     imei?.wholesale_price
    //   ).toFixed(2);
    // newItems.currency_code = imei?.currency_rate !== null ? imei?.base_currency_code : imei.purchase_currency_code;
    // newItems.imei_id = imei.id;

    // const findImei = imeis.find(e => e.id === imei.id);
    // if (findImei) {
    //   handleSelectedFoodForImei(newItems, "decrement", 1);
    //   const newImeis = imeis.filter(e => e.id !== imei.id);
    //   // const transformArray =  newImeis.map( e=>({ id: e.id, product_id: e.product_id}))
    //   setImeis(newImeis)
    // }
    // else {
    //   handleSelectedFoodForImei(newItems, "increment", 1);
    //   setImeis(e => [...e, { id: imei.id, product_id: imei.product_id }]);
    // }
    // }
    newItems.retails_price =
      imei?.currency_rate !== null
        ? Number.parseFloat(imei?.sale_price).toFixed(2)
        : Number.parseFloat(imei?.sale_price).toFixed(2);
    newItems.currency_code =
      imei?.currency_rate !== null
        ? imei?.base_currency_code
        : imei.purchase_currency_code;
    //newItems.purchase_price = imei.purchase_price;

    newItems.purchase_price =
      imei?.currency_rate !== null
        ? Number.parseFloat(imei?.purchase_price).toFixed(2)
        : Number.parseFloat(imei?.purchase_price).toFixed(2);
    newItems.wholesale_price =
      imei?.currency_rate !== null
        ? Number.parseFloat(imei?.wholesale_price).toFixed(2)
        : Number.parseFloat(imei?.wholesale_price).toFixed(2);
    newItems.imei_id = imei.id;
    const findImei = imeis.find((e) => e.id === imei.id);
    if (findImei) {
      handleSelectedFoodForImei(newItems, "decrement", 1);
      const newImeis = imeis.filter((e) => e.id !== imei.id);
      // const transformArray =  newImeis.map( e=>({ id: e.id, product_id: e.product_id}))
      setImeis(newImeis);
    } else {
      handleSelectedFoodForImei(newItems, "increment", 1);
      setImeis((e) => [...e, { id: imei.id, product_id: imei.product_id }]);
    }
  };

  useEffect(() => {
    // console.log("imei list", imeis);
  }, [imeis]);

  // console.log(selectedFood);
  // Filter the IMEIs based on the search term
  const filteredImeis = selectedFood?.imeis?.filter((imei) =>
    imei?.imei?.includes(searchVarientItem)
  );

  const handleSelectedImeis = (item) => {
    // console.log(item);
    // console.log(item);
    setSelectedFood(item);
    if (item?.image_path) {
      setSelectedFoodImg(item.image_path);
    }
  };

  const toggleImeiSelection = (imei) => {
    const isSelected = selectedImeis.includes(imei.id);



    const updatedSelections = isSelected
      ? selectedImeis.filter((id) => id !== imei.id)
      : [...selectedImeis, imei.id];
    setSelectedImeis(updatedSelections);

    handleImeisSelect(imei, selectedFood); // You might need to update this logic if you handle selection differently
  };

  const [selectedFoodImg, setSelectedFoodImg] = useState(null);
  const handleSelectedFoodImage = (imgPath = "") => {
    setSelectedFoodImg(imgPath);
  };
  const handleSelectedFoodQty = (item, e) => {
    const qty = e.target.value.startsWith("x")
      ? e.target.value.slice(1)
      : e.target.value;
    const parsedQty = parseInt(qty, 10);
    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.id === item.id
    );
    if (qty && parsedQty && existingIndex !== -1 && !isNaN(parsedQty)) {
      const existingProductIndex = filteredProducts.filter(
        (filteredItem) => filteredItem?.id === item?.id
      );
      // Item already exists, update its current_stock
      const updatedOrderList = [...orderList];
      if (
        item?.product_type != "service" &&
        type != "purchase" &&
        existingProductIndex &&
        existingProductIndex[0] &&
        existingProductIndex[0].current_stock < parsedQty
      ) {
        showModalWarning("This product is stockout!", item);
        return;
      } else {
        updatedOrderList[existingIndex].qty = parsedQty;
        setOrderList(updatedOrderList);
      }
    } else {
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].qty = 0;
      setOrderList(updatedOrderList);
    }
  };
  //console.log(orderList)

  const handleSelectedFoodPrice = (item, price,idx) => {
    const newPriceStr = String(price);
    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.id === item?.id
    );
    const existingProduct = orderList.findIndex(
      (orderItem) => orderItem?.product_id === item?.product_id
    );
    // if(newPriceStr.slice(-1)=='.'){
    //   const updatedOrderList = [...orderList];
    //   updatedOrderList[existingIndex].purchase_price = newPriceStr;
    //   setOrderList(updatedOrderList);
    //   return;
    // }
    if (item?.product_imei && item?.product_imei.length > 0  && type !== "purchase") {
      const updatedOrderList = [...orderList];
      updatedOrderList[existingProduct].price = parseInt(price);
      updatedOrderList[existingProduct].product_imei[0].sale_price = parseInt(price);
      setOrderList(updatedOrderList);
      return;
    }
    else if(item?.imeis && item?.imeis.length > 0  && type !== "purchase"){
      const updatedOrderList = [...orderList];
      updatedOrderList[idx].retails_price = parseInt(price);
      setOrderList(updatedOrderList);
      return;
    }
    else if (type === "purchase") {
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].price = parseInt(price);
      updatedOrderList[existingIndex].product_info.purchase_price =
        parseInt(price);
      setOrderList(updatedOrderList);
    }

    const newPrice = parseFloat(newPriceStr) / parseInt(item.qty);
    if (price && newPrice && existingIndex !== -1 && !isNaN(newPrice)) {
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].purchase_price = newPrice;
      setOrderList(updatedOrderList);
    } else {
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].purchase_price = 0;
      setOrderList(updatedOrderList);
    }

    const updateTotalPrice = orderList.reduce((prev,curr) => prev + curr?.price ? curr.price : curr?.retails_price,0);
    setGrandTotalDiscounted(updateTotalPrice);
  };

  const [paymentData, setPaymentData] = useState([]);

  const handleClicks = (data) => {
    if (!orderList.length) {
      showModalWarning("Please Select A product");
      return;
    }
    // else{
    setShowPaymentModal(!showPaymentModal);
    setPaidAmount(0);
    let val = paymentList.find((items) => {
      return items?.type_name == data?.type_name;
    });
    setFilteredAccountList(val.payment_type_category);
    if (val) {
      setFormData({ ...formData, payment_id: data?.id });
      setMoney(data?.type_name);
    }
    if (showPaymentModal) {
      setPaymentData((prevPaymentData) => [...prevPaymentData, data]);

      // setSelectedPaymentAccountList([]);
    } else {
      setPaymentData([data]);
    }
    // }
  };

  const handlePurchesClicks = (active) => {
    if (active === "cash" || active === "card" || active === "qr") {
      return setMoney(active);
    }
  };

  const handleDelete = (index) => {
    console.log(index);
    const newOrderList = [...orderList];
    newOrderList.splice(index, 1); // Remove the item from the copied array
    setOrderList(newOrderList);

    if (selectedProductWarrantyList && selectedProductWarrantyList.length) {
      console.log("selected warranty", selectedProductWarrantyList);
      const newWarrantyList = [...selectedProductWarrantyList];
      newWarrantyList.splice(index, 1);
      setSelectedProductWarrantyList(newWarrantyList);
    }
  };
  //  const handleDelete=(index)=>{
  //   console.log(index)
  //   const newOrderList = [...orderList];
  //   newOrderList.splice(index, 1); // Remove the item from the copied array
  //   setOrderList(newOrderList);
  //  }

  // Calculate the number of products to show in the carousel
  const numberOfProducts = Math.min(filteredProducts.length - 2, 5); // Set a maximum number of 7 products

  let settings = {};

  if (isMobile) {
    settings = {
      className: "sliderArea w-[300px]",
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1, // Adjust slidesToShow based on the number of products
      slidesToScroll: 1, // Adjust slidesToScroll as well
      variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  } else {
    settings = {
      //className: "sliderArea w-[920px]",
      className:
        "sliderArea md:max-w-[550px] lg:max-w-[600px] 2xl:max-w-[800px]",
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 3,
      variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  }

  console.log(invoiceData);

  const [deliveryFormData, setDeliveryFormData] = useState({
    deliveryName: "",
    deliveryId: invoiceData.delivery_method_id,
    companyName: "",
    companyId: invoiceData.delivery_info_id,
    customerName: invoiceData.delivery_customer_name,
    customerAddress: invoiceData.delivery_customer_address,
    customerPhone: invoiceData.delivery_customer_phone,
    deliveryFee: invoiceData.delivery_fee,
  });

  const [formData, setFormData] = useState({
    name: "",
    method_id: 0,
    account_id: 0,
    account_number: "",
    methodLabel: "",
    accountLabel: "",
  });

  // const [paymentMap, setPaymentMap] = useState([])
  const handleSubmitOrder = async (value) => {
    console.log("value", value);
    // loadingCallback(true);
    if (change === false) {
      setSelectedValue("A4");
      setPaperSize("A4");
    }

    // const transformedProductArray = orderList.map((item) => ({
    //   product_id: item?.id,
    //   qty: item.qty,
    //   price:
    //     type === "purchase"
    //       ? item?.product_info &&
    //         (item?.product_info.discount_type === "Percentage" ||
    //           item?.product_info.discount_type === "2")
    //         ? item?.product_info.purchase_price
    //         : item?.product_info
    //         ? item?.product_info.purchase_price
    //         : item?.purchase_price
    //       : item?.product_info &&
    //         (item?.product_info?.discount_type === "Percentage" ||
    //           item?.product_info?.discount_type === "2")
    //       ? item?.price -
    //         item?.price * (item?.product_info?.discount / 100)
    //       : item?.product_info
    //       ? item?.price - item?.product_info?.discount
    //       : item?.discount_type === "Percentage" || item?.discount_type === "2"
    //       ? item?.retails_price - item?.retails_price * (item?.discount / 100)
    //       : item?.retails_price - item?.discount,

    //   mode: 1,
    //   size: 1,
    //   sales_id: 1,
    // }));

    orderList.map((item)=> {
      console.log(item);
    })

    const transformedProductArray = orderList.map((item) => ({
      product_id: item?.id,
      qty: item.qty,
      price:
        type === "purchase"
          ? item?.product_info &&
            (item?.product_info.discount_type === "Percentage" ||
              item?.product_info.discount_type === "2")
            ? item?.product_info.purchase_price
            : item?.product_info
            ? item?.product_info.purchase_price
            : item?.purchase_price
          : item?.product_info &&
            (item?.product_info?.discount_type === "Percentage" ||
              item?.product_info?.discount_type === "2")
          ? item?.price - item?.price * (item?.product_info?.discount / 100)
          : item?.product_info
          ? item?.price - item?.product_info?.discount
          : item?.discount_type === "Percentage" || item?.discount_type === "2"
          ? user.type == 8
            ? item?.price - item?.price * (item?.discount / 100)
            : item?.retails_price - item?.retails_price * (item?.discount / 100)
          : user.type == 8
          ? item?.price - item?.discount
          : item?.retails_price - item?.discount,
      mode: 1,
      size: 1,
      sales_id: 1,
      imei_id : item.product_imei ?  item.product_imei[0].id : item.imei_id
    }));

    console.log(transformedProductArray);

    const totalPrice = transformedProductArray.reduce((total, item) => {
      const itemTotalPrice = item.qty * item.price;
      return total + itemTotalPrice;
    }, 0);
    const vatsN = vat && vat > 0 ? (vat / 100) * totalPrice : 0;
    const taxN = tax && tax > 0 ? (tax / 100) * totalPrice : 0;
    const sub_total_c = totalPrice - Number(totalMoreDiscount) + vatsN + taxN;

    const paymentMap = selectedPaymentAccountList.map((item) => ({
      payment_type_id: item.payment_type_id,
      payment_type_category_id: item.payment_type_category_id,
      payment_amount: item?.payment_amount
        ? item?.payment_amount
        : item?.Amount
        ? item?.Amount
        : 0,
    }));

    // const defaultWarrantyList = selectedProductWarrantyList.flatMap((item) => {
    //   const { id, warranty } = item;
    //   return warranty.map((warrantyItem) => ({
    //     product_id: id,
    //     warranty_id: warrantyItem?.id,
    //     default_warranties_count: warrantyItem.warranties_count,
    //   }));
    // });

    if (!selectedPaymentAccountList.length) {
      showModalWarning("Select At-least one payment method!");
      return;
    }

    const data = {
      invoice_id: invoiceData?.invoice_id,
      pay_mode: money,
      paid_amount: Number(paidAmount),
      sub_total: totalPrice,
      vat:
        vat && vat > 0
          ? Number(formatNumber((vat / 100) * Number(totalPrice)))
          : 0,
      tax:
        tax && tax > 0
          ? Number(formatNumber((tax / 100) * Number(totalPrice)))
          : 0,
      discount: Number(initialDiscount),
      product: transformedProductArray,
      delivery_method_id: deliveryFormData.deliveryId,
      delivery_info_id: deliveryFormData.companyId,
      delivery_customer_name: deliveryFormData.customerName,
      delivery_customer_address: deliveryFormData.customerAddress,
      delivery_customer_phone: deliveryFormData.customerPhone,
      delivery_fee: deliveryFormData.deliveryFee,
      payment_method: paymentMap,
      status: 0,
      created_at: transactionDate,
      //warranty: defaultWarrantyList,
    };

    if (type === "purchase") {
      data.vendor_id = vendor?.id;
      data.vendor_name = vendor.name;
    } else {
      data.customer_id = customer ? customer?.id : null;
      data.customer_name = customer ? customer?.name : null;
      if (currentUser) {
        data.sales_id = seller?.id ? seller?.id : currentUser.id;
      } else {
        data.sales_id = seller?.id ? seller?.id : null;
      }
    }
    if (type == "billing" && value) {
      data.status = 1;
    }
    if (type == "booking" && value) {
      data.status = 1;
    }

    console.log("discount", initialDiscount, totalMoreDiscount);
    // const url = type === "purchase" ? "save-purchase" : type === "booking" ? "update-advance-sales" : "update-sales"; save-sale
    const url = typeUrl
      ? "update-purchase"
      : type === "booking"
      ? value
        ? "save-sales"
        : "update-advance-sales"
      : "update-sales";

    console.log("url", url);

    console.log("============");
    console.log("check data", data, type);
    setDeliveryMode(deliveryFormData.deliveryName);

    // return
    await axios
      .post(`${process.env.REACT_APP_API_URL}${url}`, data, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          setOrderList([]);
          setInvoice(response.data.data);
          console.log(response.data.data);
          if (type === "purchase") {
            navigate(`/purchase-invoice/${invoiceData.invoice_id}`);
          }
          else if (type === "booking") {
            if (value) {
              navigate(`/invoice/${response.data.data.invoice_id}`);
              // navigate(`/advance-bookings/${invoiceData.invoice_id}`);
            } else {
              navigate("/advance-bookings");
              // notify("Edited successfully.Go to booking list to update", 'success');
            }
          } else {
            if (value == 1) {
              console.log("Done", isSave, status, deliveryFormData, type);
              navigate(`/invoice/${invoiceData.invoice_id}`);
            } else {
              console.log("Hold", isSave, status, deliveryFormData, type);
              setStatus(1);
              setIsSave(false);
              // notify("Edited successfully.Go to hold list to update", 'success');
              if (type === "billing" && salesType == "wholesale") {
                navigate("/hold-wholesale-invoice-list");
              } else {
                navigate(`/purchase-invoice/${response.data.data.purchase_id}`);
              }
            }
          }
        } else {
          if (response.data.message) {
            // setErrorFlashMessage("something went wrong!");
            notify(response.data.message, "error");
          }
          if (response.data.errors) {
            // setErrorFlashMessage(response.data.errors?.customer_id[0]);
            notify(response.data.errors?.customer_id[0], "error");
          }
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const shouldRenderPagination = pagination.total > perPage;
  const [showQuickProductAddForm, setShowQuickProductAddForm] = useState(false);
  const handleQuickProductFormClick = () => {
    setShowQuickProductAddForm(!showQuickProductAddForm);
  };

  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => setShowModal(!showModal);
  const [showModalSeller, setShowModalSeller] = useState(false);
  const handleOpenSeller = () => setShowModalSeller(!showModalSeller);
  const [showModalVendor, setShowModalVendor] = useState(false);
  const handleOpenVendor = () => setShowModalVendor(!showModalVendor);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const handleOpenPayment = () => setShowPaymentModal(!showPaymentModal);

  // Gneral Focus Hook
  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };

  const [inputRef, setInputFocus] = useFocus();
  const [filteredCustomers, setFilteredCustomers] = useState([{ id: 1 }]);
  const eclipse = (str, length) => {
    return str && str.length > 0 && str.length > length
      ? str.substring(0, length) + "..."
      : str;
  };
  const modifiedFilteredProducts = [...filteredProducts];
  const filteredWithoutSelected = modifiedFilteredProducts.filter(
    (item) => item?.id !== selectedFood?.id
  );
  filteredWithoutSelected.unshift(selectedFood);
  useEffect(() => {
    getSellerListData();
  }, []);
  const [filteredSeller, setFilteredSeller] = useState([{ id: 1 }]);
  const [showSeller, setShowSeller] = useState(false);

  const getSellerListData = async (searchKeyword = "") => {
    //loadingCallback(true);
    setFilteredSeller([]);
    const page1 = 1;

    const options = [{ id: "", name: "Select" }];
    axios
      .get(`${process.env.REACT_APP_API_URL}employee`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          const sellers = response.data.data.data;
          // Append other options if there are sellers
          if (sellers.length > 0) {
            const sellerOptions = sellers.map((seller) => ({
              id: seller?.id,
              employee_type: seller.employee_type,
              name: seller.name,
              email: seller.email,
              country_code: seller.country_code,
              mobile_number: seller.mobile_number,
              address: seller.address,
              country: seller.country,
              district: seller.district,
              city: seller.city,
              zipcode: seller.zipcode,
              nid: seller.nid,
              joining_date: seller.joining_date,
              blood_group_id: seller.blood_group_id,
              emergency_contact_name: seller.emergency_contact_name,
              emergency_country_code: seller.emergency_country_code,
              emergency_mobile_numbers: seller.emergency_mobile_numbers,
              relation_with: seller.relation_with,
              status: seller.status,
            }));
            options.push(...sellerOptions);
            setShowSeller(true);
          } else {
            setSeller(currentUser ? currentUser?.id : null);
          }
        } else {
          console.log("Data Not Found!");
        }
        setFilteredSeller(options);
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };
  const minWidth = 80; // Set a reasonable minimum width
  // const inputStyle = {
  //   width: `${Math.max(paidAmount.length * 10 + 30, minWidth)}px`,
  // };

  const [barcodeRead, setBarcodeRead] = useState("");

  const handleScan = (data) => {
    if (data) {
      //setBarcodeRead(data);
    }
  };

  const handleError = (error) => {
    console.log(error);
    setBarcodeRead(error);
  };

  const fetchProductData = (productId) => {
    //loadingCallback(true);
    // Make an API request to fetch product data by ID
    // Once data is retrieved, update the form fields with the data
    get("product-details/" + productId, token)
      .then((response) => {
        const productData = response.data; // Replace with your API response data

        loadingCallback(false);
        //setProductData(productData);
        handleSelectedFood(productData);
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };

  const [methodIndex, setMethodIndex] = useState(null);

  useEffect(() => {
    if (barcodeRead.length > 0) {
      fetchProductData(barcodeRead);
    }
  }, [barcodeRead]);

  // useEffect(() => {
  //   if (status === 0) {
  //     handleSubmitOrder();
  //   }
  // }, [status]);

  // useEffect(() => {
  // }, [currentPage, methodIndex]);

  const handleChangeSelect = (name, option) => {
    const { value, label } = option; //some error in this code.
    setDeliveryFormData({
      ...deliveryFormData,
      deliveryId: value,
      deliveryName: label,
      companyId: "",
      companyName: "",
    });
  };

  useEffect(() => {
    if (deliveryList?.length) {
      let selectInitial = deliveryList.find(
        (item) => item?.id == invoiceData?.delivery_method_id
      );
      // console.log("delivery List", deliveryList, selectInitial, invoiceData);
      if (selectInitial?.type_name != "hand-to-hand") {
        let selectInialInfo = selectInitial?.delivery_infos.find(
          (item) => item.id == invoiceData?.delivery_info_id
        );
        setDeliveryFormData({
          ...deliveryFormData,
          deliveryId: selectInitial?.id,
          deliveryName: selectInitial?.type_name,
          companyId: selectInialInfo?.id,
          companyName: selectInialInfo?.company_name,
        });
      } else {
        setDeliveryFormData({
          ...deliveryFormData,
          deliveryId: selectInitial?.id,
          deliveryName: selectInitial?.type_name,
        });
      }
      console.log("selectDelivery", selectInitial);
    }
  }, [deliveryList]);

  const getDelivery = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}delivery-method-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setDeliveryList(response.data.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };

  const getDeliveryAccountList = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}delivery-info-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setDeliveryAccountList(response.data.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };

  const getWarranty = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}get-warranty-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setSelectedProductWarrantyList(response.data.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };

  const handleChangeDeliveryAmount = (value) => {
    if (!orderList.length) {
      showModalWarning("Please Select A product");
      return;
    }
    if (!/[0-9.]/.test(String(value).slice(-1))) {
      setDeliveryAmount(0);
      return;
    }
    const parseFlat = parseFloat(value);
    if (!isNaN(parseFlat) && parseFlat) {
      if (value[0] == "0") {
        value = String(value).slice(1, value?.length);
        setDeliveryAmount(value);
      } else {
        setDeliveryAmount(value);
      }
    } else {
      setDeliveryAmount(0);
    }
  };

  return (
    <div className="mt-[30px]">
      <BarcodeReader onScan={handleScan} onError={handleError} />

      <div className="flex gap-x-[2%] flex-col md:flex-row">
        <div className="w-full md:w-[60%] p-3 box-border shadow-xs bg-[#fff] rounded-[15px] mb-4 md:mb-0">
          <div className="pt-0 slider-area">
            <div className="flex justify-between md:flex-row flex-col px-0 md:px-4">
              <div>
                <p className="text-[#A0AEC0] font-nunito text-bases font-normal">
                  Home <span className="text-xs">/</span> POS
                </p>
                <p className="text-[#2D3748] font-nunito text-sm font-bold">
                  Point Of Sale
                </p>
              </div>
              <div className="flex gap-2">
                <div className="flex relative w-[95%]">
                  <input
                    autoFocus
                    type="text"
                    className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[17px] pr-[45px] pl-[21px] pb-[15px] placeholder:text-[#91919F]"
                    placeholder="Search Product"
                    onChange={(e) => setSearchKeyword(e.target.value)}
                  />
                  <div className="mx-auto my-auto absolute top-[20px] right-[17px] ">
                    <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                  </div>
                </div>
                <label
                  htmlFor="my_modal_product"
                  onClick={handleQuickProductFormClick}
                  className="flex w-[5%]  justify-center items-center bg-[#407BFF] text-[#FFF] text-[11px] font-nunito font-bold py-2 px-4 cursor-pointer rounded-[5px] capitalize"
                >
                  <i className="fa-solid fa-plus"></i>
                </label>
              </div>
            </div>
            {loading && (
              <div className="custom-loader-logo">
                <img
                  className=""
                  src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
                  alt="logo"
                />
              </div>
            )}

            {searchKeyword &&
            filteredProducts.length > 0 &&
            filteredProducts.length < 5 ? (
              <div className="searchList sliderArea">
                {filteredProducts?.map((item, index) => (

                  <div
                    key={index}
                    onClick={() => {
                      handleSelectedFood(item);
                    }}
                    className="flex flex-col gap"
                  >
                    <div>{console.log(filteredProducts)}</div>
                    <div
                      className={`overflow-hidden border-2 ${
                        item?.current_stock === 0
                          ? "border-[#ca1830]"
                          : item?.current_stock <= 5
                          ? "border-[#FBBC05]"
                          : "border-[#18CA29]"
                      } bg-white mr-[20px] shadow-sm rounded-[16px] single-product hover:scale-[1.2] hover:sticky hover:z-10  transform duration-300  relative md:mr-[25px]`}
                    >
                      {/* <img src={item.foodImg} alt="" /> */}
                      <img
                        src={item?.image_path ? item.image_path : shop_logo}
                        alt="categoryDymmyImaage"
                        className="w-[130px] lg:w-[150px] object-contain rounded-tl-[16px] rounded-tr-[16px]"
                      />
                      <div className="mt-[10px] text-center flex justify-center items-center h-[62px] font-nunito text-sm px-1.5 w-[150px] font-bold">
                        <p className="break-words">
                          {item?.name?.length > 28
                            ? eclipse(item.name, 28)
                            : item.name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (searchKeyword && filteredProducts.length > 3) ||
              filteredProducts.length > 3 ? (
              <div>
                <div className="parent-sliderArea mb-[40px] md:mb-[0px]">
                  <Slider {...settings}>
                    {filteredProducts?.map((item, index) => (
                      <div
                        key={index}
                        // onClick={() => {
                        //   // setCategory(item.id);
                        //   // setCategoryName(item.name);
                        //   handleSelectedFood(item);
                        // }}

                        onClick={
                          item?.imeis?.length
                            ? () => handleSelectedImeis(item)
                            : () => handleSelectedFood(item)
                        }
                        className="flex flex-col justify-center items-center"
                      >
                        <div
                          className={`overflow-hidden border-2 ${
                            item?.current_stock === 0
                              ? "border-[#ca1830]"
                              : item?.current_stock <= 5
                              ? "border-[#FBBC05]"
                              : "border-[#18CA29]"
                          } bg-white mr-[20px] shadow-sm rounded-[16px] single-product hover:scale-[1.2] hover:sticky hover:z-10  transform duration-300  relative md:mr-[25px]`}
                        >
                          {/* <img
                            src={item?.image_path ? item.image_path : shop_logo}
                            alt="categoryDymmyImaage"
                            className="w-[130px] lg:w-[125px] object-cover rounded-tl-[16px] rounded-tr-[16px]"
                          /> */}
                          <div className="w-[150px] flex justify-center ">
                            <img
                              src={
                                item.image_path ? item.image_path : shop_logo
                              }
                              alt="categoryDymmyImaage"
                              className="h-[140px] object-contain"
                            />
                          </div>
                          <div className="mt-[10px] text-center flex justify-center items-center h-[62px] font-nunito text-sm px-1.5 w-[150px] font-bold">
                            {/*  */}
                            <p className="break-words">
                              {item.name.length > 28
                                ? eclipse(item.name, 28)
                                : item.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            ) : (
              <div className="searchList sliderArea !justify-start">
                {loader && (
                  <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
                    {" "}
                    <div className="custom-loader"></div>{" "}
                  </div>
                )}

                {filteredProducts?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      // setCategory(item.id);
                      // setCategoryName(item.name);
                      handleSelectedFood(item);
                    }}
                    className="flex flex-col min-w-[152px] justify-center items-center "
                  >
                    <div
                      className={`overflow-hidden border-2 ${
                        item?.current_stock === 0
                          ? "border-[#ca1830]"
                          : item?.current_stock <= 5
                          ? "border-[#FBBC05]"
                          : "border-[#18CA29]"
                      } bg-white mr-[20px] shadow-sm rounded-[16px] single-product hover:scale-[1.2] hover:sticky hover:z-10  transform duration-300  relative md:mr-[25px]`}
                    >
                      {/* <img src={item.foodImg} alt="" /> */}
                      <img
                        src={item?.image_path ? item.image_path : shop_logo}
                        alt="categoryDymmyImaage"
                        className="w-[150px] object-contain rounded-tl-[16px] rounded-tr-[16px]"
                      />
                      <div className="mt-[10px] text-center flex justify-center items-center h-[62px] font-nunito text-sm px-1.5 w-[150px] font-bold">
                        <p className="break-words">
                          {item?.name?.length > 28
                            ? eclipse(item.name, 28)
                            : item.name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <ToastContainer />

          {/* {searchKeyword !== '' && modifiedFilteredProducts.length != 0 ? ( */}
          <div className="">
            <div className="flex flex-col mt-[0px] md:mt-[10px] mb-[20px] px-0 md:px-3">
              <div>
                {/* pagination component */}
                {shouldRenderPagination && (
                  <Pagination
                    lastPage={pagination.last_page}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                  />
                )}
              </div>

              <div className="flex justify-between">
                <h4 className="text-[#000232] font-nunito text-[23px] font-bold">
                  Menu
                </h4>
                <h4
                  className={`${
                    !shouldRenderPagination && "right-0"
                  } text-[#00023252] ml-5 font-nunito text-lg font-bold px-3`}
                >
                  {pagination && pagination.total} Results
                </h4>
              </div>
            </div>
            <div className="foodList p-2.5 cursor-pointer">
              <div className="flex flex-col sm:flex-row gap-[2%]">
                <div className="w-[100%] sm:w-[47%] md:w-[47%] h-[561px] overflow-y-auto custom-scrollbar">
                  <div className="w-[96%] ml-[3px] mt-[3px]">
                    {/* Filtered Project list */}
                    {modifiedFilteredProducts.map((item, index) => {
                      let discount = 0;
                      if (
                        (item?.discount_type == "Percentage" ||
                          item?.discount_type == "2") &&
                        item?.discount
                      ) {
                        discount =
                          type === "purchase"
                            ? formatPrice(0)
                            : formatPrice(
                                item.retails_price * (item.discount / 100)
                              );
                      } else {
                        discount =
                          type === "purchase"
                            ? formatPrice(0)
                            : formatPrice(item?.discount);
                      }
                      return (
                        <div
                          onClick={() => {
                            handleSelectedFood(item);
                          }}
                          className={`${
                            selectedFood ? "w-[100%]" : "w-[100%]"
                          } flex align-top bg-transparent bg-base-100 mb-6 gap-2 rounded-[18px] p-2 shadow-none single-product-item border-2
                          ${
                            item?.current_stock === 0
                              ? "border-[#ca1830]"
                              : item?.current_stock <= 5
                              ? "border-[#FBBC05]"
                              : "border-[#18CA29]"
                          }`}
                          key={index}
                        >
                          {/* <div className="w-[90px] min-w-[90px] max-h[70px]"> */}
                          <div className="w-14 h-14">
                            <img
                              // className="w-[90px] min-h-[70px] max-h-[70px] rounded-[16px] object-cover"
                              className="h-full w-full rounded-lg object-contain"
                              src={
                                item?.image_path ? item.image_path : shop_logo
                              }
                              alt="Movie"
                            />
                          </div>

                          <div className="flex-1">
                            <div>
                              <h2 className="text-[#000] font-nunito text-sm px-1 font-bold mb-[2px]">
                                {/* {eclipse(item.name, 10)} */}
                                {item.name}
                              </h2>

                              {item?.category?.name && (
                                <p className="text-sm mb-[2px]">
                                  {item?.category?.name}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-wrap gap-1 text-[#000] font-nunito text-xs font-bold">
                              <div className="whitespace-nowrap">
                                {formatPrice(
                                  type === "purchase"
                                    ? item.purchase_price - discount
                                    : item.retails_price - discount
                                )}
                                <span className="text-xs">
                                  {invoiceSetting?.currency_info?.code
                                    ? invoiceSetting.currency_info.code
                                    : "BDT"}
                                </span>
                              </div>
                              {discount != 0 &&
                                (type === "booking" || type === "billing") && (
                                  <div className="flex gap-1 items-end pb-1 text-red-500 line-through text-xs whitespace-nowrap">
                                    {formatPrice(item.retails_price)}
                                    <span className="text-xs">
                                      {invoiceSetting?.currency_info?.code
                                        ? invoiceSetting.currency_info.code
                                        : "BDT"}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="w-[100%] mt-[35px] sm:mt-0 sm:w-[53%] md:w-[53%]">
                  {selectedFood
                    ? (() => {
                        let discount = 0;
                        if (
                          (selectedFood?.discount_type == "Percentage" ||
                            selectedFood?.discount_type == "2") &&
                          selectedFood?.discount
                        ) {
                          discount =
                            type === "purchase"
                              ? formatPrice(0)
                              : formatPrice(
                                  selectedFood.retails_price *
                                    (selectedFood.discount / 100)
                                );
                        } else {
                          discount =
                            type === "purchase" ||
                            (type == "billing" && salesType == "wholesale")
                              ? 0
                              : selectedFood?.discount;
                        }
                        return (
                          <div className="shadow-md h-[100%] rounded-[18px] bg-base-100 p-[10px] mb-3 single-product-item">
                            <div className="block border-0">
                              <div className="">
                                <img
                                  className="w-full sm:max-w-[390px] 2xl:max-w-[442px] m-auto rounded-[16px] object-cover"
                                  src={
                                    selectedFood?.image_path && selectedFoodImg
                                      ? selectedFoodImg
                                      : selectedFood?.image_path
                                      ? selectedFood.image_path
                                      : shop_logo
                                  }
                                  alt="Movie"
                                />
                              </div>
                              {selectedFood?.image_path1 ||
                              selectedFood?.image_path1 ? (
                                <div className="flex gap-[2.5%] mt-[16px]">
                                  {selectedFood.image_path && (
                                    <img
                                      className="w-[90px] h-[62px] md:w-[60px] md:h-[50px] lg:w-[90px] lg:h-[62px] object-cover rounded-[8px]"
                                      src={selectedFood.image_path}
                                      onClick={() => {
                                        handleSelectedFoodImage(
                                          selectedFood.image_path
                                        );
                                      }}
                                      alt=""
                                    />
                                  )}

                                  {selectedFood.image_path1 && (
                                    <img
                                      className="w-[90px] h-[62px] md:w-[60px] md:h-[50px] lg:w-[90px] lg:h-[62px] object-cover rounded-[8px]"
                                      src={selectedFood.image_path1}
                                      alt=""
                                      onClick={() => {
                                        handleSelectedFoodImage(
                                          selectedFood.image_path1
                                        );
                                      }}
                                    />
                                  )}

                                  {selectedFood.image_path2 && (
                                    <img
                                      className="w-[90px] h-[62px] md:w-[60px] md:h-[50px] lg:w-[90px] lg:h-[62px] object-cover rounded-[8px]"
                                      src={selectedFood.image_path2}
                                      alt=""
                                      onClick={() => {
                                        handleSelectedFoodImage(
                                          selectedFood.image_path2
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                              ) : null}

                              <div className="w-[100%] mt-2.5">
                                <div className="flex flex-row items-center gap-2">
                                  <p className="text-[#000] font-nunito text-[24px] font-bold mb-[2px]">
                                    {selectedFood.name}
                                  </p>

                                  {selectedFood?.brands?.name && (
                                    <p className="text-[16px] mb-[2px]">
                                      {selectedFood?.brands?.name}
                                    </p>
                                  )}
                                </div>

                                <div>
                                  {selectedFood?.category?.name && (
                                    <p className="text-base mb-[2px]">
                                      {selectedFood?.category?.name}
                                    </p>
                                  )}
                                </div>

                                <div>
                                  {selectedFood?.current_stock && (
                                    <p className="text-[16px] mb-[2px]">
                                      Quantity:{" "}
                                      <span className="font-bold">
                                        {Number.parseFloat(
                                          selectedFood?.current_stock
                                        ).toFixed(2)}{" "}
                                        {selectedFood?.unit?.name}
                                      </span>
                                    </p>
                                  )}
                                </div>

                                <div className="flex flex-wrap justify-between gap-1 text-[#000] font-nunito text-lg font-bold">
                                  <div className="flex gap-1">
                                    <span>
                                      {type == "billing" &&
                                      salesType == "wholesale"
                                        ? formatPrice(
                                            selectedFood?.wholesale_price
                                          )
                                        : formatPrice(
                                            selectedFood?.retails_price -
                                              discount
                                          )}
                                    </span>
                                    <span className="text-xs pt-2">
                                      {invoiceSetting?.currency_info?.code
                                        ? invoiceSetting?.currency_info?.code
                                        : "BDT"}
                                    </span>
                                    {discount != 0 &&
                                      (type === "booking" ||
                                        type === "billing") && (
                                        <>
                                          <span className="text-red-500 line-through text-xs pt-2">
                                            {" "}
                                            {formatPrice(
                                              selectedFood.retails_price
                                            )}
                                          </span>
                                          <span className="text-xs pt-2">
                                            {invoiceSetting?.currency_info?.code
                                              ? invoiceSetting?.currency_info
                                                  ?.code
                                              : "BDT"}
                                          </span>
                                        </>
                                      )}
                                  </div>

                                  {/* <div className="flex flex-row gap-1">
                                    <img
                                      style={{ width: "20px", height: "20px" }}
                                      src={wholeSale}
                                      alt=""
                                    />
                                    {formatPrice(selectedFood.purchase_price)}
                                    <span className="text-xs pt-2">
                                      {invoiceSetting?.currency_info?.code
                                        ? invoiceSetting?.currency_info?.code
                                        : " BDT"}
                                    </span>
                                  </div> */}
                                </div>
                                <div className="py-2">
                                  {type !== "purchase" &&
                                  type === "billing" &&
                                  selectedFood?.imeis?.length > 0 ? (
                                    <div>
                                      <div className="rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-[20px]">
                                        <div className="flex justify-between items-center mb-2 gap-3">
                                          <div className="font-lato font-[700] text-[12px] text-[#000232]">
                                            List Of Variations
                                          </div>

                                          <input
                                            type="text"
                                            placeholder="Search by IMEI"
                                            className="px-3 py-1 border-[1px] border-[#E7EBF4] rounded-[8px] text-[12px]"
                                            value={searchVarientItem}
                                            onChange={(e) =>
                                              setSearchVarientItem(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="grid grid-cols-[min-content_1fr_1fr_1fr] gap-2 font-nunito font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
                                          <div className="py-[5px]"></div>
                                          <div className="py-[5px]">
                                            Condition
                                          </div>
                                          <div className="py-[5px] text-center">
                                            Price
                                          </div>
                                          <div className="py-[5px]">
                                            IMEI/SL
                                          </div>
                                        </div>
                                        {filteredImeis.map((imei, index) => (
                                          <div
                                            key={index}
                                            className={`grid grid-cols-[min-content_1fr_1fr_1fr] gap-2 font-nunito font-[400] text-[14px] text-[#000232] ${
                                              index === filteredImeis.length - 1
                                                ? ""
                                                : "border-b-[1px]"
                                            } border-solid border-[#E7EBF4]`}
                                          >
                                            <div className="py-[4px] flex items-center justify-center">
                                              <input
                                                type="checkbox"
                                                checked={selectedImeis.includes(
                                                  imei.id
                                                )}
                                                onChange={() =>
                                                {
                                                  setSelectedImei(imei.id)
                                                  toggleImeiSelection(imei)

                                                }
                                                }
                                              />
                                            </div>
                                            <div className="py-[5px] flex items-center">
                                              {imei?.item_condition}
                                            </div>
                                            <div className="py-[5px] flex items-center justify-center">
                                              {type === "billing"
                                                ? imei?.currency_rate !== null
                                                  ? Number.parseFloat(
                                                      imei?.sale_price
                                                    ).toFixed(2)
                                                  : Number.parseFloat(
                                                      imei?.sale_price
                                                    ).toFixed(2)
                                                : imei?.currency_rate !== null
                                                ? Number.parseFloat(
                                                    imei?.purchase_price
                                                  ).toFixed(2)
                                                : Number.parseFloat(
                                                    imei?.purchase_price
                                                  ).toFixed(2)}
                                              {imei?.currency_rate !== null
                                                ? imei?.base_currency_code
                                                : imei.purchase_currency_code}
                                            </div>
                                            <div className="py-[5px] flex items-center">
                                              {imei?.imei}
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })()
                    : null}
                </div>
              </div>
            </div>
          </div>
          {/* ): null} */}
        </div>
        {/* bill - right div */}
        <div className="w-full md:w-[40%] bg-[#fff] shadow-xs rounded-[15px] px-[15px] box-border relative">
          <h4 className="text-[#000232] font-nunito font-bold text-lg pt-[18px] pb-[12px] ">
            Bills
          </h4>
          <div className="mt-[10px] mb-5">
            <div
              className={`p-[5px] ${
                orderList.length > 4
                  ? "h-[400px] overflow-y-scroll overflow-x-hidden"
                  : "overflow-hidden"
              }`}
            >
              {orderList &&
                orderList.map((orderFood, inx) => {
                  let discount = 0;
                  // let subTotalPrice = 0;
                  if (
                    (orderFood?.product_info?.discount_type == "Percentage" ||
                      orderFood?.product_info?.discount_type == "2") &&
                    orderFood?.product_info?.discount
                  ) {
                    discount =
                      type === "purchase" ||
                      (type == "billing" && salesType == "wholesale")
                        ? formatPrice(0)
                        : formatPrice(
                            orderFood?.product_info?.retails_price *
                              (orderFood?.product_info?.discount / 100)
                          );
                  } // Check discount_type in orderFood if not found in product_info
                  else if(orderFood?.imeis){

                  }
                  else if (
                    (orderFood?.discount_type === "Percentage" ||
                      orderFood?.discount_type === "2") &&
                    orderFood?.discount
                  ) {
                    discount =
                      type === "purchase"
                        ? formatPrice(0)
                        : formatPrice(
                            orderFood?.retails_price *
                              (orderFood?.discount / 100)
                          );
                  } else {
                    discount =
                      type === "purchase"
                        ? 0
                        : (orderFood?.product_info?.discount ||
                            orderFood?.discount) ??
                          0;
                  }
                  //discount = orderFood.current_stock * orderFood.discount;
                  let subtotalPrice = 0;
                  if (type == "billing" && salesType == "wholesale") {
                    subtotalPrice = formatPrice(
                      orderFood.qty *
                        (orderFood?.product_info?.wholesale_price ||
                          orderFood?.wholesale_price)
                    );
                    subtotal += Number(subtotalPrice);
                  } else if (type == "purchase") {
                    formatPrice(
                      orderFood.qty *
                        (orderFood?.product_info?.purchase_price ||
                          orderFood?.purchase_price)
                    );
                    subtotal += Number(subtotalPrice);
                  } else {

                    subtotalPrice = formatPrice(
                      orderFood.qty *
                        ((orderFood?.price || orderFood?.retails_price) -
                          discount)
                    );
                    subtotal += Number(subtotalPrice);
                  }
                  //vats = formatPrice(0.15 * subtotal);
                  //taxes = formatPrice(0.1 * subtotal);
                  const qty = `x${orderFood.qty}`;
                  return (
                    <div
                      key={inx}
                      className="flex items-center justify-between rounded-[16px] bg-[#fff] px-[0px] custom_shadow p-2 box-border mb-[15px]"
                    >
                      {/* first col */}
                      <div className="flex gap-2 w-3/6 items-center">
                        {/* image */}
                        <div className="w-[50px] h-[50px]">
                          <img
                            className="w-full h-full rounded-md object-contain"
                            src={
                              orderFood?.product_info?.image_path
                                ? orderFood?.product_info?.image_path
                                : orderFood?.image_path
                                ? orderFood?.image_path
                                : shop_logo
                            }
                            alt=""
                          />
                        </div>
                        {/* product name & discount */}
                        <div className="space-y-[2px]">
                          <h4 className="text-sm">
                            {orderFood?.product_info?.name?.length > 20 ||
                            orderFood?.name?.length > 20 ? (
                              <>
                                {orderFood?.product_info
                                  ? orderFood?.product_info?.name.slice(0, 15)
                                  : orderFood?.name
                                  ? orderFood?.name.slice(0, 15)
                                  : "" + "..."}
                              </>
                            ) : orderFood?.product_info?.name.length > 0 ? (
                              orderFood?.product_info?.name
                            ) : orderFood?.name.length > 0 ? (
                              orderFood?.name
                            ) : (
                              ""
                            )}
                          </h4>
                          <div className="flex gap-1 items-center">
                            {/* discount info */}
                            {((type == "billing" && salesType != "wholesale") ||
                              type == "booking") && (
                              <p className="text-[#00B894] w-fit text-[7px] font-nunito font-bold px-2 pt-[4px] pb-[2px] bg-[#00B8941a] text-center rounded-[5px]">
                                {orderFood?.product_info?.discount
                                  ? orderFood?.product_info?.discount +
                                    " " +
                                    (orderFood?.product_info?.discount_type ===
                                      "Percentage" ||
                                    orderFood?.product_info?.discount_type ===
                                      "2"
                                      ? "%"
                                      : invoiceSetting?.currency_info?.code ||
                                        "BDT")
                                  : orderFood?.discount
                                  ? orderFood?.discount +
                                    " " +
                                    (orderFood?.discount_type ===
                                      "Percentage" ||
                                    orderFood?.discount_type === "2"
                                      ? "%"
                                      : invoiceSetting?.currency_info?.code ||
                                        "BDT")
                                  : null}{" "}
                                off
                              </p>
                            )}

                            {/* warranty modal button */}
                            <label
                              htmlFor="my_modal_warranty"
                              className="cursor-pointer"
                              onClick={() => {
                                setWarrantyModal(true),
                                  // handleWarrantySelectedItem(orderFood)
                                  setSelectedItem(orderFood.product_info);
                              }}
                            >
                              <img
                                src={warrantyIcon}
                                alt="warrantyIcon"
                                className="w-5"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* second col */}
                      {/* <div className="w-2/6 ">
                        {type == "purchase" ? (
                          <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow p-2">
                            <input
                              type="text"
                              value={parseFloat(subtotalPrice)}
                              className="w-[100px] mb-1 text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-[6px]"
                              onChange={(e) =>
                                handleSelectedFoodPrice(
                                  orderFood,
                                  e.target.value,
                                  qty
                                )
                              }
                            />
                          </p>
                        ) : (
                          <p className="flex justify-center gap-x-1 text-[#00023280] font-nunito text-xs font-bold ">
                            <span>{subtotalPrice}</span>
                            <span className="text-[7px]">
                              {invoiceSetting?.currency_info?.code
                                ? invoiceSetting?.currency_info?.code
                                : "BDT"}
                            </span>
                          </p>
                        )}

                        <div className="flex items-center gap-x-1 w-[75px] h-[20px]">
                          <button
                            className="quantity-decrement"
                            onClick={() => {
                              handleSelectedFood(orderFood, "decrement");
                            }}
                          >
                            <FontAwesomeIcon icon={faAngleLeft} />
                          </button>
                          <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow">
                            <input
                              type="text"
                              value={qty}
                              className="text-center bg-white pb-[3px] pl-[2px] pr-[0px] pt-[3px] w-[100%]"
                              onChange={(e) =>
                                handleSelectedFoodQty(orderFood, e)
                              }
                            />
                                                        {console.log(orderFood)}
                                                        {console.log('hiiiiiiiiiiiiiiiiiii')}
                            {orderFood?.product_info?.have_variant == 1 ?
                              <input
                                type="text"
                                value={qty}
                                className="text-center bg-white pb-[3px] pl-[2px] pr-[0px] pt-[3px] w-[100%]"
                              /> :
                              <input
                                type="text"
                                value={qty}
                                className="text-center bg-white pb-[3px] pl-[2px] pr-[0px] pt-[3px] w-[100%]"
                                onChange={(e) =>
                                  handleSelectedFoodQty(orderFood, e)
                                }
                              />
                            }
                          </p>


                          <button
                            className="quantity-increment"
                            onClick={() => {
                              handleSelectedFood(orderFood, "increment");
                            }}
                          >
                            <FontAwesomeIcon icon={faAngleRight} />
                          </button>
                          {orderFood?.product_info?.have_variant == 1 ?
                            <button
                              className="quantity-increment"
                            >
                              <FontAwesomeIcon icon={faAngleRight} />
                            </button>
                            :
                            <button
                              className="quantity-increment"
                              onClick={() => {
                                handleSelectedFood(orderFood, "increment");
                              }}
                            // onClick={ orderFood?.imeis?.length ? "" : () => {handleSelectedFood(orderFood, "increment")}}
                            >
                              <FontAwesomeIcon icon={faAngleRight} />
                            </button>
                          }
                        </div>
                      </div> */}

                      <div className="w-2/6 ">
                        {/* {
                          user?.id === 129 ?
                          <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow p-2">
                              <input
                                type="text"
                                value={parseFloat(subtotalPrice)}
                                className="w-[100px] mb-1 text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-[6px]"
                                onChange={(e) =>
                                  handleSelectedFoodPrice(
                                    orderFood,
                                    e.target.value,
                                    qty
                                  )
                                }
                              />
                            </p>
                            :


                          type == "purchase" ?
                          <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow p-2">
                            <input
                              type="text"
                              value={orderFood?.purchase_price}
                              value={
                                orderFood?.product129Price
                                  ? orderFood?.product129Qty
                                    ? orderFood.product129Price *
                                      orderFood.product129Qty
                                    : orderFood.product129Price
                                  : ""
                              }
                              className="w-[100px] mb-1 text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-[6px]"
                              onChange={
                                (e) =>
                                  handleSelectedItemQty129(
                                    orderFood,
                                    "price",
                                    e
                                  )
                                handleSelectedFoodPrice(
                                  orderFood,
                                  e.target.value,
                                  qty
                                )
                              }
                              onChange={() => handleSelectedFoodPrice(orderFood,e.target.value,qty)}
                            />

                           :

                            <input
                              type="text"
                              value={orderFood?.retails_price}
                              value={
                                orderFood?.product129Price
                                  ? orderFood?.product129Qty
                                    ? orderFood.product129Price *
                                      orderFood.product129Qty
                                    : orderFood.product129Price
                                  : ""
                              }
                              className="w-[100px] mb-1 text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-[6px]"
                              onChange={
                                (e) =>
                                  handleSelectedItemQty129(
                                    orderFood,
                                    "price",
                                    e
                                  )
                                handleSelectedFoodPrice(
                                  orderFood,
                                  e.target.value,
                                  qty
                                )
                              }
                              onChange={() => handleSelectedFoodPrice(orderFood,e.target.value,qty)}
                            />
                          </p>

                          <p className="flex justify-center gap-x-1 text-[#00023280] font-nunito text-xs font-bold ">
                              <span>{subtotalPrice}</span>
                              <span className="text-[7px]">
                                {invoiceSetting?.currency_info?.code
                                  ? invoiceSetting?.currency_info?.code
                                  : "BDT"}
                              </span>
                            </p>
                        } */}

                        {type === "purchase" ? (
                          <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow p-2">
                            <input
                              type="text"
                              value={
                                orderFood.price
                                  ? orderFood.price && orderFood.qty
                                    ? orderFood.price * orderFood.qty
                                    : orderFood.price
                                  : ""
                              }
                              className="w-[100px] mb-1 text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-[6px]"
                              onChange={(e) =>
                                handleSelectedFoodPrice(
                                  orderFood,
                                  e.target.value,
                                  qty
                                )
                              }
                            />
                          </p>
                        ) : // product_imei    needs to add two or three conditions here
                        user.type == 8 ? (
                          orderFood?.product_imei?.length > 0 ? (
                            <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow p-2">
                              <input
                                type="text"
                                value={orderFood?.price ? orderFood?.price : ""}
                                className="w-[100px] mb-1 text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-[6px]"
                                onChange={(e) =>
                                  handleSelectedFoodPrice(
                                    orderFood,
                                    e.target.value
                                  )
                                }
                                // needs to modify or create function
                              />
                            </p>
                          ) : (
                            <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow p-2">
                              <input
                                type="text"
                                value={
                                  orderFood?.price
                                    ? orderFood?.qty
                                      ? orderFood?.price * orderFood?.qty
                                      : orderFood?.price
                                    : ""
                                }
                                className="w-[100px] mb-1 text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-[6px]"
                                onChange={(e) =>
                                  handleSelectedItemQty129(
                                    orderFood,
                                    "price",
                                    e
                                  )
                                }
                              />
                            </p>
                          )
                        ) :orderFood?.product_imei && orderFood?.product_imei?.length > 0 ? (
                          <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow p-2">
                            <input
                              type="text"
                              value={orderFood?.price ? orderFood?.price : ''}
                              className="w-[100px] mb-1 text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-[6px]"
                              onChange={(e) =>
                               { handleSelectedFoodPrice(
                                  orderFood,
                                  e.target.value,
                                )
                                }
                              }
                            />
                          </p>
                        ) :
                        orderFood?.imeis && orderFood?.imeis.length > 0  ?
                          (
                            <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow p-2">
                            <input
                              type="text"
                              value={parseInt(subtotalPrice)}
                              className="w-[100px] mb-1 text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-[6px]"
                              onChange={(e) =>
                               { handleSelectedFoodPrice(
                                  orderFood,
                                  e.target.value,
                                  inx
                                )
                               }
                              }
                            />
                          </p>
                          )
                        :
                        (
                          <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow p-2">
                            <input
                              type="text"
                              value={
                                orderFood?.price
                                  ? orderFood.price && orderFood?.qty
                                    ? orderFood.price * orderFood.qty
                                    : orderFood.price
                                  : ""
                              }
                              className="w-[100px] mb-1 text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-[6px]"
                              onChange={(e) =>
                                handleSelectedItemQty129(orderFood, "price", e)
                              }
                            />
                          </p>
                        )}

                        <div className="flex items-center gap-x-1 w-[75px] h-[20px]">
                          <button
                            className="quantity-decrement"
                            onClick={() => {
                              handleSelectedFood(orderFood, "decrement");
                            }}
                          >
                            <FontAwesomeIcon icon={faAngleLeft} />
                          </button>
                          <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow">
                            {/* <input
                              type="text"
                              value={qty}
                              className="text-center bg-white pb-[3px] pl-[2px] pr-[0px] pt-[3px] w-[100%]"
                              onChange={(e) =>
                                handleSelectedFoodQty(orderFood, e)
                              }
                            /> */}

                            {type !== "purchase" &&
                            orderFood?.product_info?.have_variant == 1 ? (
                              <input
                                type="text"
                                value={qty}
                                className="text-center bg-white pb-[3px] pl-[2px] pr-[0px] pt-[3px] w-[100%]"
                              />
                            ) : (
                              <input
                                type="text"
                                value={`x${
                                  orderFood?.qty ? orderFood.qty : ""
                                }`}
                                className="text-center bg-white pb-[3px] pl-[2px] pr-[0px] pt-[3px] w-[100%]"
                                onChange={(e) =>
                                  // handleSelectedFoodQty(orderFood, e)
                                  handleSelectedItemQty129(orderFood, "qty", e)
                                }
                              />
                            )}
                          </p>
                          {/* <button
                            className="quantity-increment"
                            onClick={() => {
                              handleSelectedFood(orderFood, "increment");
                            }}
                          >
                            <FontAwesomeIcon icon={faAngleRight} />
                          </button> */}
                          {type !== "purchase" &&
                          orderFood?.product_info?.have_variant == 1 ? (
                            <button className="quantity-increment">
                              <FontAwesomeIcon icon={faAngleRight} />
                            </button>
                          ) : (
                            <button
                              className="quantity-increment"
                              onClick={() => {
                                handleSelectedFood(orderFood, "increment");
                              }}
                              // onClick={ orderFood?.imeis?.length ? "" : () => {handleSelectedFood(orderFood, "increment")}}
                            >
                              <FontAwesomeIcon icon={faAngleRight} />
                            </button>
                          )}
                        </div>
                      </div>

                      {/* third col */}
                      <p
                        className="w-[23px] bg-[#FF0303] rounded-[5px] p-1 h-6"
                        onClick={() => {
                          handleDelete(inx);
                        }}
                      >
                        <MdClose className="text-[#FFFFFF]"></MdClose>
                      </p>
                    </div>
                  );
                })}
            </div>
            <div>
              <button onClick={() => setIsShowModal(true)} className="bg-[#407BFF] text-white w-full py-1 rounded-lg text-sm font-semibold">View Customer Info</button>
              {
                isShowModal && <CustomerInfoModal
                onClose={handleCloseModal}
                customerName={invoiceData?.delivery_customer_name}
                customerPhone={invoiceData?.delivery_customer_phone
                }
                customerAddress={invoiceData?.delivery_customer_address}
                title={'Customer Info'}
              />
              }

            </div>
            {(type === "booking" || type === "billing") && (
              <div className="mt-[50px] flex gap-2">
                <div className="w-full relative">
                  <select
                    name="discount_type"
                    onChange={handleChangeDiscountType}
                    className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                  >
                    <option disabled=""> Select Discount Type</option>
                    {discountType.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <input
                  type="text"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none`}
                  placeholder="Discount"
                  value={initialDiscount}
                  onChange={(e) => handleChangeDiscount(e.target.value)}
                  name={"discount"}
                />
              </div>
            )}
            <div className="flex justify-between items-start mt-10 ">
              <div className="relative border-[#C1CFEF] py-2.5 border w-full flex-1 rounded-xl">
                <DatePicker
                  selected={transactionDate}
                  onChange={(date) => setTransactionDate(date)}
                  dateFormat="dd/MM/yyyy"
                  className="w-full px-2 focus:outline-none"
                />
                <span className="bg-white absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                  Transaction Date
                </span>
                {/* <span className={`${startDate && startDate.toLocaleDateString() === "DD / MM / YYYY" ? "text-[#91919F]" : "text-[#232324]"} absolute left-[6px] px-1  text-sm lg:text-base bg-white`}>
              {startDate && startDate.toLocaleDateString()}
            </span> */}
              </div>
            </div>
            <div className="flex justify-between items-start mt-10 border-b border-dashed border-[#7F8098]">
              <div>
                <h4 className="text-[#000232] font-nunito font-bold text-lg mb-[15px]">
                  Subtotal
                </h4>
                <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  Vat ({vat + "%"})
                </p>
                <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  Tax ({tax + "%"})
                </p>
                {(type === "booking" ||
                  type === "billing" ||
                  type === "wholesale") && (
                  <h4 className="text-[#000232] font-nunito font-bold text-lg pt-[10px] mb-[15px]">
                    Delivery Fee
                  </h4>
                )}
                {/* <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  Discount ({moreDiscount + (selectedDiscountType === 'Percentage' ? '%' : (invoiceSetting?.currency_info?.code || 'BDT'))})
                </p> */}
              </div>
              <div className="text-right">
                <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  {formatPrice(subTotalNew)}{" "}
                  <span className="text-[13px]">
                    {" "}
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : "BDT"}
                  </span>
                </p>
                <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  {formatPrice(
                    vat && vat > 0 ? (vat / 100) * Number(subTotalNew) : 0
                  )}{" "}
                  <span className="text-[13px]">
                    {" "}
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : "BDT"}
                  </span>
                </p>
                <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  {formatPrice(
                    tax && tax > 0 ? (tax / 100) * Number(subTotalNew) : 0
                  )}{" "}
                  <span className="text-[13px]">
                    {" "}
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : "BDT"}
                  </span>
                </p>
                {(type === "booking" ||
                  type === "billing" ||
                  type === "wholesale") && (
                  <p className="text-[#7F8098] text-right font-nunito font-bold text-lg mb-[15px]">
                    {deliveryFormData?.deliveryName?.toLowerCase() !=
                    "hand-to-hand" ? (
                      <input
                        type="text"
                        // disabled={ (deliveryFormData?.deliveryName).toLowerCase()=='hand-to-hand' ? true : false }
                        className={`w-[80px] text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-2`}
                        placeholder="0.00"
                        value={deliveryAmount}
                        // style={inputStyle}
                        onChange={(e) =>
                          handleChangeDeliveryAmount(e.target.value)
                        }
                        name={"delivery_fee"}
                      />
                    ) : (
                      <span>{deliveryAmount}</span>
                    )}
                    <span className="text-[13px]">
                      {" "}
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : "BDT"}
                    </span>
                  </p>
                )}
                {/* <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  {totalMoreDiscount} <span className="text-[13px]"> {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}</span>
                </p> */}
              </div>
            </div>

            <div className="flex justify-between items-center border-b border-dashed border-[#7F8098]">
              <div>
                <h4 className="text-[#000232] font-nunito font-bold text-lg mb-[15px]">
                  Grand Total
                </h4>
                {(type === "booking" ||
                  type === "billing" ||
                  type == "purchase") && (
                  <h4 className="text-[#000232] font-nunito font-bold text-lg mb-[15px]">
                    Paid Amount
                  </h4>
                )}
              </div>
              <div>
                <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px] text-right">
                  {grandTotalAfterDiscount}{" "}
                  <span className="text-[13px]">
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : "BDT"}
                  </span>
                </p>

                {(type === "booking" || type === "billing") && (
                  <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px] text-right">
                    {/* <input
                      type="text"
                      className={`w-[80px] text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-2`}
                      placeholder="0.00"
                      value={
                        paidAmount
                          // ? paidAmount
                          // :
                          // Number(grandTotalAfterDiscount) - due
                      }
                      style={inputStyle}
                      // onChange={(e) => handleChangePaidAmount(e.target.value)}
                      name={"paid_amount"}
                    /> */}
                    <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                      {Number.parseFloat(paidAmount).toFixed(2)}{" "}
                      <span className="text-[13px]">
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info?.code
                          : "BDT"}
                      </span>
                    </p>
                    {/* <span className="text-[13px]">
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : "BDT"}
                    </span> */}
                  </p>
                )}

                {type == "purchase" && (
                  <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px] text-right">
                    <input
                      type="text"
                      className={`w-[80px] text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-2`}
                      placeholder="0.00"
                      value={
                        paidAmount
                          ? paidAmount
                          : Number(grandTotalAfterDiscount) - due
                      }
                      // style={inputStyle}
                      onChange={(e) => handleChangePaidAmount(e.target.value)}
                      name={"paid_amount"}
                    />
                    <span className="text-[13px]">
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : "BDT"}
                    </span>
                  </p>
                )}
              </div>
            </div>

            {(type === "booking" || type === "billing") && (
              <div className="flex justify-between items-center">
                <div>
                  <h4 className="text-[#000232] font-nunito font-bold text-lg mb-[15px]">
                    {" "}
                    Due Amount
                  </h4>
                </div>
                <div>
                  <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                    {Number.parseFloat(due).toFixed(2)}{" "}
                    <span className="text-[13px]">
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : "BDT"}
                    </span>
                  </p>
                </div>
              </div>
            )}
            {type == "purchase" && (
              <div className="flex justify-between items-center">
                <div>
                  <h4 className="text-[#000232] font-nunito font-bold text-lg mb-[15px]">
                    {" "}
                    Due Amount
                  </h4>
                </div>
                <div>
                  <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                    {Number.parseFloat(due).toFixed(2)}{" "}
                    <span className="text-[13px]">
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : "BDT"}
                    </span>
                  </p>
                </div>
              </div>
            )}
            <div className="mt-[10px] ">
              {(type === "booking" || type === "billing") && (
                <div
                  className="flex items-center justify-center py-5 gap-3 px-3"
                  onClick={() => setMethodIndex(0)}
                >
                  <ItemSelectBox
                    name="delivery_id"
                    options={deliveryList}
                    value={deliveryFormData.deliveryId}
                    onChange={handleChangeSelect.bind(this, "delivery_id")}
                    label={"Delivery Method"}
                    placeholder={deliveryFormData.deliveryName}
                    style={{ maxHeight: "300px", overflowY: "scroll" }}
                  />
                </div>
              )}

              {(type === "booking" ||
                type === "billing" ||
                type == "purchase") && (
                <div className="flex justify-center items-center">
                  <div className="grid grid-cols-3 gap-y-5 w-full">
                    {paymentList.map((items, index) => (
                      // <label
                      //   htmlFor="my_modal_payment"
                      //   onClick={() => handleClicks(items)}
                      //   className={`${
                      //     money === items?.type_name && orderList.length > 0
                      //       ? " bg-[#29B170] text-[#fff]"
                      //       : "opacity-50 bg-[#2e509b1a] text-blue-700"
                      //   } cursor-pointer w-[80%] h-[109px] flex justify-center mx-auto items-center rounded-[13px] break-words overflow-hidden`}
                      // >
                      //   <div
                      //     className={` flex flex-col justify-center w-full items-center gap-y-2 p-2`}
                      //   >
                      //     <h2 className={`font-bold text-3xl`}>
                      //       {items.icon_letter}
                      //     </h2>
                      //     <p className="text-sm  text-center font-nunito font-bold w-[90%]">
                      //       {items?.type_name}
                      //     </p>
                      //   </div>
                      // </label>
                      <label
                        htmlFor="my_modal_payment"
                        onClick={() => handleClicks(items)}
                        className={`${
                          items.icon_image
                            ? "bg-white" // Set background to white if icon_image exists
                            : money === items?.type_name && orderList.length > 0
                            ? "bg-gradient-to-r from-green-500 to-green-700 text-white"
                            : "bg-blue-100 text-blue-700"
                        } cursor-pointer w-[80%] h-[120px] flex justify-center mx-auto items-center rounded-lg shadow-md transition-transform transform hover:scale-105 overflow-hidden`}
                      >
                        <div
                          className={`flex flex-col justify-center w-full items-center p-0`}
                        >
                          {items.icon_image ? (
                            // Render the image if `items.icon_image` exists
                            <img
                              src={items.icon_image}
                              alt="Icon"
                              width="100%"
                              height="120px"
                              className={`object-cover rounded-lg`}
                            />
                          ) : (
                            // Render the original structure if `items.icon_image` does not exist
                            <>
                              <h2
                                className={`font-bold text-4xl text-gray-800`}
                              >
                                {items.icon_letter}
                              </h2>
                              <p className="text-sm text-center font-nunito font-bold w-[90%] text-gray-600">
                                {items.type_name === "cash"
                                  ? "Cash"
                                  : items.type_name}
                              </p>
                            </>
                          )}
                        </div>
                      </label>
                    ))}
                  </div>
                </div>
              )}
              {warningMessage && (
                <div className="warning-message">something wrong!</div>
              )}
              {flashMessage && (
                <div
                  className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
                    flashMessage.type
                  )}`}
                >
                  {flashMessage.text}
                </div>
              )}

              {/*
              {( type === "billing") ?
                (<div className='flex items-center justify-center py-5 gap-3 px-3' onClick={()=>setMethodIndex(0)}>
                  <ItemSelectBox
                    name="delivery_id"
                    options={deliveryList}
                    value={deliveryFormData.deliveryId}
                    onChange={handleChangeSelect.bind(this, 'delivery_id')}
                    label={"Delivery Method"}
                    placeholder={deliveryFormData.deliveryName }
                    style={{ maxHeight: '300px', overflowY: 'scroll' }}
                  />
                </div>
              ) : null } */}

              {warrantyModal ? (
                <Modal
                  state={state}
                  isOpen={warrantyModal}
                  setOpen={setWarrantyModal}
                  filteredProducts={filteredProducts}
                  setWarningMessage={setWarningMessage}
                  selectedProductWarrantyList={selectedProductWarrantyList}
                  setSelectedProductWarrantyList={
                    setSelectedProductWarrantyList
                  }
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  orderList={orderList}
                  title="Warranty Info"
                  formId="my_modal_warranty"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  type="warranty"
                />
              ) : null}

              {showPaymentModal ? (
                <Modal
                  isOpen={showPaymentModal}
                  setOpen={setShowPaymentModal}
                  getData={paymentData}
                  listData={paymentList}
                  formData={formData}
                  setFormData={setFormData}
                  paymentAccountList={paymentAccountList}
                  setData={setPaymentData}
                  filteredAccountList={filteredAccountList}
                  setPaidAmount={setPaidAmount}
                  setFilteredAccountList={setFilteredAccountList}
                  setSelectedAccountList={setSelectedPaymentAccountList}
                  setWarningMessage={setWarningMessage}
                  title="Payment Info"
                  formId="my_modal_payment"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  total={due}
                  type="payment"
                />
              ) : null}

              {/* Modal One  */}
              {showModal ? (
                <Modal
                  isOpen={showModal}
                  setOpen={setShowModal}
                  setData={setCustomer}
                  setWarningMessage={setWarningMessage}
                  title="Customer Info"
                  formId="my_modal_2"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  type="customer"
                  filteredCustomers={filteredCustomers}
                  setFilteredCustomers={setFilteredCustomers}
                />
              ) : null}
              {/* Modal Two  */}
              {showModalSeller ? (
                <Modal
                  isOpen={showModalSeller}
                  setOpen={setShowModalSeller}
                  setData={setSeller}
                  setWarningMessage={setWarningMessage}
                  title="Seller Info"
                  formId="my_modal_seller"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  type="seller"
                  filteredSeller={filteredSeller}
                  setFilteredSeller={setFilteredSeller}
                />
              ) : null}
              {showModalVendor ? (
                <Modal
                  isOpen={showModalVendor}
                  setOpen={setShowModalVendor}
                  setData={setVendor}
                  setWarningMessage={setWarningMessage}
                  title="Vendor Info"
                  formId="my_modal_vendor"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  type="vendor"
                />
              ) : null}
              {showQuickProductAddForm ? (
                <Modal
                  isOpen={showQuickProductAddForm}
                  setOpen={setShowQuickProductAddForm}
                  setData={setVendor}
                  setWarningMessage={setWarningMessage}
                  title="Quick Entry"
                  formId="my_modal_product"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  type="product"
                  saleType={type}
                  updateBrandList={updateBrandList}
                  getTopSaleProductlist={getTopSaleProductlist}
                />
              ) : null}

              {(type === "booking" || type === "billing") &&
              orderList &&
              orderList.length > 0 &&
              money != "" ? (
                <label
                  htmlFor="my_modal_2"
                  onClick={handleOpen}
                  className="mt-[22px] bg-[#034AFF] p-2 w-full font-nunito font-semibold text-lg text-center text-[#FCFCFC] cursor-pointer rounded-[5px] capitalize"
                >
                  Add Customer
                </label>
              ) : null}
              {showSeller &&
              (type === "booking" || type === "billing") &&
              orderList &&
              orderList.length > 0 &&
              money != "" ? (
                <label
                  htmlFor="my_modal_seller"
                  onClick={handleOpenSeller}
                  className="mt-[22px] bg-[#034AFF] p-2 w-full font-nunito font-semibold text-lg text-center text-[#FCFCFC] cursor-pointer rounded-[5px] capitalize"
                >
                  Add Seller
                </label>
              ) : null}
              {type === "purchase" &&
              orderList &&
              orderList.length > 0 &&
              money != "" ? (
                <label
                  htmlFor="my_modal_vendor"
                  onClick={handleOpenVendor}
                  className="mt-[22px] bg-[#034AFF] p-2 w-full font-nunito font-semibold text-lg text-center text-[#FCFCFC] cursor-pointer rounded-[5px] capitalize"
                >
                  Add Vendor
                </label>
              ) : null}

              {type === "billing" ||
              vendor ||
              customer ||
              (type === "booking" && (vendor || customer)) ? (
                <EditFormPayment
                  formId="PaymentForm"
                  isEdit={true}
                  setIsSave={setIsSave}
                  invoiceData={invoiceData}
                  status={status}
                  deliveryAccountList={deliveryAccountList}
                  setStatus={setStatus}
                  subtotal={subTotalNew}
                  deliveryFormData={deliveryFormData}
                  setDeliveryFormData={setDeliveryFormData}
                  invoice={invoice}
                  vats={formatPrice(
                    vat && vat > 0 ? (vat / 100) * Number(subTotalNew) : 0
                  )}
                  type={type}
                  taxes={formatPrice(
                    tax && tax > 0 ? (tax / 100) * Number(subTotalNew) : 0
                  )}
                  customer={customer}
                  seller={seller}
                  vendor={vendor}
                  grandTotal={grandTotalAfterDiscount}
                  currentUser={currentUser ? currentUser : null}
                  product={orderList ? orderList : null}
                  discount={totalMoreDiscount}
                  onOrderSubmit={handleSubmitOrder}
                  setInvoice={setInvoice}
                  setWarningMessage={setWarningMessage}
                  selectedValue={selectedValue}
                  handleSelectChange={handleSelectChange}
                />
              ) : null}
            </div>
          </div>

          <div className="absolute top-0 right-0 pr-[18px] pt-[18px] pb-[12px]">
            <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
              {grandTotalAfterDiscount}{" "}
              <span className="text-[13px]">
                {invoiceSetting?.currency_info?.code
                  ? invoiceSetting?.currency_info?.code
                  : "BDT"}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrderProduct;
