import React, { useEffect, useState } from "react";
import ContentLayout from "../../layout/ContentLayout";
import ImageUploaderWithPost from "./ImageUploaderWithPost";
import axios from "axios";
import { BiSave } from "react-icons/bi";
import ColorPicker from 'react-best-gradient-color-picker'
import Swal from "sweetalert2";

const CreateBanner = () => {
  const [color, setColor] = useState('linear-gradient(90deg, rgba(96,93,93,1) 0%, rgba(255,255,255,1) 100%)');

  const [color2, setColor2] = useState('linear-gradient(90deg, rgba(96,93,93,1) 0%, rgba(255,255,255,1) 100%)');

  const [color3, setColor3] = useState('linear-gradient(90deg, rgba(96,93,93,1) 0%, rgba(255,255,255,1) 100%)');

  const [color4, setColor4] = useState('linear-gradient(90deg, rgba(96,93,93,1) 0%, rgba(255,255,255,1) 100%)');

  const [color5, setColor5] = useState('linear-gradient(90deg, rgba(96,93,93,1) 0%, rgba(255,255,255,1) 100%)');

  const user = JSON.parse(localStorage.getItem('user')) || null;


  const [formData,setFormData] = useState({
    title : 'banner 1',
    button_text : '',
    button_url : '',
    background_color: '',
    image_path : ''
  })
  const [formData2,setFormData2] = useState({
    title : 'banner 2',
    button_text : '',
    button_url : '',
    background_color:'',
    image_path : ''
  })
  const [formData3,setFormData3] = useState({
    title : 'banner 3',
    button_text : '',
    button_url : '',
    background_color:'',
    image_path : ''
  })
  const [formData4,setFormData4] = useState({
    title : 'banner 4',
    button_text : '',
    button_url : '',
    background_color:'',
    image_path : ''
  })
  const [formData5,setFormData5] = useState({
    title : 'banner 5',
    button_text : '',
    button_url : '',
    background_color:'',
    image_path : ''
  })


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}get-banners/${user ?user.id : ''}`)
    .then(res => {
      if(res.data.data.length > 0){
        // bbbanner 1
        setFormData((prevData) => (
          {
          ...prevData,
          image_path : res.data.data[0].image_path
          }

        ))
        // bbanner 2
        setFormData2((prevData) => (
          {
          ...prevData,
          image_path : res?.data?.data[1] && res.data.data[1]?.image_path ? res.data.data[1].image_path : ''
          }
        ))

        // bbanner 3
        setFormData3((prevData) => (
          {
          ...prevData,
          image_path : res?.data?.data[2] && res.data.data[2]?.image_path ? res.data.data[2].image_path : ''
          }
        ))

        // banner 4
        setFormData4((prevData) => (
          {
          ...prevData,
          image_path : res?.data?.data[3] && res.data.data[3]?.image_path ? res.data.data[3].image_path : ''
          }
        ))

        // banner 5
        setFormData5((prevData) => (
          {
          ...prevData,
          image_path : res?.data?.data[4] && res.data.data[4]?.image_path ? res.data.data[4].image_path : ''
          }
        ))
      }
    })
    .catch(error => {
      console.log(error);
    })
},[])

console.log(formData,formData2,formData3,formData4,formData5)


  // color change
  const handleColorChange = (colour) => {
    setColor(colour)
    setFormData({
      ...formData,background_color:colour
    })
  }

  const handleColorChange2 = (colour) => {
    setColor2(colour)
    setFormData2({
      ...formData2,background_color:colour
    })
  }
  const handleColorChange3 = (colour) => {
    setColor3(colour)
    setFormData3({
      ...formData3,background_color:colour
    })
  }
  const handleColorChange4 = (colour) => {
    setColor4(colour)
    setFormData4({
      ...formData4,background_color:colour
    })
  }
  const handleColorChange5 = (colour) => {
    setColor5(colour)
    setFormData5({
      ...formData5,background_color:colour
    })
  }





  const handleFileChange = (files, name) => {
    console.log('Selected files:', files);
  };

  const handleFileChange02 = (files, name) => {
    console.log('Selected files:', files);
  };
  const handleFileChange03 = (files, name) => {
    console.log('Selected files:', files);
  };
  const handleFileChange04 = (files, name) => {
    console.log('Selected files:', files);
  };
  const handleFileChange05 = (files, name) => {
    console.log('Selected files:', files);
  };

  const handleChange = (e) => {
    const {name,value} = e.target;
    setFormData((prevFormData) =>( {
      ...prevFormData,
      [name] : value
    }))
  }
  const handleChange02 = (e) => {
    const {name,value} = e.target;
    setFormData2((prevFormData) =>( {
      ...prevFormData,
      [name] : value
    }))
  }
  const handleChange03 = (e) => {
    const {name,value} = e.target;
    setFormData3((prevFormData) =>( {
      ...prevFormData,
      [name] : value
    }))
  }
  const handleChange04 = (e) => {
    const {name,value} = e.target;
    setFormData4((prevFormData) =>( {
      ...prevFormData,
      [name] : value
    }))
  }
  const handleChange05 = (e) => {
    const {name,value} = e.target;
    setFormData5((prevFormData) =>( {
      ...prevFormData,
      [name] : value
    }))
  }


  const handleSubmit = async() => {

    const token = localStorage.getItem('token')
    const payload = {
      formData,
      formData2,
      formData3,
      formData4,
      formData5,
    };

    try {
      const response = await axios.post(
        'https://www.outletexpense.xyz/api/save-banner',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );
       Swal.fire({
                title: "Added!",
                text: "Your banner has been added.",
                icon: "success"
              });
    } catch (error) {
      console.error('Error posting form data:', error.response?.data || error.message);
    }
  }

  console.log(formData,formData2,formData3,formData4,formData5);

  return (
    <div>
      <ContentLayout>
        <div className="bg-white rounded-3xl mt-3 font-nunito p-[26px] ">
          <div className="mb-5">
            <span className="text-gray-400 text-xs">Banner</span>
            <p className="font-semibold text-sm">Create New Banner</p>


          </div>
          {/*slider form 01  */}
          <div className="grid lg:grid-cols-3 justify-center gap-6 mb-10 items-center">
            <div className="col-span-1 space-y-5">
              <div className={` relative`}>
                <input
                  onChange={handleChange}
                  value={formData.title}
                  name="title"
                  placeholder="Banner Title"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Title
                </label>
              </div>
              <div className={` relative`}>
                <input
                  onChange={handleChange}
                  value={formData.button_text}
                  name="button_text"
                  placeholder="Banner Button Url"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Button Text
                </label>
              </div>
              <div className={` relative`}>
                <input
                  onChange={handleChange}
                  value={formData.button_url}
                  name="button_url"
                  placeholder="Banner Button Url"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Button Url
                </label>
              </div>

            </div>
            <div className="col-span-1 h-96">
              <ImageUploaderWithPost
                name={"image_path"}
                isBig={true}
                onChange={handleFileChange}
                formData={formData}
                setFormData={setFormData}
                index={0}
                image={formData.image_path ? formData.image_path : null}
                apiEndPoint={`${process.env.REACT_APP_API_URL}file-upload`}
              ></ImageUploaderWithPost>
            </div>
            <div className="col-span-1">
              <div className="flex flex-col space-y-3 rounded-md">
              <ColorPicker  className='rounded-lg' height={90} value={color} onChange={handleColorChange}/>
              </div>

            </div>
          </div>

          {/* -----------------slider form 02------------ */}
          <div className="mb-5">

            <p className="font-semibold text-sm">Banner 02</p>
          </div>
          <div className="grid lg:grid-cols-3 justify-center gap-6 mb-10 items-center">
            <div className="col-span-1 space-y-5">
              <div className={` relative`}>
                <input
                  onChange={handleChange02}
                  value={formData2.title}
                  name="title"
                  placeholder="Banner Title2"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Title
                </label>
              </div>
              <div className={` relative`}>
                <input
                  onChange={handleChange02}
                  value={formData2.button_text}
                  name="button_text"
                  placeholder="Banner Button Url"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Button Text
                </label>
              </div>
              <div className={` relative`}>
                <input
                  onChange={handleChange02}
                  value={formData2.button_url}
                  name="button_url"
                  placeholder="Banner Button Url"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Button Url
                </label>
              </div>

            </div>
            <div className="col-span-1 h-96">
              <ImageUploaderWithPost
                name={"image_path"}
                isBig={true}
                onChange={handleFileChange02}
                formData={formData2}
                setFormData={setFormData2}
                index={0}
                image={formData2.image_path ? formData2.image_path : null}
                apiEndPoint={`${process.env.REACT_APP_API_URL}file-upload`}
              ></ImageUploaderWithPost>
            </div>
            <div className="col-span-1">
              <div className="flex flex-col space-y-3">
              <ColorPicker className='rounded-lg' height={90} value={color2} onChange={handleColorChange2} />
              </div>

            </div>
          </div>


          {/* slider form 03 */}
          <div className="mb-5">

            <p className="font-semibold text-sm">Banner 03</p>
          </div>
          <div className="grid lg:grid-cols-3 justify-center gap-6 mb-10 items-center">
            <div className="col-span-1 space-y-5">
              <div className={` relative`}>
                <input
                  onChange={handleChange03}
                  value={formData3.title}
                  name="title"
                  placeholder="Banner Title"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Title
                </label>
              </div>
              <div className={` relative`}>
                <input
                  onChange={handleChange03}
                  value={formData3.button_text}
                  name="button_text"
                  placeholder="Banner Button Url"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Button Text
                </label>
              </div>
              <div className={` relative`}>
                <input
                  onChange={handleChange03}
                  value={formData3.button_url}
                  name="button_url"
                  placeholder="Banner Button Url"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Button Url
                </label>
              </div>

            </div>
            <div className="col-span-1 h-96">
              <ImageUploaderWithPost
                name={"image_path1"}
                isBig={true}
                onChange={handleFileChange03}
                formData={formData3}
                setFormData={setFormData3}
                index={0}
                image={formData3.image_path ? formData3.image_path : null}
                apiEndPoint={`${process.env.REACT_APP_API_URL}file-upload`}
              ></ImageUploaderWithPost>
            </div>
            <div className="col-span-1">
              <div className="flex flex-col space-y-3">
              <ColorPicker className='rounded-lg' height={90} value={color3} onChange={handleColorChange3} />
              </div>

            </div>
          </div>


          {/* slider form 04 */}
          <div className="mb-5">

            <p className="font-semibold text-sm">Banner 04</p>
          </div>
          <div className="grid lg:grid-cols-3 justify-center gap-6 mb-10 items-center">
            <div className="col-span-1 space-y-5">
              <div className={` relative`}>
                <input
                  onChange={handleChange04}
                  value={formData4.title}
                  name="title"
                  placeholder="Banner Title"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Title
                </label>
              </div>
              <div className={` relative`}>
                <input
                  onChange={handleChange04}
                  value={formData4.button_text}
                  name="button_text"
                  placeholder="Banner Button Url"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Button Text
                </label>
              </div>
              <div className={` relative`}>
                <input
                  onChange={handleChange04}
                  value={formData4.button_url}
                  name="button_url"
                  placeholder="Banner Button Url"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Button Url
                </label>
              </div>

            </div>
            <div className="col-span-1 h-96">
              <ImageUploaderWithPost
                name={"image_path1"}
                isBig={true}
                onChange={handleFileChange04}
                formData={formData4}
                setFormData={setFormData4}
                index={0}
                image={formData4.image_path ? formData4.image_path : null}
                apiEndPoint={`${process.env.REACT_APP_API_URL}file-upload`}
              ></ImageUploaderWithPost>
            </div>
            <div className="col-span-1">
              <div className="flex flex-col space-y-3">
              <ColorPicker className='rounded-lg' height={90} value={color4} onChange={handleColorChange4} />
              </div>

            </div>
          </div>

          {/* slider form 05 */}
          <div className="mb-5">

            <p className="font-semibold text-sm">Banner 05</p>
          </div>
          <div className="grid lg:grid-cols-3 justify-center gap-6 mb-10 items-center">
            <div className="col-span-1 space-y-5">
              <div className={` relative`}>
                <input
                  onChange={handleChange05}
                  value={formData5.title}
                  name="title"
                  placeholder="Banner Title"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Title
                </label>
              </div>
              <div className={` relative`}>
                <input
                  onChange={handleChange05}
                  value={formData5.button_text}
                  name="button_text"
                  placeholder="Banner Button Url"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Button Text
                </label>
              </div>
              <div className={` relative`}>
                <input
                  onChange={handleChange05}
                  value={formData5.button_url}
                  name="button_url"
                  placeholder="Banner Button Url"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Button Url
                </label>
              </div>

            </div>
            <div className="col-span-1 h-96">
              <ImageUploaderWithPost
                name={"image_path1"}
                isBig={true}
                onChange={handleFileChange05}
                formData={formData5}
                setFormData={setFormData5}
                index={0}
                image={formData5.image_path ? formData5.image_path : null}
                apiEndPoint={`${process.env.REACT_APP_API_URL}file-upload`}
              ></ImageUploaderWithPost>
            </div>
            <div className="col-span-1">
              <div className="flex flex-col space-y-3">
              <ColorPicker className='rounded-lg' height={90} value={color5} onChange={handleColorChange5} />
              </div>

            </div>
          </div>


          <button
                onClick={handleSubmit}
                type="submit"
                className="bg-[#407BFF] mx-auto mt-5 rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 px-[67px] flex items-center gap-2"
              >
                <BiSave className="text-xl"></BiSave> <span>Add Banner</span>
              </button>
        </div>
      </ContentLayout>
    </div>
  );
};

export default CreateBanner;
