import React from "react";
import { IoClose } from "react-icons/io5";

const CustomerInfoModal = ({onClose, title,customerName,customerPhone,customerAddress }) => {

  return (
   <>
   {
     <div className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center z-[9999] items-center px-4">
     <dialog
       open
       className="w-full max-w-[450px] p-5 rounded-2xl flex flex-col justify-center bg-white text-black sm:w-[90%] md:w-[450px]"
     >
       <div className="flex justify-between mb-2 items-center">
         <h3 className="font-medium text-lg">{title}</h3>
         <div className="bg-[#dc2626] py-[2px]  rounded-sm">
           <IoClose className="cursor-pointer text-white" onClick={onClose} />
         </div>
       </div>
       <hr />
       <div className="mt-3">
       <div className="flex flex-col relative">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
            Customer Name
          </label>
          <input
            type="text"
            name="customer-name"
            defaultValue={customerName}
            placeholder="customer name"
            className="input p-2 input-bordered  border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
          />
        </div>

        {/* phone */}
        <div className="flex flex-col relative">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
            Customer Phone
          </label>
          <input
            type="text"
            name="customer-phone"
            defaultValue={customerPhone}
            placeholder="customer-phone"
            className="input p-2 input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
          />
        </div>
        <div className="flex flex-col relative">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
            Customer Address
          </label>
          <input
            type="text"
            name="customer-address"
            defaultValue={customerAddress}
            placeholder="customer-phone"
            className="input p-2 input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
          />
        </div>
        {/* <button
          className="w-full py-2 bg-[#407bff] font-semibold text-sm text-white rounded-lg "
        >Update Info</button> */}
       </div>
     </dialog>
   </div>

   }

    </>
  );
};

export default CustomerInfoModal;
