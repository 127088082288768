import React, { useContext, useEffect, useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../context/context.js";
import axios from 'axios'
import categoryReportsSvg from './CategoryReport.svg'
import totalCategorySvg from './totalCategory.svg'
import saleCategorySvg from './saleCategory.svg';
import dueAmountSvg from './dueAmount.svg';
import CategoryReport from '../CategoryReport/CategoryReport.js';
import ProductCategoryReport from '../ProductCategoryReport/ProductCategoryReport.js';
import TransactionReport from '../Transaction/TransactionReport/TransactionReport.js';
import ProductStockReport from '../ProductStockReport/ProductStockReport.js';
import CategoryStockReport from '../CategoryStockReport/CategoryStockReport.js';
// import WalletIcon from './🦆 icon _wallet_.svg';
import GraphIcon from './Graph.svg';
import BagIcon from './Bag.svg';
import PeopleIcon from './People.svg';
import MonyeIcon from './Monye.svg';
import WalletOrrange from './wallet_orrange .svg';
import WalletPurple from './wallet_purple.svg';
import WalletGreen from './wallet_green.svg';
import WalletBlue from './wallet_blue.svg';
import BalanceReport from '../Balance/BalanceReport/BalanceReport.js';
import AccountingReport from '../Accounting/AccountingReport/AccountingReport.js';
import DueReport from '../DueReport/DueReport.js';
import CustomerSummary from '../CustomerSummary/CustomerSummary.js';
import CustomerWiseDue from '../CustomerWiseDue/CustomerWiseDue.js';
import EmployeeWiseSales from '../EmployeeWiseSales/EmployeeWiseSales.js';
import MonthWiseReport from '../MonthWiseReport/MonthWiseReport.js';
import Swal from "sweetalert2";
import { useLocation } from 'react-router-dom';
import LedgerReport from '../LedgerReport/LedgerReport.js';
import BalanceSheetReport from '../BalanceSheetReport/BalanceSheetReport.js';
import SalesRegisterReport from '../SalesRegisterReport/SalesRegisterReport.js';
import MonthlySalesDayCountingReport from '../MonthlySalesDayCountingReport/MonthlySalesDayCountingReport.js';
import MinimumStockReport from '../MinimumStockReport/MinimumStockReport.js';
import ProfitAndLossAccountReport from '../ProfitAndLossAccountReport/ProfitAndLossAccountReport.js';
import CashBook from '../CashbookDetails/CashBook.js';
import StoreLedger from '../StoreLedger/StoreLedger.js';
import TransactionSummary from '../TransactionSummary/TransactionSummary.js';
// import SalesRegister from '../SalesRegister/SalesRegister.js';
import ImeiReport from '../ImeiReport/ImeiReport.js';
import MonthlyPurchaseReport from '../MonthlyPurchaseReport/MonthlyPurchaseReport.js';
import CashbookSummary from '../CashbookSummaryReport/CashbookSummary.jsx';

const BASE_URL = process.env.REACT_APP_ROOT_URL;


const Analytics = () => {
  const { state } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState("daily");
  const { currentUser, invoiceSetting } = state;
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  //---------------------
  const location = useLocation();
  const navigate = useNavigate();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");

    if (!employeeApiResponse) {
      setHasPermission(true);
      return; // Exit useEffect early
    }

    if (employeeApiResponse) {
      // Parse the employee data from local storage
      const employeeData = JSON.parse(employeeApiResponse);

      // Get the "Exporter" feature from the employee's role features
      const analyticsFeature = employeeData.role.features.find(
        (feature) => feature.name === "Analytics"
      );

      // Check if the "Exporter" feature and "Visible" option are both active
      if (analyticsFeature && analyticsFeature.status === 1) {

        // Grant access based on the route and permissions
        if (location.pathname === "/analytics") {
          setHasPermission(true);
          return; // Exit useEffect if permission is granted
        }
      }
      setHasPermission(false);
      // If no permission, navigate to dashboard first
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
    // If not an employee, the useEffect gets skipped
  }, [navigate, location]);
  //---------------------

  const [webCardData, setWebCardData] = useState([]);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  //const navigate = useNavigate();

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  //web-card data
  const getWebCardData = (activeTab) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}web-card?interval=${activeTab}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("==========");
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data;
          setWebCardData(fetchedData);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };
  useEffect(() => {
    if(hasPermission){
      getWebCardData(activeTab)
    }
  }, [activeTab,hasPermission]);


  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  console.log("webcard data", webCardData)
  return (
    <>

      <div className="flex flex-row gap-[1%] pt-32 md:pt-0 pb-3 md:!pb-0 fixed md:static bg-[#EBF1FF] md:bg-transparent md:translate-x-0 top-0 left-0 pl-[30px] md:pl-0 w-full z-[9998]">

        <div
          onClick={() => handleTabClick("daily")}
          className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "daily"
            ? "border-[#407BFF] text-[#407BFF]"
            : "text-[#1C2F48] border-transparent"
            }`}
        >
          Daily
        </div>
        <div
          onClick={() => handleTabClick("weekly")}
          className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "weekly"
            ? "border-[#407BFF] text-[#407BFF]"
            : "text-[#1C2F48] border-transparent"
            }`}
        >
          Weekly
        </div>
        <div
          onClick={() => handleTabClick("monthly")}
          className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "monthly"
            ? "border-[#407BFF] text-[#407BFF]"
            : "text-[#1C2F48] border-transparent"
            }`}
        >
          Monthly
        </div>
        <div
          onClick={() => handleTabClick("yearly")}
          className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "yearly"
            ? "border-[#407BFF] text-[#407BFF]"
            : "text-[#1C2F48] border-transparent"
            }`}
        >
          Yearly
        </div>
        <div
          onClick={() => handleTabClick("all")}
          className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "all"
            ? "border-[#407BFF] text-[#407BFF]"
            : "text-[#1C2F48] border-transparent"
            }`}
        >
          All Time
        </div>
      </div>
      <div className='mt-7 font-lato'>

            {/*Temporary hidden this section */}
        <div className="rounded-[25px] bg-[#fff] pt-5 px-4 pb-3 hidden">
          <div className="flex flex-col w-full">
            {activeTab && (
              <div className="category-card grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 rounded-2xl pb-4 col-p-full ">
                {/* total Balance*/}

                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-[#f538cc] text-white flex justify-between py-7  px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${MonyeIcon}`} />
                    <p>{webCardData?.total_balance ? webCardData?.total_balance : 0}
                      <sub className="text-xs font-extralight ml-1">tk</sub></p>

                    <p className="text-sm font-extralight">Total Balance</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{webCardData?.total_balance?.percentage}</p>
                    <img
                      src={`${BASE_URL}/assets/dashboard/totalcardgraph.svg`}
                      alt="logo"
                    />
                  </div>
                </div>


                {/* total "sales" */}

                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-[#ff3c00] text-white flex justify-between py-7 px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${GraphIcon}`} />
                    <p>
                      {webCardData?.total_sales ? webCardData?.total_sales : 0}
                      <sub className="text-xs font-extralight ml-1">tk</sub>
                    </p>
                    <p className="text-sm font-extralight">Total Sales Amount</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{webCardData?.total_sales?.percentage}</p>
                    <img
                      src={`${BASE_URL}/assets/dashboard/totalcardgraph.svg`}
                      alt="logo"
                    />
                  </div>
                </div>

                {/* total "Expense Amount" */}

                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-[#9B54E1] text-white flex justify-between py-7 px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${BagIcon}`} />
                    <p>
                      {webCardData?.total_expense ? webCardData?.total_expense : 0}

                    </p>
                    <p className="text-sm font-extralight">Total Expense Amount</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{webCardData?.total_expense?.percentage}</p>
                    <img
                      src={`${BASE_URL}/assets/dashboard/totalcardgraph.svg`}
                      alt="logo"
                    />
                  </div>
                </div>
                {/* total Recievable amount */}

                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-[#29b170] text-white flex justify-between py-7 px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${PeopleIcon}`} />
                    <p>{webCardData?.total_receivable ? webCardData?.total_receivable : 0}
                    </p>
                    <p className="text-sm font-extralight">Total Receivable Amount</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{webCardData?.total_receivable?.percentage}</p>
                    <img
                      src={`${BASE_URL}/assets/dashboard/totalcardgraph.svg`}
                      alt="logo"
                    />
                  </div>
                </div>

                {/* total sale due */}
                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-[#190f6d] text-white flex justify-between py-7 px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${BagIcon}`} />
                    <p>{webCardData?.total_sale_due ? webCardData?.total_sale_due : 0}
                    </p>
                    <p className="text-sm font-extralight">Total Sale Due</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{webCardData?.total_sale_due?.percentage}</p>
                    <img
                      src={`${BASE_URL}/assets/dashboard/totalcardgraph.svg`}
                      alt="logo"
                    />
                  </div>
                </div>

                {/* total purchase due */}
                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-[#ff6600] text-white flex justify-between py-7 px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${PeopleIcon}`} />
                    <p>{webCardData?.total_purchase_due ? webCardData?.total_purchase_due : 0}
                    </p>
                    <p className="text-sm font-extralight">Total Purchase Due</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{webCardData?.total_purchase_due?.percentage}</p>
                    <img
                      src={`${BASE_URL}/assets/dashboard/totalcardgraph.svg`}
                      alt="logo"
                    />
                  </div>
                </div>

                {/* total wholesale due */}
                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-[#00A9F1] text-white flex justify-between py-7 px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${PeopleIcon}`} />
                    <p>{webCardData?.total_wholesale_due ? webCardData?.total_wholesale_due : 0}
                    </p>
                    <p className="text-sm font-extralight">Total Wholesale Due</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{webCardData?.total_wholesale_due?.percentage}</p>
                    <img
                      src={`${BASE_URL}/assets/dashboard/totalcardgraph.svg`}
                      alt="logo"
                    />
                  </div>
                </div>

                {/* total Debit  Amount */}
                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-[#009689] text-white flex justify-between py-7 px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${PeopleIcon}`} />
                    <p>{webCardData?.total_debit_amount ? webCardData?.total_debit_amount : 0}
                    </p>
                    <p className="text-sm font-extralight">Total Debit Amount</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{webCardData?.total_debit_amount?.percentage}</p>
                    <img
                      src={`${BASE_URL}/assets/dashboard/totalcardgraph.svg`}
                      alt="logo"
                    />
                  </div>
                </div>

                {/* total Payable  Amount */}
                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-[#63398d] text-white flex justify-between py-7 px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${PeopleIcon}`} />
                    <p>{webCardData?.total_payable ? webCardData?.total_payable : 0}
                    </p>
                    <p className="text-sm font-extralight">Total Payable Amount</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{webCardData?.total_payable?.percentage}</p>
                    <img
                      src={`${BASE_URL}/assets/dashboard/totalcardgraph.svg`}
                      alt="logo"
                    />
                  </div>
                </div>


                {/* total  customer*/}
                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-[#053645] text-white flex justify-between py-7 px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${MonyeIcon}`} />
                    <p>{webCardData?.total_customer ? webCardData?.total_customer : 0}
                    </p>
                    <p className="text-sm font-extralight"> Total Customer</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{webCardData?.total_customer?.percentage}</p>
                    <img
                      src={`${BASE_URL}/assets/dashboard/totalcardgraph.svg`}
                      alt="logo"
                    />
                  </div>
                </div>

                {/* total credit  Amount */}
                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-[#1bb957] text-white flex justify-between py-7 px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${PeopleIcon}`} />
                    <p>{webCardData?.total_credit_amount ? webCardData?.total_credit_amount : 0}
                    </p>
                    <p className="text-sm font-extralight">Total Credit Amount</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{webCardData?.total_credit_amount?.percentage}</p>
                    <img
                      src={`${BASE_URL}/assets/dashboard/totalcardgraph.svg`}
                      alt="logo"
                    />
                  </div>
                </div>

                {/* total vendor*/}
                <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-[#ff2828] text-white flex justify-between py-7 px-3 rounded-xl">
                  <div className="space-y-1">
                    <img src={`${PeopleIcon}`} />
                    <p>{webCardData?.total_vendor ? webCardData?.total_vendor : 0}
                    </p>
                    <p className="text-sm font-extralight">Total Vendor</p>
                  </div>
                  <div className="text-right flex flex-col gap-2 justify-between">
                    <p className="">{webCardData?.total_vendor?.percentage}</p>
                    <img
                      src={`${BASE_URL}/assets/dashboard/totalcardgraph.svg`}
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>

        {/* Transaction history */}
        <TransactionReport
          categoryReportsSvg={categoryReportsSvg}
          totalCategorySvg={totalCategorySvg}
          saleCategorySvg={saleCategorySvg}
          dueAmountSvg={dueAmountSvg}
          activeTab={activeTab}
          loadingCallback={loadingCallback}
          WalletBlue={WalletBlue}
          WalletGreen={WalletGreen}
          WalletOrrange={WalletOrrange}
          WalletPurple={WalletPurple}
        >
        </TransactionReport>
        {/* Balance Report */}
        <BalanceReport
          categoryReportsSvg={categoryReportsSvg}
          totalCategorySvg={totalCategorySvg}
          saleCategorySvg={saleCategorySvg}
          dueAmountSvg={dueAmountSvg}
          activeTab={activeTab}
          loadingCallback={loadingCallback}
          WalletBlue={WalletBlue}
          WalletGreen={WalletGreen}
          WalletOrrange={WalletOrrange}
          WalletPurple={WalletPurple}
        ></BalanceReport>

        {/* Accounting History */}
        <AccountingReport
          categoryReportsSvg={categoryReportsSvg}
          totalCategorySvg={totalCategorySvg}
          saleCategorySvg={saleCategorySvg}
          dueAmountSvg={dueAmountSvg}
          activeTab={activeTab}
          loadingCallback={loadingCallback}
          WalletBlue={WalletBlue}
          WalletGreen={WalletGreen}
          WalletOrrange={WalletOrrange}
          WalletPurple={WalletPurple}
        ></AccountingReport>

        {/* category sale report */}
        <CategoryReport
          categoryReportsSvg={categoryReportsSvg}
          totalCategorySvg={totalCategorySvg}
          saleCategorySvg={saleCategorySvg}
          dueAmountSvg={dueAmountSvg}
          loadingCallback={loadingCallback}
          activeTab={activeTab}
          WalletBlue={WalletBlue}
          WalletGreen={WalletGreen}
          WalletOrrange={WalletOrrange}
          WalletPurple={WalletPurple}
        />
        {/* product sale report */}
        <ProductCategoryReport
          handleNavigate={handleNavigate}
          activeTab={activeTab}
          loadingCallback={loadingCallback}
          totalCategorySvg={totalCategorySvg}
          categoryReportsSvg={categoryReportsSvg}
          dueAmountSvg={dueAmountSvg}
          saleCategorySvg={saleCategorySvg}
          WalletBlue={WalletBlue}
          WalletGreen={WalletGreen}
          WalletOrrange={WalletOrrange}
          WalletPurple={WalletPurple}

        />

        {/* IMEI/Serial Report */}
          <ImeiReport/>

        {/* product stock report */}
        <ProductStockReport
          handleNavigate={handleNavigate}
          activeTab={activeTab}
          loadingCallback={loadingCallback}
          totalCategorySvg={totalCategorySvg}
          categoryReportsSvg={categoryReportsSvg}
          dueAmountSvg={dueAmountSvg}
          saleCategorySvg={saleCategorySvg}
          WalletBlue={WalletBlue}
          WalletGreen={WalletGreen}
          WalletOrrange={WalletOrrange}
          WalletPurple={WalletPurple}
        />
        {/* category stock report */}
        <CategoryStockReport
          handleNavigate={handleNavigate}
          activeTab={activeTab}
          loadingCallback={loadingCallback}
          totalCategorySvg={totalCategorySvg}
          categoryReportsSvg={categoryReportsSvg}
          dueAmountSvg={dueAmountSvg}
          saleCategorySvg={saleCategorySvg}
          WalletBlue={WalletBlue}
          WalletGreen={WalletGreen}
          WalletOrrange={WalletOrrange}
          WalletPurple={WalletPurple}
        />

        {/* Ledger Report */}
        <LedgerReport />

        {/* balance sheet report */}
        <BalanceSheetReport />

        {/* Sales Register Report */}
        <SalesRegisterReport />

        {/* Monthly SalesDay Counting Report */}
        <MonthlySalesDayCountingReport />

        {/* Monthly Purchase Summary report */}
        <MonthlyPurchaseReport />

        {/* Minimum Stock Report Report */}
        <MinimumStockReport />

        {/* Minimum Stock Report Report */}
          <ProfitAndLossAccountReport />

        {/* cash-book-details */}
        <CashBook />

        {/* cash-book-details */}
        <CashbookSummary />

        {/* store ledger */}
        <StoreLedger />

        {/* transaction summary */}
        <TransactionSummary />

        {/* Due Report */}
        <DueReport />

        {/* Customer Summary */}
        <CustomerSummary />

        {/* Customer Wise Due Report */}
        <CustomerWiseDue />

        {/* Employee Wise Sales Report */}
        <EmployeeWiseSales />

        {/* Month Wise Report */}
        <MonthWiseReport />

      </div>
      {loading && (

        <div className="custom-loader-logo">
          {/* <BeatLoader color={"#123abc"} size={20} /> */}
          <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
        </div>
      )}

    </>
  );
};

export default Analytics;