import React, { useState } from "react";

const DueReportHistory = ({ report, onSelect, index, total_due }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onSelect(index, report);
  };
  return (
    <div className="grid grid-cols-6 md:gap-5  gap-2 items-center justify-start font-nunito font-[500] text-[14px] text-[#000232] border-t-[1px] border-solid border-[#E7EBF4] py-[8px]">
      {/* <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-7 items-start"> */}
      <input
        type="checkbox"
        onChange={handleCheckboxChange}
        checked={report.completed}
        className="text-start justify-start w-fit"
      />
      <div>
        <div className="">
          {/* <div className="font-nunito text-[14px]"> */}
          <div className="font-[500] text-[#000232]">#{report?.name}</div>
          <div className="font-[700] text-[#87889E]">{report?.id}</div>
          {/* </div> */}
        </div>
      </div>
      {/* <div className="text-center">{expense.catogory_name}</div> */}
      <div>
        <p className="text-start">{report?.paid_amount?.toFixed(2)}</p>
      </div>
      <div>
        <p className="text-start">{report?.total_amount?.toFixed(2)}</p>
      </div>
      <div>
        <p className="text-start">{report?.due?.toFixed(2)}</p>
      </div>
      <div>
        <p className="text-start">{report?.invoice_id}</p>
      </div>
{/*       <div className="col-span-6 flex items-center justify-end mr-10 mt-5">
        <p className="font-bold text-[#000232] mr-2">Total Due:</p>
        <p className="text-start">{total_due}</p>
      </div> */}
    </div>
  );
};

export default DueReportHistory;
