import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  page: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    position: "relative",
  },
  header: {
    marginBottom: 0,
    textAlign: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 4,
  },
  shopInfo: {
    fontSize: 10,
    marginBottom: 10,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    marginVertical: 1,
    width: "100%",
  },
  thickDivider: {
    borderBottomWidth: 2,
    borderBottomColor: "#000",
    marginVertical: 2,
    width: "100%",
  },
  reportTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginTop: 2,
    marginBottom: 4,
  },
  table: {
    display: "table",
    width: "100%",
    marginTop: 2,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    paddingVertical: 5,
    paddingHorizontal: 3,
    color: "#000232",
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
    borderWidth: 0.5,
    borderColor: "#000",
    backgroundColor: "#E7EBF4",
  },
  tableColSl: {
    flex: 0.2,
    paddingVertical: 5,
    paddingHorizontal: 3,
    textAlign: "center",
    fontSize: 10,
  },
  tableColDate: {
    flex: 0.8,
    paddingVertical: 5,
    paddingHorizontal: 3,
    textAlign: "center",
    fontSize: 10,
  },
  tableColInvoice: {
    flex: 0.6,
    padding: 5,
    textAlign: "left",
    fontSize: 10,
  },
  tableColVendor: {
    flex: 1.2,
    padding: 5,
    textAlign: "left",
    fontSize: 10,
  },
  tableColProduct: {
    flex: 2,
    padding: 5,
    textAlign: "left",
    fontSize: 10,
  },
  // tableColQty: {
  //   flex: 0.5,
  //   padding: 5,
  //   textAlign: "center",
  //   fontSize: 10,
  // },
  // tableColPayMode: {
  //   flex: 0.8,
  //   padding: 5,
  //   textAlign: "center",
  //   fontSize: 10,
  // },
  tableColImei: {
    flex: 1.3,
    padding: 5,
    textAlign: "left",
    fontSize: 10,
  },
  tableColAmount: {
    flex: 1,
    padding: 5,
    textAlign: "right",
    fontSize: 10,
  },
  tableRowAlternate: {
    backgroundColor: "#F5F5F5",
  },
  grandTotalRow: {
    backgroundColor: "#E7EBF4",
    fontWeight: "bold",
  },
  footer: {
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: 10,
  },
});

// PDF document component
const MonthlyPurchaseReportPDFDocument = ({
  reports,
  user,
  startDate,
  endDate,
  dayCount,
  grandTotal,
}) => (
  <Document>
    <Page style={styles.page} size="A4" orientation="landscape">
      {/* Header */}
      <View style={styles.header}>
      <Text style={styles.reportTitle}>
          Monthly Purchase Day Counting Report
        </Text>
        <Text style={styles.shopInfo}>
          ({startDate} to {endDate})
        </Text>

        {/* Horizontal Lines */}
        {/* <View style={styles.thickDivider} /> */}
        {/* <View style={styles.divider} /> */}
        {/* <View style={styles.thickDivider} /> */}


      </View>

      {/* Table */}
      <View style={styles.table}>
        {/* Table Header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableColHeader, styles.tableColSl]}>Sl.</Text>
          <Text style={[styles.tableColHeader, styles.tableColDate]}>Date</Text>
          <Text style={[styles.tableColHeader, styles.tableColInvoice]}>Voucher No.</Text>
          <Text style={[styles.tableColHeader, styles.tableColVendor]}>Vendor Name</Text>
          <Text style={[styles.tableColHeader, styles.tableColProduct]}>Product Name</Text>
          {/* <Text style={[styles.tableColHeader, styles.tableColQty]}>Qty</Text>
          <Text style={[styles.tableColHeader, styles.tableColPayMode]}>Pay Mode</Text> */}
          <Text style={[styles.tableColHeader, styles.tableColImei]}>IMEI/Serial</Text>
          <Text style={[styles.tableColHeader, styles.tableColAmount]}>Amount (BDT)</Text>
        </View>

        {/* Table Body */}
        {reports.map((report, index) => (
          <View
            key={index}
            style={[
              styles.tableRow,
              index % 2 === 0 && styles.tableRowAlternate,
            ]}
          >
            <Text style={styles.tableColSl}>{index + 1}</Text>
            <Text style={styles.tableColDate}>{report?.date || "N/A"}</Text>
            <Text style={styles.tableColInvoice}>
            {report?.invoice_id?.split("-").pop() || "N/A"}
            </Text>
            <Text style={styles.tableColVendor}>
              {report?.vendor_name || "N/A"}
            </Text>
            <Text style={styles.tableColProduct}>{report?.product_name}</Text>
            {/* <Text style={styles.tableColQty}>{report?.qty || "N/A"}</Text>
            <Text style={styles.tableColPayMode}>{report?.pay_mode || "N/A"}</Text> */}
            <Text style={styles.tableColImei}>{report?.imei || "N/A"}</Text>
            <Text style={styles.tableColAmount}>{report?.price || 0}</Text>
          </View>
        ))}

        {/* Grand Total Row */}
        <View style={[styles.tableRow, styles.grandTotalRow]}>
          <Text style={styles.tableColSl}></Text>
          <Text style={styles.tableColDate}>{dayCount || 0} Day(s)</Text>
          <Text style={styles.tableColInvoice}></Text>
          <Text style={styles.tableColVendor}></Text>
          <Text style={styles.tableColProduct}>Grand Total</Text>
          <Text style={styles.tableColQty}></Text>
          <Text style={styles.tableColPayMode}></Text>
          <Text style={styles.tableColAmount}>{grandTotal || 0}</Text>
        </View>
      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <Text>Prepared by</Text>
        <Text>Checked/Recommended by</Text>
        <Text>Authorized by</Text>
      </View>
    </Page>
  </Document>
);

export default MonthlyPurchaseReportPDFDocument;
