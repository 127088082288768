import React, { useRef } from "react";

const user = JSON.parse(localStorage.getItem("user"));

const ImeiReportPrintPage = ({
  reports,
  componentRef,
  checkedAll,
  handleCheckboxChange,
}) => {
  console.log(reports);
  let serialNumber = 1;

  // const today = new Date().toISOString().split('T')[0];
  const today = new Date().toLocaleDateString("en-us", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
  const currentTime = new Date().toLocaleTimeString("en-us", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <div ref={componentRef} className="px-8">

      {/* Print Styles */}
      <style>
        {`
        @media print {
          @page {
            margin: 0.7in 0in; /* Top and bottom margins */
          }

          h1, p {
            margin: 0;
            padding: 0;
          }
        }
      `}
      </style>


      <div className="flex justify-between mb-2">
        <p className="font-extrabold">{currentTime}</p>
        <p className="font-extrabold">Print</p>
      </div>

      <table class="w-full border border-gray-300">
        <tr>
          <th colspan="3" class="p-2 border-b border-gray-300">
            <h1 class="text-lg font-bold text-center">
              IMEI/Serial Stock Report
            </h1>
          </th>
        </tr>
        <tbody>
          <tr>
            <td class="border border-gray-300 p-2">
              <p className="font-normal">
                Shop Name: {user?.invoice_settings.shop_name}
              </p>
              <p className="font-normal">Email: {user?.email}</p>
              <p className="font-normal">Address: {user?.address}</p>
            </td>
            <td class="border border-gray-300 p-2">
              <p className="font-normal">Date: {today}</p>
              <p className="font-normal">Mobile: {user?.phone}</p>
            </td>
            <td class="border border-gray-300 p-2 text-center">
              <img
                src={user?.invoice_settings?.shop_logo}
                alt="Company Logo"
                class="w-16 h-auto mx-auto"
              />
            </td>
          </tr>
          <tr>
            <td
              colspan="3"
              class="border border-gray-300 text-center font-semibold p-1"
            >
              From: N/A to N/A
            </td>
          </tr>
        </tbody>
      </table>

      {Object.keys(reports).map((brandName) => {
        return (
          <div key={brandName}>
            <table
              style={{
                ...styles.table,
                ...styles.productHeader,
                ...{ fontWeight: "normal" },
              }}
            >
              <tbody>
                <tr>
                  <td style={styles.productRow}>Product Name: {brandName}</td>
                </tr>
              </tbody>
            </table>

            <table style={{ ...styles.table, ...{ fontWeight: "normal" } }}>
              <thead style={{ width: "100%" }}>
                <tr>
                  <th
                    style={{
                      ...styles.th,
                      ...styles.printTh,
                      ...{ fontWeight: "normal" },
                    }}
                  >
                    Sl.
                  </th>
                  <th
                    style={{
                      ...styles.th,
                      ...styles.printTh,
                      ...{ fontWeight: "normal" },
                    }}
                  >
                    Date
                  </th>
                  {/* <th style={{ ...styles.th, ...styles.printTh, ...{ fontWeight: 'normal' } }}>Brand</th> */}
                  <th
                    style={{
                      ...styles.th,
                      ...styles.printTh,
                      ...{ fontWeight: "normal" },
                    }}
                  >
                    Name
                  </th>
                  <th
                    style={{
                      ...styles.th,
                      ...styles.printTh,
                      ...{ fontWeight: "normal" },
                    }}
                  >
                    IMEI/Serial
                  </th>
                  <th
                    style={{
                      ...styles.th,
                      ...styles.printTh,
                      ...{ fontWeight: "normal" },
                    }}
                  >
                    P. Inv.
                  </th>
                  <th
                    style={{
                      ...styles.th,
                      ...styles.printTh,
                      ...{ fontWeight: "normal" },
                    }}
                  >
                    S. Inv.
                  </th>
                  <th
                    style={{
                      ...styles.th,
                      ...styles.printTh,
                      ...{ fontWeight: "normal" },
                    }}
                  >
                    P. Price
                  </th>
                  <th
                    style={{
                      ...styles.th,
                      ...styles.printTh,
                      ...{ fontWeight: "normal" },
                    }}
                  >
                    S. Price
                  </th>

                </tr>
              </thead>
              <tbody>
                {reports[brandName].map((product, index) => (
                  <tr key={index}>
                    <td style={{ ...styles.td, ...{ fontWeight: "normal" } }}>
                      {index + 1}
                      <span className="hidden">{serialNumber++}</span>
                    </td>
                    <td
                      style={{
                        ...styles.tdTransactionDate,
                        ...{ fontWeight: "normal" },
                      }}
                    >
                      {product.date}
                    </td>
                    {/* <td style={{ ...styles.td, ...{ fontWeight: 'normal' }}}>{product.brand_name}</td> */}
                    <td style={{ ...styles.td, ...{ fontWeight: "normal" } }}>
                      {product.product_name}
                    </td>
                    <td style={{ ...styles.td, ...{ fontWeight: "normal" } }}>
                      {product.imei}
                    </td>
                    <td style={{ ...styles.td, ...{ fontWeight: "normal" } }}>
                      {product?.purchase_invoice
                        ? product?.purchase_invoice?.split("-").pop()
                        : "N/A"}
                    </td>
                    <td style={{ ...styles.td, ...{ fontWeight: "normal" } }}>
                      {product?.sale_invoice
                        ? product?.sale_invoice?.split("-").pop()
                        : "N/A"}
                    </td>
                    <td style={{ ...styles.td, ...{ fontWeight: "normal" } }}>
                      {product.purchase_price || "N/A"}{" "}
                      {/* {user?.invoice_settings.currency_info.code} */}
                    </td>
                    <td style={{ ...styles.td, ...{ fontWeight: "normal" } }}>
                      {product.sale_price || "N/A"}{" "}
                      {/* {user?.invoice_settings.currency_info.code} */}
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
      <h1 style={{ marginTop: "20px", fontWeight: "bold", textAlign: "right" }} >
        Total Rows:{" "}
        {Object.values(reports).reduce(
          (total, brandProducts) => total + brandProducts.length,
          0
        )}
      </h1>
    </div>
  );
};

const styles = {
  titletext: {
    border: "1px solid black",
    textAlign: "center",
    margin: "0",
    padding: "2px",
    fontSize: "12px",
    width: "100%",
  },
  productHeader: {
    marginTop: "30px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  column: {
    border: "1px solid black",
    textAlign: "left",
    verticalAlign: "top",
    padding: "5px",
    fontSize: "12px",
  },
  logo: {
    width: "100px", // Adjust size as needed
    height: "auto",
  },
  dateRange: {
    textAlign: "center",
    padding: "5px",
    fontSize: "12px",
    border: "1px solid black",
  },
  productRow: {
    border: "1px solid black",
    textAlign: "center",
    padding: "5px",
    fontSize: "14px",
    width: "100%",
  },
  th: {
    border: "1px solid black",
    padding: "5px",
    textAlign: "center",
    fontSize: "14px",
  },
  td: {
    border: "1px solid black",
    padding: "5px",
    textAlign: "center",
    fontSize: "12px",
  },
  tdTransactionDate: {
    border: "1px solid black",
    padding: "5px",
    textAlign: "center",
    fontSize: "12px",
    whiteSpace: "nowrap",
  },
};

export default ImeiReportPrintPage;