import React, { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { timeDiff,formatPrice } from '../../utils/Common';
import Pagination from '../../utils/pagination'; // Adjust the path accordingly
import { AppContext } from '../../context/context.js';
import DatePicker from "react-datepicker";
import ContentLayout from '../../layout/ContentLayout';
import { get, post } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import CustomerListItem from '../../components/Invoice/CustomerListItem.js';
import { useFlashMessage } from '../../context/FlashMessageContext';
import { BiPlus } from "react-icons/bi";
import { Modal } from "../../utils/Modal";
import ExpenseCategoryItem from "../../components/Expense/ExpenseCategoryItem.js";
import Swal from "sweetalert2";
import axios from "axios";
import { useLocation } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_ROOT_URL;

const ExpenseCategoryPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [showAddExpenseModal, setShowAddExpenseModal] = useState(false);
  const [expenseCategory, setExpenseCategory] = useState(null);
  const [warningMessage, setWarningMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const navigate = useNavigate();
  const { flashMessage, getFlashMessageStyle, setErrorFlashMessage, } = useFlashMessage();
  const buttonRef = useRef(null);

  const [formData, setFormData] = useState({

    // expense_category_description:""
  });

  //-----------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  const [isViewActive, setIsViewActive] = useState(false);
  const [isCreateActive, setIsCreateActive] = useState(false);

  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");
    if (!employeeApiResponse) {
      setHasPermission(true);
      setIsCreateActive(true);
      return;
    }
      const employeeData = JSON.parse(employeeApiResponse);
      const expenseFeature = employeeData.role.features.find(
        (feature) => feature.name === "Expense" && feature.status === 1
      );
      if (expenseFeature && location.pathname === "/expensecategory") {
        const viewExpenseOptionActive = expenseFeature.feature_options.find(
          (option) => option.name === "View" && option.status === 1
        );
        const createExpenseOptionActive = expenseFeature.feature_options.find(
          (option) => option.name === "Create" && option.status === 1
        );
        if (viewExpenseOptionActive) {
          setIsViewActive(true);
          setHasPermission(true);
        } else {
          setIsViewActive(false);
          setHasPermission(false);
        }
        setIsCreateActive(!!createExpenseOptionActive);
      } else {
        setHasPermission(false);
        setIsCreateActive(false);
        navigate("/dashboard", { replace: true });
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Permission Denied",
            text: "You do not have permission for this feature.",
          });
        }, 100);
      }
    }
  , []);
  //-----------------

  const [expenseCategoryList, setExpenseCategoryList] = useState([]);
  const [refetch, setRefetch] = useState(false)
  const token = localStorage.getItem("token");

  useEffect(()=>{
    if ((isViewActive && hasPermission) || !localStorage.getItem("employee")) {
      getExpenseCategoryList()
    }
  },[refetch,,isViewActive,hasPermission])

  const getExpenseCategoryList = (page, searchKeyword) => {
    loadingCallback(true);
    get(`get-expense-type-list`, token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          //console.log(response.data)
          setExpenseCategoryList(response.data)
        }
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        localStorage.clear();
        localStorage.setItem("token", false);
        // Clear session and local storage
        localStorage.setItem("isPinVerify", false);

        navigate("/login");
      });
  };

  // -----------------------

  const showModalBeforeDelete = (id, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id, index);
      }
    });
  };

  const deleteData = (id, index) => {
    loadingCallback(true);
    const reqData = { id: id };
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}delete-expense-type`,
      data: reqData,
      headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
  })
  .then((response) => {
      loadingCallback(false);
      if (response.data.success) {
          console.log("Expense deleted successfully.");
          setSuccessFlashMessage("Expense deleted successfully.");

          getExpenseCategoryList(currentPage);
      } else {
          setErrorFlashMessage("Expense delete failed.");
          console.log("Expense delete failed.");
      }
  })
  .catch((error) => {
      loadingCallback(false);
      if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);
          navigate("/login");
      }
      console.log(error);
  });
  };

  const handleCreateClick = () => {
    if (isCreateActive) {
      // Show modal if creation is allowed
      setShowAddExpenseModal((val) => !val);
    } else {
      // Show warning if creation is not allowed
      Swal.fire({
        icon: "warning",
        title: "Permission Denied",
        text: "You do not have permission for this action.",
      });
    }
  };

  return (
    <>
      <ContentLayout>
      {/* {showModal ? (
          <Modal
            isOpen={showModal}
            setOpen={setShowModal}
            setData={setExpenseCategory}
            setWarningMessage={setWarningMessage}
            title="Expense Category Info"
            formId="my_modal_export_category"
            loadingCallback={loadingCallback}
            navigate={navigate}
            type="exportcategory"
          />
        ) : null} */}
      <div className="main-content recentTableContent">
          <div className="flex flex-col w-full">
            <div className='flex flex-col md:flex-row justify-between mb-4'>
              <div>
                <h1 className='text-[#000232] text-[22px] font-[600] font-lato mb-2'>Expense Category List</h1>
              </div>
              <div className='flex gap-2 justify-center'>
                <div className="flex relative  w-[95%]">
                  <input
                    autoFocus
                    type="text"
                    className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[28px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                    placeholder="Search Category"
                    // onChange={(e) => {
                    //   setSearchFilter({...searchFilter, keyword: e.target.value})
                    // }}
                  />

                  <div className="absolute top-[37%] left-[10px]">
                    <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                  </div>

                  {/* <div
                    ref={buttonRef}
                    onClick={handleButtonClick}
                    className="absolute top-[36%] right-[10px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z" fill="#102048" />
                      <path d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z" fill="#102048" />
                      <path d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z" fill="#102048" />
                      <path d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z" fill="#102048" />
                      <path d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z" fill="#102048" />
                      <path d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z" fill="#102048" />
                      <path d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z" fill="#102048" />
                      <path d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z" fill="#102048" />
                      <path d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z" fill="#102048" />
                    </svg>
                  </div> */}
                  {/* {showNavMenu && (
                    <div ref={menuRef} className="absolute z-50 mt-[2px] w-[230px] shadow-sm left-[15px] top-[50px] bg-[#FFF] border-1 border-solid p-3 rounded-[10px]">
                      <div onClick={(e)=> setSearchFilter({...searchFilter, invoiceId: (!searchFilter.invoiceId)})} className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start">
                        <div className=''>
                          <input
                            type="checkbox"
                            checked={searchFilter.invoiceId}
                            onChange={(e)=>setSearchFilter({...searchFilter, invoiceId: (!searchFilter.invoiceId)})}
                          />
                        </div>
                        <div className="">
                          <p className=" text-[#000] text-left font-[500] ps-2 text-xs">InvoiceID</p>
                        </div>
                      </div>
                      <div>
                        <p className="p-2 text-md">Date</p>
                        <div className="flex justify-between w-full col-span-3 items-center mt-[5px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
                          <div className="relative border-[#C1CFEF] py-1 border w-full flex-1 rounded-xl">
                            <DatePicker
                            selected={searchFilter.startDate}
                            onChange={(date) => setSearchFilter({...searchFilter, startDate: date})}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="00/00/00"
                            className="w-full px-2 text-xs focus:outline-none"
                            />
                              <span className="bg-white absolute left-2 font-semibold px-1 text-[8px] top-0 text-[#102048] translate-y-[-50%]">
                                Start Date
                            </span>
                          </div>
                          <div className="relative border-[#C1CFEF] py-1 border w-full flex-1 rounded-xl">
                            <DatePicker
                            selected={searchFilter.endDate}
                            onChange={(date) => setSearchFilter({...searchFilter, endDate: date})}
                            dateFormat="dd/MM/yyyy"
                            className="w-full px-2 text-xs focus:outline-none"
                            />
                            <span className="bg-white absolute left-2 font-semibold px-1 text-[8px] top-0 text-[#102048] translate-y-[-50%]">
                              End Date
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
                <button
                  onClick={handleCreateClick}
                  htmlFor="add_expense_category_modal"
                  //onClick={() => setShowAddExpenseModal((val) => !val)}
                  className={`cursor-pointer bg-[#407BFF] rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 pl-[22px] pr-[24px] flex items-center gap-2 ${
                    !isCreateActive && "opacity-50 cursor-not-allowed"
                  }`} // Apply disabled styles
                  style={{ pointerEvents: isCreateActive ? "auto" : "none" }}
                >
                  <BiPlus className="text-xl "></BiPlus>
                </button>
              </div>
            </div>
          </div>
          {(isViewActive && hasPermission) || !localStorage.getItem("employee") ? (
            <>
          <div className="table-conatiner">
              <div className="grid grid-cols-5 gap-2 items-center font-nunito font-[700] text-[14px] text-[#000232]">
                <div className="py-[8px]">ID</div>
                <div className="py-[8px] text-center">Name</div>
                <div className="py-[8px] text-center">Category</div>
                <div className="py-[8px] text-center">Description</div>
                <div className="py-[8px] text-right">Action</div>
              </div>
          </div>
          {flashMessage && (
                <div
                  className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
                    flashMessage.type
                  )}`}
                >
                  {flashMessage.text}
                </div>
          )}
          {loading && (
                <div className="custom-loader-logo">
                  <img
                    className=""
                    src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
                    alt="logo"
                  />
                </div>
          )}

          <div className="max-h-[70vh] overflow-y-auto">
            {expenseCategoryList
                  ? expenseCategoryList.map((item, index) => (
                    <ExpenseCategoryItem
                      key={item.id}
                      index={index}
                      loadingCallback={loadingCallback}
                      navigate={navigate}
                      expensecategory={item}
                      loading={loading}
                      showModalBeforeDelete = {showModalBeforeDelete}
                    />
                    ))
                  : null}


              <div className="hrtable"></div>
          </div>
          </>
          ) : null}
          <div className="hrtable"></div>

      </div>
      {showAddExpenseModal ? (
          <Modal
          loadingCallback={loadingCallback}
          navigate={navigate}
          isOpen={showAddExpenseModal}
          setOpen={setShowAddExpenseModal}
          title="Create Expense Category"
          formId="add_expense_category_modal"
          // type="addExpenseCategory"
          type="addExpenseCategoryModal"
          selectType="expense"
          refetch={()=>setRefetch(val=>!val)}
          formData={formData}
          setFormData={setFormData}
          setWarningMessage={setWarningMessage}
          />
        ) : (
          ""
        )}
      </ContentLayout>
    </>
  );

}

export default ExpenseCategoryPage;