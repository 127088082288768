// import React, { useEffect, useRef, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import { QRCodeCanvas } from "qrcode.react";
// import html2pdf from "html2pdf.js";

// const InvoiceViewer = () => {
//   const { id } = useParams();
//   const [invoiceData, setInvoiceData] = useState(null);
//   const invoiceRef = useRef(null);
//   const [printContentVisible, setPrintContentVisible] = useState(false);

//   console.log(invoiceData);

//   useEffect(() => {
//     const fetchInvoice = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}get-invoice-details/${id}`
//         );
//         const data = await response.json();
//         if (data.success) {
//           setInvoiceData(data.data);
//         }
//       } catch (error) {
//         console.error("Error fetching invoice:", error);
//       }
//     };

//     if (id) {
//       fetchInvoice();
//     }
//   }, [id]);

//   const handleDownloadPDF = () => {
//     if (invoiceRef.current) {
//       const opt = {
//         margin: 10,
//         filename: `invoice-${id}.pdf`,
//         image: { type: "jpeg", quality: 0.98 },
//         html2canvas: { scale: 2 },
//         jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
//       };

//       html2pdf().set(opt).from(invoiceRef.current).save();
//     }
//   };



//   if (!invoiceData)
//     return (
//       <div className="custom-loader-logo flex justify-center">
//         <img
//           src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
//           alt="logo"
//           className="w-10 h-10 animate-spin"
//         />
//       </div>
//     );



//   const handlePrintCustomize = (elementId) => {
//     const printElement = document.getElementById(elementId);

//     if (printElement) {
//       setPrintContentVisible(true);

//       // Get the content of the element to print
//       const printContents = printElement.innerHTML;

//       // Initialize printable content
//       let printableHTML = printContents;

//       // Add specific styles only if the elementId is "pdfConentAreasPad"
//       if (elementId === "pdfConentAreasPad") {
//         const printStyles = `
//                 <style>
//                     @media print {
//                         body {
//                             margin: 0;
//                             padding: 0;
//                         }
//                         #${elementId} {
//                             padding-top: 50px; /* Space at the top of each page */
//                             padding-bottom: 30px; /* Space at the bottom of each page */
//                             page-break-inside: avoid;
//                         }
//                         @page {
//                             margin: 1.5in 0.5in 0.7in 0.5in; /* Top, right, bottom, left */
//                         }
//                     }
//                 </style>
//             `;
//         printableHTML = `
//                 ${printStyles}
//                 ${printContents}
//             `;
//       }

//       // Set the body content and styles dynamically
//       document.body.innerHTML = printableHTML;

//       // Trigger print
//       window.print();

//       // Reload the page to restore original content
//       window.location.reload();
//     } else {
//       console.error(`Element with id "${elementId}" not found.`);
//     }
//   };

//   window.onafterprint = () => {
//     setPrintContentVisible(false);
//     document.body.innerHTML = originalContents;
//     // Restore original content
//     window.location.reload();
//   };

//   return (
//     <div className="min-h-screen bg-gray-100 py-4 sm:py-8">
//       <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div
//           ref={invoiceRef}
//           className="bg-white shadow-lg rounded-lg p-4 sm:p-8"
//           id="pdfConentAreasCustomize"
//         >
//           {/* Header */}
//           <div className="flex flex-col sm:flex-row justify-between items-center pt-2 pb-1 border-b-4 border-blue-300">
//             <div className="w-full sm:w-1/3 flex flex-col justify-start items-center sm:items-start  sm:mb-0">
//               <img
//                 src={invoiceData.user_info.invoice_settings.shop_logo}
//                 alt="Shop Logo"
//                 className="w-[70px] sm:w-[90px] object-contain"
//               />
//               <p className="mt-2">
//                 <span className="font-semibold text-lg tracking-wider">
//                   Sale Invoice
//                 </span>
//               </p>
//             </div>

//             {/* <div className="w-full sm:w-1/3 flex items-center justify-center  mb-2 sm:mb-0">
//               <div className="w-[65px] sm:w-[100px] flex items-center justify-center">
//                 <QRCodeCanvas
//                   value={`${process.env.REACT_APP_ROOT_URL}/invoice-view/${invoiceData.invoice_id}`}
//                   size={70}
//                   className="w-full object-contain"
//                 />
//               </div>
//             </div> */}

//             <div className="w-full sm:w-1/3 flex flex-col justify-end items-center sm:items-end border-t-4 border-blue-300 md:border-t-0">
//               <span className="font-semibold text-lg sm:text-xl md:text-2xl mb-1 tracking-wider text-center sm:text-right">
//                 {invoiceData.user_info.invoice_settings.shop_name}
//               </span>
//               <span className="text-sm sm:text-md text-gray-600 text-center sm:text-right">
//                 <strong>Phone:</strong> {invoiceData.user_info.phone}
//               </span>
//               <span className="text-sm sm:text-md text-gray-600 text-center sm:text-right">
//                 <strong>Email:</strong> {invoiceData.user_info.email}
//               </span>
//               <span className="text-sm sm:text-md text-gray-600 text-center sm:text-right">
//                 <strong>Address:</strong> {invoiceData.user_info.address}
//               </span>
//             </div>
//           </div>

//           {/* Customer Info */}
//           <div className="flex flex-col sm:flex-row items-start sm:items-start mt-4 sm:mt-[10px] justify-between font-nunito">
//             <div className="text-black space-y-[2px] md:space-y-[6px] text-xs lg:text-base sm:mb-0">
//               <p>
//                 <span className="font-semibold">Bill to,</span> <br />
//                 <span className="font-semibold">Customer Name </span>:{" "}
//                 {invoiceData.customer_name}
//               </p>
//               <p>
//                 <span className="font-semibold">Customer Phone</span>:{" "}
//                 {invoiceData.customer_phone}
//               </p>
//               <p>
//                 <span className="font-semibold">Customer ID </span>:{" "}
//                 {invoiceData.customer_id}
//               </p>
//             </div>
//             <div className="flex flex-col justify-start items-start sm:items-end text-black space-y-[2px] md:space-y-[4px] text-xs lg:text-base">
//               <p>
//                 <span className="font-semibold">Invoice No</span>:{" "}
//                 {invoiceData.invoice_id}
//               </p>
//               <p>
//                 <span className="font-semibold">Invoice Date</span>:{" "}
//                 {new Date(invoiceData.created_at).toLocaleString()}
//               </p>
//             </div>
//           </div>

//           {/* Items Table */}
//           <div className="w-full mt-4 mb-0 relative">
//             {/* Transparent Paid by Cash Text */}
//             {invoiceData.multiple_payment &&
//               invoiceData.multiple_payment.length > 0 && (
//                 <div className=" absolute left-0 top-2/3 transform  -rotate-45 -translate-y-1/2 opacity-30 text-gray-500 text-lg font-nunito font-semibold whitespace-nowrap">
//                   Paid by{" "}
//                   {invoiceData.multiple_payment
//                     .map((payment) => payment.payment_type.type_name)
//                     .join(", ")
//                     .replace(/, ([^,]*)$/, " and $1")}
//                 </div>
//               )}
//             <table className="w-full">
//               <thead>
//                 <tr className="leading-normal text-xs lg:text-base font-nunito tracking-wide border-1 border-gray-300">
//                   <th className="font-nunito text-left p-2 sm:p-3 w-[70%] border border-r-1 border-gray-300">
//                     Item Name
//                   </th>
//                   <th className="font-nunito text-right p-2 sm:p-3 w-[30%] border border-r-1 border-gray-300">
//                     Total
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {invoiceData.sales_details.map((item) => (
//                   <tr
//                     key={item.id}
//                     className="font-nunito border-1  border-gray-300 font-normal text-sm"
//                   >
//                     <td className="p-2 sm:p-3 break-words border border-r-1 border-b-3 border-gray-300">
//                       {item.product_info.name} (Qty:{" "}
//                       {item.remaining_qty || item.qty})
//                       <br />
//                       <span className="text-[13px]">
//                         {item.product_imei[0]?.imei || ""}
//                       </span>
//                     </td>
//                     <td className="p-2 sm:p-3 text-right font-semibold whitespace-nowrap border border-r-1 border-b-3 border-gray-300">
//                       {invoiceData.user_info.invoice_settings.currency_info
//                         .symbol || ""}
//                       {Number(item.price) * (item.remaining_qty || item.qty)}
//                     </td>
//                   </tr>
//                 ))}
//                 <tr className="font-nunito border-1 border-gray-300 font-normal text-sm">
//                   <td className="p-2 sm:p-3 break-words text-right border border-r-1 border-gray-300">
//                     Sub Total
//                   </td>
//                   <td className="p-2 sm:p-3 text-right font-semibold whitespace-nowrap border border-r-1 border-gray-300">
//                     {invoiceData.user_info.invoice_settings.currency_info
//                       .symbol || ""}
//                     {invoiceData.sub_total}
//                   </td>
//                 </tr>

//                 <tr className="font-nunito border-1 border-gray-300 font-normal text-sm ">
//                   <td className="p-2 sm:p-3 break-words text-right border border-r-1 border-gray-300">
//                     Discount
//                   </td>
//                   <td className="p-2 sm:p-3 text-right font-semibold whitespace-nowrap border border-r-1 border-gray-300">
//                     {invoiceData.user_info.invoice_settings.currency_info
//                       .symbol || ""}
//                     {invoiceData.discount}
//                   </td>
//                 </tr>
//                 <tr className="font-nunito border-1 border-gray-300 font-normal text-sm">
//                   <td className="p-2 sm:p-3 break-words text-right border border-r-1 border-gray-300">
//                     Grand Total
//                   </td>
//                   <td className="p-2 sm:p-3 text-right font-semibold whitespace-nowrap border border-r-1 border-gray-300">
//                     {invoiceData.user_info.invoice_settings.currency_info
//                       .symbol || ""}
//                     {invoiceData.sub_total - invoiceData.discount}
//                   </td>
//                 </tr>
//                 <tr className="font-nunito border-1 border-gray-300 font-normal text-sm">
//                   <td className="p-2 sm:p-3 break-words text-right border border-r-1 border-gray-300">
//                     Paid Amount
//                   </td>
//                   <td className="p-2 sm:p-3 text-right font-semibold whitespace-nowrap border border-r-1 border-gray-300">
//                     {invoiceData.user_info.invoice_settings.currency_info
//                       .symbol || ""}
//                     {invoiceData.paid_amount}
//                   </td>
//                 </tr>
//                 <tr className="font-nunito border-1 border-gray-300 font-normal text-sm">
//                   <td className="p-2 sm:p-3 break-words text-right border border-r-1 border-gray-300">
//                     Due Amount
//                   </td>
//                   <td className="p-2 sm:p-3 text-right font-semibold whitespace-nowrap border border-r-1 border-gray-300">
//                     {
//                       invoiceData.user_info.invoice_settings.currency_info
//                         .symbol
//                     }
//                     {Math.max(
//                       0,
//                       invoiceData.sub_total -
//                         invoiceData.discount -
//                         invoiceData.paid_amount
//                     )}
//                   </td>
//                 </tr>
//               </tbody>
//             </table>

//           </div>

//           {/* Summary and Payment Method */}
//           {/* <div className="w-full flex flex-col sm:flex-row justify-between pt-4 font-nunito mx:auto">
//             <div className=" text-sm  transform -rotate-45  sm:pl-44 pt-24 mb-4 sm:mb-0 hidden sm:block">
//               {invoiceData.multiple_payment &&
//                 invoiceData.multiple_payment.length > 0 && (
//                   <span>
//                     Paid by{" "}
//                     {invoiceData.multiple_payment
//                       .map((payment) => payment.payment_type.type_name)
//                       .join(", ")
//                       .replace(/, ([^,]*)$/, " and $1")}
//                   </span>
//                 )}
//             </div>

//             <div className="w-full sm:w-1/3 text-sm font-semibold pl-0 sm:pl-12">
//               <div className="space-y-[2px] mb-2">
//                 <p className="flex justify-between">
//                   <span className="pl-5">Sub Total</span>
//                   <span>
//                     {invoiceData.sub_total}
//                     {invoiceData.user_info.invoice_settings.currency_info.code}
//                   </span>
//                 </p>
//                 <p className="flex justify-between">
//                   <span className="pl-5">Discount</span>
//                   <span>
//                     {invoiceData.discount}
//                     {invoiceData.user_info.invoice_settings.currency_info.code}
//                   </span>
//                 </p>
//               </div>
//               <hr />
//               <div className="my-2">
//                 <p className="flex justify-between">
//                   <span className="pl-5">Grand Total</span>
//                   <span>
//                     {invoiceData.sub_total - invoiceData.discount}
//                     {invoiceData.user_info.invoice_settings.currency_info.code}
//                   </span>
//                 </p>
//                 <p className="flex justify-between">
//                   <span className="pl-5">Paid Amount</span>
//                   <span>
//                     {invoiceData.paid_amount}
//                     {invoiceData.user_info.invoice_settings.currency_info.code}
//                   </span>
//                 </p>
//               </div>
//               <hr />
//               <p className="flex justify-between mt-2">
//                 <span className="pl-5">Due Amount</span>
//                 <span>
//                   {Math.max(
//                     0,
//                     invoiceData.sub_total -
//                       invoiceData.discount -
//                       invoiceData.paid_amount
//                   )}
//                   {invoiceData.user_info.invoice_settings.currency_info.symbol}
//                 </span>
//               </p>
//             </div>
//           </div> */}

//           {/* Savings Banner */}
//           <div className="w-full bg-[#3056FA] text-center p-2 my-4">
//             <p className="text-white font-nunito text-sm font-bold">
//               You saved {invoiceData.user_info.invoice_settings.currency_info.symbol}{invoiceData.discount}{" "}
//                in
//               this purchase
//             </p>
//           </div>

//           {/* Terms and Signature */}
//           <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 text-xs md:text-sm lg:text-base">
//             <div className=" w-full sm:w-1/2">
//               <h3 className="text-sm text-center font-semibold text-gray-800 mb-2">
//                 Terms and Conditions
//               </h3>
//               <ul className="list-disc list-inside text-gray-700 text-xs">
//                 {invoiceData.terms_conditions.map((term) => (
//                   <li key={term.id}>{term.description}</li>
//                 ))}
//               </ul>
//             </div>
//             {/* <div className="p-4 flex flex-col items-end w-full sm:w-1/2">
//               <div className="mt-2 pt-0 border-t">
//                 <p>Authorized Signature</p>
//               </div>
//             </div> */}
//           </div>

//           {/* Web Address QR */}
//           <div className="my-3 flex justify-center items-center">
//             <QRCodeCanvas
//               value={
//                 invoiceData?.user_info?.web_address ||
//                 invoiceData?.user_info?.invoice_settings?.web_address ||
//                 "No Web Address set to the QR code"
//               }
//               size={70}
//               className="w-[70px] object-contain"
//             />
//             {/* <div className="text-sm  transform -rotate-45 pt-2 pr-8 pb-4 mb-4 sm:mb-0 block sm:hidden ">
//               {invoiceData.multiple_payment &&
//                 invoiceData.multiple_payment.length > 0 && (
//                   <span>
//                     Paid by{" "}
//                     {invoiceData.multiple_payment
//                       .map((payment) => payment.payment_type.type_name)
//                       .join(", ")
//                       .replace(/, ([^,]*)$/, " and $1")}
//                   </span>
//                 )}
//             </div> */}
//           </div>

//           {/* Footer */}
//           <div className="text-center font-nunito mt-8">
//             <h2 className="text-orange-500 text-xl font-semibold">
//               Thanks for purchase
//             </h2>
//           </div>
//         </div>

//         {/* Action Buttons */}
//         <div className="mt-8 sm:flex flex-col sm:flex-row justify-center gap-4 hidden ">
//           <button
//             onClick={handleDownloadPDF}
//             className="flex items-center justify-center gap-2 bg-[#3056FA] text-white px-6 py-2 rounded-lg hover:bg-blue-700 w-full sm:w-auto"
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               className="h-5 w-5"
//               viewBox="0 0 20 20"
//               fill="currentColor"
//             >
//               <path
//                 fillRule="evenodd"
//                 d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
//                 clipRule="evenodd"
//               />
//             </svg>
//             Download PDF
//           </button>
//           <button
//             onClick={() => {
//               handlePrintCustomize("pdfConentAreasCustomize");
//             }}
//             className="flex items-center justify-center gap-2 bg-gray-600 text-white px-6 py-2 rounded-lg hover:bg-gray-700 w-full sm:w-auto"
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               className="h-5 w-5"
//               viewBox="0 0 20 20"
//               fill="currentColor"
//             >
//               <path
//                 fillRule="evenodd"
//                 d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
//                 clipRule="evenodd"
//               />
//             </svg>
//             Print
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default InvoiceViewer;





import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { QRCodeCanvas } from "qrcode.react"
import html2pdf from "html2pdf.js"
import { formatPrice } from "../../utils/Common"
// import { formatPrice } from "../utils/Common"
import QRCode from "react-qr-code";

const InvoiceViewer = () => {
  const { id } = useParams()
  const [invoiceData, setInvoiceData] = useState(null)
  const invoiceRef = useRef(null)
  const [printContentVisible, setPrintContentVisible] = useState(false)

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}get-invoice-details/${id}`)
        const data = await response.json()
        if (data.success) {
          setInvoiceData(data.data)
        }
      } catch (error) {
        console.error("Error fetching invoice:", error)
      }
    }

    if (id) {
      fetchInvoice()
    }
  }, [id])

  const handleDownloadPDF = () => {
    if (invoiceRef.current) {
      const opt = {
        margin: 10,
        filename: `invoice-${id}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      }

      html2pdf().set(opt).from(invoiceRef.current).save()
    }
  }


  const handlePrintCustomize = (elementId) => {
    const printElement = document.getElementById(elementId)

    if (printElement) {
      setPrintContentVisible(true)
      const printContents = printElement.innerHTML
      let printableHTML = printContents

      // if (elementId === "pdfConentAreasCustomize") {
      //   const printStyles = `
      //     <style>
      //       @media print {
      //         body { margin: 0; padding: 0; }
      //         #${elementId} {
      //           padding-top: 50px;
      //           padding-bottom: 30px;
      //           page-break-inside: avoid;
      //         }
      //         @page { margin: 1.5in 0.5in 0.7in 0.5in; }
      //       }
      //     </style>
      //   `
      //   printableHTML = `${printStyles}${printContents}`
      // }

      document.body.innerHTML = printableHTML
      window.print()
      window.location.reload()
    } else {
      console.error(`Element with id "${elementId}" not found.`)
    }
  }

  // window.onafterprint = () => {
  //   setPrintContentVisible(false);
  //   document.body.innerHTML = originalContents;
  //   // Restore original content
  //   window.location.reload();
  // };

  if (!invoiceData)
    return (
      <div className="custom-loader-logo flex justify-center">
        <img
          src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
          alt="logo"
          className="w-10 h-10 animate-spin"
        />
      </div>
    )

  const invoiceSetting = invoiceData.user_info.invoice_settings;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;

  return (
    <div>
      <div className="min-h-screen bg-gray-100 py-4 sm:py-8">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div
          // ref={invoiceRef}
          className="bg-white shadow-lg rounded-lg p-4 sm:p-8"
          id=""
        >
          {/* Header */}
          <div className="flex flex-col sm:flex-row justify-between items-center pt-2 pb-1 border-b-4 border-blue-300">
            <div className="w-full sm:w-1/3 flex flex-col justify-start items-center sm:items-start  sm:mb-0">
              <img
                src={invoiceData.user_info.invoice_settings.shop_logo}
                alt="Shop Logo"
                className="w-[70px] sm:w-[90px] object-contain"
              />
              <p className="mt-2">
                <span className="font-semibold text-lg tracking-wider">
                  Sale Invoice
                </span>
              </p>
            </div>

            {/* <div className="w-full sm:w-1/3 flex items-center justify-center  mb-2 sm:mb-0">
              <div className="w-[65px] sm:w-[100px] flex items-center justify-center">
                <QRCodeCanvas
                  value={`${process.env.REACT_APP_ROOT_URL}/invoice-view/${invoiceData.invoice_id}`}
                  size={70}
                  className="w-full object-contain"
                />
              </div>
            </div> */}

            <div className="w-full sm:w-1/3 flex flex-col justify-end items-center sm:items-end border-t-4 border-blue-300 md:border-t-0">
              <span className="font-semibold text-lg sm:text-xl md:text-2xl mb-1 tracking-wider text-center sm:text-right">
                {invoiceData.user_info.invoice_settings.shop_name}
              </span>
              <span className="text-sm sm:text-md text-gray-600 text-center sm:text-right">
                <strong>Phone:</strong> {invoiceData.user_info.phone}
              </span>
              <span className="text-sm sm:text-md text-gray-600 text-center sm:text-right">
                <strong>Email:</strong> {invoiceData.user_info.email}
              </span>
              <span className="text-sm sm:text-md text-gray-600 text-center sm:text-right">
                <strong>Address:</strong> {invoiceData.user_info.address}
              </span>
            </div>
          </div>

          {/* Customer Info */}
          <div className="flex flex-col sm:flex-row items-start sm:items-start mt-4 sm:mt-[10px] justify-between font-nunito">
            <div className="text-black space-y-[2px] md:space-y-[6px] text-xs lg:text-base sm:mb-0">
              <p>
                <span className="font-semibold">Bill to,</span> <br />
                <span className="font-semibold">Customer Name </span>:{" "}
                {invoiceData.customer_name}
              </p>
              <p>
                <span className="font-semibold">Customer Phone</span>:{" "}
                {invoiceData.customer_phone}
              </p>
              <p>
                <span className="font-semibold">Customer ID </span>:{" "}
                {invoiceData.customer_id}
              </p>
            </div>
            <div className="flex flex-col justify-start items-start sm:items-end text-black space-y-[2px] md:space-y-[4px] text-xs lg:text-base">
              <p>
                <span className="font-semibold">Invoice No</span>:{" "}
                {invoiceData.invoice_id}
              </p>
              <p>
                <span className="font-semibold">Invoice Date</span>:{" "}
                {new Date(invoiceData.created_at).toLocaleString()}
              </p>
            </div>
          </div>

          {/* Items Table */}
          <div className="w-full mt-4 mb-0 relative">
            {/* Transparent Paid by Cash Text */}
            {invoiceData.multiple_payment &&
              invoiceData.multiple_payment.length > 0 && (
                <div className=" absolute left-0 top-2/3 transform  -rotate-45 -translate-y-1/2 opacity-30 text-gray-500 text-lg font-nunito font-semibold whitespace-nowrap">
                  Paid by{" "}
                  {invoiceData.multiple_payment
                    .map((payment) => payment.payment_type.type_name)
                    .join(", ")
                    .replace(/, ([^,]*)$/, " and $1")}
                </div>
              )}
            <table className="w-full">
              <thead>
                <tr className="leading-normal text-xs lg:text-base font-nunito tracking-wide border-1 border-gray-300">
                  <th className="font-nunito text-left p-2 sm:p-3 w-[70%] border border-r-1 border-gray-300">
                    Item Name
                  </th>
                  <th className="font-nunito text-right p-2 sm:p-3 w-[30%] border border-r-1 border-gray-300">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoiceData.sales_details.map((item) => (
                  <tr
                    key={item.id}
                    className="font-nunito border-1  border-gray-300 font-normal text-sm"
                  >
                    <td className="p-2 sm:p-3 break-words border border-r-1 border-b-3 border-gray-300">
                      {item.product_info.name} (Qty:{" "}
                      {item.remaining_qty || item.qty})
                      <br />
                      <span className="text-[13px]">
                        {item.product_imei[0]?.imei || ""}
                      </span>
                    </td>
                    <td className="p-2 sm:p-3 text-right font-semibold whitespace-nowrap border border-r-1 border-b-3 border-gray-300">
                      {invoiceData.user_info.invoice_settings.currency_info
                        .symbol || ""}
                      {Number(item.price) * (item.remaining_qty || item.qty)}
                    </td>
                  </tr>
                ))}
                <tr className="font-nunito border-1 border-gray-300 font-normal text-sm">
                  <td className="p-2 sm:p-3 break-words text-right border border-r-1 border-gray-300">
                    Sub Total
                  </td>
                  <td className="p-2 sm:p-3 text-right font-semibold whitespace-nowrap border border-r-1 border-gray-300">
                    {invoiceData.user_info.invoice_settings.currency_info
                      .symbol || ""}
                    {invoiceData.sub_total}
                  </td>
                </tr>

                <tr className="font-nunito border-1 border-gray-300 font-normal text-sm ">
                  <td className="p-2 sm:p-3 break-words text-right border border-r-1 border-gray-300">
                    Discount
                  </td>
                  <td className="p-2 sm:p-3 text-right font-semibold whitespace-nowrap border border-r-1 border-gray-300">
                    {invoiceData.user_info.invoice_settings.currency_info
                      .symbol || ""}
                    {invoiceData.discount}
                  </td>
                </tr>
                <tr className="font-nunito border-1 border-gray-300 font-normal text-sm">
                  <td className="p-2 sm:p-3 break-words text-right border border-r-1 border-gray-300">
                    Grand Total
                  </td>
                  <td className="p-2 sm:p-3 text-right font-semibold whitespace-nowrap border border-r-1 border-gray-300">
                    {invoiceData.user_info.invoice_settings.currency_info
                      .symbol || ""}
                    {invoiceData.sub_total - invoiceData.discount}
                  </td>
                </tr>
                <tr className="font-nunito border-1 border-gray-300 font-normal text-sm">
                  <td className="p-2 sm:p-3 break-words text-right border border-r-1 border-gray-300">
                    Paid Amount
                  </td>
                  <td className="p-2 sm:p-3 text-right font-semibold whitespace-nowrap border border-r-1 border-gray-300">
                    {invoiceData.user_info.invoice_settings.currency_info
                      .symbol || ""}
                    {invoiceData.paid_amount}
                  </td>
                </tr>
                <tr className="font-nunito border-1 border-gray-300 font-normal text-sm">
                  <td className="p-2 sm:p-3 break-words text-right border border-r-1 border-gray-300">
                    Due Amount
                  </td>
                  <td className="p-2 sm:p-3 text-right font-semibold whitespace-nowrap border border-r-1 border-gray-300">
                    {
                      invoiceData.user_info.invoice_settings.currency_info
                        .symbol
                    }
                    {Math.max(
                      0,
                      invoiceData.sub_total -
                        invoiceData.discount -
                        invoiceData.paid_amount
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

          </div>

          {/* Summary and Payment Method */}
          {/* <div className="w-full flex flex-col sm:flex-row justify-between pt-4 font-nunito mx:auto">
            <div className=" text-sm  transform -rotate-45  sm:pl-44 pt-24 mb-4 sm:mb-0 hidden sm:block">
              {invoiceData.multiple_payment &&
                invoiceData.multiple_payment.length > 0 && (
                  <span>
                    Paid by{" "}
                    {invoiceData.multiple_payment
                      .map((payment) => payment.payment_type.type_name)
                      .join(", ")
                      .replace(/, ([^,]*)$/, " and $1")}
                  </span>
                )}
            </div>

            <div className="w-full sm:w-1/3 text-sm font-semibold pl-0 sm:pl-12">
              <div className="space-y-[2px] mb-2">
                <p className="flex justify-between">
                  <span className="pl-5">Sub Total</span>
                  <span>
                    {invoiceData.sub_total}
                    {invoiceData.user_info.invoice_settings.currency_info.code}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="pl-5">Discount</span>
                  <span>
                    {invoiceData.discount}
                    {invoiceData.user_info.invoice_settings.currency_info.code}
                  </span>
                </p>
              </div>
              <hr />
              <div className="my-2">
                <p className="flex justify-between">
                  <span className="pl-5">Grand Total</span>
                  <span>
                    {invoiceData.sub_total - invoiceData.discount}
                    {invoiceData.user_info.invoice_settings.currency_info.code}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="pl-5">Paid Amount</span>
                  <span>
                    {invoiceData.paid_amount}
                    {invoiceData.user_info.invoice_settings.currency_info.code}
                  </span>
                </p>
              </div>
              <hr />
              <p className="flex justify-between mt-2">
                <span className="pl-5">Due Amount</span>
                <span>
                  {Math.max(
                    0,
                    invoiceData.sub_total -
                      invoiceData.discount -
                      invoiceData.paid_amount
                  )}
                  {invoiceData.user_info.invoice_settings.currency_info.symbol}
                </span>
              </p>
            </div>
          </div> */}

          {/* Savings Banner */}
          <div className="w-full bg-[#3056FA] text-center p-2 my-4">
            <p className="text-white font-nunito text-sm font-bold">
              You saved {invoiceData.user_info.invoice_settings.currency_info.symbol}{invoiceData.discount}{" "}
               in
              this purchase
            </p>
          </div>

          {/* Terms and Signature */}
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 text-xs md:text-sm lg:text-base">
            <div className=" w-full sm:w-1/2">
              <h3 className="text-sm text-center font-semibold text-gray-800 mb-2">
                Terms and Conditions
              </h3>
              <ul className="list-disc list-inside text-gray-700 text-xs">
                {invoiceData.terms_conditions.map((term) => (
                  <li key={term.id}>{term.description}</li>
                ))}
              </ul>
            </div>
            {/* <div className="p-4 flex flex-col items-end w-full sm:w-1/2">
              <div className="mt-2 pt-0 border-t">
                <p>Authorized Signature</p>
              </div>
            </div> */}
          </div>

          {/* Web Address QR */}
          <div className="my-3 flex justify-center items-center">
            <QRCodeCanvas
              value={
                invoiceData?.user_info?.web_address ||
                invoiceData?.user_info?.invoice_settings?.web_address ||
                "No Web Address set to the QR code"
              }
              size={70}
              className="w-[70px] object-contain"
            />
            {/* <div className="text-sm  transform -rotate-45 pt-2 pr-8 pb-4 mb-4 sm:mb-0 block sm:hidden ">
              {invoiceData.multiple_payment &&
                invoiceData.multiple_payment.length > 0 && (
                  <span>
                    Paid by{" "}
                    {invoiceData.multiple_payment
                      .map((payment) => payment.payment_type.type_name)
                      .join(", ")
                      .replace(/, ([^,]*)$/, " and $1")}
                  </span>
                )}
            </div> */}
          </div>

          {/* Footer */}
          <div className="text-center font-nunito mt-8">
            <h2 className="text-orange-500 text-xl font-semibold">
              Thanks for purchase
            </h2>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="mt-8 sm:flex flex-col sm:flex-row justify-center gap-4 ">
          {/* <button
            onClick={handleDownloadPDF}
            className="flex items-center justify-center gap-2 bg-[#3056FA] text-white px-6 py-2 rounded-lg hover:bg-blue-700 w-full sm:w-auto"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            Download PDF
          </button> */}
          <button
            onClick={() => {
              handlePrintCustomize("pdfConentAreasCustomize");
            }}
            className="flex items-center justify-center gap-2 bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-gray-700 w-full sm:w-auto"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                clipRule="evenodd"
              />
            </svg>
            Print
          </button>
        </div>
      </div>
    </div>
      <div className="min-h-screen bg-gray-100 py-4 sm:py-8 hidden">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div ref={invoiceRef} className="bg-white shadow-lg rounded-lg overflow-hidden" id="pdfConentAreasCustomize">
          {/* Header */}
          <div className="relative">
            <div className="w-full bg-gray-800 relative h-28 overflow-hidden">
              <div className="relative z-10 flex justify-between items-start">
                {/* Logo Section */}
                <div
                  className="relative h-[30rem] w-[26rem] transform"
                  style={{
                    backgroundColor: invoiceSetting.second_code || "black",
                    borderColor: invoiceSetting.first_code || "orange",
                    clipPath: "polygon(100% 0px, 90% -2%, 0% 125%, 0px 0px)",
                    borderWidth: "1px",
                  }}
                >
                  <div className="text-white text-2xl font-bold tracking-wider p-2 flex items-center gap-2">
                    <img
                      src={invoiceSetting.shop_logo || "/placeholder.svg"}
                      alt="Shop Logo"
                      className="w-[50px] sm:w-[70px] md:w-[90px] object-contain mt-1"
                    />
                    <p className="text-white text-sm tracking-widest mt-1 p-2">{invoiceSetting.slogan || ""}</p>
                  </div>
                </div>

                <div
                  className="absolute left-52 h-3 -rotate-[58deg] w-80 z-10"
                  style={{
                    backgroundColor: invoiceSetting.first_code || "orange",
                  }}
                ></div>
                <div
                  className="absolute top-[6.3rem] h-3 w-[19rem]"
                  style={{
                    backgroundColor: invoiceSetting.first_code || "orange",
                  }}
                ></div>

                {/* Invoice Section */}
                <div className="text-right pt-4 pr-4 pb-2">
                  <h2
                    className="text-3xl"
                    style={{
                      color: invoiceSetting.first_code || "orange",
                    }}
                  >
                    INVOICE
                  </h2>
                  <p className="text-white text-sm mt-1">ID NO : {invoiceData.invoice_id}</p>
                </div>
              </div>

              {/* Bottom Border */}
              <div className="absolute bottom-0 left-0 w-full h-4 bg-white" />
            </div>
            <div className="absolute bottom-0 right-0 w-1/3 h-full bg-orange-300 -z-10 transform skew-x-12 origin-bottom-right" />
          </div>

          {/* Invoice Details */}
          <div className="grid grid-cols-3 gap-4 p-4">
            {/* Invoice To Section */}
            <div className="flex flex-col justify-start space-y-2">
              <h3
                className={`border-t-4 border-l-4 text-white px-4 py-1 inline-block ${
                  invoiceSetting.second_code ? "" : "bg-gray-900"
                }`}
                style={{
                  backgroundColor: invoiceSetting.second_code || undefined,
                  borderColor: invoiceSetting.first_code || "orange",
                }}
              >
                Invoice To:
              </h3>
              <div className="mt-2">
                <p className="font-semibold">{invoiceData.customer_name}</p>
                <p className="text-gray-600">Phone: {invoiceData.customer_phone}</p>
                <p className="text-gray-600">Customer ID: {invoiceData.customer_id}</p>
              </div>
            </div>

            {/* QR Code Section */}
            <div className="flex justify-center items-center pb-2">
              <div className="w-[45px] sm:w-[65px] md:w-[100px]">
              <QRCode
                className="w-full object-contain"
                value={BASE_URL + "/invoice-view/" + invoiceData?.invoice_id}
                size={100}
              />
              </div>
            </div>

            {/* Invoice From Section */}
            <div className="flex flex-col justify-between items-end space-y-2">
              <h3
                className={`border-t-4 border-l-4 text-white px-4 py-1 inline-block ${
                  invoiceSetting.second_code ? "" : "bg-gray-900"
                }`}
                style={{
                  backgroundColor: invoiceSetting.second_code || undefined,
                  borderColor: invoiceSetting.first_code || "orange",
                }}
              >
                Invoice From:
              </h3>
              <div className="mt-2 text-right">
                <p className="font-semibold">{invoiceSetting.shop_name}</p>
                <p className="text-gray-600">Phone: {invoiceData.user_info.phone}</p>
                <p className="text-gray-600">Address: {invoiceData.user_info.address}</p>
              </div>
            </div>
          </div>

          {/* Services Table */}
          <div className=" overflow-x-auto px-4">
            <table className="w-full">
              <thead>
                <tr
                  style={{
                    backgroundColor: invoiceSetting.first_code || "orange",
                  }}
                >
                  <th className="border-r-4 border-white text-center py-2 text-white">#</th>
                  <th className="border-r-4 border-white text-center py-2 text-white">DESCRIPTION</th>
                  <th className="border-r-4 border-white text-center py-2 text-white">WARRANTY</th>
                  <th className="border-r-4 border-white text-center py-2 text-white">QTY</th>
                  <th className="border-r-4 border-white text-center py-2 text-white">PRICE</th>
                  <th className="border-r-4 border-white text-center py-2 text-white">TOTAL</th>
                </tr>
              </thead>
              <tbody className="divide-y-2 font-nunito font-semibold">
                {invoiceData.sales_details.map((item, index) => (
                  <tr key={item.id} className="text-sm">
                    <td className="py-1 px-4 text-center">{index + 1}</td>
                    <td className="py-1 px-4">
                      {item.product_info.name}
                      <br />
                      <span className="text-[10px]">{item.product_info.serial}</span>
                    </td>
                    <td className="py-1 px-4 text-center">
                      {invoiceData.defaultwarranties?.find((w) => w.product_id === item.product_id)
                        ?.default_warranties_count || "N/A"}
                    </td>
                    <td className="py-1 px-4 text-center">{item.qty}</td>
                    <td className="py-1 px-4 text-right">{formatPrice(item.price)}</td>
                    <td className="py-1  text-right">
                      {formatPrice(item.price * item.qty)} {invoiceSetting.currency_info.code}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Footer */}
          <div className="mt-8 flex justify-between gap-8 px-4">
            {/* Left: Paid By Section */}
            <div className="flex-1 relative">
              {invoiceData.multiple_payment?.length > 0 && (
                <span
                  style={{
                    letterSpacing: "0.08em",
                    transform: "rotate(-45deg)",
                    display: "inline-block",
                    position: "absolute",
                    opacity: 1,
                    fontSize: "14px",
                    color: "gray",
                    top: "50%",
                    left: "50%",
                    transformOrigin: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  Paid by {invoiceData.multiple_payment.map((p) => p.payment_type.type_name).join(", ")}
                </span>
              )}
            </div>

            {/* Right: Subtotal Section */}
            <div className="flex-1 text-right">
              <p className="flex justify-between">
                <span className="font-semibold font-nunito">Subtotal:</span>
                <span className="font-semibold font-nunito">
                  {formatPrice(invoiceData.sub_total)} {invoiceSetting.currency_info.code}
                </span>
              </p>
              <p className="flex justify-between">
                <span className="font-semibold font-nunito">Tax:</span>
                <span className="font-semibold font-nunito">
                  {formatPrice(invoiceData.tax)} {invoiceSetting.currency_info.code}
                </span>
              </p>
              <p className="flex justify-between">
                <span className="font-semibold font-nunito">Discount:</span>
                <span className="font-semibold font-nunito">
                  {formatPrice(invoiceData.discount)} {invoiceSetting.currency_info.code}
                </span>
              </p>
              <div
                className="mt-4 text-white px-4 py-2 inline-block"
                style={{
                  backgroundColor: invoiceSetting.first_code || "orange",
                }}
              >
                <span className="font-bold">
                  TOTAL: {formatPrice(invoiceData.sub_total - invoiceData.discount)} {invoiceSetting.currency_info.code}
                </span>
              </div>
            </div>
          </div>

          {/* Terms and Signature */}
          <div className="flex justify-between space-x-5 text-[10px] lg:text-sm mt-4 px-4">
            <div className="w-1/2">
              <h3 className="text-[10px] font-semibold text-gray-800 mb-2">Terms and Conditions</h3>
              <ul className="list-disc list-inside text-gray-700 text-[10px]">
                {invoiceData.terms_conditions.map((term, index) => (
                  <li key={term.id || index}>{term.description}</li>
                ))}
              </ul>
            </div>
            <div className="flex flex-col justify-end items-end">
              <div className="flex flex-col items-center mt-4">
                <div className="border-t border-gray-400">
                  <p className="font-bold">Customer Signature</p>
                </div>
              </div>
              <div className="flex flex-col items-center mt-5">
                <div className="border-t border-gray-400">
                  <p className="font-bold">Authorized Signature</p>
                </div>
              </div>
              <div className="flex justify-start mt-2">
              <QRCode
                className="w-[50px] object-contain"
                value={
                  invoiceData?.user_info?.web_address ||
                  invoiceData?.user_info?.invoice_settings?.web_address ||
                  "No Web Address set to the QR code"
                }
                size={50}
              />
              </div>
            </div>
          </div>

          {/* Bottom Stripe */}
          <div className="relative h-16 mt-8">
            <div className="absolute bottom-0 left-0 w-full h-full bg-white"></div>
            <div
              className="absolute bottom-0 left-0 w-full h-4"
              style={{
                backgroundColor: invoiceSetting.first_code || "orange",
              }}
            ></div>
            <div
              className="absolute bottom-0 right-0 w-1/4 h-12 clip-custom"
              style={{
                backgroundColor: invoiceSetting.second_code || "black",
              }}
            ></div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="mt-8 sm:flex flex-col sm:flex-row justify-center gap-4 hidden">
          {/* <button
            onClick={handleDownloadPDF}
            className="flex items-center justify-center gap-2 bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 w-full sm:w-auto"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            Download PDF
          </button> */}
          <button
            onClick={() => handlePrintCustomize("pdfConentAreasCustomize")}
            className="flex items-center justify-center gap-2 bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-gray-700 w-full sm:w-auto"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                clipRule="evenodd"
              />
            </svg>
            Print
          </button>
        </div>
      </div>
    </div>
    </div>
  )
}

export default InvoiceViewer
