import React, { useEffect, useState, useContext } from "react";
import Resizer from "react-image-file-resizer";
import { useNavigate, useParams } from "react-router-dom";
import { useFlashMessage } from "../context/FlashMessageContext";
import { get, post } from "../services/apiService";
import Input from "./Input";
import SelectBox from "./Select";
import { AppContext } from "../context/context";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import ImageUploaderFrame from "./ImageUploaderFrame";
import {
  uploadFile,
  uploadFileMultipleInput,
  uploadFileMultipleInputVariants,
  validateData,
} from "../utils/Common";
import qrIcon from "../assets/images/qr_icon.png";
import QRCode from "react-qr-code";

const ExporterAddProductForm = (props) => {
  const {
    type,
    navigate,
    loadingCallback,
    setExporterProduct,
    setWarningMessage,
    setShowModal,
    setExporterData,
    showNestedModal,
    setShowNestedModal,
    setNestedModalData,
    exporterProduct,
    productId,
    exporterOrder,
    setAddedProduct,
  } = props;
  const {
    state,
    isLoading,
    updateCategoryList,
    updateSingleCategoryList,
    updateSubCategoryList,
    updateBrandList,
    updateUnitList,
    updateInvoiceSetting,
  } = useContext(AppContext);
  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const [countryList, setCountryList] = useState([
    { id: "Refurbished", name: "Refurbished", label: "Refurbished" },
    { id: "Global", name: "Global", label: "Global" },
    { id: "USA", name: "USA", label: "USA" },
    { id: "UAE", name: "UAE", label: "UAE" },
    { id: "Japan", name: "Japan", label: "Japan" },
    { id: "Ireland", name: "Ireland", label: "Ireland" },
    { id: "Czech Republic", name: "Czech Republic", label: "Czech Republic" },
    { id: "Singapore", name: "Singapore", label: "Singapore" },
    { id: "Malaysia", name: "Malaysia", label: "Malaysia" },
    { id: "Korea", name: "Korea", label: "Korea" },
    { id: "Taiwan", name: "Taiwan", label: "Taiwan" },
    { id: "China", name: "China", label: "China" },
    { id: "India", name: "India", label: "India" },
    { id: "Vietnam", name: "Vietnam", label: "Vietnam" },
    { id: "Thailand", name: "Thailand", label: "Thailand" },
    { id: "Bangladesh", name: "Bangladesh", label: "Bangladesh" },
  ]);
  const [category, setCategory] = useState(state.categoryList);
  const [subCategory, setSubCategory] = useState([]);
  const [filteredSubCategory, setFilteredSubCategory] = useState([]);
  const [Units, setUnits] = useState([]);
  const [brands, setBrands] = useState(state.brandList);
  const [currency, setCurrency] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [formErrorsImei, setFormErrorsImei] = useState({});
  const [startDate, setStartDate] = useState(new Date());

  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [exporterProductId, setExporterProductId] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState("");
  const [userData, setUserData] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    exporter_order_id: exporterOrder ? exporterOrder.id : null,
    exporter_id: exporterOrder ? exporterOrder.exporter_id : null,
    product_id: exporterProduct ? exporterProduct.id : null,
    category_id: 0,
    sub_category_id: 0,
    brand_id: 0,
    qty: 0,
    booking_date: format(startDate, "yyyy-MM-dd"),
    image_path:
      exporterProduct && exporterProduct?.image_path
        ? exporterProduct.image_path
        : null,
    image_path1:
      exporterProduct && exporterProduct?.image_path1
        ? exporterProduct.image_path1
        : null,
    image_path2:
      exporterProduct && exporterProduct?.image_path2
        ? exporterProduct.image_path2
        : null,
    image_path3:
      exporterProduct && exporterProduct?.image_path3
        ? exporterProduct.image_path3
        : null,
    purchase_price: 0,
    sale_price: 0,
    condition: "New",
    country: "",
    ram: "",
    storage: "",
    currency_id: selectedCurrency,
  });
  const [formDataImei, setFormDataImei] = useState([
    {
      exporter_product_id: exporterProductId,
      optional_name: "",
      imeis: "",
      // currency_id: selectedCurrency,
      // purchase_price: "",
      // sale_price: "",
      // country: "",
      // ram: "",
      // storage: "",
      exporter_order_id: exporterOrder ? exporterOrder.id : null,
      exporter_id: exporterOrder ? exporterOrder.exporter_id : null,
      product_id: exporterProduct ? exporterProduct.id : null,
      //id:1
    },
  ]);
  // Function to generate the formDataImei array based on quantity
  const generateFormDataImei = () => {
    const quantity = formData.qty;
    const newFormDataImei = Array.from({ length: quantity }, (_, index) => ({
      exporter_product_id: exporterProductId,
      optional_name: "",
      imei: "",
      // currency: selectedCurrency,
      // purchase_price: "",
      // sale_price: "",
      // country: "",
      // ram: "",
      // storage: "",
      exporter_order_id: exporterOrder ? exporterOrder.id : null,
      exporter_id: exporterOrder ? exporterOrder.exporter_id : null,
      product_id: exporterProduct ? exporterProduct.id : null,
      // Add additional fields as needed
      // id: index + 1, // Example: Set the ID based on the array index
    }));
    setFormDataImei(newFormDataImei);
  };

  // Call the function initially and whenever formData.qty changes
  useEffect(() => {
    generateFormDataImei();
  }, [formData.qty]);
  useEffect(() => {
    if (exporterProductId !== null) {
      // Create products with product_id based on exporterProductId
      const updatedProducts = formDataImei.map((product, index) => ({
        ...product,
        exporter_product_id: exporterProductId, // Example: product_id based on index
      }));
      setFormDataImei(updatedProducts);
    }
  }, [exporterProductId]);
  const getCurrency = () => {
    get("currency", token)
      .then((response) => {
        if (response.success) {
          setCurrency(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCategory();
    //getSubCategory();
    //console.log(state);
    getCurrency();
    //getUnits();
    if (state.brandList.length === 0) {
      getBrand();
    }
  }, []);
  useEffect(() => {
    getCategory();
    if (state.brandList.length === 0) {
      getBrand();
    }
    if (exporterProduct) {
      //setFormData(exporterProduct);
      // Assuming exporterProduct is an object with an 'id' property
      const { id, ...formDataWithoutId } = exporterProduct;

      // Use formDataWithoutId when setting the form data
      setFormData(formDataWithoutId);
      setFormData((prevFormData) => ({
        ...prevFormData,
        exporter_order_id: exporterOrder ? exporterOrder.id : null,
        exporter_id: exporterOrder ? exporterOrder.exporter_id : null,
        product_id: exporterProduct ? exporterProduct?.id : null,
      }));
      if (exporterProduct.category_id != "") {
        setSelectedCategory(exporterProduct.category_id);
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        exporter_order_id: exporterOrder ? exporterOrder.id : null,
        exporter_id: exporterOrder ? exporterOrder.exporter_id : null,
      }));
    }
    //getSubCategory();
    //console.log(state);
    //getCurrency();
    //getUnits();
  }, [showNestedModal, exporterProduct, exporterOrder]);
  useEffect(() => {
    // Filter sub-categories based on the selected category
    const selectedCategoryObject = state.categoryList.find(
      (category) => category.id === selectedCategory
    );

    if (selectedCategoryObject) {
      setFilteredSubCategory(selectedCategoryObject.sub_category || []);
    } else {
      setFilteredSubCategory([]);
    }
  }, [selectedCategory, state.categoryList]);

  // useEffect(() => {
  //  if(formData.category_id){
  //   getSubCategory();
  //  }
  // }, [formData.category_id])

  const getCategory = () => {
    loadingCallback(true);
    get("get-all-category", token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          setCategory(response.data);
          updateCategoryList(response.data);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
      });
  };
  const getBrand = () => {
    get("brands?page=1&limit=500", token)
      .then((response) => {
        // console.log(response.data.data.data);

        if (response.success) {
          setLoader(false);
          setBrands(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleValidation = (formData) => {
    const errors = {};
    let validate = true;

    if (formData.name === "") {
      errors.customer_name = "name is required.";
      setIsValid(false);
      validate = false;
    }

    if (!formData.qty || formData.qty === "") {
      errors.qty = "qty is required.";
      setIsValid(false);
      validate = false;
    }
    if (!formData.purchase_price || formData.purchase_price === "") {
      errors.purchase_price = "Purchase price is required.";
      validate = false;
    }

    if (!formData.sale_price || formData.sale_price === "") {
      errors.sale_price = "Sale price is required.";
      validate = false;
    }
    // if(!formData.currency){
    //   errors.currency = "";
    //   validate = false;
    // }
    if (selectedCurrency === "" || selectedCurrency === "Select Currency") {
      errors.currency_id = "Currency is required.";
      validate = false;
    }
    setFormErrors(errors);
    if (!validate) {
      setErrorFlashMessage("Please fill all the required fields.");
    }

    return validate;
  };

  useEffect(() => {
    console.log("form Data", formData);
  }, [formData]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formattedDate = format(startDate, "yyyy-MM-dd");
    formData.booking_date = formattedDate;

    // setExporterProductId(1);
    // setProductAddForm(false);

    if (handleValidation(formData)) {
      updateProduct2(formData);
    } else {
      setWarningMessage(true);
      //setErrorFlashMessage('Please fill all the required fields');
      const timer = setTimeout(() => {
        setWarningMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  };

  const handleImeiChange = (index, event) => {
    const { name, value } = event.target;
    console.log(name, "value: " + value);
    // Extract product ID from name (assuming it's in the format "imei[productId].name")
    // Extract product ID from name (assuming format "imei[productId].fieldName")
    const productId = parseInt(name.match(/imei\[(\d+)\]\.([^\]]+)/)[1]);
    const fieldName = name.match(/imei\[(\d+)\]\.([^\]]+)/)[2];

    setFormDataImei((prevProducts) =>
      prevProducts.map((product, index) =>
        index === productId
          ? { ...product, [fieldName]: value } // Update specific field
          : product
      )
    );
  };

  // const handleImeiChange = async (index, event) => {
  //   const { name, value } = event.target;

  //   // Extract product ID and field name
  //   const productId = parseInt(name.match(/imei\[(\d+)\]\.([^\]]+)/)[1]);
  //   const fieldName = name.match(/imei\[(\d+)\]\.([^\]]+)/)[2];

  //   // Update the state for non-IMEI fields or when the field is empty
  //   if (fieldName !== "imei" || value.trim() === "") {
  //     setFormDataImei((prevProducts) =>
  //       prevProducts.map((product, i) =>
  //         i === productId ? { ...product, [fieldName]: value } : product
  //       )
  //     );
  //     return;
  //   }

  //   // Perform checks for duplicate IMEI within the form
  //   const isDuplicate =
  //     formDataImei.filter(
  //       (product, i) => product.imei === value && i !== productId
  //     ).length > 0;

  //   if (isDuplicate) {
  //     alert("IMEI must be unique within the form.");
  //     return; // Do not proceed if duplicate found in the form
  //   }

  //   // API call to check if IMEI exists in the database
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}check-product-imei`,
  //       {
  //         method: "POST",
  //         headers: {
  //           authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ imei: value }),
  //       }
  //     );

  //     const data = await response.json();

  //     if (!data.available) {
  //       alert("IMEI already exists in the database.");
  //     } else {
  //       // Update the state for IMEI
  //       setFormDataImei((prevProducts) =>
  //         prevProducts.map((product, i) =>
  //           i === productId ? { ...product, imei: value } : product
  //         )
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error checking IMEI:", error);
  //     alert("An error occurred while checking the IMEI. Please try again.");
  //   }
  // };

  const handleImeiChangeSelect = (name, option) => {
    const { value, label } = option;

    // Extract both index and field name in one capture
    const match = name.match(/imei\[(\d+)\]\.([^\]]+)/);

    if (match) {
      const productId = parseInt(match[1]);
      const fieldName = match[2];

      setFormDataImei((prevProducts) =>
        prevProducts.map((product, index) =>
          index === productId
            ? { ...product, [fieldName]: value } // Update specific field
            : product
        )
      );
    } else {
      if (name === "currency_id") {
        setSelectedCurrency(value);
      }
      // Handle cases where the name doesn't match the expected format
      console.warn("Unexpected name format:", name);
    }
  };

  const handleAddProduct = () => {
    setFormDataImei([
      ...formDataImei,
      {
        //id: formDataImei.length + 1,
        exporter_order_id: exporterOrder ? exporterOrder.id : null,
        exporter_id: exporterOrder ? exporterOrder.exporter_id : null,
        product_id: productId,
      },
    ]);
  };

  const handleValidationImei = (formDataImei) => {
    const errors = {};
    let validate = true;

    formDataImei.forEach((item, index) => {
      // Initialize the errors object for the current index if it doesn't exist
      if (!errors[index]) {
        errors[index] = {};
      }
      if (!item.imei || item.imei === "") {
        errors[index].imei = "IMEI is required.";
        validate = false;
      }
    });
    if (selectedCurrency === "" || selectedCurrency === "Select Currency") {
      errors.currency = "Currency is required.";
      validate = false;
    }

    setFormErrorsImei(errors);

    if (!validate) {
      setErrorFlashMessage("Please fill all the required fields.");
    }

    return validate;
  };

  const [productAddFormData, setProductAddFormData] = useState({});
  const [updateProductImeiData, setUpdateProductImeiData] = useState({});
  const updateProduct2 = (formData) => {
    console.log("product data", formData);
    setProductAddFormData(formData);
    setProductAddForm(false);
  };

  // const handleSubmitImei2 = (event) => {
  //   event.preventDefault();
  //   if (handleValidationImei(formDataImei)) {
  //     updateProduct(productAddFormData);
  //   } else {
  //     setWarningMessage(true);
  //     const timer = setTimeout(() => {
  //       setWarningMessage(false);
  //     }, 3000);
  //     return () => clearTimeout(timer);
  //   }
  // };

  // const handleSubmitImei2 = async (event) => {
  //   event.preventDefault();

  //   // Before proceeding, check for duplicate IMEIs within the form
  //   const duplicateImei = checkImeiDuplicates(formDataImei);

  //   if (duplicateImei) {
  //     // Show a warning if duplicates are found within the form
  //     alert("Some IMEI numbers are duplicate in the form. Please remove duplicates and try again.");
  //     return;
  //   }

  //   // If no duplicates, continue with form submission
  //   if (handleValidationImei(formDataImei)) {
  //     updateProduct(productAddFormData);
  //   } else {
  //     setWarningMessage(true);
  //     const timer = setTimeout(() => {
  //       setWarningMessage(false);
  //     }, 3000);
  //     return () => clearTimeout(timer);
  //   }
  // };

  // // Function to check for duplicates within the form data
  // const checkImeiDuplicates = (formData) => {
  //   const imeiList = formData.map((product) => product.imei);

  //   // Using Set to check duplicates in the form data
  //   const uniqueImei = [...new Set(imeiList)];
  //   return imeiList.length !== uniqueImei.length; // Returns true if there are duplicates
  // };

  const handleSubmitImei2 = async (event) => {
    event.preventDefault();

    // Step 1: Check for duplicate IMEIs within the form
    const duplicateImei = checkImeiDuplicates(formDataImei);
    if (duplicateImei) {
      alert(
        "Some IMEI numbers are duplicate in the form. Please remove duplicates and try again."
      );
      return;
    }

    // Step 2: Validate IMEI format or other form data
    if (!handleValidationImei(formDataImei)) {
      setWarningMessage(true);
      const timer = setTimeout(() => {
        setWarningMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }

    // Step 3: Check each IMEI against the database using the API
    try {
      const databaseCheckResults = await checkImeiInDatabase(formDataImei);

      // Filter out IMEIs that already exist in the database
      const duplicateInDatabase = databaseCheckResults.filter(
        (result) => result.available === false
      );

      if (duplicateInDatabase.length > 0) {
        // Show a warning for IMEIs that already exist in the database
        const existingImeis = duplicateInDatabase
          .map((result) => result.imei)
          .join(", ");
        alert(
          `The following IMEI(s) already exist in the database: ${existingImeis}. Please update or remove them.`
        );
        return;
      }

      // Step 4: Proceed with the form submission if all checks pass
      updateProduct(productAddFormData);
    } catch (error) {
      console.error("Error checking IMEIs in the database:", error);
      alert("An error occurred while checking IMEIs. Please try again later.");
    }
  };

  // Function to check for duplicates within the form data
  const checkImeiDuplicates = (formData) => {
    const imeiList = formData.map((product) => product.imei);
    const uniqueImei = [...new Set(imeiList)];
    return imeiList.length !== uniqueImei.length; // Returns true if there are duplicates
  };

  // Function to check each IMEI against the database
  const checkImeiInDatabase = async (formData) => {
    const imeiPromises = formData.map((product) =>
      fetch(`${process.env.REACT_APP_API_URL}check-product-imei`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ imei: product.imei }),
      })
        .then((response) => response.json())
        .then((data) => ({ imei: product.imei, ...data }))
    );

    // Use Promise.all to wait for all API calls to resolve
    return Promise.all(imeiPromises);
  };

  const handleSubmitImei = (exporterProductId, product_id) => {
    // event.preventDefault();

    if (formDataImei.length > 0) {
      const formDataImeiAll = {
        imeis: formDataImei,
        exporter_product_id: exporterProductId,
        // currency_id: selectedCurrency,
        product_id: product_id,
      };

      if (handleValidationImei(formDataImei)) {
        updateProductImei(formDataImeiAll);
        // setUpdateProductImeiData(formDataImeiAll)
        // updateProductImei(formDataImeiAll);
      } else {
        setWarningMessage(true);
        //setErrorFlashMessage('Please fill all the required fields');
        const timer = setTimeout(() => {
          setWarningMessage(false);
        }, 3000);
        return () => clearTimeout(timer);
      }
    } else {
      setWarningMessage(true);
      //setErrorFlashMessage('Please fill all the required fields');
      const timer = setTimeout(() => {
        setWarningMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  };

  const updateProduct = async (formData) => {
    // console.log("product data", formData);
    loadingCallback(true);
    // Use your API service functions to make API requests here
    //if(isValid){
    post("save-exporter-product", formData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          //setExporter(response.data);
          //setShowModal(false);
          setSuccessFlashMessage("Exporter updated successfully");
          // setProductAddForm(false);
          setExporterProductId(response.data.id);
          handleSubmitImei(response.data.id, response.data.product_id);
          // updateProductImei(updateProductImeiData);
          //navigate(`/add-exporter/${selectedExporter.id}`);
        } else {
          console.log("Exporter update failed");
          setErrorFlashMessage("Exporter update failed");

          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
    //}
  };

  const updateProductImei = (formData) => {
    console.log("product imei data", formData);

    loadingCallback(true);

    // Use your API service functions to make API requests here
    //if(isValid){
    post("save-product-imei", formData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          //setExporter(response.data);
          //setShowModal(false);
          setSuccessFlashMessage("Exporter product added successfully");
          hideNestedModal();
          //setExporterProductId(response.data);
          setAddedProduct(true);
          //navigate(`/add-exporter/${selectedExporter.id}`);
        } else {
          console.log("Exporter product add failed");
          setErrorFlashMessage("Exporter product add failed");

          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
    //}
  };

  // Function to handle changes in the input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "category_id") {
      // If the selected category changes, filter and update the subcategories
      if (value != "Select Category" && formData.category_id != value) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          sub_category_id: "", // Reset subcategory when category changes
        }));
        // const filteredSubcategories = subCategory.filter(
        //   (subCat) => subCat.category_id === Number(value)
        // );
        // setFilteredSubCategory(filteredSubcategories);
        setSelectedCategory(value);
      }
      if (value == "Select Category") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          sub_category_id: "", // Reset subcategory when category changes
        }));
      }
    }
  };
  // Function to handle changes in the input fields
  const handleChangeDate = (date, name) => {
    const formattedDate = format(date, "yyyy-MM-dd");

    setFormData({
      ...formData,
      [name]: formattedDate,
    });
    if (name === "booking_date") {
      setStartDate(date);
    }
  };
  // Function to handle changes in the input fields
  const handleChangeSelect = (name, option) => {
    const { value, label } = option;

    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "category_id") {
      // If the selected category changes, filter and update the subcategories
      if (value != "Select Category" && formData.category_id != value) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          sub_category_id: "", // Reset subcategory when category changes
        }));
        // const filteredSubcategories = subCategory.filter(
        //   (subCat) => subCat.category_id === Number(value)
        // );
        // setFilteredSubCategory(filteredSubcategories);
        setSelectedCategory(value);
      }
      if (value == "Select Category") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          sub_category_id: "", // Reset subcategory when category changes
        }));
      }
    } else if (name === "currency_id") {
      setSelectedCurrency(value);
      const chosenCurrency = currency.find((cur) => cur.id === value);
      if (chosenCurrency) {
        setSelectedCurrencySymbol(chosenCurrency.symbol); // Set the symbol based on the selected currency
      } else {
        setSelectedCurrencySymbol(""); // Reset if no currency is found
      }
    }
  };
  const [uploadDetails, setUploadDetails] = useState([]);

  const handleCancelMethod = (id, par) => {
    if (formDataImei.length > 1) {
      const indexToRemove = formDataImei.findIndex(
        (item, index) => index === par
      );
      if (indexToRemove !== -1) {
        const newData = [...formDataImei];
        newData.splice(indexToRemove, 1);
        setFormDataImei(newData);
      }
    }
  };

  const handleShowAddBrandModal = () => {
    setNestedModalData({
      title: "Add New Brand",
      type: "brand",
      formId: "addBrandForm",
    });
    setShowNestedModal(true);
  };
  const handleShowAddCategoryModal = () => {
    // setShowAddCategoryModal(true);
    // setProductForm(false);
    setNestedModalData({
      title: "Add New Category",
      type: "category",
      formId: "addCategoryForm",
    });
    setShowNestedModal(true);
  };

  const handleShowAddSubCategoryModal = () => {
    setNestedModalData({
      title: "Add Subcategory",
      type: "subcategory",
      formId: "addSubCategoryForm",
      categoryId: formData.category_id,
    });
    setShowNestedModal(true);
  };

  const hideNestedModal = () => {
    setShowModal(false);
  };

  const [productAddForm, setProductAddForm] = useState(true);
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  const handleImageChange = async (files, id = null) => {
    let tempUploadDetails = uploadDetails;
    const name = `image_path${
      id !== null && id !== 1 ? id - 1 : id !== null && id == 1 ? "" : ""
    }`;
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    // console.log("============");
    // console.log(name);
    // Validate file type
    if (!allowedImageTypes.includes(files.type)) {
      setErrorFlashMessage(
        `Invalid file type for ${files.name}. Only JPG, JPEG, and PNG are allowed.`
      );
      console.error(
        `Invalid file type for ${files.name}. Only JPG, JPEG, and PNG are allowed.`
      );
      // Handle invalid file type (display a message, set an error state, etc.)
      setIsValid(false);
      return;
      //continue; // Skip the current iteration for invalid file types
    }

    let size = files.size / 1024;
    let sizeName = "";

    if (size > 1024) {
      size = (size / 1024).toFixed(2);
      sizeName = size + "MB";
    } else {
      sizeName = size.toFixed(2) + "KB";
    }
    const resizedImage = await resizeFile(files);
    // Extract base64 data
    const base64Data = resizedImage.split(",")[1];

    // Convert base64 to Blob
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let j = 0; j < byteCharacters.length; j++) {
      byteNumbers[j] = byteCharacters.charCodeAt(j);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

    // Create a File from Blob
    const file = new File([blob], files.name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

    const existingIndex = tempUploadDetails.findIndex(
      (variant) => variant.input_name === name
    );
    if (existingIndex !== -1) {
      loadingCallback(true);
      // If a variant with the same name exists, update it
      tempUploadDetails[existingIndex] = {
        name: files.name,
        size: sizeName,
        percentage: 0,
        file: file,
        input_name: name,
        image_path: URL.createObjectURL(files),
      };
      const brandLogo = await uploadFileMultipleInputVariants(
        tempUploadDetails,
        name
      );
      if (brandLogo) {
        setFormData({
          ...formData,
          [name]: brandLogo.path,
        });
        loadingCallback(false);
      }
    } else {
      loadingCallback(true);
      tempUploadDetails.push({
        name: files.name,
        size: sizeName,
        percentage: 0,
        file: file,
        input_name: name,
        image_path: URL.createObjectURL(files),
      });

      const brandLogo = await uploadFileMultipleInputVariants(
        tempUploadDetails,
        name
      );

      if (brandLogo) {
        loadingCallback(false);
        setFormData({
          ...formData,
          [name]: brandLogo.path,
        });
      }
    }
    setUploadDetails(tempUploadDetails);
  };
  // console.log("============");
  // console.log(formData);
  // console.log(state);

  // const handleKeyDown = (e) => {
  //   // Prevent form submission if the Enter key is pressed
  //   if (e.key === "Enter") {
  //     e.preventDefault();
  //     // Optionally, handle the barcode input
  //   }
  // };

  // const handleKeyDown = (event) => {
  //   // Prevent form submission when the Enter key is pressed
  //   if (event.key === 'Enter') {
  //     event.preventDefault();

  //     // Custom logic: Move to the next input field (example logic for inputs)
  //     const form = event.target.form;
  //     const index = Array.prototype.indexOf.call(form, event.target);
  //     const nextInput = form.elements[index + 1];

  //     if (nextInput) {
  //       nextInput.focus(); // Move to the next input
  //     }
  //   }
  // };

  const handleKeyDown = (event, index) => {
    if (event.key === "Enter") {
      event.preventDefault();

      // Get all SE/IMEI inputs by their name
      const imeiInputs = Array.from(
        document.querySelectorAll('input[name^="imei"]')
      );

      // Find the current input index
      const currentIndex = imeiInputs.findIndex(
        (input) => input === event.target
      );

      // Move to the next input if it exists
      const nextInput = imeiInputs[currentIndex + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    setUserData(storedUserData);
  }, []);

  const currencyName = userData?.invoice_settings?.currency_info?.name || "";

  return (
    <>
      {productAddForm ? (
        <form
          method="get"
          className="col-sm-12 min-h-[10vh]"
          data-autosubmit="false"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-1 mt-3">
            <div className="relative mb-3">
              <SelectBox
                //options=""
                options={currency}
                label={"Currency"}
                placeholder="Select Currency"
                // value={selectedCurrency}
                value={formData.currency_id}
                name={`currency_id`}
                // onChange={handleImeiChangeSelect.bind(this, `currency_id`)}
                onChange={handleChangeSelect.bind(this, "currency_id")}
                error={
                  formErrors && formErrors.currency_id
                    ? formErrors.currency_id
                    : null
                } // Pass the error message for the 'name' field
                // setFormErrors={setFormErrorsImei}
                setFormErrors={setFormErrors}
              />
              <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                Currency
              </label>
            </div>
            <div className="mb-3">
              <Input
                label={`Product Name*`}
                placeholder={"Product Name"}
                name="name"
                value={formData.name} // Set the value from formData
                onChange={handleChange}
                error={formErrors.name} // Pass the error message for the 'name' field
                setFormErrors={setFormErrors}
              ></Input>
            </div>

            <div className="flex flex-row gap-3">
              <div className="w-[90%]">
                <div className="mb-3 relative">
                  <SelectBox
                    name="brand_id"
                    options={state.brandList}
                    value={formData.brand_id}
                    onChange={handleChangeSelect.bind(this, "brand_id")}
                    // error={formErrors.brand_id}
                    // setFormErrors={setFormErrors}
                    placeholder="Select Brand"
                  />
                  <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                    Select Brand
                  </label>
                </div>
              </div>

              <label
                htmlFor="addBrandForm"
                onClick={handleShowAddBrandModal}
                className="cursor-pointer flex-1"
              >
                <i className="fa-solid fa-plus font-semibold border border-red-50 text-2xl px-3 py-2 rounded-[10px] cursor-pointer"></i>
              </label>
            </div>

            <div className="flex flex-row gap-3 mt-3">
              <div className="w-[90%]">
                <div className="mb-3 relative">
                  <SelectBox
                    name="category_id"
                    options={state.categoryList}
                    value={formData.category_id}
                    onChange={handleChangeSelect.bind(this, "category_id")}
                    style={{ maxHeight: "300px", overflowY: "scroll" }}
                    // error={formErrors.category_id}
                    // setFormErrors={setFormErrors}
                    placeholder="Select Category"
                  />
                  <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                    Category Name
                  </label>
                </div>
              </div>

              <label
                htmlFor="addCategoryForm"
                onClick={handleShowAddCategoryModal}
                className="cursor-pointer flex-1"
              >
                <i className="fa-solid fa-plus font-semibold border border-red-50 text-2xl px-3 py-2 rounded-[10px] cursor-pointer"></i>
              </label>
            </div>

            <div className="flex flex-row gap-3">
              <div className="w-[90%]">
                <div className="mb-3 relative">
                  <SelectBox
                    name="sub_category_id"
                    options={filteredSubCategory}
                    value={formData.sub_category_id}
                    //onChange={handleChangeSelect}
                    onChange={handleChangeSelect.bind(this, "sub_category_id")}
                    // error={formErrors.sub_category_id}
                    // setFormErrors={setFormErrors}
                    // label={"Sub Category"}
                    placeholder="Select Sub Category"
                    style={{ maxHeight: "300px", overflowY: "scroll" }}
                  />
                  <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                    Subcategory Name
                  </label>
                </div>
              </div>

              <label
                htmlFor="addSubCategoryForm"
                onClick={handleShowAddSubCategoryModal}
                className="flex-1"
              >
                <i className="fa-solid fa-plus font-semibold border border-red-50 text-2xl px-3 py-2 rounded-[10px] cursor-pointer"></i>
              </label>
            </div>

            <div className="flex gap-2 mb-3">
              <div className="w-[50%]">
                <div className="relative">
                  <Input
                    label={`Purchase Price ${
                      selectedCurrencySymbol
                        ? `(${selectedCurrencySymbol})`
                        : ""
                    }`}
                    placeholder={"Purchase Price"}
                    value={formData.purchase_price}
                    name={"purchase_price"}
                    onChange={handleChange}
                    error={formErrors.purchase_price} // Pass the error message for the 'name' field
                    setFormErrors={setFormErrors}
                  ></Input>
                </div>
              </div>
              <div className="w-[50%]">
                <div className="relative">
                  <Input
                    label={`Sale Price  ${
                      currencyName ? `(${currencyName})` : ""
                    }`}
                    placeholder={"Sale Price"}
                    value={formData.sale_price}
                    name={"sale_price"}
                    onChange={handleChange}
                    error={formErrors.sale_price} // Pass the error message for the 'name' field
                    setFormErrors={setFormErrors}
                  ></Input>
                </div>
              </div>
            </div>

            <div className="flex gap-2 mb-3">
              <div className="w-[50%]">
                <div className="relative">
                  <SelectBox
                    options={[
                      { id: "New", name: "New", label: "New" },
                      { id: "Used", name: "Used", label: "Used" },
                      { id: "Old", name: "Old", label: "Old" },
                    ]}
                    label={"Condition"}
                    placeholder="Select Condition"
                    value={formData.condition}
                    name={`condition`}
                    onChange={handleChangeSelect.bind(this, "condition")}
                  />
                  <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                    Condition
                  </label>
                </div>
              </div>
              <div className="w-[50%]">
                <div className="relative">
                  <SelectBox
                    options={countryList}
                    value={formData.country}
                    name={`country`}
                    onChange={handleChangeSelect.bind(this, "country")}
                    placeholder="Select Country"
                  />
                  <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                    Country
                  </label>
                </div>
              </div>
            </div>

            <div className="flex gap-2">
              <div className="w-[50%]">
                <div className="mb-3 relative">
                  <Input
                    label={`Ram`}
                    placeholder={"Ram"}
                    value={formData.ram}
                    name={`ram`}
                    onChange={handleChange}
                  ></Input>
                </div>
              </div>
              <div className="w-[50%]">
                <div className="mb-3 relative">
                  <Input
                    label={`Storage`}
                    placeholder={"Storage"}
                    value={formData.storage}
                    name={`storage`}
                    onChange={handleChange}
                  ></Input>
                </div>
              </div>
            </div>
            <div className="mb-3 relative">
              <Input
                label={`Qty`}
                placeholder={"Qty"}
                name="qty"
                value={formData.qty} // Set the value from formData
                onChange={handleChange}
                error={formErrors.qty} // Pass the error message for the 'name' field
                setFormErrors={setFormErrors}
              ></Input>
              <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                Qty
              </label>
            </div>

            <div className="w-full sm:w-[100%] felx flex-col">
              <div className="mb-2 h-[49%]">
                <ImageUploaderFrame
                  name={"image_path"}
                  isBig={true}
                  onChange={handleImageChange}
                  noUpload={
                    exporterProduct && exporterProduct?.image_path
                      ? true
                      : false
                  }
                  //onChange={(files) => handleImageChange("image_path1", files)}
                  images={[
                    formData.image_path,
                    formData.image_path1,
                    formData.image_path2,
                    formData.image_path3,
                  ]}
                ></ImageUploaderFrame>
              </div>
            </div>

            <div className="relative border-[#C1CFEF] py-2.5 border w-full flex-1 rounded-xl">
              <DatePicker
                selected={startDate}
                value={formData.booking_date} // Set the value from formData
                onChange={(date) => handleChangeDate(date, "booking_date")}
                dateFormat="yyyy-MM-dd"
                className="w-full px-2 focus:outline-none"
              />
              <span className="bg-white absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                Booking Date
              </span>
            </div>

            <div className="flex flex-row gap-3">
              <input
                onClick={hideNestedModal}
                type="button"
                value="Back"
                className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none"
              />
              <input
                type="submit"
                value="Next"
                className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF]"
              />
            </div>
          </div>
        </form>
      ) : (
        <>
          <form
            method="get"
            className="col-sm-12 min-h-[10vh]"
            data-autosubmit="false"
            autoComplete="off"
            onSubmit={handleSubmitImei2}
            onKeyDown={handleKeyDown}
          >
            {formDataImei.map((product, index) => (
              <div key={index}>
                <div className="font-nunito font-[700] text-[16px] text-[#000232] mb-4">
                  {index + 1}
                </div>

                <div className="flex flex-row gap-3 mb-3">
                  <div className="flex-1">
                    <div className="relative">
                      {/* <Input
                        label={`Optional Name`}
                        placeholder={"Optional Name"}
                        value={product.optional_name}
                        name={`imei[${index}].optional_name`}
                        onChange={(event) => handleImeiChange(index, event)}
                        //error={formErrorsImei[index] && formErrorsImei[index]?.imei ? formErrorsImei[index].imei : null} // Pass the error message for the 'name' field
                        //setFormErrors={setFormErrorsImei}
                      ></Input> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row gap-3 mb-3">
                  <div className="flex-1">
                    <div className="relative">
                      {/* <Input
                        label={`SE/IMEI`}
                        placeholder={"IMEI"}
                        value={product.imei}
                        name={`imei[${index}].imei`}
                        onChange={(event) => handleImeiChange(index, event)}
                        // error={formErrorsImei[index] && formErrorsImei[index]?.imei ? formErrorsImei[index].imei : null} // Pass the error message for the 'name' field
                        // setFormErrors={setFormErrorsImei}
                        error={formErrorsImei[index]?.imei || null}
                      ></Input> */}

                      <Input
                        label={`SE/IMEI`}
                        placeholder={"IMEI"}
                        value={product.imei}
                        name={`imei[${index}].imei`}
                        onChange={(event) => handleImeiChange(index, event)}
                        error={formErrorsImei[index]?.imei || null}
                        // onKeyDown={(event) => handleKeyDown(event, index)} // Pass event and index
                      />
                    </div>
                  </div>

                  <div className="w-[50px]">
                    <div className="font-semibold border-[1px] border-solid border-[#D9D9D9] rounded-[10px] cursor-pointer text-2xl px-[14px] py-[14px] flex items-center">
                      <img src={qrIcon} />
                    </div>
                  </div>
                </div>

                <div className="flex flex-row justify-between items-center py-3 px-1">
                  <h1 className="text-md font-semibold font-lato pb-1 px-1"></h1>
                  {/* {index != 0 && (
                  <div
                    className="bg-red-600 text-white rounded-md cursor-pointer"
                    onClick={() => handleCancelMethod(product.id, index)}
                  >
                    <p className="text-sm px-[4px] py-[2px]">Cancel</p>
                  </div>
                )} */}
                </div>
              </div>
            ))}
            {/* <div className="flex flex-row justify-between items-center py-3 px-1">
            <h1 className="text-md font-semibold font-lato pb-1 px-1">

            </h1>
            <button
            type="button"
            className="bg-blue-600 text-white rounded-md cursor-pointer p-1"
            onClick={handleAddProduct}>
              Add IMEI
            </button>
          </div> */}

            <div className="flex flex-row gap-3 font-nunito font-[600] text-[18px]">
              <input
                onClick={hideNestedModal}
                type="button"
                value="Back"
                className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
              />
              <input
                type="submit"
                value="Save"
                className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
              />
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default ExporterAddProductForm;
