import axios from 'axios';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const ImageUploaderWithPost = ({ onChange,isBig,index, name,image,apiEndPoint,formData,setFormData,type }) => {

  const token = localStorage.getItem('token');
  const [uploadedImageURLs, setUploadedImageURLs] = useState([]);
  const { acceptedFiles,getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    onDrop: async(acceptedFiles) => {
      const limitedFiles = acceptedFiles.slice(0, 3); // Only allow 3 files
      // setSelectedFiles(limitedFiles); // Update local state
      if (onChange) onChange(limitedFiles, name); // Pass to onChange callback if provided
      const urls = await handleUpload(limitedFiles);
      setUploadedImageURLs(urls);
    },
  });

  // const files = acceptedFiles.slice(0, 3).map((file) => {
  //   const blobUrl = URL.createObjectURL(file);
  //   return (
  //     <div key={file.path}>
  //       <p>{file.path} - {(file.size / 1024).toFixed(2)} KB</p>
  //       <img src={blobUrl} alt={file.path}  />
  //     </div>
  //   );
  // });



  const handleUpload = async (files) => {
    const formDataToSend = new FormData();
    files.forEach(file => {
      formDataToSend.append('file_name',file);
    });

    try {
      const response = await axios.post(apiEndPoint,formDataToSend,{
        headers : {
          Authorization : `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        }
      })
      const path = response.data.path
      if(type === 'slider'){
        if(formData.image_path.length > 2){
          setFormData((prevFormData) => ({
            ...prevFormData,
            image_path : prevFormData.image_path.map((item,i) =>
              i === index ? path : item
            )
           }))
        }
        else{
          setFormData((prevFormData) => ({
            ...prevFormData,
            image_path : [...prevFormData.image_path,path]
           }))
        }
      }else{
        setFormData((prevFormData) => {
          let updatedImagePath = prevFormData.image_path ? [prevFormData.image_path] : '';
          updatedImagePath = path;

        return {
          ...prevFormData,
          image_path: updatedImagePath,
        }
        })
      }


    } catch (error) {
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
      } else if (error.request) {
        console.error("Request made but no response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
      return [];
    }
  };

  console.log(image);
  return (
         <>
      <div
        className={`flex items-center justify-center h-[100%] bg-[#FFF] gap-1 px-1 py-3  border-dashed border-2 border-[#6D81B4] rounded-[22px] cursor-pointer relative`}
      >
       <div {...getRootProps()} className="dropzone" >
       <label htmlFor="drop" className="cursor-pointer block">
            {acceptedFiles.length > 0 ? (
              <div className={`${isBig ? "w-20" : "w-10"} mx-auto `}>
                {console.log('accepted')}
                <img
                  className="w-20 object-cover flex items-center justify-center"
                  src={URL.createObjectURL(acceptedFiles[0])} // Display the first uploaded file
                  alt="Uploaded1"
                />
              </div>
            ) :  (image !== null && image !='http://127.0.0.1:8000/uploads/15-Islam/1690304960.jpg') ? (
              <div className={`${isBig ? "w-20" : "w-10"} mx-auto `}>
                <img
                  className="w-20 object-cover flex items-center justify-center"
                  src={image} // Display the first uploaded file
                  alt="Uploaded2"
                />
              </div>
            ) : (
              // Add additional conditions here if needed
              <div className={`${isBig ? "w-20" : "w-10"} mx-auto `}>
                <img className="w-20 object-contain flex items-center justify-center" src={`${process.env.REACT_APP_ROOT_URL + "/icon.svg"}`} alt="" />
              </div>
            )}
            <p
              className={`md:block text-[#3E4F71] text-center font-lato ${isBig ? "text-[9px]" : "text-[9px]"
                } font-bold mb-[7px]`}
            >
              {acceptedFiles.length > 0
                ? "Image uploaded"
                : "Drop your image here, or "}
              <span className="text-[#407BFF] block">Browse</span>
            </p>
            <p
            className={`text-[#3E4F71] font-lato ${isBig ? "text-[7px]" : "text-[7px]"
              } text-center  font-normal`}
            >
            Supports: JPG, JPEG, PNG
          </p>
          </label>
      <input {...getInputProps()} />
      {/* <p>Drag & drop up to 3 images here, or click to select</p> */}

      {/* <div >
        {selectedFiles.map((file) => (
          <p key={file.path}>
            {file.path} - {(file.size / 1024).toFixed(2)} KB
          </p>
        ))}
      </div> */}
    </div>

      </div>
    </>
  );
};

export default ImageUploaderWithPost;