import axios from "axios";
import React, { useDebugValue, useEffect, useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import Swal from "sweetalert2";
import crose from "../../../assets/images/crose.svg";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FlashMessage } from "../../../utils/FlashMessage";
import FileResizer from "react-image-file-resizer";
import { uploadFileMultipleInputVariants } from "../../../utils/Common";

const PaymentTableItemsV2 = ({loader,  data, accountData, getPayment, getPaymentAccountList}) => {
  // const paymentData = data;
  const paymentData = data.data ? data.data : data;

  const token = localStorage.getItem("token");

  const [showModal, setShowModal] = useState(false);
  const [showNestedModal, setShowNestedModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const [name, setName] = useState("");
  const [methodInfo, setMethodInfo] = useState({})

  const [addAccount, setAddAccount] = useState({
    id: "",
    accountName: "",
    accountNumber: "",
  })

  const [viewAccountDetails, setViewAccountDetails] = useState({
    id: "",
    payment_type_category: [],
  })
  const notify = (msg,type) =>{
    if(type=='success'){
        toast.success(msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            // transition: Bounce,
        });
    }
    if(type=='error'){
        toast.error(msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            // transition: Bounce,
        });
    }
  }

  const [editAccountDetails, setEditAccountDetails] = useState({})

  const handleEditMethodInfo = (items) =>{
    setMethodInfo(items)
    setEditModal( !editModal);
    setViewModal(false);
    setAddModal(false);
    setShowModal(true);
  }

  const handleOpen = (modal, items) => {
    resetFormError();
    console.log(editAccountDetails);
    if(modal=="add_modal"){
      setAddModal( !addModal);
      setViewModal(false);
      setEditModal(false);
      setShowModal(true);
    }
    else if(modal=="edit_modal"){
      setEditModal( !editModal);
      setViewModal(false);
      setAddModal(false);
      setShowModal(true);
    }
    else if(modal=="view_modal"){

      setViewModal( !viewModal);
      setAddModal(false);
      setEditModal(false);
      setShowModal(true);
    }
    else if(modal=='editAcount_modal'&&showModal){
      setShowNestedModal(!showNestedModal)
      setAddModal(false);
      setEditModal(false);
    }
    else {
      setViewModal(false);
      setAddModal(false);
      setEditModal(false);
      setShowModal(false);
      setShowNestedModal(false)
    }
  };
  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
    return [ htmlElRef, setFocus ]
  }
  const [inputRef, setInputFocus] = useFocus();
  const [inputErors, setInputErrors] = useState({
    name: 'This field can not be empty!',
    error1: false,
    error2: false,
    error3: false
  });
  const resetFormError = () => {
    setInputErrors({
        ...inputErors,
        error1: false,
        error2: false,
        error3: false
    });
  };

  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
      setLoading(loading);
  };

  const showModalWarning = (msg, icon='warning') => {
    Swal.fire({
      title: msg,
      text: "",
      icon: icon,
    });
  };

  // Add Paymenet method edit code

  // const handleSubmitPayment = (e) => {
  //   e.preventDefault();
  //   setInputErrors({...inputErors, error1: (methodInfo.type_name==''), error2: methodInfo.icon_letter=='' })
  //   if(methodInfo.type_name&&methodInfo.icon_letter){
  //     loadingCallback(true)
  //     axios.post(
  //       `${process.env.REACT_APP_API_URL}payment-type-update`,
  //       { id: methodInfo.id, type_name: methodInfo.type_name, icon_letter: methodInfo.icon_letter },
  //       {
  //         headers: {
  //           authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //         if(response.data.success)
  //         {
  //           showModalWarning("Account updated successfully!", 'success')
  //           setShowModal(false);
  //           getPayment();
  //         }
  //         else {
  //           showModalWarning("method name already exists")
  //         }
  //         loadingCallback(false)
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   }
  // }

  const [paymentMethodImage, setPaymentMethodImage] = useState(null);

  function handleImageChangePaymentMethod(e) {
    const file = e.target.files[0];
    const allowedExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
    ];

    if (!allowedExtensions.includes(file?.type)) {
      alert("Please upload a valid image file (JPEG, PNG, GIF).");
      return;
    }

    FileResizer.imageFileResizer(
      file,
      500, // Desired width
      500, // Desired height
      "JPEG",
      100,
      0,
      (uri) => {
        const byteString = atob(uri.split(",")[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blobFile = new Blob([ab], { type: file?.type });
        setPaymentMethodImage(blobFile); // Save the processed image as Blob
      },
      "base64"
    );
  }

  const handleSubmitPayment = async (e) => {
    e.preventDefault();

    // Validation for text fields and image
    setInputErrors({
      ...inputErors,
      error1: methodInfo.type_name === "",
      error2: methodInfo.icon_letter === "",
      error3: paymentMethodImage === null,
    });

    if (!methodInfo.type_name || !methodInfo.icon_letter || !paymentMethodImage) {
      return;
    }

    loadingCallback(true);

    try {
      // Upload the image
      const uploadedImage = await uploadFileMultipleInputVariants(
        [{ file: paymentMethodImage, input_name: "icon_image" }],
        "icon_image"
      );

      if (!uploadedImage) {
        throw new Error("Image upload failed.");
      }

      // Prepare payload
      const payload = {
        id: methodInfo.id,
        type_name: methodInfo.type_name,
        icon_letter: methodInfo.icon_letter,
        icon_image: uploadedImage.path, // Include uploaded image path
      };

      // Submit updated payment method
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}payment-type-update`,
        payload,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.success) {
        showModalWarning("Account updated successfully!", "success");
        setShowModal(false);
        getPayment(); // Refresh payment list
      } else {
        showModalWarning(response?.data?.errors?.type_name || "Method name already exists");
      }
    } catch (error) {
      console.error("Error updating payment method:", error);
    } finally {
      loadingCallback(false);
    }
  };

  const handleSubmitAccountInfo = (e) =>{
    e.preventDefault();
    setInputErrors({...inputErors, error1: (addAccount.accountName==''), error2: addAccount.accountNumber=='' })
    if(addAccount.accountName&&addAccount.accountNumber){
      loadingCallback(true)
      axios.post(
        `${process.env.REACT_APP_API_URL}payment-type-category-save`,
        { payment_type_id: addAccount.id, payment_category_name: addAccount.accountName, account_number: addAccount.accountNumber },
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
          if(response.data.success)
          {
            showModalWarning("Account added successfully!", 'success')
            setShowModal(false);
            getPayment();
            getPaymentAccountList();
            setAddAccount({
              id: "",
              accountName: "",
              accountNumber: "",
            })
          }
          loadingCallback(false)
      })
      .catch((error) => {
        console.log(error);
      });
      }
  }
  const handleUpdateAccountDetails = (e) =>{
    e.preventDefault();
    setInputErrors({...inputErors, error1: (editAccountDetails.payment_category_name==''), error2: editAccountDetails.account_number=='' })
    if(editAccountDetails.payment_category_name&&editAccountDetails.account_number){
      loadingCallback(true)
      axios.post(
        `${process.env.REACT_APP_API_URL}payment-type-category-update`,
        {
          id: editAccountDetails.id,
          payment_type_id: editAccountDetails.payment_type_id,
          payment_category_name: editAccountDetails.payment_category_name,
          account_number: editAccountDetails.account_number
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if(response.data.success){
          setShowNestedModal(false);
          setViewAccountDetails(prevState => ({
            ...prevState,
            payment_type_category: prevState.payment_type_category.map(item =>
              item.id === editAccountDetails.id ? { ...item, payment_category_name: editAccountDetails.payment_category_name,
                account_number: editAccountDetails.account_number  } : item
            )
          }));
          showModalWarning("Account updated successfully!", 'success')
          getPayment();
          getPaymentAccountList();
        }
        loadingCallback(false)
      })
      .catch((error) => {
        loadingCallback(false)

      });
    }
  }
  const handleDeleteCondition = (id) =>{
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Condition!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {

        loadingCallback(true)
        axios.post(
          `${process.env.REACT_APP_API_URL}payment-type-delete`,{
            paymenttypeId: id
        },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
            loadingCallback(false)
            if(response.data.success)
            {
              // notify("Payment Method deleted successfully!", 'success')
              FlashMessage("Payment Method deleted successfully!", 'success')
              getPayment();
            }
            else if(!response.data.success) {
              notify(response.data.message, 'error')
            }
            else {
              notify("Something went wrong!", 'error')
            }
        })
        .catch((error) => {
          loadingCallback(false)
          console.log(error);
        });
      }
    });
  }

  const handleDeleteAccount = (id) =>{
    console.log(id);
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {

        loadingCallback(true)
        axios.post(
          `${process.env.REACT_APP_API_URL}payment-type-category-delete`,{
            paymenttypecategoryId: id
        },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
            loadingCallback(false)
            if(response.data.success)
            {
              notify("Account deleted successfully!", 'success')
              getPayment();
              getPaymentAccountList();
            }
            else if(!response.data.success) {
              notify(response.data.message, 'error')
            }
            else {
              notify("Something went wrong!", 'error')
            }
        })
        .catch((error) => {
          loadingCallback(false)
          console.log(error);
        });
      }
    });
  }

  const handleCancelMethod = (modal) =>{
    handleOpen(modal)
    setAddAccount({
      id: "",
      accountName: "",
      accountNumber: "",
    })
    // console.log(addAccount);
  }
  const [viewAccount, setViewAccount] = useState({});
  // useEffect(()=>{
  //   console.log("Account Data", viewAccount);
  // },[viewAccount])

  return (
    <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
      <ToastContainer />
      {loading && (
        <div className="custom-loader-logo">
          <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
        </div>
      )}
      <table className=" w-full text-sm text-left">
        <tbody>
          {paymentData.map((items, index) => (
            <>
              <tr key={items.id} className="border-t border-[#005DAE1C] rounded-xl">
                <td className="py-2 w-1/12">
                  <div className={`rounded-full w-[42px] h-[42px] mr-8 bg-white flex justify-center font-oleo items-center text-[#551FFF] `}>
                    {items.icon_letter}
                  </div>
                </td>
                <td className="py-3">
                  <h4 className="font-lato font-semibold text-18px text-[#000232]">{items.type_name=='cash' ? "Cash" : items.type_name} </h4>
                </td>
                { items.type_name=="cash" ?
                  <td>
                    <div className="text-[12px] font-nunito font-[7000] flex flex-col lg:flex-row gap-2 justify-center items-center">
                      <h1>By Default</h1>
                    </div>
                  </td> :
                  <td className="w-1/4 text-right">
                    <div className="text-[12px] font-nunito font-[7000] flex flex-col lg:flex-row gap-2 justify-center items-center">
                      <label onClick={()=>{handleOpen('add_modal'), setAddAccount({...addAccount, id: items.id})}} htmlFor='add_modal' className="cursor-pointer block border-[1px] border-solid border-[#407BFF] text-[#fff] hover:text-[#fff] bg-[#407BFF] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]"  >Add</label>
                      <label onClick={()=>{handleOpen('view_modal'), setViewAccount(items), setViewAccountDetails({...viewAccountDetails, id: items.id, payment_type_category: items.payment_type_category})}} htmlFor='view_modal' className="cursor-pointer block border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]"  >View</label>
                      <label onClick={()=>handleEditMethodInfo(items)} htmlFor='edit_modal' className="cursor-pointer block border-[1px] border-solid border-[#407BFF]  text-[#fff] hover:text-[#fff] bg-[#407BFF] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]"  >Edit</label>
                      <label onClick={()=>handleDeleteCondition(items.id)} className=" cursor-pointer block border-[1px] border-solid border-[#f14343] text-[#f14343] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]"  >Delete</label>
                    </div>
                  </td>
                }
              </tr>
            </>
          ))}
        </tbody>
      </table>

      {/* Add Account Info */}
      {showModal ? (
        <>
          <div>
            <input type='checkbox' id='add_modal' className='modal-toggle' />
            <div className='modal'>
              <div className='modal-box w-[390px] p-0 bg-white'>
                <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                  <h4 className='font-nunito font-semibold text-lg '>Account Info</h4>
                  <div className='modal-action'>
                    <label htmlFor='add_modal'  className='cursor-pointer modal-action bg-red-600 text-white rounded-md' onClick={()=>handleCancelMethod()}>
                      <img src={crose} width={23} height={20} alt="" />
                    </label>
                  </div>
                </div>
                <div className='px-5 pt-5 pb-[22px]'>
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitAccountInfo}
                  >
                    <input
                      type='text'
                      name="name"
                      onChange={(e) => {
                        const val = e.target.value;
                        setAddAccount({...addAccount, accountName: val})
                        setInputErrors({ ...inputErors, error1: false });
                      }}
                      ref={inputRef}
                      placeholder='Account Name'
                      className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                    />
                    {inputErors.error1
                    ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                    : null}
                    <input
                      type='text'
                      name="name"
                      onChange={(e) => {
                        const val = e.target.value;
                        setAddAccount({...addAccount, accountNumber: val})
                        setInputErrors({ ...inputErors, error2: false });
                      }}
                      ref={inputRef}
                      placeholder='Account Number'
                      className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                    />
                    {inputErors.error2
                    ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                    : null}
                    <div className="button-disabled">
                      <button
                        htmlFor='add_modal'
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {""}
                        Save{""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* { edit payment method form} */}
       {showModal ? (
        <>
          <div>
            <input type='checkbox' id='edit_modal' className='modal-toggle' />
            <div className='modal'>
                <div className='modal-box w-[390px] p-0 bg-white'>
                  <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                    <h4 className='font-nunito font-semibold text-lg '>Edit Payment Method</h4>
                    <div className='modal-action'>
                      <label htmlFor='edit_modal' className='cursor-pointer modal-action bg-red-600 text-white rounded-md' onClick={()=>handleOpen('edit_modal')}>
                        <img src={crose} width={23} height={20} alt="" />
                      </label>
                    </div>
                  </div>
                  <div className='px-5 pt-5 pb-[22px]'>
  <form
    method="post"
    className="col-sm-12"
    data-autosubmit="false"
    autoComplete="off"
    onSubmit={handleSubmitPayment}
    encType="multipart/form-data"
  >
    <input
      type='text'
      name="name"
      value={methodInfo.type_name}
      onChange={(e) => {
        const val = e.target.value;
        setMethodInfo({ ...methodInfo, type_name: val });
        setInputErrors({ ...inputErors, error1: false });
      }}
      placeholder='Edit Payment Method'
      className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
    />
    {inputErors.error1 && (
      <p className="error-message text-red-500 mb-3 pl-1">This field cannot be empty.</p>
    )}

    <input
      type='text'
      name="methodIcon"
      value={methodInfo.icon_letter}
      onChange={(e) => {
        const val = e.target.value;
        setMethodInfo({ ...methodInfo, icon_letter: val });
        setInputErrors({ ...inputErors, error2: false });
      }}
      placeholder='Edit Icon Letter'
      className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
    />
    {inputErors.error2 && (
      <p className="error-message text-red-500 mb-3 pl-1">This field cannot be empty.</p>
    )}

    <div className="flex flex-col w-full mb-[15px]">
      <label
        htmlFor="paymentMethodImage"
        className="text-sm font-medium text-gray-700 mb-2 cursor-pointer"
      >
        Payment Method Logo
      </label>
      <input
        type="file"
        id="paymentMethodImage"
        accept="image/*"
        className="input input-bordered w-full rounded-2xl bg-white px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        onChange={handleImageChangePaymentMethod}
      />
      {inputErors.error3 && (
        <p className="error-message text-red-500 mt-2">Please upload a valid image file.</p>
      )}
    </div>

    <div className="button-disabled">
      <button
        type="submit"
        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
      >
        Update
      </button>
    </div>
  </form>
</div>

                </div>
            </div>
          </div>
        </>
      ) : null}

      {/* view account List  */}

      {/* {showModal ? (
        <>
          <div>
            <input type='checkbox' id='view_modal' className='modal-toggle' />
            <div className='modal'>
              <div className='modal-box max-w-[500px] p-0 bg-white'>
                <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                  <h4 className='font-nunito font-semibold text-lg '>Account List</h4>
                  <div className='modal-action'>
                    <label htmlFor='view_modal' className='cursor-pointer modal-action bg-red-600 text-white rounded-md' onClick={()=>handleOpen('view_modal')}>
                      <img src={crose} width={23} height={20} alt="" />
                    </label>
                  </div>
                </div>
                <div className='px-5 pt-4 pb-[22px] h-96 overflow-y-auto'>
                  <table className=" w-full text-sm text-left rounded-lg font-nunito">
                    <thead className="">
                      <tr>
                        <th className="font-semibold p-1 text-center">Name</th>
                        <th className="font-semibold text-center">Account Number</th>
                        <th className="font-semibold text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="font-thin">
                      {viewAccountDetails?.payment_type_category.map((items, index) => (
                          <tr className="border-t border-[#005DAE1C] rounded-xl">
                            <td className="py-2 w-1/12 text-center">
                              <h4 className="font-nunito font-medium text-18px text-[#000232] p-[6px]">{items.payment_category_name} </h4>
                            </td>
                            <td className="py-2 text-center">
                              <h4 className="font-nunito me-2 font-medium text-18px text-[#000232]">{items.account_number} </h4>
                            </td>
                            <td className="py-2 text-center">
                              <label onClick={()=>{handleOpen('editAcount_modal'),setEditAccountDetails(items)}} htmlFor='editAcount_modal' className="cursor-pointer block border-[1px] border-solid border-[#407BFF] text-[#fff] hover:text-[#fff] bg-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"  >Edit</label>
                              <label onClick={()=>handleDeleteAccount(items.id)} className=" cursor-pointer block border-[1px] border-solid border-[#f14343] text-[#f14343] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]"  >Delete</label>
                            </td>
                          </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null} */}
      {showModal ? (
        <>
          <div>
            <input type='checkbox' id='view_modal' className='modal-toggle' />
            <div className='modal'>
              <div className='modal-box max-w-[500px] p-0 bg-white'>
                <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                  <h4 className='font-nunito font-semibold text-lg '>Account List</h4>
                  <div className='modal-action'>
                    <label htmlFor='view_modal' className='cursor-pointer modal-action bg-red-600 text-white rounded-md' onClick={()=>handleOpen('view_modal')}>
                      <img src={crose} width={23} height={20} alt="" />
                    </label>
                  </div>
                </div>
                <div className='px-5 pt-4 pb-[22px] h-96 overflow-y-auto'>
                  <table className=" w-full text-sm text-left rounded-lg font-nunito">
                    <thead className="">
                      <tr>
                        <th className="font-semibold p-1 text-center">Name</th>
                        <th className="font-semibold text-center">Account Number</th>
                        <th className="font-semibold text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="font-thin">
                      {accountData.map((items, index) => (
                          items.payment_type_id == viewAccount.id &&
                          <tr className="border-t border-[#005DAE1C] rounded-xl">
                            <td className="py-2 w-1/12 text-center">
                              <h4 className="font-nunito font-medium text-18px text-[#000232] p-[6px]">{items.payment_category_name} </h4>
                            </td>
                            <td className="py-2 text-center">
                              <h4 className="font-nunito me-2 font-medium text-18px text-[#000232]">{items.account_number} </h4>
                            </td>
                            <td className="py-2 text-center">
                              <label onClick={()=>{handleOpen('editAcount_modal'),setEditAccountDetails(items)}} htmlFor='editAcount_modal' className="cursor-pointer block border-[1px] border-solid border-[#407BFF] text-[#fff] hover:text-[#fff] bg-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"  >Edit</label>
                              <label onClick={()=>handleDeleteAccount(items.id)} className=" cursor-pointer block border-[1px] border-solid border-[#f14343] text-[#f14343] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]"  >Delete</label>
                            </td>
                          </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}


      {/* nested view edit modal */}
      {showNestedModal ? (
        <>
          <div>
            <input type='checkbox' id='editAcount_modal' className='modal-toggle' />
            <div className='modal'>
              <div className='modal-box w-[390px] p-0 bg-white'>
                <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                  <h4 className='font-nunito font-semibold text-lg '>Account Info</h4>
                  <div className='modal-action'>
                    <label htmlFor='editAcount_modal' className='cursor-pointer modal-action bg-red-600 text-white rounded-md' onClick={()=>handleOpen('editAcount_modal')}>
                      <img src={crose} width={23} height={20} alt="" />
                    </label>
                  </div>
                </div>
                <div className='px-5 pt-5 pb-[22px]'>
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleUpdateAccountDetails}
                  >
                    <input
                      type='text'
                      name="name"
                      value={editAccountDetails.payment_category_name}
                      onChange={(e) => {
                        const val = e.target.value;
                        setEditAccountDetails({...editAccountDetails, payment_category_name: val});
                        setInputErrors({ ...inputErors, error1: false });
                      }}
                      ref={inputRef}
                      placeholder='Edit AC Name'
                      className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                    />
                    {inputErors.error1
                    ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                    : null}
                    <input
                      type='text'
                      name="name"
                      value={editAccountDetails.account_number}
                      onChange={(e) => {
                        const val = e.target.value;
                        setEditAccountDetails({...editAccountDetails, account_number: val});
                        setInputErrors({ ...inputErors, error2: false });
                      }}
                      ref={inputRef}
                      placeholder='Edit Account Number'
                      className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                    />
                    {inputErors.error2
                    ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                    : null}
                    <div className="button-disabled">
                      <button
                        htmlFor='editAcount_modal'
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {""}
                        Save{""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

    </div>
  );
};

export default PaymentTableItemsV2;
