import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/context";
import { formatPrice, formatDate } from "../../utils/Common";
import { parse } from "@fortawesome/fontawesome-svg-core";

const RecentInvoiceItem = (props) => {
  const { invoice, className, type } = props;
  const { config, state } = useContext(AppContext);
  const { BASE_URL } = config;
  const navigate = useNavigate();

  const [hasPermission, setHasPermission] = useState(false);

  console.log(hasPermission);

  const isVerified = JSON.parse(localStorage.getItem("isPinVerify"));

  console.log(isVerified);

  useEffect(() => {
    const employeeData = JSON.parse(localStorage.getItem("employee"));

    if (employeeData) {
      const saleEditFeature = employeeData.role.features.find(
        (feature) => feature.name === "Sale"
      );

      if (saleEditFeature && saleEditFeature.status === 1) {
        const saleBillingOptionActive = saleEditFeature.feature_options.find(
          (option) => option.name === "Sale Edit" && option.status === 1
        );

        if (saleBillingOptionActive) {
          setHasPermission(true);
        }
      }
    }
  }, []);

  return (
    // <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-4 sm:grid-cols-5 items-center">
    <div className="border-t-[1px] text-sm border-solid border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-5 sm:grid-cols-6 items-start">
      <div className="">
        <h1 className="text-[#000232] font-[500]">{invoice.name}</h1>
        <p className="text-[#87889E] font-[700]">{invoice.sname}</p>
      </div>

      <div className=" text-[#000] font-[500] text-center">{invoice.price}</div>
      <div className=" text-[#000] font-[500] text-center">{invoice.due}</div>
      <div className="hidden sm:block">
        <h1 className=" text-[#000] font-[500] text-center">
          {formatDate(invoice.date)}
        </h1>
        <p className=" text-[#87889E] font-[600] text-center">{invoice.time}</p>
      </div>
      {/* <div className={` ${invoice.status && invoice.status === 1 ? "text-green-600" :"text-red-600"} font-[500] text-center`}>{invoice.status ? invoice.status === 1 ? "Completed" : "Hold" : "N/A"}</div> */}
      <div
        className={` ${
          invoice.status && invoice.status === 1
            ? "text-green-600"
            : "text-red-600"
        } font-[500] text-center`}
      >
        {invoice?.status === 1 ? "Completed" : "Hold"}
      </div>
      <div className="text-[12px] font-nunito font-[700] flex flex-col lg:flex-row gap-2 justify-end items-center">
        {type === "wholesale" ? (
          <a
            className="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
            href={`/wholesale-invoice/${invoice.name}`}
          >
            VIEW
          </a>
        ) : (
          <a
            className="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
            href={`/invoice/${invoice.name}`}
          >
            VIEW
          </a>
        )}
        {/* {invoice?.status === 1 ? <button disabled className="block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#e5e5e5] px-[12px] pt-[2px] pb-[2px]">EDIT</button>:<a className="block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#407BFF] px-[12px] pt-[2px] pb-[2px]" href={`/invoice/edit/${invoice.name}`}>EDIT</a>} */}
        {isVerified ? (
          <a
            className="block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#407BFF] px-[12px] pt-[2px] pb-[2px]"
            href={`/invoice/edit/${invoice.name}`}
          >
            EDIT
          </a>
        ) : (
          hasPermission && (
            <a
              className="block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#407BFF] px-[12px] pt-[2px] pb-[2px]"
              href={`/invoice/edit/${invoice.name}`}
            >
              EDIT
            </a>
          )
        )}
      </div>
    </div>
  );
};

RecentInvoiceItem.defaultProps = { className: null };

export default RecentInvoiceItem;
