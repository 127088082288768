import axios from "axios";
import domtoimage from "dom-to-image";
import React, { useContext, useEffect, useRef, useState } from "react";
import Barcode from "react-barcode";
import { BiPlus } from "react-icons/bi";
import { MdModeEdit } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import Pagination from "../../../utils/pagination";
import { useFlashMessage } from "../../../context/FlashMessageContext";
import { AppContext } from "../../../context/context";
import { post } from "../../../services/apiService";
import { formatNumber, scrollUp, useStateArray } from "../../../utils/Common";
import "./index.scss";

import downloadIcon from "../../../assets/images/download.svg";
import printIcon from "../../../assets/images/print.svg";

import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import { AiOutlineSearch } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import { useLocation } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const shop_logo = `${BASE_URL}/layoutlogo.svg`;

function ProductsList() {
  const { state } = useContext(AppContext);
  const { currentUser, invoiceSetting } = state;
  const navigate = useNavigate();
  //--------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");

    if (!employeeApiResponse) {
      setHasPermission(true);
      return; // Exit useEffect early
    }

    if (employeeApiResponse) {
      // Parse the employee data from local storage
      const employeeData = JSON.parse(employeeApiResponse);

      // Get the "Add Product" feature from the employee's role features
      const productFeature = employeeData.role.features.find(
        (feature) => feature.name === "Products"
      );

      // Check if the "Add Product" feature and "Create" option are both active
      if (productFeature && productFeature.status === 1) {
        // Grant access based on the route and permissions
        if (location.pathname === "/products") {
          setHasPermission(true);
          return; // Exit useEffect if permission is granted
        }
      }
      setHasPermission(false);
      // If no permission, navigate to dashboard first
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
    // If not an employee, the useEffect gets skipped
  }, [navigate, location]);
  //------------
  const { flashMessage, getFlashMessageStyle } = useFlashMessage();
  const token = localStorage.getItem("token");
  const [products, setProducts] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const perPage = 10;
  const barcodeRef = useRef();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showNavMenu, setShowNavMenu] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [productImeis, setProductImeis] = useState([]);
  const [totalProduct, setTotalProduct] = useState(0);

  console.log(productImeis?.imeis);

  const [searchFilter, setSearchFilter] = useState({
    keyword: "",
    categoryId: false,
    subCategoryId: false,
    unitId: false,
    brandId: false,
    product_type: "",
    stockIn: false,
    stockOut: false,
  });

  useEffect(() => {
    if (
      searchFilter.keyword ||
      searchFilter.categoryId ||
      searchFilter.subCategoryId ||
      searchFilter.brandId ||
      searchFilter.unitId ||
      searchFilter.stockIn ||
      searchFilter.stockOut
    ) {
      getFilteredProductlist2(currentPage);
    }
  }, [
    searchFilter.keyword,
    searchFilter.categoryId,
    searchFilter.subCategoryId,
    searchFilter.brandId,
    searchFilter.unitId,
    searchFilter.stockIn,
    searchFilter.stockOut,
  ]);

  const getFilteredProductlist2 = (page) => {
    const data = {
      keyword: searchFilter.keyword,
      categoryId: searchFilter.categoryId,
      subCategoryId: searchFilter.subCategoryId,
      unitId: searchFilter.unitId,
      brandId: searchFilter.brandId,
      product_type: searchFilter.product_type,
      stockIn: searchFilter.stockIn,
      stockOut: searchFilter.stockOut,
    };
    loadingCallback(true);
    console.log("data", data);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}search-product-v1?page=${page}&limit=${perPage}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          setProducts(response.data.data);
          setTotalProduct(response.data.data.total);
          setProductsData(response.data.data.data);
        } else {
          console.log("Data Not Found!");
        }
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  const [prevSearchKeyword, setPrevSearchKeyword] = useState("");
  useEffect(() => {
    // Check if the search keyword has changed
    const isSearchKeywordChanged = searchKeyword !== prevSearchKeyword;

    // Update the previous search keyword
    setPrevSearchKeyword(searchKeyword);

    // If it's a new search keyword, set the page to 1
    if (isSearchKeywordChanged) {
      setCurrentPage(1);
    } else {
      if (hasPermission) {
        getProductlist(currentPage, searchKeyword);
      }
    }
  }, [currentPage, searchKeyword, prevSearchKeyword, hasPermission]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const getFilteredProductlist = (page, searchKeyword) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}search-product-v1?page=${page}&limit=${perPage}`,
        { keyword: searchKeyword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          setTotalProduct(response.data.data.total);
          setProducts(response.data.data);
          setProductsData(response.data.data.data);
        } else {
          console.log("Data Not Found!");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  const getProductlist = (page, searchKeyword = null) => {
    setLoader(true);
    if (searchKeyword) {
      getFilteredProductlist(page, searchKeyword);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}product?page=${page}&limit=${perPage}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          //console.log(response.data.data.data);

          if (response.data.success) {
            setLoader(false);
            setProducts(response.data.data);
            setProductsData(response.data.data.data);
            setTotalProduct(response.data.data.total);
          }
        })
        .catch((error) => {
          console.log(error);
          loadingCallback(false);
          if (error?.response?.status === 401) {
            navigate("/login");
            localStorage.setItem("token", "");
            localStorage.setItem("token", "");
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    }
  };
  const deleteProduct = (id, index) => {
    loadingCallback(true);
    const reqData = { productId: id };

    //let cartProducts = [...products];

    // cartProducts.splice(index, 1);
    // setProducts(cartProducts);
    // Use your API service functions to make API requests here
    post("delete-product", reqData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          console.log("Product deleted");
          let cartProducts = [...products];

          cartProducts.splice(index, 1);
          setProducts(cartProducts);
          setProductsData(cartProducts);
        } else {
          console.log("Product delete failed");

          // setWarningMessage(true);
          // const timer = setTimeout(() => {
          //     setWarningMessage(false);
          // }, 3000);
          // return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
  };
  const handleGeneratePDF = () => {
    const input = document.getElementById("invoice-detail-page");

    // Configure html2pdf options
    const pdfOptions = {
      margin: 10, // Set margin to ensure entire content is captured
      filename: "invoice.pdf",
      image: { type: "jpeg", quality: 0.98 }, // Adjust image quality if needed
      html2canvas: { scale: 1 }, // Adjust scale if needed
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .from(input)
      .set(pdfOptions)
      .outputPdf((pdf) => {
        // You can save or display the PDF here if needed
        // For example, you can save it to a file using FileSaver.js:
        // import { saveAs } from 'file-saver';
        // saveAs(pdf, 'invoice.pdf');
      });
  };

  const showModalBeforeDelete = (id, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProduct(id, index);
      }
    });
  };

  const GotoAddProducts = () => {
    navigate("/addproducts");
  };
  const handleClick = (id) => {
    navigate(`/products/${id}`);
  };

  // const handleImageClick = async (imageName, item, id) => {
  //     const barcodeContainer = document.getElementById(`barcode-container-${id}`);
  //     // const barcodeContainer = barcodeRef.current;
  //     const styles = window.getComputedStyle(barcodeContainer);
  //     const color = styles.color;
  //     // console.log(window.getComputedStyle(color));
  //     if (!barcodeContainer) return;
  //     // console.log(item);
  //     if (imageName === 'Download Icon') {
  //         const canvas = await html2canvas(barcodeContainer);

  //         // Create a new canvas for the image and additional information
  //         const newCanvas = document.createElement('canvas');
  //         newCanvas.width = canvas.width;
  //         newCanvas.height = canvas.height + 120; // Adjust height for additional info
  //         console.log(color,'modal');
  //         const ctx = newCanvas.getContext('2d');

  //         // Draw the barcode image onto the new canvas
  //         ctx.drawImage(canvas, 0, 0);
  //         // Add product name and price
  //         ctx.fillStyle = '#fff';
  //         ctx.font = '14px Arial';
  //         ctx.fillText(`Product Name: ${item?.name}`, 10, canvas.height + 20);
  //         ctx.fillText(`Product Price: ${item?.retails_price}`, 10, canvas.height + 40);
  //         ctx.fillText(`Imei Number: ${item?.imeis[0]?.imei}`, 10, canvas.height + 60);
  //         ctx.fillText(`Condition: ${item?.imeis[0]?.item_condition}`, 10, canvas.height + 80);
  //         ctx.fillText(`Storage: ${item?.imeis[0]?.storage}`, 10, canvas.height + 100);
  //         ctx.fillText(`Battery Health: ${item?.imeis[0]?.battery_life}`, 10, canvas.height + 120);

  //         // Convert the canvas to a blob
  //         newCanvas.toBlob((blob) => {
  //             saveAs(blob, `barcode-${item.id}.png`);
  //         });
  //     } else if (imageName === 'Print Icon') {
  //         const printContent = `
  //             <div id="print-content" style="text-align: center;">
  //             <div>${barcodeContainer.innerHTML}</div>
  //                 <div>Product Name: ${item?.name}</div>
  //                 <div>Product Price: ${item?.retails_price}</div>
  //                 <div>Imei Number : ${item?.imeis[0]?.imei} </div>
  //                 <div>Condition : ${item?.imeis[0]?.item_condition} </div>
  //                 <div>Storage : ${item?.imeis[0]?.storage} </div>
  //                 <div>Battery Health : ${item?.imeis[0]?.battery_life} </div>
  //             </div>
  //         `;

  //         const printWindow = window.open('', '_blank');
  //         if (printWindow) {
  //             printWindow.document.write(printContent);
  //             printWindow.document.close();
  //             printWindow.print();
  //             printWindow.close();
  //         }
  //         else {
  //             console.error('Failed to open print window.');
  //         }
  //     }else if(imageName === 'Each Product'){
  //         const barcodeContainerImei = document.getElementById(`barcode-container-${id}`);
  //         // const barcodeContainerImei = barcodeRef.current;
  //         const canvas = await html2canvas(barcodeContainerImei);
  //         const styles = window.getComputedStyle(barcodeContainerImei);
  //         const color = styles.color;
  //         // console.log(color,'modal');
  //         // Check if color is in the unsupported "oklch" format
  //         if (color.includes('oklch')) {
  //             // Convert to a supported color, e.g., replace with an RGB color
  //             element.style.color = 'rgb(120, 80, 200)'; // Replace with your desired RGB fallback
  //         }

  //         // Create a new canvas for the image and additional information
  //         const newCanvas = document.createElement('canvas');
  //         newCanvas.width = canvas.width;
  //         newCanvas.height = canvas.height + 20; // Adjust height for additional info

  //         const ctx = newCanvas.getContext('2d');

  //         // Draw the barcode image onto the new canvas
  //         ctx.drawImage(canvas, 0, 0);
  //         // console.log(item);
  //         // Add product name and price

  //         const bhText = `BH: ${item.battery_life ? item.battery_life: null}`
  //         const storageText = `Storage: ${item?.storage}`;

  //         // Measure width of bh text to position Storage text

  //         ctx.fillStyle = '#fff';
  //         ctx.font = '14px Arial';
  //         ctx.fillText(storageText, 10, canvas.height + 20);
  //         const storageTextWidth = ctx.measureText(storageText).width;
  //         ctx.fillText(bhText, 10 + storageTextWidth + 55, canvas.height + 20);
  //         // ctx.fillText(`BH: ${item.battery_health ? item.battery_health: null}`, 10, canvas.height + 40);

  //         // Convert the canvas to a blob
  //         newCanvas.toBlob((blob) => {
  //             saveAs(blob, `barcode-${item.id}.png`);
  //         });
  //     }
  // };

  // console.log(totalProduct);

  const handleImageClick = async (imageName, item, id) => {
    const barcodeContainer = document.getElementById(`barcode-container-${id}`);
    barcodeContainer.style.fontFamily = "Nunito, Roboto, sans-serif";
    if (!barcodeContainer) return;

    if (imageName === "Download Icon") {
      try {
        // Use dom-to-image to capture the barcodeContainer as an image
        const dataUrl = await domtoimage.toPng(barcodeContainer);

        // Create an image element from the data URL
        const img = new Image();
        img.src = dataUrl;

        // Create a new canvas for the image and additional information
        const newCanvas = document.createElement("canvas");
        const ctx = newCanvas.getContext("2d");

        // Convert 1.5cm x 1cm to pixels (assuming 96 DPI)
        const widthInPixels = (5 / 2.54) * 96; // ≈ 56.69
        const heightInPixels = (2.5 / 2.54) * 96; // ≈ 37.79

        // Set the new canvas size
        newCanvas.width = widthInPixels;
        newCanvas.height = heightInPixels; // Adjust height for additional info

        // // Draw the image onto the new canvas
        ctx.drawImage(img, 0, 0, newCanvas.width, newCanvas.height);

        // // Add product name and price
        ctx.fillStyle = "#fff";
        ctx.font = "14px Arial";
        // ctx.fillText(`Product Name: ${item?.name}`, 10, img.height + 20);
        // ctx.fillText(
        //   `Product Price: ${item?.retails_price}`,
        //   10,
        //   img.height + 40
        // );
        // ctx.fillText(`Imei: ${item?.imeis[0]?.imei}`, 10, img.height + 60);
        // ctx.fillText(
        //   `Condition: ${item?.imeis[0]?.item_condition}`,
        //   10,
        //   img.height + 80
        // );
        // ctx.fillText(
        //   `Storage: ${item?.imeis[0]?.storage}`,
        //   10,
        //   img.height + 100
        // );
        // ctx.fillText(
        //   `Battery Health: ${item?.imeis[0]?.battery_life}`,
        //   10,
        //   img.height + 120
        // );

        // Convert the canvas to a blob and save the image
        newCanvas.toBlob((blob) => {
          if (blob) {
            saveAs(blob, `barcode-${item.id}.png`);
          } else {
            console.error("Failed to create blob from canvas");
          }
        });
      } catch (error) {
        console.error("Error generating image:", error);
      }
    } else if (imageName === "Print Icon") {
      const printContent = `
                <div id="print-content" style="text-align: center;">
                    <div>${barcodeContainer.innerHTML}</div>
                    <div>Product Name: ${item?.name}</div>
                    <div>Product Price: ${item?.retails_price}</div>
                    <div>Imei Number: ${item?.imeis[0]?.imei}</div>
                    <div>Condition: ${item?.imeis[0]?.item_condition}</div>
                    <div>Storage: ${item?.imeis[0]?.storage}</div>
                    <div>Battery Health: ${item?.imeis[0]?.battery_life}</div>
                </div>
            `;

      const printWindow = window.open("", "_blank");
      if (printWindow) {
        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.print();
        // printWindow.close();
      } else {
        console.error("Failed to open print window.");
      }
    } else if (imageName === "Each Product") {
      try {
        const barcodeContainerImei = document.getElementById(
          `barcode-container-${id}`
        );
        barcodeContainerImei.style.fontFamily = "Nunito, Roboto, sans-serif";
        const dataUrl = await domtoimage.toPng(barcodeContainerImei);

        // Create an image element from the data URL
        const img = new Image();
        img.src = dataUrl;

        // Create a new canvas for the image and additional information
        const newCanvas = document.createElement("canvas");
        const ctx = newCanvas.getContext("2d");

        // Convert 1.5cm x 1cm to pixels (assuming 96 DPI)
        const widthInPixels = (5 / 2.54) * 96; // ≈ 56.69
        const heightInPixels = (2.5 / 2.54) * 96; // ≈ 37.79

        // Set the new canvas size
        newCanvas.width = widthInPixels;
        newCanvas.height = heightInPixels; // Adjust height for additional info

        // // Draw the image onto the new canvas
        ctx.drawImage(img, 0, 0, newCanvas.width, newCanvas.height);

        // // Add product information
        // const bhText = `BH: ${item.battery_life ? item.battery_life : null}`;
        // const storageText = `Storage: ${item?.storage}`;

        // // Measure width of bh text to position Storage text
        // ctx.fillStyle = "#fff";
        // ctx.font = "14px Arial";
        // ctx.fillText(storageText, 10, img.height + 20);
        // const storageTextWidth = ctx.measureText(storageText).width;
        // ctx.fillText(bhText, 10 + storageTextWidth + 55, img.height + 20);

        // Convert the canvas to a blob and save the image
        newCanvas.toBlob((blob) => {
          if (blob) {
            saveAs(blob, `barcode-${item.id}.png`);
          } else {
            console.error("Failed to create blob from canvas");
          }
        });
      } catch (error) {
        console.error("Error generating image:", error);
      }
    }
  };








  const handleButtonClick = () => {
    console.log("Clicked");
    setShowNavMenu((prevShowNavMenu) => !prevShowNavMenu);
  };

  // const handleClickOutside = (event) => {
  //     if (menuRef.current && !menuRef.current.contains(event.target)) {
  //     setShowNavMenu(false);
  //     }
  // };
  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowNavMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const shouldRenderPagination = products.total > perPage;
  //scroll up
  useEffect(() => {
    scrollUp();
  }, [currentPage]);

  //-----------------
  const [isPurchasePriceVisible, setIsPurchasePriceVisible] = useState(false);
  useEffect(() => {
    // Retrieve employee data from localStorage
    const employeeApiResponse = localStorage.getItem("employee");

    if (!employeeApiResponse) {
      setIsPurchasePriceVisible(true);
      return; // Exit useEffect early
    }

    if (employeeApiResponse) {
      const employeeData = JSON.parse(employeeApiResponse);

      // Find the "Product Purchase Price" feature
      const productPurchasePriceFeature = employeeData.role.features.find(
        (feature) =>
          feature.name === "Product Purchase Price" && feature.status === 1
      );

      if (productPurchasePriceFeature) {
        // Check for the feature option "Hidden" or "Visible"
        const hiddenOption = productPurchasePriceFeature.feature_options.find(
          (option) => option.name === "Hidden" && option.status === 1
        );
        const visibleOption = productPurchasePriceFeature.feature_options.find(
          (option) => option.name === "Visible" && option.status === 1
        );

        // Set the visibility state for the Purchase Price section
        if (visibleOption) {
          setIsPurchasePriceVisible(true);
        } else if (hiddenOption) {
          setIsPurchasePriceVisible(false);
        }
      }
    }
  }, []);
  //-----------------

  const [editingIndex, setEditingIndex] = useState(null);
  const [editedImei, setEditedImei] = useState("");

  const handleEditClick = (index, imei) => {
    setEditingIndex(index);
    setEditedImei(imei);
  };

  const handleInputChange = (e) => {
    setEditedImei(e.target.value);
  };

  // const handleSubmit = async (id) => {
  //   console.log(id);
  //   try {
  //     loadingCallback(true); // Start loading
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}update-product-imei/${id}`,
  //       {
  //         imei: editedImei,
  //       },
  //       {
  //         headers: {
  //           authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     console.log("IMEI updated:", response.data);

  //     // Update the productImeis state locally
  //     const updatedImeis = productImeis.imeis.map((item, idx) =>
  //       idx === editingIndex ? { ...item, imei: editedImei } : item
  //     );

  //     setProductImeis((prevState) => ({
  //       ...prevState,
  //       imeis: updatedImeis,
  //     }));

  //     // Update productsData state with the updated IMEI data
  //     // setProductsData((prevProductsData) => {
  //     //     const updatedProducts = prevProductsData.map((product) => {
  //     //       if (product.id === id) {
  //     //         return {
  //     //           ...product,
  //     //           imeis: updatedImeis, // Update the IMEI list for the specific product
  //     //         };
  //     //       }
  //     //       return product; // Keep other products unchanged
  //     //     });
  //     //     return updatedProducts;
  //     //   });
  //     // Update the products state
  //     // setProducts((prevProducts) =>
  //     //     prevProducts.data.map((product) =>
  //     //       product.id === id
  //     //         ? { ...product, imeis: updatedImeis }
  //     //         : product
  //     //     )
  //     //   );

  //     console.log(products);

  //     // Reset editing state
  //     setEditingIndex(null);
  //     setEditedImei("");
  //     loadingCallback(false);
  //     window.location.reload();
  //   } catch (error) {
  //     console.error("Error updating IMEI:", error);
  //     loadingCallback(false);
  //   }
  // };

  const handleSubmit = async (id) => {
    console.log(id);
    try {
      loadingCallback(true); // Start loading

      // Step 1: Check if the IMEI already exists
      const checkImeiResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}check-product-imei`,
        { imei: editedImei },
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!checkImeiResponse.data.available) {
        // IMEI is already taken
        alert(checkImeiResponse.data.message || "IMEI is already existed.");
        loadingCallback(false);
        return; // Stop further execution
      }

      // Step 2: Proceed with updating the product IMEI
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}update-product-imei/${id}`,
        {
          imei: editedImei,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("IMEI updated:", response.data);

      // Update the productImeis state locally
      const updatedImeis = productImeis.imeis.map((item, idx) =>
        idx === editingIndex ? { ...item, imei: editedImei } : item
      );

      setProductImeis((prevState) => ({
        ...prevState,
        imeis: updatedImeis,
      }));

      console.log(products);

      // Reset editing state
      setEditingIndex(null);
      setEditedImei("");
      loadingCallback(false);
      window.location.reload();
    } catch (error) {
      console.error("Error updating IMEI:", error);
      loadingCallback(false);
    }
  };

  return (
    <div className="productsList-container">
      <div className="productsList-header mb-6">
        <div className="productsList-header-left">
          <span>Products / Products</span>
          <p>Product List ({totalProduct})</p>
        </div>
        <div className="productsList-header-right">
          <div className="flex gap-2 justify-center">
            <div className="flex relative w-[95%]">
              <input
                autoFocus
                type="text"
                className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[45px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                placeholder="Search Product"
                onChange={(e) => {
                  const value = e.target.value;
                  if (
                    searchFilter.brandId || searchFilter.unitId
                      ? value.length >= 2
                      : value.length >= 3
                  ) {
                    setSearchFilter({ ...searchFilter, keyword: value });
                  } else {
                    setSearchFilter({ ...searchFilter, keyword: "" });
                    getProductlist(1, "");
                  }
                }}
              />
              <div className="absolute top-[17px] left-[10px]">
                <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
              </div>

              <div className="absolute top-[17px]  right-[10px]">
                <div
                  // ref={menuRef}
                  ref={buttonRef}
                  onClick={handleButtonClick}
                  className=" relative flex flex-col items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z"
                      fill="#102048"
                    />
                    <path
                      d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z"
                      fill="#102048"
                    />
                    <path
                      d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z"
                      fill="#102048"
                    />
                    <path
                      d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z"
                      fill="#102048"
                    />
                    <path
                      d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z"
                      fill="#102048"
                    />
                    <path
                      d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z"
                      fill="#102048"
                    />
                    <path
                      d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z"
                      fill="#102048"
                    />
                    <path
                      d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z"
                      fill="#102048"
                    />
                    <path
                      d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z"
                      fill="#102048"
                    />
                  </svg>
                </div>
              </div>
              {showNavMenu && (
                <div
                  ref={menuRef}
                  className="absolute z-50  w-[230px] shadow-sm left-[35px] top-[50px] bg-[#FFF] border-1 border-solid p-3 rounded-[10px]"
                >
                  <div
                    onClick={(e) =>
                      setSearchFilter({
                        ...searchFilter,
                        categoryId: !searchFilter.categoryId,
                      })
                    }
                    className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start"
                  >
                    <div className="">
                      <input
                        type="checkbox"
                        checked={searchFilter.categoryId}
                        onChange={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            categoryId: !searchFilter.categoryId,
                          })
                        }
                        // className="bg-[#E5EDFF] p-3 "
                      />
                    </div>
                    <div className="">
                      <p className=" text-[#000] font-[500] ps-2 text-xs">
                        Category
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={(e) =>
                      setSearchFilter({
                        ...searchFilter,
                        subCategoryId: !searchFilter.subCategoryId,
                      })
                    }
                    className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start"
                  >
                    <div className="">
                      <input
                        type="checkbox"
                        checked={searchFilter.subCategoryId}
                        onChange={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            subCategoryId: !searchFilter.subCategoryId,
                          })
                        }
                        // className="bg-[#E5EDFF] p-3 "
                      />
                    </div>
                    <div className="">
                      <p className=" text-[#000] whitespace-nowrap font-[500] ps-2 text-xs">
                        Sub Category
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={(e) =>
                      setSearchFilter({
                        ...searchFilter,
                        unitId: !searchFilter.unitId,
                      })
                    }
                    className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start"
                  >
                    <div className="">
                      <input
                        type="checkbox"
                        checked={searchFilter.unitId}
                        onChange={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            unitId: !searchFilter.unitId,
                          })
                        }
                        // className="bg-[#E5EDFF] p-3 "
                      />
                    </div>
                    <div className="">
                      <p className=" text-[#000] font-[500] ps-2 text-xs">
                        Unit
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={(e) =>
                      setSearchFilter({
                        ...searchFilter,
                        brandId: !searchFilter.brandId,
                      })
                    }
                    className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start"
                  >
                    <div className="">
                      <input
                        type="checkbox"
                        checked={searchFilter.brandId}
                        onChange={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            brandId: !searchFilter.brandId,
                          })
                        }
                        // className="bg-[#E5EDFF] p-3 "
                      />
                    </div>
                    <div className="">
                      <p className=" text-[#000] font-[500] ps-2 text-xs">
                        Brand
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={(e) => {
                      // Prevent the checkbox from being toggled twice
                      if (e.target.tagName !== "INPUT") {
                        setSearchFilter({
                          ...searchFilter,
                          product_type: "service",
                        });
                      }
                    }}
                    className="border-b-[1px] text-center text-sm border-solid border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start"
                  >
                    <div>
                      <input
                        type="checkbox"
                        checked={searchFilter.product_type === "service"}
                        onChange={(e) => {
                          setSearchFilter({
                            ...searchFilter,
                            product_type: e.target.checked ? "service" : "",
                          });
                        }}
                      />
                    </div>
                    <div className="">
                      <p className="text-[#000] font-[500] ps-2 text-xs">
                        Service
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between w-full col-span-3 items-center pt-[10px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
                    <div
                      onClick={(e) =>
                        setSearchFilter({
                          ...searchFilter,
                          stockIn: !searchFilter.stockIn,
                        })
                      }
                      className={`relative ${
                        searchFilter.stockIn
                          ? "bg-[#407BFF] cursor-pointer text-white"
                          : "bg-gray-100"
                      }  border-gray-100 py-1 border w-full flex-1 rounded-xl`}
                    >
                      <p className="w-full text-[13px] cursor-pointer text-center">
                        Stock In
                      </p>
                    </div>
                    <div
                      onClick={(e) =>
                        setSearchFilter({
                          ...searchFilter,
                          stockOut: !searchFilter.stockOut,
                        })
                      }
                      className={`relative ${
                        searchFilter.stockOut
                          ? "bg-[#407BFF] cursor-pointer text-white"
                          : "bg-gray-100"
                      }  border-gray-100 py-1 border w-full flex-1 rounded-xl`}
                    >
                      <p className="w-full text-[13px] cursor-pointer text-center ">
                        Stock Out
                      </p>
                    </div>
                  </div>
                  {/* <div>
                                        <p className="p-2 text-md">Date</p>
                                        <div className="flex justify-between w-full col-span-3 items-center mt-[5px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
                                            <div className="relative border-[#C1CFEF] py-1 border w-full flex-1 rounded-xl">
                                                <DatePicker
                                                selected={searchFilter.startDate}
                                                onChange={(date) => setSearchFilter({...searchFilter, startDate: date})}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="00/00/00"
                                                className="w-full px-2 text-xs focus:outline-none"
                                                />
                                                 <span className="bg-white absolute left-2 font-semibold px-1 text-[8px] top-0 text-[#102048] translate-y-[-50%]">
                                                    Start Date
                                                </span>
                                            </div>
                                            <div className="relative border-[#C1CFEF] py-1 border w-full flex-1 rounded-xl">
                                                <DatePicker
                                                selected={searchFilter.endDate}
                                                onChange={(date) => setSearchFilter({...searchFilter, endDate: date})}
                                                dateFormat="dd/MM/yyyy"
                                                className="w-full px-2 text-xs focus:outline-none"
                                                />
                                                 <span className="bg-white absolute left-2 font-semibold px-1 text-[8px] top-0 text-[#102048] translate-y-[-50%]">
                                                    End Date
                                                </span>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              )}
            </div>

            <button
              onClick={GotoAddProducts}
              className="bg-[#407BFF] rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 pl-[22px] pr-[24px] flex items-center gap-2"
            >
              <BiPlus className="text-xl"></BiPlus>
            </button>
          </div>
        </div>
        {/* Pagination component */}
      </div>
      {flashMessage && (
        <div
          className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
            flashMessage.type
          )}`}
        >
          {flashMessage.text}
        </div>
      )}
      {loading && (
        <div className="custom-loader-logo">
          {/* <BeatLoader color={"#123abc"} size={20} /> */}
          <img
            className=""
            src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
            alt="logo"
          />
        </div>
      )}

      {productsData.map((item, index) => {
        return (
          <div
            className={`gap-3 ${
              /* ?  ( Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 0 : */
              Number.parseInt(item?.current_stock ? item?.current_stock : 0) <=
              0
                ? // ( Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) === 0
                  "border-[#ca1830]"
                : item?.imeis
                    ?.length /* ?  ( Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 5 : */ &&
                  Number.parseInt(
                    item?.current_stock ? item?.current_stock : 0
                  ) <= 5
                ? // ( Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 5
                  "border-[#FBBC05]"
                : "border-[#18CA29]"
            } border-2 border-solid productlist-table`}
            style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
            key={item.id}
          >
            <div className="flex-1 flex flex-col lg:flex-row gap-3">
              {/* product header image*/}
              <div className="table-content-image w-full md:w-[160px] h-auto md:h-[160px]">
                <img
                  className="w-[160px] h-[160px] md:w-[100%] md:max-h-[160px] md:min-h-[160px] rounded-[12px] m-auto md:m-0"
                  src={item?.image_path ? item.image_path : shop_logo}
                  alt="img"
                />
              </div>

              {/* old code */}
              {/* <div className="table-content-data">
                                <div className="flex flex-row gap-[20px] mb-[12px]">
                                    <div className="font-nunito">
                                        <div className="text-[#2D3748] text-[16px] font-bold">{item.name}</div>
                                        <div className="text-[#718096] text-[14px] font-extrabold">#{item.barcode}</div>
                                    </div>
                                    <div className="border-l-[1px] border-solid border-#718096 p-l-[16px] flex items-center">
                                        <span className="bg-[#FF6B02] rounded-[5px] text-[#FFF] text-[12px] font-extrabold px-2 pt-[2px] pb-[2px] ml-[20px]">
                                            {item?.discount ? item?.discount : 0}{' '}
                                            {(item?.discount_type ===
                                                'Percentage' || item?.discount_type ===
                                                '2')
                                                ? '%'
                                                : invoiceSetting
                                                    ?.currency_info
                                                    ?.code || 'BDT'}
                                        </span>
                                    </div>
                                </div>
                                <div className="table-content-data-details">
                                    <div className="left table-content-data-left grid grid-cols-[100px,1fr] gap-x-[5px] gap-y-[2px]">
                                        <div className="text-[#718096] text-[14px] font-medium font-nunito">Category</div>
                                        <div className="text-[#2D3748] text-[14px] font-bold font-nunito">: {item.category ? item.category.name : null}</div>

                                        <div className="text-[#718096] text-[14px] font-medium font-nunito">Sub Category</div>
                                        <div className="text-[#2D3748] text-[14px] font-bold whitespace-nowrap font-nunito">: {item.sub_category ? item.sub_category.name : null}</div>

                                        <div className="text-[#718096] text-[14px] font-medium font-nunito">Brand</div>
                                        <div className="text-[#2D3748] text-[14px] font-bold font-nunito">: {item.brands ? item.brands.name : null}</div>

                                        <div
                                            className={`${item?.current_stock === 0
                                                ? 'text-[#F00000]'
                                                : 'text-[#718096]'
                                                }  text-[14px] font-medium font-nunito`}
                                        >
                                            {item?.current_stock === 0
                                                ? 'Stock Out'
                                                : 'Stock In'}
                                        </div>
                                        <div className="text-[#2D3748] text-[14px] whitespace-nowrap font-bold font-nunito">: {(item.current_stock != "") ? formatNumber(item.current_stock) : '0'} {item.unit_id ? item?.unit?.name : null}</div>
                                        <div className="text-[#2D3748] text-[14px] whitespace-nowrap font-bold font-nunito">
                                            Serial/IMEI : {item?.serial ? item?.serial  : null}
                                        </div>
                                    </div>
                                    <div className="right table-content-data-left grid grid-cols-[100px,1fr] gap-x-[5px] gap-y-[2px]">
                                        <div className="text-[#718096] text-[14px] font-medium font-nunito">Unit</div>
                                        <div className="text-[#718096] text-[14px] font-bold font-nunito">: {item.unit_id ? item?.unit?.name : null}</div>

                                        <div className="text-[#718096] text-[14px] font-medium font-nunito">Purchase price</div>
                                        <div className="text-[#2D3748] text-[14px] font-bold font-nunito">: {formatNumber(item.purchase_price)} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}</div>

                                        <div className="text-[#718096] text-[14px] font-medium font-nunito">Retail price</div>
                                        <div className="text-[#2D3748] text-[14px] font-bold font-nunito">: {formatNumber(item.retails_price)} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}</div>

                                        <div className="text-[#718096] text-[14px] font-medium font-nunito">Wholesale price</div>
                                        <div className="text-[#2D3748] text-[14px] font-bold font-nunito">: {formatNumber(item.wholesale_price)} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}</div>

                                    </div>
                                </div>
                            </div> */}

              {/* new code */}

              {/* table info */}
              <div className="flex-1 font-nunito">
                {/* product header content*/}
                <div className="flex items-center gap-4 mt-2 mb-3">
                  <div>
                    <div className="text-[#2D3748] text-[16px] font-bold">
                      {item.name}
                    </div>
                    <div className="text-[#718096] text-[14px] font-extrabold">
                      #{item.id}
                    </div>
                  </div>
                  <div className="bg-[#718096] w-px rounded-full h-10"></div>
                  <span className="bg-[#FF6B02] rounded-[5px] text-[#FFF] text-xs font-extrabold px-2 py-2">
                    {item?.discount ? item?.discount : 0}{" "}
                    {item?.discount_type === "Percentage" ||
                    item?.discount_type === "2"
                      ? "%"
                      : invoiceSetting?.currency_info?.code || "BDT"}
                  </span>
                </div>

                <div className="flex flex-col lg:flex-row gap-3 w-full sm:gap-4 md:gap-5 lg:gap-6">
                  {/* first info */}
                  <div className="text-sm font-nunito flex-1 space-y-1">
                    {/* category */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] font-medium col-span-2">
                        Category
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        <span style={{ wordBreak: "break-word" }}>
                          {item.category ? item.category.name : null}
                        </span>
                      </div>
                    </div>
                    {/* sub category */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] whitespace-nowrap font-medium col-span-2">
                        Sub Category
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        <span style={{ wordBreak: "break-word" }}>
                          {item.sub_category ? item.sub_category.name : null}
                        </span>
                      </div>
                    </div>
                    {/* brand */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] font-medium col-span-2">
                        Brand
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        <span style={{ wordBreak: "break-word" }}>
                          {item.brands ? item.brands.name : null}
                        </span>
                      </div>
                    </div>
                    {/* stock */}
                    <div className="grid grid-cols-5">
                      <div
                        className={`font-medium col-span-2 ${
                          item /*  ?  ( Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no: 0)) <= 0 : */ &&
                          Number.parseInt(
                            item?.current_stock ? item?.current_stock : 0
                          ) <= 0
                            ? // ( Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0))  === 0
                              "text-[#F00000]"
                            : "text-[#718096]"
                        }`}
                      >
                        {item /* ?  ( Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 0 : */ &&
                        Number.parseInt(
                          item?.current_stock ? item?.current_stock : 0
                        ) <= 0
                          ? // ( Number.parseInt(item?.stock_unit_no)) === 0
                            "Stock Out"
                          : "Stock In"}
                      </div>
                      {item?.have_variant == 1 && item.imeis.length >= 0 ? (
                        <label
                          onClick={() => {
                            setShowModal((e) => !e), setProductImeis(item);
                          }}
                          htmlFor="variation_list_view_modal"
                          className="text-[#3276FF] underline font-bold flex gap-1 col-span-3"
                        >
                          <span>:</span>
                          <span style={{ wordBreak: "break-word" }}>
                            {/* {item.current_stock != ""
                              ? item.current_stock
                              : "0"}{" "} */}
                            {item?.imeis?.length > 0
                              ? formatNumber(item?.imeis?.length)
                              : "0"}{" "}
                            {item.unit_id ? item?.unit?.name : null}
                          </span>
                        </label>
                      ) : (
                        <label
                          className="text-[#3276FF] underline"
                          style={{ wordBreak: "break-word" }}
                        >
                          {item.current_stock != ""
                            ? formatNumber(item.current_stock)
                            : "0"}{" "}
                          {item.unit_name ? item?.unit_name : null}
                        </label>
                      )}
                    </div>
                    {/* serial */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] font-medium col-span-2">
                        Serial/IMEI
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        <span style={{ wordBreak: "break-word" }}>
                          {item?.serial ? item?.serial : null}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* second info */}
                  <div className="text-sm font-nunito flex-1 space-y-1">
                    {/* unit */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] font-medium col-span-2">
                        Unit
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        <span style={{ wordBreak: "break-word" }}>
                          {item.unit_id ? item?.unit?.name : null}
                        </span>
                      </div>
                    </div>
                    {/* Purchase */}
                    {isPurchasePriceVisible && (
                      <div className="grid grid-cols-5">
                        <div className="text-[#718096] font-medium col-span-2">
                          Purchase price
                        </div>
                        <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                          <span>:</span>
                          {/*  */}
                          {item?.have_variant == 1 ? (
                            <label
                              onClick={() => {
                                setShowModal((e) => !e), setProductImeis(item);
                              }}
                              htmlFor="variation_list_view_modal"
                              className="text-[#3276FF] underline font-bold flex gap-1 col-span-3"
                            >
                              <span style={{ wordBreak: "break-word" }}>
                                Variation
                              </span>
                            </label>
                          ) : (
                            <span style={{ wordBreak: "break-word" }}>
                              {formatNumber(item.purchase_price)}{" "}
                              {invoiceSetting?.currency_info?.code
                                ? invoiceSetting?.currency_info?.code
                                : "BDT"}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    {/* Retail price */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] font-medium col-span-2">
                        Retail price
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        {item?.have_variant == 1 ? (
                          <label
                            onClick={() => {
                              setShowModal((e) => !e), setProductImeis(item);
                            }}
                            htmlFor="variation_list_view_modal"
                            className="text-[#3276FF] underline font-bold flex gap-1 col-span-3"
                          >
                            <span style={{ wordBreak: "break-word" }}>
                              Variation
                            </span>
                          </label>
                        ) : (
                          <span style={{ wordBreak: "break-word" }}>
                            {formatNumber(item.retails_price)}{" "}
                            {invoiceSetting?.currency_info?.code
                              ? invoiceSetting?.currency_info?.code
                              : "BDT"}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* Wholesale price */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] font-medium col-span-2">
                        Wholesale price
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        {/* <span style={{wordBreak:"break-word"}}>{formatNumber(item.wholesale_price)} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}</span> */}
                        {item?.have_variant == 1 ? (
                          <label
                            onClick={() => {
                              setShowModal((e) => !e), setProductImeis(item);
                            }}
                            htmlFor="variation_list_view_modal"
                            className="text-[#3276FF] underline font-bold flex gap-1 col-span-3"
                          >
                            <span style={{ wordBreak: "break-word" }}>
                              Variation
                            </span>
                          </label>
                        ) : (
                          <span style={{ wordBreak: "break-word" }}>
                            {formatNumber(item.wholesale_price)}{" "}
                            {invoiceSetting?.currency_info?.code
                              ? invoiceSetting?.currency_info?.code
                              : "BDT"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* table info */}
            </div>

            <div className="flex flex-col justify-start">
              <div className="table-action justify-center">
                {/* <div className="table-action-delete">
                                    <button onClick={() => showModalBeforeDelete(item.id, index)} className='flex text-[10px] justify-center items-center text-[#E53E3E] bg-transparent' >
                                        <MdDelete /><span>Delete</span>
                                    </button>
                                </div> */}

                <div className="table-action-edit">
                  <Link
                    className="hover:text-gray-700"
                    to={`/editproduct/${item.id}`}
                  >
                    <button className="flex justify-center gap-[2px] items-center bg-[#E6E6E6] text-gray-700 !text-[12px] rounded-[5px] px-3  py-[6px]">
                      <MdModeEdit />
                      <span>Edit</span>
                    </button>
                  </Link>
                </div>

                <div className="table-action-view">
                  <Link
                    className="hover:text-[#FFF]"
                    to={`/products/${item.id}`}
                  >
                    {" "}
                    <button className="flex justify-center items-center bg-[#407BFF] text-[#FFF] text-[12px] rounded-[5px] px-3 py-[6px]">
                      <span>View Product</span>
                    </button>
                  </Link>
                </div>
              </div>

              <div className="flex flex-col gap-3 items-start md:items-end">
                <div className="w-full flex-col md:mt-3">
                  {/* <div
                    id="barcode"
                    className="w-full"
                    style={{ backgroundColor: "#ffffff", color: "#000000" }}
                  >
                    <div
                      id={`barcode-container-${index}`}
                      ref={barcodeRef}
                      className="flex justify-center mb-[10px] w-full"
                      style={{ backgroundColor: "#ffffff", color: "#000000" }}
                    >

                      <Barcode value={item.id} options={{ width: "100%" }} />
                    </div>
                  </div> */}

                  <div
                    id="barcode"
                    className="barcode-wrapper"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    <div
                      id={`barcode-container-${index}`}
                      className="barcode-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginBottom: "10px",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      {/* Barcode Component */}
                      <div style={{ position: "relative" }}>
                        <Barcode
                          value={item.id}
                          displayValue={false} // This will hide the default barcode text
                          options={{
                            width: 2,
                            height: 100,
                          }}
                        />
                      </div>

                      {/* Custom Inline Text */}
                      <div
                        style={{
                          marginTop: "5px",
                          fontSize: "14px",
                          fontWeight: "bold",
                          textAlign: "center",
                          letterSpacing: "1px",
                          backgroundColor: "#36454F",
                          color: "#ffffff",
                          padding: "1px 2px",
                          borderRadius: "5px",
                        }}
                      >
                        {item.id} &nbsp;|&nbsp; {item?.currency?.symbol}
                        {item.retails_price}
                      </div>
                    </div>
                  </div>

                  {/* {console.log(item)} */}
                  <div className="flex justify-center gap-3">
                    <img
                      className="cursor-pointer"
                      onClick={() =>
                        handleImageClick("Download Icon", item, index)
                      }
                      src={downloadIcon}
                    />
                    <img
                      className="cursor-pointer"
                      onClick={() =>
                        handleImageClick("Print Icon", item, index)
                      }
                      src={printIcon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {shouldRenderPagination && (
        <Pagination
          currentPage={currentPage}
          lastPage={products.last_page}
          onPageChange={onPageChange}
        />
      )}

      <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
        {loader && <div className="custom-loader"></div>}
      </div>

      {showModal ? (
        <div>
          <input
            type="checkbox"
            id="variation_list_view_modal"
            className="modal-toggle"
          />
          <div className="modal">
            <div className="modal-box min-w-[800px] p-0 bg-white">
              <div className="flex justify-between flex-col w-full md:flex-row px-[18px] py-[18px] border-b border-[#cccbcb]">
                <h4 className="font-nunito font-semibold text-lg ">
                  Product SL/IMEI
                </h4>
                <div className="modal-action">
                  <label
                    htmlFor="variation_list_view_modal"
                    className="cursor-pointer h-[22px] w-[22px] justify-between items-center flex rounded-lg bg-red-500"
                    onClick={() => setShowModal((e) => !e)}
                  >
                    <GrFormClose className="justify-between items-center flex text-white" />
                  </label>
                </div>
              </div>
              <div className="px-[16px] pt-3 pb-[22px]">
                <table className=" w-full  text-sm text-left rounded-lg font-nunito">
                  <thead className="border-b-[1px] border-solid border-[#d9d9da]">
                    <tr>
                      <td className="p-2 font-semibold text-center whitespace-nowrap">
                        SL NO
                      </td>
                      <td className="p-2 font-semibold text-center">Sl/IMEI</td>
                      <td className="p-2 font-semibold text-center">
                        Optional Name
                      </td>
                      <td className="p-2 font-semibold text-center">
                        Purchase Price
                      </td>
                      <td className="p-2 font-semibold text-center">
                        Sale Price
                      </td>
                      <td className="p-2 font-semibold text-center">
                        WholeSale Price
                      </td>
                      <td className="p-2 font-semibold text-center">
                        Bar Code
                      </td>
                      <td className="p-3 font-semibold text-center"></td>
                    </tr>
                  </thead>
                  <tbody className="font-thin">
                    {productImeis?.imeis?.map((items, index) => (
                      <tr className="border-t border-[#005DAE1C] rounded-xl">
                        <td className="py-3  text-center">
                          <h4 className="font-nunito font-medium text-18px text-[#000232] ">
                            {index + 1}{" "}
                          </h4>
                        </td>
                        <td className="py-3 text-center">
                          {editingIndex === index ? (
                            <input
                              type="text"
                              value={editedImei}
                              onChange={handleInputChange}
                              className="border rounded px-2 py-1"
                            />
                          ) : (
                            <h4 className="font-nunito me-2 font-medium text-18px text-[#000232]">
                              {items?.imei}
                            </h4>
                          )}
                        </td>
                        <td className="py-3 text-center">
                          <h4 className="font-nunito me-2 font-medium text-18px text-[#000232]">
                            {items?.optional_name}{" "}
                          </h4>
                        </td>
                        <td className="py-3 text-center">
                          <h4 className="font-nunito me-2 font-medium text-18px text-[#000232]">
                            {items?.currency_rate !== null
                              ? Number.parseFloat(
                                  items?.purchase_price * items?.currency_rate +
                                    items?.carrier_charge
                                ).toFixed(2) +
                                " " +
                                items?.base_currency_code
                              : Number.parseFloat(
                                  items?.purchase_price + items?.carrier_charge
                                ).toFixed(2) +
                                " " +
                                items?.purchase_currency_code}
                          </h4>
                        </td>
                        <td className="py-3 text-center">
                          <h4 className="font-nunito me-2 font-medium text-18px text-[#000232]">
                            {items?.currency_rate !== null
                              ? Number.parseFloat(items?.sale_price).toFixed(
                                  2
                                ) +
                                " " +
                                items?.base_currency_code
                              : Number.parseFloat(items?.sale_price).toFixed(
                                  2
                                ) +
                                " " +
                                items?.purchase_currency_code}
                          </h4>
                        </td>
                        <td className="py-3 text-center">
                          <h4 className="font-nunito me-2 font-medium text-18px text-[#000232]">
                            {productImeis?.wholesale_price
                              ? productImeis.wholesale_price
                              : 0}{" "}
                            {productImeis?.purchase_currency}
                            {invoiceSetting?.currency_info?.code
                              ? invoiceSetting?.currency_info?.code
                              : "BDT"}
                          </h4>
                        </td>
                        <td>
                          <div
                            id="barcode"
                            className="w-full"
                            style={{
                              backgroundColor: "#ffffff",
                              color: "#000000",
                            }}
                          >
                            <div
                              id={`barcode-container-${index}`}
                              ref={barcodeRef}
                              className="flex justify-center mb-[10px] w-28"
                              style={{
                                backgroundColor: "#ffffff",
                                color: "#000000",
                              }}
                            >
                              <Barcode
                                value={items?.imei}
                                options={{ width: "100%" }}
                              />
                            </div>
                          </div>

                          {/* <div
                            id="barcode"
                            className="barcode-wrapper"
                            style={{
                              backgroundColor: "#ffffff",
                              color: "#000000",
                              width: "100%",
                            }}
                          >
                            <div
                              id={`barcode-container-${index}`}
                              className="barcode-container"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginBottom: "10px",
                                width: "100%",
                                position: "relative",
                              }}
                            >

                              <div style={{ position: "relative" }}>
                                <Barcode
                                  value={items?.imei || ""}
                                  displayValue={false}
                                  options={{
                                    width: 2,
                                    height: 100,
                                  }}
                                />
                              </div>


                              <div
                                style={{
                                  marginTop: "5px",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  letterSpacing: "1px",
                                  backgroundColor: "#36454F",
                                  color: "#ffffff",
                                  padding: "1px 2px",
                                  borderRadius: "5px",
                                }}
                              >
                                {items?.imei || "N/A"} &nbsp;|&nbsp;{" "}
                                {items?.battery_life || "N/A"} &nbsp;|&nbsp;{" "}
                                {items?.storage || "N/A"}
                              </div>
                            </div>
                          </div> */}
                        </td>

                        <td className="my-auto ">
                          <div className="flex flex-col justify-center gap-2 items-center">
                            <td className="my-auto">
                              <div className="flex flex-col justify-center gap-2 items-center">
                                {editingIndex === index ? (
                                  <button
                                    onClick={() => handleSubmit(items?.id)} // Pass the item's ID for the update API
                                    className="text-blue-500"
                                  >
                                    Submit
                                  </button>
                                ) : (
                                  <i
                                    className="fa-regular fa-pen-to-square cursor-pointer"
                                    onClick={() =>
                                      handleEditClick(index, items?.imei)
                                    }
                                  ></i>
                                )}
                              </div>
                            </td>

                            <img
                              className="cursor-pointer pl-3 h-5"
                              onClick={() =>
                                handleImageClick("Each Product", items, "imei")
                              }
                              src={downloadIcon}
                            />
                          </div>
                        </td>

                        <td
                          style={{
                            position: "absolute",
                            opacity: 0,
                            pointerEvents: "none",
                            top: "-9999px",
                            left: "-9999px",
                          }}
                        >
                          <div
                            id="barcode"
                            className="w-full"
                            style={{
                              backgroundColor: "#ffffff",
                              color: "#000000",
                            }}
                          >
                            <div
                              id={`barcode-container-imei`}
                              className="flex justify-center mb-[10px] w-full"
                              ref={barcodeRef}
                              style={{
                                backgroundColor: "#ffffff",
                                color: "#000000",
                              }}
                            >
                              {/* <img src={barcodeIcon} /> */}
                              <Barcode
                                value={items?.imei}
                                options={{ width: "100%" }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ProductsList;
