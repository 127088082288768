import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  page: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    position: "relative",
  },
  header: {
    // marginBottom: 10,
    marginBottom: 0, // Remove any extra space after header
    textAlign: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 4,
  },
  shopInfo: {
    fontSize: 10,
    marginBottom: 10,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    marginVertical: 1,
    width: "100%",
  },
  thickDivider: {
    borderBottomWidth: 2,
    borderBottomColor: "#000",
    marginVertical: 2,
    width: "100%",
  },
  reportTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginTop: 2,
    marginBottom: 4,
  },
  table: {
    display: "table",
    width: "100%",
    // marginVertical: 10,
    marginTop: 2, // Ensure table starts immediately after header
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    paddingVertical: 5,
    paddingHorizontal: 3,
    color: "#000232",
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
    borderWidth: 0.5,
    borderColor: "#000",
    backgroundColor: "#E7EBF4",
  },
  tableColSl: {
    flex: 0.2, // Reduced width for SL column
    paddingVertical: 5,
    paddingHorizontal: 3,
    textAlign: "center",
    fontSize: 10,
  },
  tableColDate: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 3,
    textAlign: "center",
    fontSize: 10,
  },
  tableColName: {
    flex: 2.5, // Larger width for Product Name
    padding: 5,
    textAlign: "left", // Left-aligned text
    fontSize: 10,
  },
  tableColInvoice: {
    flex: 0.6, // Smaller width for Invoice column
    padding: 5,
    textAlign: "left",
    fontSize: 10,
  },
  tableColImei: {
    flex: 1.2, // Smaller width for Customer Name
    padding: 5,
    textAlign: "left", // Left-aligned text
    fontSize: 10,
  },
  tableColCustomer: {
    flex: 1.2, // Reduced width for sales amount
    padding: 5,
    textAlign: "left",
    fontSize: 10,
  },
  tableColSalesAmount: {
    flex: 0.8, // Reduced width for sales amount
    padding: 5,
    textAlign: "right",
    fontSize: 10,
  },
  tableRowAlternate: {
    backgroundColor: "#F5F5F5",
  },
  grandTotalRow: {
    backgroundColor: "#E7EBF4",
    fontWeight: "bold",
  },
  footer: {
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: 10,
  },
});

// PDF document component
const MonthlySalesDayCountPDFDocument = ({
  reports,
  user,
  startDate,
  endDate,
  dayCount,
  grandTotal,
}) => (
  <Document>
    <Page style={styles.page} size="A4" orientation="landscape">
      {/* Header */}
      <View style={styles.header}>
        {/* <Text style={styles.title}>
          {user?.invoice_settings?.shop_name || "N/A"}
        </Text>
        <Text style={styles.shopInfo}>{user?.address}</Text> */}
        {/* <Text style={styles.shopInfo}>Mobile: {user?.phone || "N/A"}</Text> */}


        <Text style={styles.reportTitle}>
          Monthly Sales Day Counting Report
        </Text>
        <Text style={styles.shopInfo}>
          ({startDate} to {endDate})
        </Text>

        {/* Horizontal Lines */}
        {/* <View style={styles.thickDivider} /> */}
        {/* <View style={styles.divider} />
        <View style={styles.thickDivider} /> */}


      </View>

      {/* Table */}
      <View style={styles.table}>
        {/* Table Header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableColHeader, styles.tableColSl]}>Sl.</Text>
          <Text style={[styles.tableColHeader, styles.tableColDate]}>Date</Text>
          <Text style={[styles.tableColHeader, styles.tableColInvoice]}>Invoice No.</Text>
          <Text style={[styles.tableColHeader, styles.tableColCustomer]}>Customer Name</Text>
          <Text style={[styles.tableColHeader, styles.tableColName]}>Product Name</Text>
          <Text style={[styles.tableColHeader, styles.tableColImei]}>IMEI/Serial</Text>
          <Text style={[styles.tableColHeader, styles.tableColSalesAmount]}>Amount (BDT)</Text>
        </View>

        {/* Table Body */}
        {reports.map((report, index) => (
          <View
            key={index}
            style={[
              styles.tableRow,
              index % 2 === 0 && styles.tableRowAlternate,
            ]}
          >
            <Text style={styles.tableColSl}>{index + 1}</Text>
            <Text style={styles.tableColDate}>{report?.date || "N/A"}</Text>
            <Text style={styles.tableColInvoice}>
              {report?.invoice_id?.split("-").pop() || "N/A"}
            </Text>
            <Text style={styles.tableColCustomer}>
              {report?.customer_name || "N/A"}
            </Text>
            <Text style={styles.tableColName}>{report?.product_name}</Text>
            <Text style={styles.tableColImei}>{report?.imei || "N/A"}</Text>
            <Text style={styles.tableColSalesAmount}>{report?.price || 0}</Text>
          </View>
        ))}

        {/* Grand Total Row */}
        <View style={[styles.tableRow, styles.grandTotalRow]}>
          <Text style={styles.tableColSl}></Text>
          <Text style={styles.tableColDate}>{dayCount || 0} Day(s)</Text>
          <Text style={styles.tableColName}>Grand Total</Text>
          <Text style={styles.tableColInvoice}></Text>
          <Text style={styles.tableColCustomer}></Text>
          <Text style={styles.tableColSalesAmount}>{grandTotal || 0}</Text>
        </View>
      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <Text>Prepared by</Text>
        <Text>Checked/Recommended by</Text>
        <Text>Authorized by</Text>
      </View>
    </Page>
  </Document>
);

export default MonthlySalesDayCountPDFDocument;
