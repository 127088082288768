import React, { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { timeDiff,formatPrice } from '../../utils/Common.js';
import Pagination from '../../utils/pagination.js'; // Adjust the path accordingly
import { AppContext } from '../../context/context.js';
import DatePicker from "react-datepicker";
import ContentLayout from '../../layout/ContentLayout/index.jsx';
import { get, post } from '../../services/apiService.js';
import { useNavigate } from 'react-router-dom';
import CustomerListItem from '../../components/Invoice/CustomerListItem.js';
import { useFlashMessage } from '../../context/FlashMessageContext.js';
import { BiPlus } from "react-icons/bi";
import { Modal } from "../../utils/Modal.js";
import ExpenseItem from "../../components/Expense/ExpenseItem.js";
import Swal from "sweetalert2";
import axios from "axios";
import { useLocation } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_ROOT_URL;

const QuickPaymentPage = () => {
  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage(); // Retrieve the dispatch function from your state management library

  const handleOpen = () => setShowModal(!showModal);
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  //-----------------
  const navigate = useNavigate();
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  const [isViewActive, setIsViewActive] = useState(false);
  const [isCreateActive, setIsCreateActive] = useState(false);

  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");
    if (!employeeApiResponse) {
      setHasPermission(true);
      setIsCreateActive(true);
      return;
    }
      const employeeData = JSON.parse(employeeApiResponse);
      const expenseFeature = employeeData.role.features.find(
        (feature) => feature.name === "Expense" && feature.status === 1
      );
      if (expenseFeature && location.pathname === "/expenselist") {
        const viewExpenseOptionActive = expenseFeature.feature_options.find(
          (option) => option.name === "View" && option.status === 1
        );
        const createExpenseOptionActive = expenseFeature.feature_options.find(
          (option) => option.name === "Create" && option.status === 1
        );
        if (viewExpenseOptionActive) {
          setIsViewActive(true);
          setHasPermission(true);
        } else {
          setIsViewActive(false);
          setHasPermission(false);
        }
        setIsCreateActive(!!createExpenseOptionActive);
      } else {
        setHasPermission(false);
        setIsCreateActive(false);
        navigate("/dashboard", { replace: true });
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Permission Denied",
            text: "You do not have permission for this feature.",
          });
        }, 100);
      }
    }
  , []);
  //-----------------

  const [expenseList, setExpenseList] = useState([]);
  const [refetch, setRefetch] = useState(false)
  const token = localStorage.getItem("token");
  //const navigate = useNavigate();
  useEffect(()=>{
    if ((isViewActive && hasPermission) || !localStorage.getItem("employee")) {
      getExpenseList()
    }
  },[refetch,isViewActive,hasPermission])

  const getExpenseList = (page, searchKeyword) => {
    loadingCallback(true);
    get(`get-payment-expense`, token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          //console.log(response.data)
          setExpenseList(response.data.data)
        }
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        localStorage.clear();
        localStorage.setItem("token", false);
        // Clear session and local storage
        localStorage.setItem("isPinVerify", false);

        navigate("/login");
      });
  };

  const [showNewExpenseModal, setShowNewExpenseModal] = useState(false);
  const [formData, setFormData] = useState({

  });
  const [warningMessage, setWarningMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showModalBeforeDelete = (id, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id, index);
      }
    });
  };

  const deleteData = (id, index) => {
    loadingCallback(true);
    const reqData = { id: id };
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}delete-expense`,
      data: reqData,
      headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
  })
  .then((response) => {
      loadingCallback(false);
      if (response.data.success) {
          console.log("Expense deleted successfully.");
          setSuccessFlashMessage("Expense deleted successfully.");

          getExpenseList(currentPage);
      } else {
          setErrorFlashMessage("Expense delete failed.");
          console.log("Expense delete failed.");
      }
  })
  .catch((error) => {
      loadingCallback(false);
      if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);
          navigate("/login");
      }
      console.log(error);
  });
  };

  const handleCreateClick = () => {
    if (isCreateActive) {
      // Show modal if creation is allowed
      setShowNewExpenseModal((val) => !val);
    } else {
      // Show warning if creation is not allowed
      Swal.fire({
        icon: "warning",
        title: "Permission Denied",
        text: "You do not have permission for this action.",
      });
    }
  };

  return(
    <>
      <ContentLayout>
      <div className="main-content recentTableContent">
          <div className="flex flex-col w-full">
            <div className='flex flex-col md:flex-row justify-between mb-4'>
              <div>
                <h1 className='text-[#000232] text-[22px] font-[600] font-lato mb-2'>Quick Payment List</h1>
              </div>
              <div className='flex gap-2 justify-center'>
                <div className="flex relative  w-[95%]">
                  <input
                    autoFocus
                    type="text"
                    className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[28px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                    placeholder="Search Category"
                    // onChange={(e) => {
                    //   setSearchFilter({...searchFilter, keyword: e.target.value})
                    // }}
                  />

                  <div className="absolute top-[37%] left-[10px]">
                    <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                  </div>
                </div>
                <label
                  onClick={handleCreateClick}
                  //onClick={() => setShowNewExpenseModal((val) => !val)}
                  htmlFor="add_expense_modal"
                  className={`cursor-pointer bg-[#407BFF] rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 pl-[22px] pr-[24px] flex items-center gap-2 ${
                    !isCreateActive && "opacity-50 cursor-not-allowed"
                  }`} // Apply disabled styles
                  style={{ pointerEvents: isCreateActive ? "auto" : "none" }}
                >
                  <BiPlus className="text-xl"></BiPlus>
                </label>
              </div>
            </div>
            {flashMessage && (
            <div
              className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
                flashMessage.type
              )}`}
            >
              {flashMessage.text}
            </div>
          )}
          {loading && (
            <div className="custom-loader-logo">
              {/* <BeatLoader color={"#123abc"} size={20} /> */}
              <img
                className=""
                src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
                alt="logo"
              />
            </div>
          )}
          {(isViewActive && hasPermission) || !localStorage.getItem("employee") ? (
          <>
          <div className="grid grid-cols-5  gap-2 md:gap-5 text-center font-nunito font-bold text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
            {/* <div className="py-[8px]">ID</div> */}
            <div className="text-left py-[8px] hidden sm:block">
              Name
            </div>
            <div className="py-[8px] text-center hidden sm:block">
              Date
            </div>
            <div className="py-[8px] text-center hidden sm:block">
              Amount
            </div>
            <div className="py-[8px] text-center">
              Description
            </div>
            <div className="py-[8px] text-right">Action</div>
          </div>
          {/* {(isViewActive && hasPermission) || !localStorage.getItem("employee") ? ( */}
            <div className="max-h-[70vh] overflow-y-auto">
              {expenseList
                    ? expenseList.map((item, index) => (
                      <ExpenseItem
                        key={item.id}
                        index={index}
                        loadingCallback={loadingCallback}
                        navigate={navigate}
                        expense={item}
                        loading={loading}
                        showModalBeforeDelete = {showModalBeforeDelete}
                        //setShowNewExpenseModal={setShowNewExpenseModal}
                      />
                      ))
                    : null}


                <div className="hrtable"></div>
            </div>
            </>
          ) : null}

          </div>

      </div>
      {showNewExpenseModal ? (
          <Modal
          loadingCallback={loadingCallback}
          navigate={navigate}
          isOpen={showNewExpenseModal}
          setOpen={setShowNewExpenseModal}
          title="Create Quick Payment"
          formId="add_quick_payment_modal"
          // formId="edit_expense_modal"
          type="addQuickPaymentModal"
          selectType="expense"
          refetch={()=>setRefetch(val=>!val)}
          formData={formData}
          setFormData={setFormData}
          setWarningMessage={setWarningMessage}
          />
        ) : (
          ""
        )}
      </ContentLayout>
    </>
  );
};

export default QuickPaymentPage;