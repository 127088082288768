import React from 'react';
import Login from "./components/registration/login/Index";
import VerifyPin from "./components/registration/login/VerifyPin/Index";
import SignUp from "./components/registration/signup/Index";
import ResetPassword from "./components/reset-password/Index";
import SetNewPassword from "./components/reset-password/SetNewPassword";
import ConfirmPin from "./components/set-pin/ConfirmPin";
import SetPin from "./components/set-pin/SetPin";
import VerifyOtp from "./components/verify-otp/Index";
import Home from "./pages/home/Index";
import Brands from "./components/settings/Settings_Pages/Brands";
import Category from "./components/settings/Settings_Pages/Category";
import Discounts from "./components/settings/Settings_Pages/Discounts";
import Help from "./components/settings/Settings_Pages/Help";
import InvoiceSettings from "./components/settings/Settings_Pages/InvoiceSettings";
import InvoiceList from "./components/settings/Settings_Pages/InvoiceList";
import Send_feedback from "./components/settings/Settings_Pages/Send_feedback";
import SubcategoryPage from "./components/settings/Settings_Pages/SubcategoryPage";
import Units from "./components/settings/Settings_Pages/Units";
import AdvanceBookingPage from "./pages/AdvanceBookingPage/AdvanceBookingPage";
import BarcodeListPage from "./pages/BarcodeListPage/BarcodeListPage";
import OrderProductPage from "./pages/OrderProductPage/OrderProductPage";
import { default as ProductDetailPage } from "./pages/ProductDetailPage/ProductDetailPage";
import PurchaseBillingPage from "./pages/PurchaseBillingPage/PurchaseBillingPage";
import WholeSaleBillingPage from "./pages/WholeSaleBillingPage/WholeSaleBillingPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import { default as AddProductsPage, default as EditProductPage } from "./pages/addProducts/Index";
import ProductsPage from "./pages/products/Index";

import { default as RecentlyAddProductsPage, default as RecentlyEditProductPage } from "./pages/RecentlyAddProductsPage/RecentlyAddProductsPage";
import { default as RecentlyProductDetailPage } from "./pages/RecentlyProductDetailPage/RecentlyProductDetailPage";

import InvoiceDetailPage from "./pages/InvoiceDetailPage/InvoiceDetailPage";
import WholeSaleInvoiceDetailPage from "./pages/WholeSaleInvoiceDetailPage/WholeSaleInvoiceDetailPage";
import InvoiceDetailPagePrint from "./pages/InvoiceDetailPage/InvoiceDetailPagePrint";
import InvoiceEditPage from "./pages/InvoiceEditPage/InvoiceEditPage";
import WholesaleInvoiceEditPage from "./pages/WholesaleInvoiceEditPage/WholesaleInvoiceEditPage";
import InvoiceListPage from "./pages/InvoiceListPage/InvoiceListPage";
import MostSellingProductPage from "./pages/MostSellingProductPage/MostSellingProductPage";
import RecentlyAddedProductsPage from "./pages/RecentlyAddedProductsPage/RecentlyAddedProductsPage";

import PurchaseInvoiceDetailPage from "./pages/PurchaseInvoiceDetailPage/PurchaseInvoiceDetailPage";
import PurchaseInvoiceListPage from "./pages/PurchaseInvoiceListPage/PurchaseInvoiceListPage";

import ShopSettings from './components/settings/Settings_Pages/ShopSettings';
import AdvanceBookingDetailPage from "./pages/AdvanceBookingDetailPage/AdvanceBookingDetailPage";
import AdvanceBookingEditPage from "./pages/AdvanceBookingEditPage/AdvanceBookingEditPage";
import AdvanceBookingListPage from "./pages/AdvanceBookingListPage/AdvanceBookingListPage";
import WholesaleListPage from "./pages/WholesaleListPage/WholesaleListPage";

import PurchaseReturnListPage from "./pages/PurchaseReturnListPage/PurchaseReturnListPage";
import SalesReturnListPage from "./pages/SalesReturnListPage/SalesReturnListPage";
import SalesReturnItem from './components/Invoice/SalesReturnItem';
import SalesReturnDetailPage from './pages/SalesReturnDetailPage/SalesReturnDetailPage';
import PurchaseReturnDetailPage from './pages/PurchaseReturnDetailPage/PurchaseReturnDetailPage';
//import BranchSettings from './components/settings/Settings_Pages/BranchSettings';
import {
  default as BranchSettings,
  default as EditBranchSettings,
} from "./components/settings/Settings_Pages/BranchSettings";
import DefectListPage from './pages/DefectListPage/DefectListPage';
import PaymentSettings from './components/settings/Settings_Pages/PaymentSettings';
import BranchList from './components/settings/Settings_Pages/BranchList';
import HoldInvoiceListPage from './pages/HoldInvoiceListPage/HoldInvoiceListPage';
import HoldWholeSaleInvoiceListPage from './pages/HoldWholeSaleInvoiceListPage/HoldWholeSaleInvoiceListPage';
import HoldInvoiceEditPage from "./pages/HoldInvoiceEditPage/HoldInvoiceEditPage";
import DeliverySettings from './components/settings/Settings_Pages/DeliverySettings';
import CategoryHistory from './components/CategoryHistory/CategoryHistory';
import CategoryReportPage from './pages/CategoryReportPage/CategoryReportPage';
import CustomerListPage from './pages/CustomerListPage/CustomerListPage';
import VendorListPage from './pages/VendorListPage/VendorListPage';
import VendorProfilePage from './pages/VendorProfilePage/VendorProfilePage';
import VendorInvoiceHistoryPage from './pages/VendorInvoiceHistoryPage/VendorInvoiceHistoryPage';
import VendorMostPurchaseProductPage from './pages/VendorMostPurchaseProductPage/VendorMostPurchaseProductPage';

import CustomerProfilePage from './pages/CustomerProfilePage/CustomerProfilePage';
import CustomerMostPurchaseProductPage from './pages/CustomerMostPurchaseProductPage/CustomerMostPurchaseProductPage';
import CustomerInvoiceHistoryPage from './pages/CustomerInvoiceHistoryPage/CustomerInvoiceHistoryPage';
import CustomerEditPage from './pages/VendorCustomerEditPage/CustomerEditPage';
import VendorEditPage from './pages/VendorCustomerEditPage/VendorEditPage';
import WholesalerEditPage from './pages/VendorCustomerEditPage/WholesalerEditPage';
import ExporterEditPage from './pages/VendorCustomerEditPage/ExporterEditpage';
import ExporterStockPage from './components/Exporter/ExporterStock/ExporterStock';



import CategoryHistoryDetails from './pages/CategoryHistoryDetailPage/CategoryHistoryDetails';
import ProductCategoryHistory from './components/ProductCategoryHistory/ProductCategoryHistory';
import ProductCategoryHistoryDetails from './pages/ProductCategoryHistoryDetails/ProductCategoryHistoryDetails';
import WarrantySettings from './components/settings/Settings_Pages/WarrantySettings';
import TransactionHistory from './components/Transaction/TransactionHistory/TransactionHistory';
import AnalyticsPage from './pages/AnalyticsPage/AnalyticsPage';
import ProductStockReport from './components/ProductStockReport/ProductStockReport';
import ProductStockHistoryDetails from './pages/ProductStockHistoryDetails/ProductStockHistoryDetails';
import ProductStockHistory from './components/ProductStockHistory/ProductStockHistory';
import CategoryStockHistory from './components/CategoryStockHistory/CategoryStockHistory';
import ExporterListPage from './pages/ExporterListPage/ExporterListPage';

import VendorDueInvoicePage from './pages/VendorDueInvoicePage/VendorDueInvoicePage';
import CarrierDueInvoicePage from './pages/VendorDueInvoicePage/CarrierDueInvoicePage';
import ExporterDueInvoicePage from './pages/VendorDueInvoicePage/ExporterDueInvoicePage';
import WholesalerListPage from './pages/WholesalerListPage/WholesalerListPage';
import WholesalerProfilePage from './pages/WholesalerProfilePage/WholesalerProfilePage';
import WholesalerInvoiceHistoryPage from './pages/WholesalerInvoiceHistoryPage/WholesalerInvoiceHistoryPage';
import WholesalerMostPurchaseProductPage from './pages/WholesalerMostPurchaseProductPage/WholesalerMostPurchaseProductPage';
import CustomerAddPage from './pages/CustomerAddPage/CustomerAddPage';
import WholesalerAddPage from './pages/WholesalerAddPage/WholesalerAddPage';
import VendorAddPage from './pages/VendorAddPage/VendorAddPage';
import BalanceHistory from './components/Balance/BalanceHistory/BalanceHistory';

import ExporterProfilePage from './pages/ExporterProfilePage/ExporterProfilePage';
import ExporterAddPage from './pages/ExporterAddPage/ExporterAddPage';
import CarrierListPage from './pages/CarrierListPage/CarrierListPage';
import CarrierProfilePage from './pages/CarrierProfilePage/CarrierProfilePage';
import CarrierEditPage from './pages/VendorCustomerEditPage/CarrierEditPage';
import CarrierAddPage from './pages/CarrierAddPage/CarrierAddPage';
import CarrierInvoiceDetailPage from './pages/CarrierInvoiceDetailPage/CarrierInvoiceDetailPage';
import ExporterInvoiceDetailPage from './pages/ExporterInvoiceDetailPage/ExporterInvoiceDetailPage';
import CarrierInvoiceHistoryPage from './pages/CarrierInvoiceHistoryPage/CarrierInvoiceHistoryPage';
import ExporterInvoiceHistoryPage from './pages/ExporterInvoiceHistoryPage/ExporterInvoiceHistoryPage';


import ExporterPage from './pages/ExporterPage/ExporterPage';
import AddExporterPage from './pages/AddExporterPage/AddExporterPage';
import AccountingHistory from './components/Accounting/AccountingHistory/AccountingHistory';

import AccountsListPage from './pages/AccountsListPage/AccountsListPage';
import ChartOfAccountPage from './pages/ChartOfAccountPage/ChartOfAccountPage';
import ExpenseCategory from './pages/ExpenseCategory/ExpenseCategory';
import FundTransfer from './pages/FundTransfer/FundTransfer';
import ExpenseListPage from './pages/ExpenseListPage/ExpenseListPage';
import PartyNameListPage from './pages/PartyNameListPage/PartyNameListPage';
import ExporterProductListPage from './pages/ExporterProductListPage/ExporterProductListPage';
import ExporterProductDetailPage from './components/ExporterProduct/ExporterProductDetailPage/ExporterProductDetailPage';

import ExpenseCategoryPage from './pages/Expense/ExpenseCategoryPage';
import ExpensePage from './pages/Expense/ExpensePage';

import EmployeePage from './pages/HRM/EmployeePage';
import EmployeeAddPage from './pages/HRM/EmployeeAddPage';
import EmployeeViewPage from './pages/HRM/EmployeeViewPage';
import EmployeeEditPage from './pages/HRM/EmployeeEditPage';
import DesignationPage from './pages/HRM/DesignationPage';
import DepartmentPage from './pages/HRM/DepartmentPage';
import NewSalesBillingPage from './pages/NewSalesBillingPage/NewSalesBillingPage';
import Playground from './Playground';
import SettingsPageV2 from './pages/SettingsPage/SettingsPageV2';
import ShopSettingsV2 from './components/settings-v2/SettingsV2_Pages/ShopSettingsV2';
import CategoryV2 from './components/settings-v2/SettingsV2_Pages/CategoryV2';
import SubcategoryPageV2 from './components/settings-v2/SettingsV2_Pages/SubcategoryPageV2';
import BrandsV2 from './components/settings-v2/SettingsV2_Pages/BrandsV2';
import UnitsV2 from './components/settings-v2/SettingsV2_Pages/Units';
import PaymentSettingsV2 from './components/settings-v2/SettingsV2_Pages/PaymentSettingsV2';
import DeliverySettingsV2 from './components/settings-v2/SettingsV2_Pages/DeliverySettingsV2';
import WarrantySettingsV2 from './components/settings-v2/SettingsV2_Pages/WarrantySettingsV2';
import HelpV2 from './components/settings-v2/SettingsV2_Pages/HelpV2';
import InvoiceSettingsV2 from './components/settings-v2/SettingsV2_Pages/InvoiceSettingsV2';
import FinanceSettingsV2 from './components/settings-v2/SettingsV2_Pages/FinanceSettingsV2';
import ChangePassword from './components/settings-v2/SettingsV2_Pages/ChangePassword';
import ChangePin from './components/settings-v2/SettingsV2_Pages/ChangePin';
import CreateWareHouse from './pages/WareHouse/CreateWareHouse';
import WareHouseList from './pages/WareHouse/WareHouseList';
import DueReportExpand from './components/DueReport/DueReportExpand';
import CustomerSummaryPage from './components/CustomerSummary/CustomerSummaryPage';
import CustomerWiseDueReportPage from './components/CustomerWiseDue/CustomerWiseDueReportPage';
import EmployeeWiseSalesPage from './components/EmployeeWiseSales/EmployeeWiseSalesPage';
import MonthWiseReportPage from './components/MonthWiseReport/MonthWiseReportPage';
import RolePage from './pages/HRM/RolePage';
import RoleAddPage from './pages/HRM/RoleAddPage';
import RoleViewPage from './pages/HRM/RoleViewPage';
import RoleEditPage from './pages/HRM/RoleEditPage';
import EmployeeProfilePage from './pages/HRM/EmployeeProfilePage';
import TermsAndCondition from './components/settings-v2/SettingsV2_Pages/TermsAndCondition';
import LedgerReportExpand from './components/LedgerReport/LedgerReportExpand';
import BalanceSheetReportExpand from './components/BalanceSheetReport/BalanceSheetReportExpand';
import SalesRegisterReportExpand from './components/SalesRegisterReport/SalesRegisterReportExpand';
import MonthlySalesDayCountingExpand from './components/MonthlySalesDayCountingReport/MonthlySalesDayCountingExpand';
import MinimumStockReportExpand from './components/MinimumStockReport/MinimumStockReportExpand';
import ProfitAndLossAccountReportExpand from './components/ProfitAndLossAccountReport/ProfitAndLossAccountReportExpand';

import CashBookExpands from './components/CashbookDetails/CashBookExpands';
import { element } from 'prop-types';
import StoreLedgerExpand from './components/StoreLedger/StoreLedgerExpand';
import TransactionSummaryExpand from './components/TransactionSummary/TransactionSummaryExpand';
import CreateBanner from './pages/Ecommerce/CreateBanner';
import CreateSlider from './pages/Ecommerce/CreateSlider';

import ImeiReportPage from './components/ImeiReport/ImeiReportPage';
import MonthlyPurchaseReportExpand from './components/MonthlyPurchaseReport/MonthlyPurchaseReportExpand';
import CashbookSummaryReportExpand from './components/CashbookSummaryReport/CashbookSummaryReportExpand';
import QuickPaymentPage from './pages/QuickPayment/QuickPaymentPage';
import QuickPaymentCategoryPage from './pages/QuickPayment/QuickPaymentCategoryPage';


const routeConfiguration = () => {
  const SettingChild = [
    {
      path: "",
      name: "InvoiceList",
      element: <InvoiceList />,
    },
    {
      path: "/settings/invoice",
      name: "InvoiceList",
      element: <InvoiceList />,
    },
    {
      path: "/settings/category",
      name: "Category",
      element: <Category />,
    },
    {
      path: "/settings/subcategory",
      name: "SubcategoryPage",
      element: <SubcategoryPage />,
    },
    {
      path: "/settings/brands",
      name: "Brands",
      element: <Brands />,
    },
    {
      path: "/settings/units",
      name: "Units",
      element: <Units />,
    },
    {
      path: "/settings/discounts",
      name: "Discounts",
      element: <Discounts />,
    },
    {
      path: "/settings/shop-settings",
      name: "ShopSettings",
      element: <ShopSettings />,
    },
    {
      path: "/settings/payment",
      name: "PaymentSettings",
      element: <PaymentSettings />,
    },
    {
      path: "/settings/branch-settings",
      name: "BranchSettings",
      element: <BranchSettings />,
    },
    {
      path: "/settings/branch-settings/:id",
      name: "EditBranchSettings",
      element: <EditBranchSettings />,
    },
    {
      path: "/settings/branch-list",
      name: "BranchList",
      element: <BranchList />,
    },
    {
      path: "/settings/invoice-settings",
      name: "InvoiceSettings",
      element: <InvoiceSettings />,
    },
    {
      path: "/settings/send-feedback",
      name: "Send_feedback",
      element: <Send_feedback />,
    },
    {
      path: "/settings/help",
      name: "Help",
      element: <Help />,
    },
    {
      path: "/settings/delivery",
      name: "Help",
      element: <DeliverySettings />,
    },
    {
      path: "/settings/warranty",
      name: "Help",
      element: <WarrantySettings />,
    },
  ];
  const SettingChildV2 = [
    {
      path: "",
      name: "ShopSettings",
      element: <ShopSettingsV2 />
    },
    {
      path: "/settings-v2/shop-settings",
      name: "ShopSettings",
      element: <ShopSettingsV2 />
    },
    {
      path: "/settings-v2/invoice",
      name: "InvoiceList",
      element: <InvoiceList />,
    },
    {
      path: "/settings-v2/category",
      name: "Category",
      element: <CategoryV2 />,
    },
    {
      path: "/settings-v2/subcategory",
      name: "SubcategoryPage",
      element: <SubcategoryPageV2 />,
    },
    {
      path: "/settings-v2/brands",
      name: "Brands",
      element: <BrandsV2 />,
    },
    {
      path: "/settings-v2/units",
      name: "Units",
      element: <UnitsV2 />,
    },
    {
      path: "/settings-v2/discounts",
      name: "Discounts",
      element: <Discounts />,
    },
    {
      path: "/settings-v2/shop-settings",
      name: "ShopSettings",
      element: <ShopSettingsV2 />,
    },
    {
      path: "/settings-v2/payment",
      name: "PaymentSettings",
      element: <PaymentSettingsV2 />,
    },
    {
      path: "/settings-v2/finance",
      name: "FinanceSettings",
      element: <FinanceSettingsV2 />,
    },
    {
      path: "/settings-v2/branch-settings",
      name: "BranchSettings",
      element: <BranchSettings />,
    },
    {
      path: "/settings-v2/branch-settings/:id",
      name: "EditBranchSettings",
      element: <EditBranchSettings />,
    },
    {
      path: "/settings-v2/branch-list",
      name: "BranchList",
      element: <BranchList />,
    },
    {
      path: "/settings-v2/invoice-settings",
      name: "InvoiceSettings",
      element: <InvoiceSettingsV2 />,
    },
    {
      path: "/settings-v2/send-feedback",
      name: "Send_feedback",
      element: <Send_feedback />,
    },
    {
      path: "/settings-v2/help",
      name: "Help",
      element: <HelpV2 />,
    },
    {
      path: "/settings-v2/terms-and-condition",
      name: "Terms and condition",
      element: <TermsAndCondition />,
    },
    {
      path: "/settings-v2/delivery",
      name: "Help",
      element: <DeliverySettingsV2 />,
    },
    {
      path: "/settings-v2/warranty",
      name: "Help",
      element: <WarrantySettingsV2 />,
    },
    {
      path: "/settings-v2/change-password",
      name: "ChangePassword",
      element: <ChangePassword />,
    },
    {
      path: "/settings-v2/change-pin",
      name: "ChangePin",
      element: <ChangePin />,
    },
  ];
  return [
    {
      path: '/login',
      name: 'Login',
      element: <Login />,
    },
    {
      path: "/playground",
      element: <Playground />
    },
    {
      path: '/dashboard',
      name: 'Home',
      element: <Home />,
    },
    {
      path: '/set-pin',
      name: 'SetPin',
      element: <SetPin />,
    },
    {
      path: '/confirm-pin',
      name: 'ConfirmPin',
      element: <ConfirmPin />,
    },
    {
      path: '/verifypin',
      name: 'VerifyPin',
      element: <VerifyPin />,
    },
    {
      path: '/forget-password',
      name: 'ResetPassword',
      element: <ResetPassword />,
    },
    {
      path: '/set-new-password',
      name: 'SetNewPassword',
      element: <SetNewPassword />,
    },
    {
      path: '/verify-otp',
      name: 'VerifyOtp',
      element: <VerifyOtp />,
    },
    {
      path: '/signup',
      name: 'SignUp',
      element: <SignUp />,
    },
    {
      path: '/products',
      name: 'ProductsPage',
      element: <ProductsPage />,
    },
    {
      path: '/addproducts',
      name: 'AddProductsPage',
      element: <AddProductsPage />,
    },
    {
      path: '/editproduct/:id',
      name: 'EditProductPage',
      element: <EditProductPage />,
    },
    {
      path: '/products/:id',
      name: 'ProductDetailPage',
      element: <ProductDetailPage />,
    },
    {
      path: '/barcodes',
      name: 'BarcodeListPage',
      element: <BarcodeListPage />,
    },
    {
      path: '/billing',
      name: 'OrderProduct',
      element: <OrderProductPage />,
    },
    {
      path: '/new-sales-billing',
      name: 'OrderProduct',
      element: <NewSalesBillingPage />,
    },
    {
      path: '/purchase-billing',
      name: 'PurchaseBillingPage',
      element: <PurchaseBillingPage />,
    },
    {
      path: '/whole-sale-billing',
      name: 'WholeSaleBillingPage',
      element: <WholeSaleBillingPage />,
    },
    {
      path: '/settings',
      name: 'SettingsPage',
      childGroup: SettingChild,
      element: <SettingsPage />,
    },
    {
      path: '/settings-v2',
      name: 'SettingsPageV2',
      childGroup: SettingChildV2,
      element: <SettingsPageV2 />,
    },
    {
      path: '/most-selling-product',
      name: 'MostSellingProductPage',
      element: <MostSellingProductPage />,
    },
    {
      path: '/invoice',
      name: 'InvoiceListPage',
      element: <InvoiceListPage />,
    },
    {
      path: '/invoice/:id',
      name: 'InvoiceDetailPage',
      element: <InvoiceDetailPage />,
    },
    {
      path: '/wholesale-invoice/:id',
      name: 'WholeSaleInvoiceDetailPage',
      element: <WholeSaleInvoiceDetailPage />,
    },
    {
      path: '/print-invoice/:id',
      name: 'InvoiceDetailPagePrint',
      element: <InvoiceDetailPagePrint />,
    },
    {
      path: '/invoice/edit/:id',
      name: 'InvoiceEditPage',
      element: <InvoiceEditPage />,
    },
    {
      path: '/wholesale-invoice/edit/:id',
      name: 'WholesaleInvoiceEditPage',
      element: <WholesaleInvoiceEditPage />,
    },
    {
      path: '/purchase-invoice',
      name: 'PurchaseInvoiceListPage',
      element: <PurchaseInvoiceListPage />,
    },
    {
      path: '/purchase-invoice/:id',
      name: 'PurchaseInvoiceDetailPage',
      element: <PurchaseInvoiceDetailPage />,
    },
    {
      path: '/purchase-invoice/edit/:id',
      name: 'InvoiceEditPage',
      element: <InvoiceEditPage />,
    },
    {
      path: '/recently-added',
      name: 'RecentlyAddedProductsPage',
      element: <RecentlyAddedProductsPage />,
    },
    {
      path: '/recently-addproducts',
      name: 'RecentlyAddProductsPage',
      element: <RecentlyAddProductsPage />,
    },
    {
      path: '/recently-editproduct/:id',
      name: 'RecentlyEditProductPage',
      element: <RecentlyEditProductPage />,
    },
    {
      path: '/recently-added/:id',
      name: 'RecentlyProductDetailPage',
      element: <RecentlyProductDetailPage />,
    },
    {
      path: '/advance-booking',
      name: 'AdvanceBookingPage',
      element: <AdvanceBookingPage />,
    },
    {
      path: '/advance-bookings',
      name: 'AdvanceBookingListPage',
      element: <AdvanceBookingListPage />,
    },
    {
      path: '/wholesale-list',
      name: 'WholesaleListPage',
      element: <WholesaleListPage />,
    },
    // {
    //   path: '/wholesale/:id',
    //   name: 'WholeSaleDetailPage',
    //   element: <WholeSaleDetailPage />,
    // },
    {
      path: '/advance-bookings/:id',
      name: 'AdvanceBookingDetailPage',
      element: <AdvanceBookingDetailPage />,
    },
    {
      path: '/advance-bookings/edit/:id',
      name: 'AdvanceBookingEditPage',
      element: <AdvanceBookingEditPage />,
    },
    // {
    //   path: '/defect-list',
    //   name: 'DefectListPage',
    //   element: <DefectListPage />,
    // },
    {
      path: '/hold-invoice-list',
      name: 'HoldInvoiceListPage',
      element: <HoldInvoiceListPage />,
    },
    {
      path: '/hold-wholesale-invoice-list',
      name: 'HoldWholeSaleInvoiceListPage',
      element: <HoldWholeSaleInvoiceListPage />,
    },
    // {
    //   path: '/invoice/edit/:id',
    //   name: 'HoldInvoiceEditPage',
    //   element: <HoldInvoiceEditPage />,
    // },
    {
      path: '/analytics',
      name: 'AnalyticsPage',
      element: <AnalyticsPage/>,
    },

    // {
    //   path: '/analytics',
    //   name: 'TransactionHistoryPage',
    //   element: <CategoryReportPage/>,
    // },

    {
      path:'customer-list',
      name:'CustomerListPage',
      element: <CustomerListPage/>,
    },
    {
      path:'vendor-list',
      name:'VendorListPage',
      element: <VendorListPage/>,
    },
    {
      path: '/sales-return/:id',
      name: 'SalesReturnDetailPage',
      element: <SalesReturnDetailPage />,
    },
    {
      path: '/sales-return',
      name: 'SalesReturnListPage',
      element: <SalesReturnListPage />,
    },
    {
      path: '/purchase-return',
      name: 'PurchaseReturnListPage',
      element: <PurchaseReturnListPage />,
    },
    {
      path: '/purchase-return/:id',
      name: 'PurchaseReturnDetailPage',
      element: <PurchaseReturnDetailPage />,
    },
    {
      path: '/category-history',
      name: 'CategoryHistory',
      element: <CategoryHistory/>,
    },
    {
      path: '/product-report-history',
      name: 'ProductCategoryHistory',
      element: <ProductCategoryHistory/>,
    },
    {
      path: '/category-stock-history',
      name: 'CategoryStockHistory',
      element: <CategoryStockHistory/>,
    },
    {
      path: '/product-stock-history',
      name: 'ProductStockReport',
      element: <ProductStockHistory/>,
    },
    {
      path: '/product-stock-history-details',
      name: 'ProductStockHistoryDetails',
      element: <ProductStockHistoryDetails/>,
    },
    {
      path: '/product-report-history-details',
      name: 'ProductCategoryHistoryDetails',
      element: <ProductCategoryHistoryDetails/>,
    },
    {
      path: '/transaction-history',
      name: 'TransactionHistory',
      element: <TransactionHistory/>,
    },
    {
      path: '/accounting-history',
      name: 'TransactionHistory',
      element: <AccountingHistory/>,
    },
    {
      path:'balance-history',
      name: 'BalanceHistory',
      element:<BalanceHistory/>,
    },
    {
      path: '/category-history-details',
      name: 'CategoryHistoryDetails',
      element: <CategoryHistoryDetails/>,

    },
    {
      path:'vendor-profile/:id',
      name:'VendorProfilePage',
      element: <VendorProfilePage/>,
    },
    {
      path:'vendor-invoice-history/:id',
      name:'VendorInvoiceHistoryPage',
      element: <VendorInvoiceHistoryPage/>,
    },
    {
      path:'vendor-most-purchase-products/:id',
      name:'VendorMostPurchaseProductPage',
      element: <VendorMostPurchaseProductPage />,
    },
    {
      path:'customer-profile/:id',
      name:'CustomerProfilePage',
      element: <CustomerProfilePage/>,
    },
    {
      path:'customer-most-purchase-products/:id',
      name:'CustomerMostPurchaseProductPage',
      element: <CustomerMostPurchaseProductPage />,
    },
    {
      path:'customer-invoice-history/:id',
      name:'CustomerInvoiceHistoryPage',
      element: <CustomerInvoiceHistoryPage/>,
    },
    {
      path:'customer-edit/:id',
      name:'CustomerEditPage',
      element: <CustomerEditPage />,
    },
    {
      path:'vendor-edit/:id',
      name:'VendorEditPage',
      element: <VendorEditPage />,
    },
    {
      path:'wholesaler-edit/:id',
      name:'WholesalerEditPage',
      element: <WholesalerEditPage />,
    },
    {
      path: '/due-invoice/:id',
      name: 'VendorDueInvoicePage',
      element: <VendorDueInvoicePage />,
    },
    {
      path: '/carrier-due-invoice/:id',
      name: 'CarrierDueInvoicePage',
      element: <CarrierDueInvoicePage />,
    },
    {
      path: '/exporter-due-invoice/:id',
      name: 'ExporterDueInvoicePage',
      element: <ExporterDueInvoicePage />,
    },
    {
      path:'carrier-invoice-history/:id',
      name:'CarrierInvoiceHistoryPage',
      element: <CarrierInvoiceHistoryPage/>,
    },
    {
      path:'exporter-invoice-history/:id',
      name:'ExporterInvoiceHistoryPage',
      element: <ExporterInvoiceHistoryPage/>,
    },
    {
      path: '/wholesaller-list',
      name: 'WholesalerListPage',
      element: <WholesalerListPage />,
    },
    {
      path: '/wholesaler-profile/:id',
      name: 'WholesalerProfilePage',
      element: <WholesalerProfilePage />,
    },
    {
      path:'wholesaler-invoice-history/:id',
      name:'WholesalerInvoiceHistoryPage',
      element: <WholesalerInvoiceHistoryPage/>,
    },
    {
      path:'wholesaler-most-purchase-products/:id',
      name:'WholesalerMostPurchaseProductPage',
      element: <WholesalerMostPurchaseProductPage />,
    },
    {
      path:'customer-add',
      name:'CustomerAddPage',
      element: <CustomerAddPage />,
    },
    {
      path:'vendor-add',
      name:'VendorAddPage',
      element: <VendorAddPage />,
    },
    {
      path:'wholesaler-add',
      name:'WholesalerAddPage',
      element: <WholesalerAddPage />,
    },
    {
      path: '/exporter',
      name: 'ExporterPage',
      element: <ExporterPage />,
    },
    {
      path: '/exporter-profile/:id',
      //path: '/exporter-profile',
      name: 'ExporterProfilePage',
      element: <ExporterProfilePage />,
    },
    {
      path:'exporter-edit/:id',
      name:'ExporterEditPage',
      element: <ExporterEditPage/>,
    },
    {
      path:'exporter-add',
      name:'ExporterAddPage',
      element: <ExporterAddPage />,
    },
    {
      path:'carrier-list',
      name:'CarrierListPage',
      element: <CarrierListPage/>,
    },
    {
      path: '/carrier-profile/:id',
      //path: '/carrier-profile',
      name: 'CarrierProfilePage',
      element: <CarrierProfilePage/>,
    },
    {
      path:'carrier-add',
      name:'CarrierAddPage',
      element: <CarrierAddPage />,
    },
    {
      path:'carrier-edit/:id',
      name:'CarrierEditPage',
      element: <CarrierEditPage/>,
    },
    {
      path: '/carrier-invoice/:id',
      //path: '/carrier-invoice',
      name: 'CarrierInvoiceDetailPage',
      element: <CarrierInvoiceDetailPage />,
    },
    {
      path: '/exporter-invoice/:id',
      //path: '/carrier-invoice',
      name: 'ExporterInvoiceDetailPage',
      element: <ExporterInvoiceDetailPage />,
    },
    {
      path: '/exporter-list',
      name: 'ExporterListPage',
      element: <ExporterListPage/>
    },
    {
      path: '/exporter-product-list',
      name: 'ExporterProductListPage',
      // element: <ExporterListPage/>
      element: <ExporterProductListPage/>

    },
    {
      path: '/exporter-products/:id',
      name: 'ExporterProductListPage',
      // element: <ExporterListPage/>
      element: <ExporterProductDetailPage/>
    },
    {
      path: '/add-exporter/:id',
      name: 'AddExporterPage',
      element: <AddExporterPage />,
    },
    {
      path: '/exporter-stock',
      name: 'ExporterStockPage',
      element: <ExporterStockPage />,
    },
    {
      path: '/expense-category',
      name: 'ExpenseCategory',
      element: <ExpenseCategory/>,
    },
    {
      path: '/journal-list',
      name: 'AccountsListPage',
      element: <AccountsListPage/>,
    },
    {
      path: '/chart-of-account',
      name: 'ChartOfAccountPage',
      element:<ChartOfAccountPage/>
    },
    {
      path: '/fund-transfer',
      name: 'ChartOfAccountPage',
      element:<FundTransfer/>
    },
    {
      path: '/expense-list',
      name: 'ExpenseListPage',
      element:<ExpenseListPage/>,
    },
    // {
    //   path: '/party-name-list',
    //   name: 'PartyNameListPage',
    //   element:<PartyNameListPage/>
    // },
    {
      path: '/expenselist',
      name: 'ExpenseListPage',
      element:<ExpensePage/>,
    },
    {
      path: '/quick-payment-list',
      name: 'QuickPaymentList',
      element:<QuickPaymentPage/>,
    },
    {
      path: '/expensecategory',
      name: 'ExpenseCategoryPage',
      element:<ExpenseCategoryPage/>
    },
    {
      path: '/quick-payment-category',
      name: 'QuickPaymentCategory',
      element:<QuickPaymentCategoryPage/>
    },
    {
      path: '/createwarehouse',
      name: 'CreateWareHousePage',
      element:<CreateWareHouse/>
    },
    {
      path: '/warehouselist',
      name: 'WareHouseListPage',
      element:<WareHouseList/>
    },
    {
      path: '/employee',
      name: 'EmployeePage',
      element:<EmployeePage/>
    },
    {
      path: '/employee-add',
      name: 'EmployeePage',
      element:<EmployeeAddPage/>
    },
    {
      path: '/employee-view/:id',
      name: 'EmployeeViewPage',
      element:<EmployeeViewPage/>
    },
    {
      path: '/employee-edit/:id',
      name: 'EmployeeEditPage',
      element:<EmployeeEditPage/>
    },
    {
      path: '/employee-profile',
      name: 'EmployeeProfilePage',
      element:<EmployeeProfilePage/>
    },
    {
      path: '/designation',
      name: 'DesignationPage',
      element:<DesignationPage/>
    },
    {
      path: '/department',
      name: 'DepartmentPage',
      element:<DepartmentPage/>
    },
    {
      path : '/duereport-history',
      name : 'DueReportPage',
      element : <DueReportExpand />
    },
    {
      path : '/ledger-report-history',
      name : 'LedgerReport',
      element : <LedgerReportExpand />
    },
    {
      path : '/balance-sheet-report-history',
      name : 'BalanceSheetReport',
      element : <BalanceSheetReportExpand />
    },
    {
      path : '/sales-register-report',
      name : 'SalesRegisterReport',
      element : <SalesRegisterReportExpand />
    },
    {
      path : '/monthly-sales-day-counting-report',
      name : 'MonthlySalesDayCountingReport',
      element : <MonthlySalesDayCountingExpand />
    },
    {
      path : '/monthly-purchase-report',
      name : 'MonthlyPurchaseReport',
      element : <MonthlyPurchaseReportExpand />
    },
    {
      path : '/minimum-stock-report',
      name : 'MinimumStockReport',
      element : <MinimumStockReportExpand />
    },
    {
      path : '/profit-loss-account-report',
      name : 'ProfitAndLossAccountREport',
      element : <ProfitAndLossAccountReportExpand />
    },
    {
      path : '/cashbook-details-history',
      name : 'CashBookDetailsPage',
      element : <CashBookExpands />
    },
    {
      path : '/cashbook-summary-report',
      name : 'CashbookSummaryReport',
      element : <CashbookSummaryReportExpand />
    },
    {
      path : '/store-ledger-report',
      name : 'StoreLedgerPage',
      element : <StoreLedgerExpand />
    },
    {
      path : '/transaction-summary-report',
      name : 'TransactionSummaryPage',
      element : <TransactionSummaryExpand />
    },
    {
      path : '/createbanner',
      name : 'BannerPage',
      element : <CreateBanner />
    },
    {
      path : '/createslider',
      name : 'SliderPage',
      element : <CreateSlider />
    },
    {
      path: '/role',
      name: 'RolePage',
      element:<RolePage/>
    },
    {
      path: '/role-add',
      name: 'RoleAddPage',
      element:<RoleAddPage/>
    },
    {
      path: '/role-view/:id',
      name: 'RoleViewPage',
      element:<RoleViewPage/>
    },
    {
      path: '/role-edit/:id',
      name: 'RoleEditPage',
      element:<RoleEditPage/>
    },
    {
      path: '/imeireport-page',
      name: 'ImeiReportPage',
      element: <ImeiReportPage/>
    },
    {
      path: '/customer-summary-report',
      name: 'CustomerSummaryPage',
      element: <CustomerSummaryPage/>
    },
    {
      path: '/customer-wise-due-report',
      name: 'CustomerSummaryPage',
      element: <CustomerWiseDueReportPage/>
    },
    {
      path: '/employee-wise-sales-report',
      name: 'EmployeeWiseSalesPage',
      element: <EmployeeWiseSalesPage/>
    },
    {
      path: '/month-wise-report',
      name: 'MonthWiseReportPage',
      element: <MonthWiseReportPage/>
    },
  ];
};

export default routeConfiguration;