import axios from "axios";
import React, { useEffect, useState,useContext } from 'react';
import { GrFormClose } from 'react-icons/gr';
import BrandForm from '../forms/BrandForm';
import CategoryForm from '../forms/CategoryForm';
import InvoiceSellerForm from '../forms/InvoiceSellerForm';
import InvoiceVendorForm from '../forms/InvoiceVendorForm';
import ProductEditModal from './ProductEditModal';
import { AppContext } from '../context/context';
import InvoiceCustomerForm from '../forms/InvoiceCustomerForm';
import InvoiceWholeSalerForm from '../forms/InvoiceWholeSalerForm';
import NewCustomerForm from '../forms/NewCustomerForm';
import NewWholeSalerForm from '../forms/NewWholeSalerForm';
import SalesReturnForm from '../forms/SalesReturnForm';
import NewVendorForm from '../forms/NewVendorForm';
import crose from "../assets/images/crose.svg";
import PaymentTypeForm from "../forms/PaymentTypeForm";
import DuePaymentForm from "../forms/DuePaymentForm";
import PaymentInfoForm from "../forms/PaymentInfoForm";
import WarrantyTypeForm from "../forms/WarrantyTypeForm";
import AddAccountsTransactionForm from "../forms/AddAccountsTransactionForm";
import AddTxnTypeForm from "../forms/AddTxnTypeForm";
import ViewFinanceForm from "../forms/ViewFinanceForm";
import AddExpenseTypeForm from "../forms/AddExpenseForm";
import AddExpenseForm from "../forms/AddExpenseTypeForm";
import AddExpenseTypeForm1 from "../forms/AddExpenseTypeForm1";
import ExpenseDetailForm from "../forms/ExpenseDetailForm";
import ExpenseEditForm from "../forms/ExpenseEditForm";
import ExpenseCategoryEditForm from "../forms/ExpenseCategoryEditForm";
import ExpenseCategoryDetailForm from "../forms/ExpenseCategoryDetailForm";
import ViewExpenseCategoryForm from "../forms/ViewExpenseCategoryForm";
import ProductVariationForm from "../forms/ProductVariationForm";
import ExporterInfoForm from '../forms/ExporterInfoForm';
import ExporterAddForm from '../forms/ExporterAddForm';
import ExporterSearchProduct from '../forms/ExporterSearchProduct';
import ExporterAddProductForm from '../forms/ExporterAddProductForm';
import DueDiscountForm from '../forms/DueDiscountForm';
import SubCategoryForm from '../forms/SubCategoryForm';
import ExporterProductViewForm from '../forms/ExporterProductViewForm';
import ExporterSearchCarrier from '../forms/ExporterSearchCarrier';
import ExporterAddCarrierForm from '../forms/ExporterAddCarrierForm';
import ExporterCarrierInfoViewForm from '../forms/ExporterCarrierInfoViewForm';
import ExporterPaymentDetailForm from '../forms/ExporterPaymentDetailForm';
import ExporterCarrierProductListForm from '../forms/ExporterCarrierProductListForm';
import ExporterPaymentDescriptionForm from '../forms/ExporterPaymentDescriptionForm';
import CarrierPendingProductView from '../forms/CarrierPendingProductView';
import AddDesignationForm from '../forms/AddDesignationForm';
import EditDesignationForm from '../forms/EditDesignationForm';
import DesignationDetailForm from '../forms/DesignationDetailForm';
import AddDepartmentForm from '../forms/AddDepartmentForm';
import DepartmentDetailForm from '../forms/DepartmentDetailForm';
import EditDepartmentForm from '../forms/EditDepartmentForm';
import WareHouseListForm from "../forms/WareHouseListForm";
import WareHouseEditForm from "../forms/WareHouseEditForm";
import WareHouseDetailsForm from "../forms/WareHouseDetailsForm";
import AddUserDataForm from '../forms/AddUserDataForm';
import ChoosePrintOptions from '../forms/ChoosePrintOptions';
import AddQuickPaymentForm from "../forms/AddQuickPaymentForm";
import AddQuickPaymentTypeForm from "../forms/AddQuickPaymentTypeForm";

export const Modal = (props) => {
  const {
    loadingCallback,
    navigate,
    formId,
    type,
    title,
    isOpen,
    listData,
    setOpen,
    getData,
    setData,
    filteredAccountList,
    setFilteredAccountList,
    setSelectedAccountList,
    setPaidAmount,
    setWarningMessage,
    paymentAccountList,
    filteredProducts,
    setFilteredProducts,
    setFilteredCustomers,
    selectedProductWarrantyList,
    setSelectedProductWarrantyList,
    selectedItem,
    setSelectedItem,
    filteredCustomers,
    formData,
    setFormData,
    orderList,
    setOrderList,
    filteredWholeSaler,
    setFilteredWholeSaler,
    variationData,
    seteVariationData,
    saleType,
    notify,
    selectType,
    exporterId,
    exporterOrder,
    productData,
    total_due,
    addedProduct,
    setAddedProduct,
    addedCarrier,
    setAddedCarrier,
    showPaymentModal,
    grandTotalAfterDiscount,
    is129TotalPrice,
    postAccountTxn,
    carrierData,
    setSuccessFlashMessage,
    setErrorFlashMessage,
    setPayment,
    baseCurrency,
    dueAmount,
    productQty,
    receivedQty,
    refetch,
    setShowPaymentModal,
    purchaseAmount,
    expenseId,
    expenseCategoryId,
    expenseFormData,
    expenseCategoryFormData,
    designationData,
    designationId,
    setShowModal,
    departmentId,
    departmentData,
    warehouseId,
    total,
    wareHouseFormData,
    warehouseData,
    modalData,
    setModalData,
    handleModalChange,
    handleModalSubmit,
    handleChange,
    handlePrint,
    customerDueInvoiceListState,
    dueType,
    getCustomerDueInvoiceList
  } = props;

  const {
    state,
    updateBrandList,
    updateCategoryList,
    updateFilteredCustomer,
    updateFilteredExporter,
    updateFilteredWholeSaler,
    updateFilteredVendor,
    updateFilteredProduct,
    updateCarrierLists
  } = useContext(AppContext);

  const [showNestedModal, setShowNestedModal] = useState(false);
  const [nestedModalData, setNestedModalData] = useState([]);
  const [showChildNestedModal, setShowChildNestedModal] = useState(false);
  const [nestedChildModalData, setNestedChildModalData] = useState([]);
  const [brandData, setBrandData] = useState(false);
  const [categoryData, setCategoryData] = useState(false);
  const [customerData, setCustomerData] = useState(false);
  const [vendorData, setVendorData] = useState(false);
  const [exporterData, setExporterData] = useState(false);
  const [wholeSalerData, setWholeSalerData] = useState(false);
  const { customerDueInvoiceList, vendorDueInvoiceList, exporterDueInvoiceList, carrierDueInvoiceList, wholesellerDueInvoiceList } = state;

  const handleNestedModalClose = () => {
    setShowNestedModal(false);
  };
  const handleNestedChildModalClose = () => {
    setShowChildNestedModal(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const token = localStorage.getItem("token");

  const getBrand = () => {

      axios
      .get(
          `${process.env.REACT_APP_API_URL}brands`,
          {
              headers: {
                  authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
              },
          }
      )
      .then((response) => {

          //console.log(response.data.data.data)

          if(response.data.success)
          {

              if (response.data.data.data.length > 0) {
                setOpen(false);
                updateBrandList(response.data.data.data)
              }

          }
          //loadingCallback(false);

      })
      .catch((error) => {
          console.log(error);
          //loadingCallback(false);
          if (error?.response?.status === 401) {
              navigate('/login');
              localStorage.setItem('token', '');
              localStorage.setItem('token', '');
              // Clear session and local storage
              localStorage.setItem('isPinVerify', false);

              navigate('/login');
          }
      });
  };
  const getBrandState = () => {

      axios
      .get(
          `${process.env.REACT_APP_API_URL}brands`,
          {
              headers: {
                  authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
              },
          }
      )
      .then((response) => {

          //console.log(response.data.data.data)

          if(response.data.success)
          {

              if (response.data.data.data.length > 0) {
                //setOpen(false);
                updateBrandList(response.data.data.data)
              }

          }
          //loadingCallback(false);

      })
      .catch((error) => {
          console.log(error);
          //loadingCallback(false);
          if (error?.response?.status === 401) {
              navigate('/login');
              localStorage.setItem('token', '');
              localStorage.setItem('token', '');
              // Clear session and local storage
              localStorage.setItem('isPinVerify', false);

              navigate('/login');
          }
      });
  };
  const getCategory = () => {
    //loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}get-all-category`, {
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        //loadingCallback(false);
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setOpen(false);
            updateCategoryList(response.data.data)
          }
        }

      })
      .catch((error) => {
        console.log(error);
        //loadingCallback(false);
        if (error?.response?.status === 401) {
          navigate('/login');
          localStorage.setItem('token', '');
          localStorage.setItem('token', '');
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
  };

  const getCustomerListData = async (searchKeyword='') => {
    //loadingCallback(true);
    //setFilteredCustomers([]);
    updateFilteredCustomer([]);
    const options = [];
    axios
    .get(
      `${process.env.REACT_APP_API_URL}customer`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {

      if (response.status === 200) {
        const customers = response?.data?.data?.data ? response.data.data.data : response.data.data;
        // Append other options if there are customers
        if (customers.length > 0) {
          const customerOptions = customers.map((customer) => ({
            id: customer.id,
            name: customer.name,
            email: customer.email,
            country_code: customer.country_code,
            mobile_number: customer.mobile_number,
            address: customer.address,
            country: customer.country,
            district: customer.district,
            city: customer.city,
            zipcode: customer.zipcode,
            nid: customer.nid
          }));
          options.push(...customerOptions);
        }
      } else {
        console.log("Data Not Found!");
      }
      setOpen(false);
      updateFilteredCustomer(options);
      //loadingCallback(false);
    })
    .catch((error) => {
      //loadingCallback(false);
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.setItem('token', '');
        localStorage.setItem('token', '');
        // Clear session and local storage
        localStorage.setItem('isPinVerify', false);

        navigate('/login');
      }
    });

  };
  const getExporterListData = async (searchKeyword = "") => {
    //loadingCallback(true);
    //setFilteredVendor([]);
    updateFilteredExporter([]);
    const page1 = 1;
    const options = [];
    axios
      .get(`${process.env.REACT_APP_API_URL}exporter-list`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const vendors = response?.data?.data?.data
            ? response?.data?.data?.data
            : response?.data?.data;
          console.log("============");
          console.log(vendors);
          // Append other options if there are vendors
          if (vendors.length > 0) {
            const vendorOptions = vendors.map((vendor) => ({
              id: vendor.id,
              name: vendor.name,
              email: vendor.email,
              country_code: vendor.country_code,
              proprietor_name: vendor?.proprietor_name,
              mobile_number: vendor.mobile_number,
              address: vendor.address,
              country: vendor.country,
              district: vendor.district,
              city: vendor.city,
              zipcode: vendor.zipcode,
            }));
            options.push(...vendorOptions);
          }
        } else {
          console.log("Data Not Found!");
        }
        setOpen(false);
        updateFilteredExporter(options);
        //loadingCallback(false);
      })
      .catch((error) => {
        //loadingCallback(false);
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.setItem("token", "");
          localStorage.setItem("token", "");
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };
  const getVendorListData = async (searchKeyword='') => {
    //loadingCallback(true);
    //setFilteredVendor([]);
    updateFilteredVendor([]);
    const page1 = 1;
    const options = [];
    axios
    .get(
      `${process.env.REACT_APP_API_URL}vendor`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const vendors = response?.data?.data?.data ? response?.data?.data?.data :response?.data?.data;
        console.log("============");
        console.log(vendors);
        // Append other options if there are vendors
        if (vendors.length > 0) {
          const vendorOptions = vendors.map((vendor) => ({
            id: vendor.id,
            name: vendor.name,
            email: vendor.email,
            country_code: vendor.country_code,
            proprietor_name: vendor?.proprietor_name,
            mobile_number: vendor.mobile_number,
            address: vendor.address,
            country: vendor.country,
            district: vendor.district,
            city: vendor.city,
            zipcode: vendor.zipcode,
          }));
          options.push(...vendorOptions);
        }
      } else {
        console.log("Data Not Found!");
      }
      setOpen(false);
      updateFilteredVendor(options);
      //loadingCallback(false);
    })
    .catch((error) => {
      //loadingCallback(false);
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.setItem('token', '');
        localStorage.setItem('token', '');
        // Clear session and local storage
        localStorage.setItem('isPinVerify', false);

        navigate('/login');
      }
    });
  };
  const getWholeSalerListData = async (searchKeyword='') => {
    //loadingCallback(true);
    //setFilteredVendor([]);
    updateFilteredWholeSaler([]);
    const page1 = 1;
    const options = [];
    axios
    .get(
      `${process.env.REACT_APP_API_URL}whole-sellers`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const wholesellers = response.data.data ? response.data.data : response.data;
        console.log("============");
        console.log(wholesellers);
        // Append other options if there are wholesellers
        if (wholesellers.length > 0) {
          const wholesellerOptions = wholesellers.map((wholeseller) => ({
            id: wholeseller.id,
            wholeseller_id: wholeseller.id,
            name: wholeseller.name,
            email: wholeseller.email,
            country_code: wholeseller.country_code,
            //proprietor_name: wholeseller?.proprietor_name,
            mobile_number: wholeseller.mobile_number,
            address: wholeseller.address,
            country: wholeseller.country,
            district: wholeseller.district,
            city: wholeseller.city,
            zipcode: wholeseller.zipcode,
          }));
          options.push(...wholesellerOptions);
        }
      } else {
        console.log("Data Not Found!");
      }
      setOpen(false);
      updateFilteredWholeSaler(options);
      //loadingCallback(false);
    })
    .catch((error) => {
      //loadingCallback(false);
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.setItem('token', '');
        // Clear session and local storage
        localStorage.setItem('isPinVerify', false);

        navigate('/login');
      }
    });
  };
  useEffect(() => {
    if (state?.brandList?.length === 0) {
      getBrandState();
    }
  }, []);
  useEffect(() => {

    if (brandData && isOpen && brandData=='ok') {
      getBrand();
    }
    if (categoryData && isOpen && categoryData=='ok') {
      getCategory();
    }
    if (customerData && isOpen) {
      //setNestedModalData(customerData);
      getCustomerListData();
    }
    // console.log("============");
    // console.log(vendorData);
    if (vendorData && isOpen) {
      //setNestedModalData(vendorData);
      getVendorListData();
    }
    if (wholeSalerData && isOpen) {
      getWholeSalerListData();
    }
    if (exporterData && isOpen) {
      //setNestedModalData(vendorData);
      getExporterListData();
    }

  }, [exporterData,vendorData,brandData,categoryData,customerData,wholeSalerData]);


  const [modalTitle, setModalTitle] = useState('');//Add Return

  const updateModalTitle = (newTitle) => {
    setModalTitle(newTitle);
  };


  const hideNmodal = () => {
    alert("---gggg---");
    setShowNestedModal(false);
    setShowNestedModal(false);
  }




  return (
    <>
      {isOpen && (
        <div>
          {console.log('Modal '+JSON.stringify(customerDueInvoiceList, null, 2))}
          {/* Put this part before </body> tag */}
          <input type="checkbox" id={formId} className="modal-toggle" checked />
          <div className="modal z-[9999]">
            <div
              className={`${
                type == "carrier_pending_products" || type == "salesReturnList" || type == "purchaseReturnList"
                  ? "max-w-[600px] px-0 "
                  : type == "payment"
                  ? "max-w-[450px]"
                  : "max-w-[500px] "
              } modal-box  pt-2 bg-white overflow-auto`}
            >
              <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                <h4 className=" font-nunito font-bold text-lg  ">
                  {type == "carrier_pending_products"
                  || type == "salesReturnList" || type == "purchaseReturnList"
                    ? title
                    : modalTitle ? modalTitle : title}
                </h4>
                <div className="modal-action bg-red-600 text-white rounded-md">
                  <label
                    htmlFor={formId}
                    className="cursor-pointer "
                    onClick={handleClose}
                  >
                    <img src={crose} width={23} height={20} alt="" />
                  </label>
                </div>
              </div>

              <div
                className={`${
                  type == "salesReturnList" || type == "purchaseReturnList"
                    ? "px-[10px] md:px-[40px] pt-[30px] "
                    : type == "payment"
                    ? "pt-3 px-2"
                    : "px-0 pt-3 "
                } pb-[22px]`}
              >
                {type === "customer" && (
                  <InvoiceCustomerForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setCustomer={setData}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    setFilteredCustomers={updateFilteredCustomer}
                    filteredCustomers={state.customerList}
                    showNestedModal={showNestedModal}
                    setShowNestedModal={setShowNestedModal}
                    setNestedModalData={setNestedModalData}
                    notify={notify}
                  />
                )}
                {type === "new-customer" && (
                  <NewCustomerForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setCustomer={setData}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setShowModal}
                    setCustomerData={setCustomerData}
                    isOpen={isOpen}
                    title={title}

                  />
                )}
                {type === "wholesaler" && (
                  <InvoiceWholeSalerForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setWholeSaler={setData}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    setFilteredWholeSaler={updateFilteredWholeSaler}
                    filteredWholeSaler={state.wholeSalerList}
                    showNestedModal={showNestedModal}
                    setShowNestedModal={setShowNestedModal}
                    setNestedModalData={setNestedModalData}
                  />
                )}
                {type === "new-wholesaler" && (
                  <NewWholeSalerForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setWholeSaler={setData.setData}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    setWholeSalerData={setWholeSalerData}
                  />
                )}
                {type === "seller" && (
                  <InvoiceSellerForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setSeller={setData}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    setFilteredSeller={props.setFilteredSeller}
                    filteredSeller={props.filteredSeller}
                  />
                )}
                {type === "vendor" && (
                  <InvoiceVendorForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setVendor={setData}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    setFilteredVendor={updateFilteredVendor}
                    filteredVendor={state.vendorList}
                    showNestedModal={showNestedModal}
                    setShowNestedModal={setShowNestedModal}
                    setNestedModalData={setNestedModalData}
                  />
                )}
                {type === "new-vendor" && (
                  <NewVendorForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setVendor={setData.setData}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    setVendorData={setVendorData}
                  />
                )}
                {type === "brand" && (
                  <BrandForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setBrand={setData}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    setBrandData={setBrandData}
                  />
                )}
                {type === "category" && (
                  <CategoryForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setBrand={setData}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    setCategoryData={setCategoryData}
                  />
                )}
                {type === "product" && (
                  <ProductEditModal
                    formId={formId}
                    type={type}
                    saleType={saleType}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setProduct={setData}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    showNestedModal={showNestedModal}
                    setShowNestedModal={setShowNestedModal}
                    setNestedModalData={setNestedModalData}
                    getTopSaleProductlist={props.getTopSaleProductlist}
                  />
                )}
                {type === "purchaseReturnList" && (
                  <SalesReturnForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setShowModal={setOpen}
                    setWarningMessage={setWarningMessage}
                    updateModalTitle={updateModalTitle}
                    type={type}
                  />
                )}
                {type === "salesReturnList" && (
                  <SalesReturnForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setShowModal={setOpen}
                    setWarningMessage={setWarningMessage}
                    updateModalTitle={updateModalTitle}
                    type={type}
                  />
                )}
                {type === "payment" && (
                  <PaymentTypeForm
                    formId={formId}
                    paymentData={getData}
                    paymentList={listData}
                    paymentAccountList={paymentAccountList}
                    setData={setData}
                    formData={formData}
                    setFormData={setFormData}
                    filteredAccountList={filteredAccountList}
                    setFilteredAccountList={setFilteredAccountList}
                    setPaidAmount={setPaidAmount}
                    loadingCallback={loadingCallback}
                    setSelectedAccountList={setSelectedAccountList}
                    navigate={navigate}
                    setShowModal={setOpen}
                    isOpen={isOpen}
                    title={title}
                    setWarningMessage={setWarningMessage}
                    type={type}
                    selectType={selectType}
                    postAccountTxn={postAccountTxn}
                    total={total}
                    grandTotalAfterDiscount={grandTotalAfterDiscount}
                    is129TotalPrice={is129TotalPrice}
                  />
                )}
                {type === "addAccountsTransaction" && (
                   <AddAccountsTransactionForm
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setShowModal={setOpen}
                    setShowPaymentModal={setShowPaymentModal}
                    isOpen={isOpen}
                    formId={formId}
                    title={title}
                    formData={formData}
                    setFormData={setFormData}
                    setWarningMessage={setWarningMessage}
                    type={type}
                    selectType={selectType}
                  />
                )}
                {type === "addTxnType" && (
                   <AddTxnTypeForm
                  formData={formData}
                  setFormData={setFormData}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setShowModal={setOpen}
                    isOpen={isOpen}
                    formId={formId}
                    refetch={refetch}
                    title={title}
                    setWarningMessage={setWarningMessage}
                    type={type}
                    selectType={selectType}
                  />
                )}

                {/* expense category form */}
                {type === "addExpenseType" && (
                   <AddExpenseTypeForm
                   loadingCallback={loadingCallback}
                   navigate={navigate}
                   setShowModal={setOpen}
                   setShowPaymentModal={setShowPaymentModal}
                   isOpen={isOpen}
                   formId={formId}
                   title={title}
                   refetch={refetch}
                   formData={formData}
                   setFormData={setFormData}
                   setWarningMessage={setWarningMessage}
                   type={type}
                  />
                )}

                {/* expense category form 2 */}
                {type === "addExpenseCategoryModal" && (
                   <AddExpenseTypeForm1
                   loadingCallback={loadingCallback}
                   navigate={navigate}
                   setShowModal={setOpen}
                   //setShowPaymentModal={setShowPaymentModal}
                   isOpen={isOpen}
                   formId={formId}
                   title={title}
                   refetch={refetch}
                   formData={formData}
                   setFormData={setFormData}
                   setWarningMessage={setWarningMessage}
                   type={type}
                  />
                )}
                {/* quick payment category */}
                {type === "addQuickPaymentCategoryModal" && (
                   <AddQuickPaymentTypeForm
                   loadingCallback={loadingCallback}
                   navigate={navigate}
                   setShowModal={setOpen}
                   setShowPaymentModal={setShowPaymentModal}
                   isOpen={isOpen}
                   formId={formId}
                   title={title}
                   refetch={refetch}
                   formData={formData}
                   setFormData={setFormData}
                   setWarningMessage={setWarningMessage}
                   type={type}
                  />
                )}

                {/* expense form */}
                {type === "addExpenseModal" && (
                   <AddExpenseForm
                   loadingCallback={loadingCallback}
                   navigate={navigate}
                   setShowModal={setOpen}
                   setShowPaymentModal={setShowPaymentModal}
                   isOpen={isOpen}
                   formId={formId}
                   title={title}
                   refetch={refetch}
                   formData={formData}
                   setFormData={setFormData}
                   setWarningMessage={setWarningMessage}
                   type={type}
                  />
                )}
                {type === "addQuickPaymentModal" && (
                   <AddQuickPaymentForm
                   loadingCallback={loadingCallback}
                   navigate={navigate}
                   setShowModal={setOpen}
                   setShowPaymentModal={setShowPaymentModal}
                   isOpen={isOpen}
                   formId={formId}
                   title={title}
                   refetch={refetch}
                   formData={formData}
                   setFormData={setFormData}
                   setWarningMessage={setWarningMessage}
                   type={type}
                  />
                )}

                {/* view expense form */}
                {type === "view_expense_modal" && (
                  <ExpenseDetailForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    updateModalTitle={updateModalTitle}
                    expenseId={expenseId}
                  />
                )}

                {/* edit expense form */}
                {type === "edit_expense_modal" && (
                  <ExpenseEditForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    //updateModalTitle={updateModalTitle}
                    expenseFormData={expenseFormData}
                    formData={formData}
                    setFormData={setFormData}
                    expenseId={expenseId}
                    setOpen={setOpen}
                    refetch={refetch}
                  />
                )}

                {/* edit expense category form */}
                {type === "edit_expense_category_modal" && (
                  <ExpenseCategoryEditForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    expenseCategoryFormData={expenseCategoryFormData}
                    formData={formData}
                    setFormData={setFormData}
                    expenseCategoryId={expenseCategoryId}
                    setOpen={setOpen}
                    refetch={refetch}
                  />
                )}

                {/* view expense category form */}
                {type === "view_expense_category_modal" && (
                  <ExpenseCategoryDetailForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    updateModalTitle={updateModalTitle}
                    expenseCategoryId={expenseCategoryId}
                  />
                )}

                {/* use selectType to conditionally render accounts view modal info / txn type modal info */}
                {type === "viewFinance" && (
                   <ViewFinanceForm
                    formData={formData}
                    setShowModal={setOpen}
                    title={title}
                    selectType={selectType}
                  />
                )}

                {/* view expense category */}
               {type === "viewExpense" && (
                   <ViewExpenseCategoryForm
                    formData={formData}
                    setShowModal={setOpen}
                    title={title}
                    selectType={selectType}
                  />
                )}


                {type === "due-payment" && (
                  <DuePaymentForm
                    formId={formId}
                    dueAmount={dueAmount}
                    paymentData={getData}
                    paymentList={listData}
                    paymentAccountList={paymentAccountList}
                    filteredAccountList={filteredAccountList}
                    setFilteredAccountList={setFilteredAccountList}
                    setPaidAmount={setPaidAmount}
                    setSelectedAccountList={setSelectedAccountList}
                    setData={setData}
                    formData={formData}
                    setFormData={setFormData}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setShowModal={setOpen}
                    isOpen={isOpen}
                    selectType={selectType}
                    title={title}
                    setWarningMessage={setWarningMessage}
                    type={type}
                    customerDueInvoiceList={customerDueInvoiceList}
                    vendorDueInvoiceList={vendorDueInvoiceList}
                    exporterDueInvoiceList={exporterDueInvoiceList}
                    carrierDueInvoiceList={carrierDueInvoiceList}
                    wholesellerDueInvoiceList={wholesellerDueInvoiceList}
                  />
                )}


                {
                  type === "due-discount" && (
                    <DueDiscountForm
                      isOpen={isOpen}
                      setOpen={setOpen}
                      customerDueInvoiceListState={customerDueInvoiceListState}
                      type={type}
                      formId={formId}
                      title={title}
                      dueAmount={dueAmount}
                      dueType={dueType}
                      customerDueInvoiceList={customerDueInvoiceList}
                      getCustomerDueInvoiceList={getCustomerDueInvoiceList}
                      vendorDueInvoiceList={vendorDueInvoiceList}
                      exporterDueInvoiceList={exporterDueInvoiceList}
                      carrierDueInvoiceList={carrierDueInvoiceList}
                      wholesellerDueInvoiceList={wholesellerDueInvoiceList}
                    />
                  )
                }

                {type === "payment-info" && (
                  <PaymentInfoForm
                    formId={formId}
                    purchaseAmount={purchaseAmount}
                    paymentData={getData}
                    paymentList={listData}
                    formData={formData}
                    setFormData={setFormData}
                    filteredAccountList={filteredAccountList}
                    setFilteredAccountList={setFilteredAccountList}
                    setData={setData}
                    paymentAccountList={paymentAccountList}
                    setPaidAmount={setPaidAmount}
                    setSelectedAccountList={setSelectedAccountList}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setShowModal={setOpen}
                    isOpen={isOpen}
                    selectType={selectType}
                    title={title}
                    setWarningMessage={setWarningMessage}
                    type={type}
                  />
                )}
                {type === "warranty" && (
                  <WarrantyTypeForm
                    state={state}
                    formId={formId}
                    setFilteredProducts={setFilteredProducts}
                    filteredProducts={filteredProducts}
                    loadingCallback={loadingCallback}
                    selectedProductWarrantyList={selectedProductWarrantyList}
                    setSelectedProductWarrantyList={
                      setSelectedProductWarrantyList
                    }
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    orderList={orderList}
                    navigate={navigate}
                    setShowModal={setOpen}
                    isOpen={isOpen}
                    title={title}
                    setWarningMessage={setWarningMessage}
                    type={type}
                  />
                )}

                {type === "exporter" && (
                  <ExporterInfoForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setShowModal={setOpen}
                    setWarningMessage={setWarningMessage}
                    setShowNestedModal={setShowNestedModal}
                    setNestedModalData={setNestedModalData}
                    type={type}
                    setExporter={setData}
                    state={state}
                    setFilteredExporters={updateFilteredExporter}
                    filteredExporter={state.exporterList}
                  />
                )}
                {type === "add_new_exporter" && (
                  <ExporterAddForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setExporter={setData.setData}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    setExporterData={setExporterData}
                    handleNestedModalClose={handleNestedModalClose}
                  />
                )}
                {type === "exporter_search_product" && (
                  <ExporterSearchProduct
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setShowModal={setOpen}
                    setWarningMessage={setWarningMessage}
                    setShowNestedModal={setShowNestedModal}
                    setNestedModalData={setNestedModalData}
                    type={type}
                    baseCurrency={baseCurrency}
                    state={state}
                    setExporterProduct={setData}
                    updateFilteredProduct={updateFilteredProduct}
                    filteredProduct={state.productList}
                    exporterId={exporterId}
                    exporterOrder={exporterOrder}
                    addedProduct={addedProduct}
                    setAddedProduct={setAddedProduct}
                  />
                )}
                {type === "exporter_add_product_info" && (
                  <ExporterAddProductForm
                    formId={formId}
                    type={type}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setExporterProduct={setData.setData}
                    setAddedProduct={setData.setAddedProduct}
                    productId={setData.product_id}
                    exporterOrder={setData.exporterOrder}
                    exporterProduct={setData.exporterProduct}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    showNestedModal={showChildNestedModal}
                    setShowNestedModal={setShowChildNestedModal}
                    setNestedModalData={setNestedModalData}
                  />
                )}
                {type === "subcategory" && (
                  <SubCategoryForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    categoryId={setData.categoryId}
                  />
                )}
                {type === "exporter_product_view" && (
                  <ExporterProductViewForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    productData={productData}
                    navigate={navigate}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    baseCurrency={baseCurrency}
                    setShowModal={setOpen}
                    exporterOrder={exporterOrder}
                  />
                )}
                {type === "exporter_search_carrier" && (
                  <ExporterSearchCarrier
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setShowModal={setOpen}
                    setWarningMessage={setWarningMessage}
                    setShowNestedModal={setShowNestedModal}
                    setNestedModalData={setNestedModalData}
                    type={type}
                    state={state}
                    baseCurrency={baseCurrency}
                    setExporterCarrier={setData}
                    updateFilteredCarrier={updateCarrierLists}
                    filteredCarrier={state.carrierLists}
                    exporterId={exporterId}
                    exporterOrder={exporterOrder}
                    addedCarrier={addedCarrier}
                    setAddedCarrier={setAddedCarrier}
                    productQty={productQty}
                    receivedQty={receivedQty}
                  />
                )}
                {type === "exporter_add_carrier_info" && (
                  <ExporterAddCarrierForm
                    formId={formId}
                    type={type}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setExporterProduct={setData.setData}
                    setAddedCarrier={setData.setAddedCarrier}
                    carrierId={setData.carrierId}
                    exporterOrder={setData.exporterOrder}
                    exporterCarrier={setData.exporterCarrier}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    showNestedModal={showChildNestedModal}
                    setShowNestedModal={setShowChildNestedModal}
                    setNestedModalData={setNestedModalData}
                    updateModalTitle={updateModalTitle}
                    productQty={setData.productQty}
                    receivedQty={setData.receivedQty}
                  />
                )}
                {type === "exporter_carrier_info_view" && (
                  <ExporterCarrierInfoViewForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    carrierData={carrierData}
                    navigate={navigate}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    exporterOrder={exporterOrder}
                    baseCurrency={baseCurrency}
                  />
                )}
                {type === "exporter_payment_detail" && (
                  <ExporterPaymentDetailForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setWarningMessage={setWarningMessage}
                    setErrorFlashMessage={setErrorFlashMessage}
                    setSuccessFlashMessage={setSuccessFlashMessage}
                    state={state}
                    setShowModal={setOpen}
                    updateModalTitle={updateModalTitle}
                    exporterOrder={exporterOrder}
                    setPayment={setPayment}
                    baseCurrency={baseCurrency}
                  />
                )}
                {type === "carrier_pending_products" && (
                  <CarrierPendingProductView
                    formId={formId}
                    loadingCallback={loadingCallback}
                    state={state}
                    setShowModal={setOpen}
                    updateModalTitle={updateModalTitle}
                    productData={productData}
                    title={title}
                    total_due={total_due}
                  />
                )}
                {type === "exporter_carrier_product_list" && (
                  <ExporterCarrierProductListForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setSuccessFlashMessage={setSuccessFlashMessage}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    updateModalTitle={updateModalTitle}
                    exporterOrder={exporterOrder}
                    setPayment={setPayment}
                  />
                )}
                {type === "exporter_product_description_view" && (
                  <ExporterPaymentDescriptionForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setWarningMessage={setWarningMessage}
                    state={state}
                    setShowModal={setOpen}
                    updateModalTitle={updateModalTitle}
                    exporterOrder={exporterOrder}
                  />
                )}
                {type === 'productVariation' && (
                      <ProductVariationForm
                        state={state}
                        formId={formId}
                        loadingCallback={loadingCallback}
                        selectedItem={selectedItem}
                        variationData={variationData}
                        seteVariationData={seteVariationData}
                        setSelectedItem={setSelectedItem}
                        orderList={orderList}
                        setOrderList={setOrderList}
                        navigate={navigate}
                        setShowModal={setOpen}
                        isOpen={isOpen}
                        title={title}
                        setWarningMessage={setWarningMessage}
                        type={type}
                      />
                    )}

                    {/* add designation form */}
                      {type === "addDesignationModal" && (
                        <AddDesignationForm
                          loadingCallback={loadingCallback}
                          navigate={navigate}
                          setShowModal={setOpen}
                          isOpen={isOpen}
                          formId={formId}
                          title={title}
                          refetch={refetch}
                          formData={formData}
                          setFormData={setFormData}
                          setWarningMessage={setWarningMessage}
                          type={type}
                        />
                    )}
                    {/* edit designation form */}
                      {type === "edit_designation_modal" && (
                        <EditDesignationForm
                          formId={formId}
                          loadingCallback={loadingCallback}
                          navigate={navigate}
                          setWarningMessage={setWarningMessage}
                          state={state}
                          setShowModal={setOpen}
                          designationData={designationData}
                          formData={formData}
                          setFormData={setFormData}
                          designationId={designationData}
                          setOpen={setOpen}
                          refetch={refetch}
                        />
                  )}
                  {/* view designation form */}
                  {type === "view_designation_modal" && (
                    <DesignationDetailForm
                      formId={formId}
                      loadingCallback={loadingCallback}
                      navigate={navigate}
                      setWarningMessage={setWarningMessage}
                      state={state}
                      setShowModal={setOpen}
                      updateModalTitle={updateModalTitle}
                      designationId={designationId}
                      formData={formData}
                    />
                  )}
                  {/* add departmentn form */}
                  {type === "addDepartmentModal" && (
                        <AddDepartmentForm
                          loadingCallback={loadingCallback}
                          navigate={navigate}
                          setShowModal={setOpen}
                          isOpen={isOpen}
                          formId={formId}
                          title={title}
                          refetch={refetch}
                          formData={formData}
                          setFormData={setFormData}
                          setWarningMessage={setWarningMessage}
                          type={type}
                        />
                  )}
                  {/* view department form */}
                  {type === "view_department_modal" && (
                    <DepartmentDetailForm
                      formId={formId}
                      loadingCallback={loadingCallback}
                      navigate={navigate}
                      setWarningMessage={setWarningMessage}
                      state={state}
                      setShowModal={setOpen}
                      updateModalTitle={updateModalTitle}
                      departmentId={departmentId}
                      formData={formData}
                    />
                  )}
                  {/* edit department form */}
                  {type === "edit_department_modal" && (
                        <EditDepartmentForm
                          formId={formId}
                          loadingCallback={loadingCallback}
                          navigate={navigate}
                          setWarningMessage={setWarningMessage}
                          state={state}
                          setShowModal={setOpen}
                          departmentData={departmentData}
                          formData={formData}
                          setFormData={setFormData}
                          departmentId={departmentId}
                          setOpen={setOpen}
                          refetch={refetch}
                        />
                  )}
                  {/* warehouse creation form */}
                  {type === "create_warehouse_list" && (
                    <WareHouseListForm
                      formId={formId}
                      loadingCallback={loadingCallback}
                      setWarningMessage={setWarningMessage}
                      setOpen={setOpen}
                      isOpen={isOpen}
                      title={title}
                      type={type}
                      formData={formData}
                      setFormData={setFormData}
                    />
                  )}
                  {/* edit expense form */}
                {type === "view_warehouse_modal" && (
                  <WareHouseDetailsForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setWarningMessage={setWarningMessage}
                    wareHouseFormData={wareHouseFormData}
                    formData={formData}
                    setFormData={setFormData}
                    warehouseId={warehouseId}
                    setOpen={setOpen}
                    isOpen={isOpen}
                    refetch={refetch}
                    title={title}
                    warehouseData={warehouseData}
                  />
                )}
                {type === "edit_warehouse_modal" && (
                  <WareHouseEditForm
                    formId={formId}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setWarningMessage={setWarningMessage}
                    wareHouseFormData={wareHouseFormData}
                    formData={formData}
                    setFormData={setFormData}
                    warehouseId={warehouseId}
                    warehouseData={warehouseData}
                    setOpen={setOpen}
                    isOpen={isOpen}
                    refetch={refetch}
                    title={title}
                  />
                )}
                {/* add user details form */}
                {type === "addUserDataModal" && (
                        <AddUserDataForm
                          loadingCallback={loadingCallback}
                          navigate={navigate}
                          setShowModal={setOpen}
                          isOpen={isOpen}
                          formId={formId}
                          title={title}
                          formData={formData}
                          setFormData={setFormData}
                          type={type}
                          handleModalSubmit={handleModalSubmit}
                          handleChange={handleChange}
                        />
                )}
                {/* show print options */}
                {type === "choosePrintOption" && (
                        <ChoosePrintOptions
                          loadingCallback={loadingCallback}
                          navigate={navigate}
                          setShowModal={setOpen}
                          isOpen={isOpen}
                          formId={formId}
                          title={title}
                          //refetch={refetch}
                          //formData={formData}
                          //setFormData={setFormData}
                          //setWarningMessage={setWarningMessage}
                          type={type}
                          handlePrint={handlePrint}
                        />
                  )}

              </div>
            </div>
          </div>
        </div>
      )}
      {/* Render nested modal */}
      {showNestedModal && (
        <Modal
          isOpen={showNestedModal}
          setOpen={setShowNestedModal}
          setData={nestedModalData}
          setWarningMessage={setWarningMessage}
          title={nestedModalData.title}
          formId="nested_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type={nestedModalData.type}
          handleClose={handleNestedModalClose}
        />
      )}
      {showChildNestedModal && (
        <Modal
          isOpen={showChildNestedModal}
          setOpen={setShowChildNestedModal}
          setData={nestedModalData}
          setWarningMessage={setWarningMessage}
          title={nestedModalData.title}
          formId={nestedModalData.formId}
          loadingCallback={loadingCallback}
          navigate={navigate}
          type={nestedModalData.type}
          handleClose={handleNestedChildModalClose}
        />
      )}
    </>
  );
}
