import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ItemSelectBox from "./ItemSelectBox";
import ItemSelectBox2 from "./ItemSelectBox2";
import ReactDatePicker from "react-datepicker";
import SelectBox from "./Select";
import axios from "axios";
import moment from "moment";
import {Modal} from '../utils/Modal';

const AddExpenseTypeForm = (props) => {
  const [date, setDate] = useState(new Date());
  const {
    loadingCallback,
    navigate,
    // setShowPaymentModal,
    setShowModal,
    isOpen,
    title,
    setWarningMessage,
    type,
    //formData,
    //setFormData,
    refetch,
    hideModal,
  } = props;
  const token = localStorage.getItem("token");
  // const [txnCategoryList, setTxnCategoryList] = useState([])
  const [formData, setFormData] = useState({
    name: "",
    method_id: 0,
    account_id: 0,
    account_number: "",
    methodLabel: "",
    accountLabel: ""
  });
  // const handleSubmit = (data) => {
  //   loadingCallback(true);
  //   console.log("fData",formData)
  //   console.log("sData",data)
  //   const payload = { ...formData, ...data };
  //   axios.post(`${process.env.REACT_APP_API_URL}save-expense`, payload, {
  //     headers:{
  //       authorization: `Bearer ${token}`,
  //       "content-type":"application/json",
  //     }
  //   }).then((res) => {
  //       loadingCallback(false);
  //       if (res.data.success) {
  //         console.log(res)
  //       refetch();
  //       }
  //     }).catch(err=>{
  //       loadingCallback(false)
  //       console.log(err)
  //     })
  //   setShowModal(val=>!val)
  // };


  const handleSubmit = (data) => {
    loadingCallback(true);
    console.log("fData", formData);
    console.log("sData", data);

    // Adding expense_type_id = 1 to the payload
    const payload = { ...formData, ...data, type_id: 1 };

    axios.post(`${process.env.REACT_APP_API_URL}save-expense`, payload, {
        headers: {
            authorization: `Bearer ${token}`,
            "content-type": "application/json",
        },
    })
    .then((res) => {
        loadingCallback(false);
        if (res.data.success) {
            console.log(res);
            refetch();
        }
    })
    .catch((err) => {
        loadingCallback(false);
        console.log(err);
    });

    setShowModal((val) => !val);
};


  const [showPaymentModal, setShowPaymentModal] = useState(false);
  useEffect(() => {
    setShowPaymentModal(false);
  }, [hideModal]);
  const handleClicks = (data) => {
    setShowPaymentModal(true);
  };
  const [paymentData, setPaymentData] = useState([]);
  const getPayment = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data.success){
        loadingCallback(false)
        setPaymentData([...paymentData, response?.data?.data?.data[0]])
        //updatePaymentList(response?.data?.data);
        setPaymentList(response?.data?.data?.data);
        console.log("1st payment data: ", response?.data?.data?.data[0])
        console.log("payment list: ", response?.data?.data?.data)
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  useEffect(()=>{
    getPayment();
    getPaymentAccountList()
  },[])
  const [paymentList, setPaymentList] = useState([]);
  const [filteredAccountList, setFilteredAccountList] = useState([]);
  const [paymentAccountList, setPaymentAccountList] = useState([]);
  const [paidAmount, setPaidAmount] = useState(0.00);
  const [selectedPaymentAccountList, setSelectedPaymentAccountList] = useState([]);
  const getPaymentAccountList = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-category-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {

      if(response?.data.success){
        loadingCallback(false)
        setPaymentAccountList(response?.data?.data?.data);
        console.log(response?.data?.data?.data)
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  useEffect(() => {
    submitPaymentAmount(selectedPaymentAccountList);
  }, [selectedPaymentAccountList]);
  const submitPaymentAmount = async(selectedPaymentAccountList) => {
    if (selectedPaymentAccountList.length > 0) {
      setShowPaymentModal(!showPaymentModal);
      const paymentMap = selectedPaymentAccountList.map(item => ({
        payment_type_id: item?.payment_type_id,
        payment_type_category_id: item?.payment_type_category_id,
        payment_amount: item?.Amount,
      }));
      const data = {
        paid_amount: Number(paidAmount),
        payment_method: paymentMap
      }
      console.log("payment data", data);
      handleSubmit(data);
    }
  };
  // --------------------------
  const [expenseAddFormData, setExpenseAddFormData] = useState({});
  const [expenseAddForm, setExpenseAddForm] = useState(true);

  const handleSubmit1 = async (event) => {
    event.preventDefault();
    updateExpense2(formData);
  }

  const updateExpense2 = (formData) => {
    console.log("expense data", formData);
    setExpenseAddFormData(formData)
    setExpenseAddForm(false);
  }

  const handleCancelMethod = (id, par) => {
    if(paymentData.length>1){
      const indexToRemove = paymentData.findIndex((item, index) => index === par);
      if (indexToRemove !== -1) {
        const newData = [...paymentData];
        newData.splice(indexToRemove, 1);
        setPaymentData(newData);
      }
      const indexToRemove2 = paymentAccountAmount.findIndex((item, index) => index === par);
      if (indexToRemove2 !== -1) {
        const newData2 = [...paymentAccountAmount];
        newData2.splice(indexToRemove2, 1);
        setPaymentAccountAmount(newData2);
      }
    }
  };

  const [paymentAccountAmount, setPaymentAccountAmount] = useState([{
    methodName: '',
    AccountName: "",
    account_number: "",
    payment_type_id: '',
    payment_type_category_id: "",
    Amount: '',
  }]);

  const [methodIndex, setMethodIndex] = useState(null)

  const handleChangeSelect = (name, option) => {
    const { value, label } = option;
      console.log("Option", name, option);
      console.log("PaymentList", paymentAccountList, paymentList);
      if (name === 'payment_id') {
        if (label != 'Select Payment' && value) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            method_id: '',
            account_number: "",
          }));
          const selectedMethodObject = paymentList.find(
            (method) => method.id === value
          );
          if (selectedMethodObject) {
            setFilteredAccountList(selectedMethodObject.payment_type_category || []);
          } else {
            setFilteredAccountList([]);
          }
          const newArray = [...paymentData];
          newArray[methodIndex] = selectedMethodObject;
          setPaymentData(newArray);

          const newArray2 = [...paymentAccountAmount];
          newArray2[methodIndex] = {...amountData,
            payment_type_id: value,
            methodName: label,

          }
          setPaymentAccountAmount(newArray2);
        }
        if (label == 'Select Payment') {
          setFormData((prevFormData) => ({
            ...prevFormData,
            account_id: '',
            account_number: "",
          }));
        }
      }
      else if(name=='account_id'){
        if (label == 'Select Account') {
          setFormData((prevFormData) => ({
            ...prevFormData,
            account_id: '',
            account_number: "",
          }));
          const newArray = [...paymentAccountAmount];
          newArray[methodIndex] = {...amountData,
            account_number: "",
            AccountName: "",
            payment_type_category_id: ''
          }
          setPaymentAccountAmount(newArray);
          setFilteredAccountDetails({})
        }
        else{
          const selectedAccount = paymentAccountList.find(
            (method) => method.id === value
          );

          console.log("account",selectedAccount, methodIndex);
          if(selectedAccount){
            const newArray = [...paymentAccountAmount];
            newArray[methodIndex] = {...newArray[methodIndex],
              AccountName: label,
              account_number: selectedAccount?.account_number,
              payment_type_category_id: value
            }
            setPaymentAccountAmount(newArray);
            setFilteredAccountDetails(selectedAccount)
          }
        }
      }
   }

   const amountData = {
    methodName: "cash",
    AccountName: "",
    account_number: "",
    payment_type_id: "",
    payment_type_category_id: "",
    Amount: '',
  }

  const [filteredAccountDetails, setFilteredAccountDetails] = useState({})

  const handleAddMethod = async () =>{
    if(paymentData.length<paymentList.length){
      const selectedAccount = paymentList.find(
        (method) => method.type_name === "cash"
      );
      setPaymentData([...paymentData, selectedAccount])
      const newArray = [...paymentAccountAmount];
      let data = {...amountData,
        methodName: selectedAccount.type_name,
        payment_type_id: selectedAccount.id
      }
      setPaymentAccountAmount([...paymentAccountAmount, data])
    }
  }

  const handleSubmitPaymentWithPayment = (event) =>{
    event.preventDefault();
      updateProduct(expenseAddFormData)
  }

  const updateProduct = async (formData) => {
    // Adding expense_type_id to formData
    // formData.expense_type_id = 1;

    loadingCallback(true);
    console.log("All data", formData);

    axios.post(`${process.env.REACT_APP_API_URL}save-expense`, formData, {
        headers: {
            authorization: `Bearer ${token}`,
            "content-type": "application/json",
        },
    })
    .then((res) => {
        loadingCallback(false);
        if (res.data.success) {
            console.log(res);
            refetch();
        }
    })
    .catch((err) => {
        loadingCallback(false);
        console.log(err);
    });
};


  const handleSubmit2 = (e) =>{
    e.preventDefault();
    const uniqueNamesMap = new Set();

    const uniqueMap = paymentAccountAmount.reduce((acc, t) => {
      const key = `${t.payment_type_category_id}_${t.payment_type_id}`;

      if (!uniqueNamesMap.has(key) && Number(t.Amount)>=0 ) {
        uniqueNamesMap.add(key);

        const total = paymentAccountAmount.reduce((innerAcc, item) => {
          if (
            t.payment_type_category_id === item.payment_type_category_id &&
            t.payment_type_id === item.payment_type_id && Number(item.Amount)>0
          ) {
            return innerAcc + (Number(item.Amount));
          } else {
            return innerAcc;
          }
        }, 0);

        console.log("total Amount:", total);
        acc.push({ ...t, Amount: total });
      }

      return acc;
    }, []);

    console.log("patmentList", uniqueMap);

    const totalAmount = uniqueMap.reduce((acc, item) => {
      return acc + Number(item.Amount);
    }, 0);
    console.log(totalAmount);
    setPaidAmount(totalAmount);

    setSelectedPaymentAccountList(uniqueMap)
    //setShowModal(!isOpen)
  }

  // =========================
  const [txnTypeList, settxnTypeList] = useState([])

  useEffect(() => {
    getTxnTypeList()
  }, [])

  const getTxnTypeList = (page, searchKeyword) => {
    loadingCallback(true);
    axios(`${process.env.REACT_APP_API_URL}expense-type-list`, {
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
      }
    }).then((res) => {
      loadingCallback(false);
      if (res.data.success) {
        // const formattedData = res.data.data.map(item => ({
        //   label: item.transaction_category,
        //   value: item.id,
        // }));
        settxnTypeList(res.data.data)
        console.log("expense type list: ", res.data.data)
        //setTxnCategoryList(formattedData)
      }
    }).catch(err => {
      loadingCallback(false)
      console.log(err)
    })
  };

  const handleTxnTypeChange = (e) => {
    console.log("Selected Expense Type:", e);
    setFormData({
      ...formData,
      expense_name: e.label,
      expense_type_id: e.value
    })
  }

  return (
    <>
      {expenseAddForm ? (
        <form
            method="get"
            className="col-sm-12 min-h-[10vh]"
            data-autosubmit="false"
            autoComplete="off"
            onSubmit={handleSubmit1}
          >
          <div className="duration-500">

            <div className="w-full relative">
              <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
                Name
              </label>
              <input
                type="text"
                name="party_name"
                placeholder="Name"
                //value={formData.catogory_name || ''}
                onChange={(e) => setFormData({
                  ...formData,
                  catogory_name: e.target.value
                })}
                className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
              />
            </div>
            <div className="w-full relative mt-2">
              <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
                Date
              </label>
              <input
                type="date"
                name="party_name"
                placeholder="Date"
                //value={formData.transaction_date || ''}
                onChange={(e) => {
                  const selectedDate = e.target.value; // The value from date picker in YYYY-MM-DD format
                  setFormData({
                    ...formData,
                    transaction_date: selectedDate
                  });
                }}
                className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
              />
            </div>
            <div className="w-full relative mb-3">
              <label className=" font-nunito text-xs text-[#102048]  bg-white px-1 font-semibold">
                Expense Type
              </label>
                <SelectBox
                  name="txnCategoryType"
                  options={txnTypeList}
                  value={txnTypeList[0]}
                  onChange={handleTxnTypeChange}
                  label={false}
                  placeholder="Expense Type"
                  className="w-full bg-[#FFF] rounded-[16px] focus:outline-none customSelect py-[1px]"
                />
              </div>
            <div className="w-full relative">
              <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
                Amount
              </label>
              <input
                type="text"
                name="party_name"
                placeholder="0.00"
                onChange={(e) => setFormData({
                  ...formData,
                  amount: e.target.value
                })}
                className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
              />
            </div>
            <div className="w-full relative mt-2">
              <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                Description
              </label>
              <textarea
                type="text"
                name="description"
                //value={formData.description || ''}
                onChange={(e) => setFormData({
                  ...formData,
                  description: e.target.value
                })}
                placeholder="Text"
                className="input input-bordered border-[#C1CFEF] border-[1px] focus:outline-none  w-full  h-20 pt-2 rounded-xl bg-white resize-none"
              />
            </div>

            <div>
              <button
                htmlFor="my_modal_payment_info"
                // onClick={ () => handleClicks()}
                type="submit"
                className="cursor-pointer w-full mt-4 bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
              >
                Next
              </button>
            </div>
            {/* {showPaymentModal ? (
                  <Modal
                    isOpen={showPaymentModal}
                    setOpen={setShowPaymentModal}
                    purchaseAmount={formData.amount}
                    getData={paymentData}
                    listData={paymentList}
                    formData={formData}
                    setFormData={setFormData}
                    paymentAccountList={paymentAccountList}
                    setSelectedAccountList={setSelectedPaymentAccountList}
                    setData={setPaymentData}
                    setPaidAmount={setPaidAmount}
                    // setWarningMessage={setWarningMessage}
                    title="Payment Info"
                    formId="my_modal_payment_info"
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    type="payment-info"
                    selectType={type}
                    setFilteredAccountList={setFilteredAccountList}
                    filteredAccountList={filteredAccountList}
                  />
                ) : null} */}
          </div>
        </form>
      ) : (
        <>
          <form
          method="get"
          className="col-sm-12 min-h-[10vh]"
          data-autosubmit="false"
          autoComplete="off"
          // onSubmit={handleSubmitPaymentWithPayment}
          onSubmit= {handleSubmit2}
          //onKeyDown={handleKeyDown}
          >
            <div className="duration-500">
              <div class="flex flex-row justify-between items-center py-3 px-1">
                <h1 class="text-md font-semibold font-lato pb-1 px-1">Expense Amount</h1>
                <div class="">
                  <p class="text-sm px-[4px] py-[2px]">{formData.amount}</p>
                </div>
              </div>
              {paymentData.map((item, index) => (
                <div key={index} className="">
                  <div className="flex flex-row justify-between items-center py-3 px-1">
                    <h1 className="text-md font-semibold font-lato pb-1 px-1">
                      {item.type_name}
                    </h1>
                    {index != 0 && (
                      <div
                        className="bg-red-600 text-white rounded-md cursor-pointer"
                        onClick={() => handleCancelMethod(item.id, index)}
                      >
                        <p className="text-sm px-[4px] py-[2px]">Cancel</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="flex items-center justify-center py-3 gap-3"
                    onClick={() => setMethodIndex(index)}
                  >
                    <ItemSelectBox
                      name="payment_id"
                      options={paymentList}
                      value={formData.payment_id}
                      onChange={handleChangeSelect.bind(this, "payment_id")}
                      label={"Method Name"}
                      placeholder={item.type_name}
                      style={{ maxHeight: "300px", overflowY: "scroll" }}
                    />
                  </div>
                  {item.type_name != "cash" && (
                    <>
                      <div
                        className="flex items-center justify-center py-3 gap-3"
                        onClick={() => setMethodIndex(index)}
                      >
                        <ItemSelectBox
                          name="account_id"
                          options={filteredAccountList}
                          value={formData.account_id}
                          label={"Account Name"}
                          onChange={handleChangeSelect.bind(this, "account_id")}
                          placeholder="Select Account"
                        />
                      </div>

                      <div className="flex items-center justify-center py-3 gap-3">
                        <div className="w-full relative">
                          <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                            Account Number
                          </label>
                          <input
                            type="text"
                            name="name"
                            disabled
                            value={paymentAccountAmount[index].account_number}
                            className="input input-bordered border-2 border-gray-500 w-full mb-[10px] rounded-xl bg-white"
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="flex items-center justify-center py-2 gap-3">
                    <div className="w-full relative">
                      <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[14px] left-[12px] bg-white px-1 font-semibold">
                        Amount
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="000.0"
                        disabled={
                          item.type_name == "cash"
                            ? false
                            : paymentAccountAmount[index].account_number
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          setPaymentAccountAmount((prevAmount) => [
                            ...prevAmount.slice(0, index),
                            { ...prevAmount[index], Amount: e.target.value },
                            ...prevAmount.slice(index + 1),
                          ]);
                        }}
                        className="input input-bordered w-full mb-[10px] rounded-xl bg-white"
                      />
                    </div>
                  </div>
                  {paymentData.length > 1 && <hr />}
                </div>
              ))}
              <div className="flex flex-row justify-between items-center py-3 px-1">
                <div>
                  <h1 className="text-md font-semibold font-lato">
                    Pay with more Method
                  </h1>
                </div>
                <div>
                  <div
                    onClick={handleAddMethod}
                    className="flex justify-center items-center border-2 text-[#000] text-[18px] font-nunito font-bold py-[10px] px-[10px] cursor-pointer rounded-lg capitalize"
                  >
                    <i class="fa-solid fa-plus"></i>
                  </div>
                </div>
              </div>
              <div>
              <button
                htmlFor="my_modal_payment_info"
                // onClick={handleSubmit}
                type="submit"
                className="cursor-pointer w-full bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
              >
                Save
              </button>
            </div>

            </div>

          </form>
        </>
      )
      }


    </>
  );
};

export default AddExpenseTypeForm;
