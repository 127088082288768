import React, { useContext, useEffect, useRef, useState } from "react";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaCaretLeft, FaCaretRight, FaPause } from "react-icons/fa6";
import { BsThreeDots } from "react-icons/bs";
import { GoPlus } from "react-icons/go";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Swal from "sweetalert2";
import FormPayment from "../../../forms/FormPayment.js";
import BarcodeReader from "react-barcode-reader";
import DatePicker from "react-datepicker";
import { get } from "../../../services/apiService.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import warrantyIcon from "../../../assets/images/warranty-icon.png";
import ProductVariationIcon from "../../../assets/images/product-variation.png";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineSearch,
} from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { BeatLoader, ClockLoader } from "react-spinners";
import { Modal } from "../../../utils/Modal.js";
import "../OrderProduct.css";
//import categoryDymmyImaage from "../../assits/product1.png";
import { GrFormClose } from "react-icons/gr";
import Pagination from "../../../utils/pagination.js"; // Adjust the path accordingly
import { useFlashMessage } from "../../../context/FlashMessageContext.js";
import { AppContext } from "../../../context/context.js";

import cardIcon from "../../../assets/images/card.svg";
import moneyIcon from "../../../assets/images/coin-hand.svg";
import qrIcon from "../../../assets/images/qr_code.svg";
import wholeSale from "../../../assets/images/wholesale.png";
import { formatPrice, eclipse, formatNumber } from "../../../utils/Common.js";
import ItemSelectBox2 from "../../../forms/ItemSelectBox2.js";
import { FlashMessage } from "../../../utils/FlashMessage.js";
import { TopSaleProducts } from "./TopSaleProducts/index.jsx";
import { Helmet } from "react-helmet-async";
import DeliveryMethod from "./DeliveryMethod/index.jsx";
import InvoiceCustomerForm from "../../../forms/InvoiceCustomerForm.js";
import { useSelector } from "react-redux";
import { OrderTable } from "./order-table.jsx";
import PaymentTypeForm from "../../../forms/PaymentTypeForm.js";
import { BiBarcodeReader } from "react-icons/bi";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="p-[6px] rounded-full absolute top-[40%] -right-[8px]"
      style={{ ...style, display: "inline-block", background: "#7F8098" }}
      onClick={onClick}
    >
      <AiOutlineArrowRight className="text-[#FFF] text-base font-bold"></AiOutlineArrowRight>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="p-[6px] rounded-full absolute top-[40%] -left-[9px] z-50"
      style={{ ...style, display: "inline-block", background: "#7F8098" }}
      onClick={onClick}
    >
      <AiOutlineArrowLeft className="text-[#FFF] text-base font-bold"></AiOutlineArrowLeft>
    </div>
  );
}
const BASE_URL = process.env.REACT_APP_ROOT_URL;
const shop_logo = `${BASE_URL}/layoutlogo.svg`;

const NewSalesBilling = (props) => {
  const newSalesBillingState = useSelector((state) => state.newSalesBilling);

  const [testSearchKeyword, setTestSearchKeyword] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPaymentRightDiv, setShowPaymentRightDiv] = useState(false);

  const [customerList, setCustomerList] = useState([]);
  const user = localStorage.getItem("user");

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}search-customer?page=1&limit=20`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const data = res?.data?.data?.data;
        setCustomerList(data);
      })
      .catch((error) => console.error(error));
  }, []);

  // State to track which checkboxes are selected
  const [isSmsChecked, setIsSmsChecked] = useState(false);
  const [isDeliveryChecked, setIsDeliveryChecked] = useState(false);
  const [isSellerChecked, setIsSellerChecked] = useState(false);

  const productData = [
    {
      id: 1,
      name: "iPhone 12 Pro Max",
      barcode: "78765448",
      itemCode: "PN654548",
      stock: 120,
      price: 120000,
    },
    {
      id: 2,
      name: "iPhone 12 Pro Max",
      barcode: "65649448",
      itemCode: "6549448",
      stock: 120,
      price: 120000,
    },
    // Add more product objects here...
  ];

  const filteredData = productData.filter((product) =>
    product.name.toLowerCase().includes(testSearchKeyword.toLowerCase())
  );

  const [openMenuIndex, setOpenMenuIndex] = useState(null);

  // const toggleMenuPB = () => {
  //   setShowMenuPB(!showMenuPB);
  // };

  // Newly added state
  const [searchDiv, setSearchDiv] = useState(true);

  const [suggestedProducts, setSuggestedProducts] = useState([]);

  useEffect(() => {
    // Token for authorization
    const token = localStorage.getItem("token"); // Replace this with your actual token

    // Fetching data from the API with authorization header
    axios
      .get(`https://www.outletexpense.xyz/api/product`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the authorization header
        },
      })
      .then((response) => {
        setSuggestedProducts(response.data); // Assuming the data comes in 'response.data'
        setLoading(false); // Stop the loading spinner
      })
      .catch((err) => {
        // setError("Failed to fetch products"); // Set error state
        setLoading(false);
      });
  }, []);

  const { type } = props;
  const {
    state,
    setPaperSize,
    setDeliveryMode,
    updateBrandList,
    updateFilteredCustomer,
    updateFilteredWholeSaler,
    updateWarrantyList,
    updateCountryList,
    countryList,
  } = useContext(AppContext);

  const [paymentList, setPaymentList] = useState([]);

  const [deliveryList, setDeliveryList] = useState([]);

  const [deliveryAccountList, setDeliveryAccountList] = useState([]);
  const [searchVarientItem, setSearchVarientItem] = useState(""); //this is the state for search of imei of varient item//
  const [selectedImeis, setSelectedImeis] = useState([]); // Track selected IMEIs
  const [status, setStatus] = useState(1);
  const [transactionDate, setTransactionDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage(); // Retrieve the dispatch function from your state management library

  const { currentUser, invoiceSetting } = state;
  const [category, setCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [filterItem, setFilterItem] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [customer, setCustomer] = useState(null);

  const [wholeSaler, setWholeSaler] = useState(null);
  const [seller, setSeller] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [warningMessage, setWarningMessage] = useState(false);
  const [vat, setVat] = useState(
    invoiceSetting?.vat != "" ? Number(invoiceSetting?.vat) : 0
  );
  const [tax, setTax] = useState(
    invoiceSetting?.tax != "" ? Number(invoiceSetting?.tax) : 0
  );
  const [moodActive, setMoodActive] = useState(null);
  const [sizeActive, setSizeActive] = useState(null);
  const [sugarActive, setSugarActive] = useState(null);
  const [iceActive, setIceActive] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [pagination, setPagination] = useState([]);
  const [selectedFood, setSelectedFood] = useState(null);

  const [orderList, setOrderList] = useState([]);

  const [categoryData, setCategoryData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [moreDiscount, setMoreDiscount] = useState(0);
  const [totalMoreDiscount, setTotalMoreDiscount] = useState(0);
  const [selectedDiscountType, setSelectedDiscountType] = useState("");
  const [grandTotal, setGrandTotal] = useState(0);
  const [subTotalNew, setSubTotalNew] = useState(0);
  const [grandTotalAfterDiscount, setGrandTotalDiscounted] = useState(0);
  const [change, setChange] = useState(false);
  const [loader, setLoader] = useState(true);
  const [paymentAccountList, setPaymentAccountList] = useState([]);
  const [due, setDue] = useState(0.0);
  const [paidAmount, setPaidAmount] = useState(0.0);
  const [selectedProductWarrantyList, setSelectedProductWarrantyList] =
    useState([]);
  const [deliveryAmount, setDeliveryAmount] = useState(0);
  const [isHandToHand, setisHandToHand] = useState(false);
  const [saveNoRedirect, setSaveNoRedirect] = useState(false);
  const [imeis, setImeis] = useState([]);
  const [subtotalPurchasePrice, setSubtotalPurchasePrice] = useState(0); // Control subtotalPurchasePrice with state
  const [isMobile, setIsMobile] = useState(false);
  const MAX_MOBILE_SCREEN_WIDTH = 767;
  let subtotal = 0;
  // Calculate totals
  const token = localStorage.getItem("token");

  const getCountryList = () => {
    const page = 1;
    const perPage = 100;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}get-countries?page=${page}&limit=${perPage}`,
        {
          headers: {
            authorization: `Bearer ${token} `,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          updateCountryList(response.data.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (orderList.length == 0) {
      setSubTotalNew(0);
      setGrandTotal(0);
      setGrandTotalDiscounted(0);
      setPaidAmount(0);
      setTotalMoreDiscount(0);
      setMoreDiscount(0);
      setDue(0);
      setMoney("");
      setDeliveryAmount(0);
      return;
    }
    let discountTotal = 0;
    let subtotaln = 0;
    orderList.forEach((orderFood) => {
      let discount = 0;
      if (type == "purchase" || type == "wholesale") {
        discount = 0;
      } else {
        if (
          (orderFood?.discount_type === "Percentage" ||
            orderFood?.discount_type === "2") &&
          orderFood?.discount
        ) {
          discount = formatPrice(
            orderFood?.retails_price * (orderFood?.discount / 100)
          );
        } else {
          discount = orderFood?.discount;
        }
      }
      let subtotalPrice = 0;
      if (type == "purchase") {
        if (orderFood.variantsSum) {
          subtotalPrice = orderFood.variants.reduce(
            (s, item) => (s += Number(item.purchase_price)),
            0
          );
        } else {
          subtotalPrice = formatPrice(
            orderFood?.qty * (orderFood?.purchase_price - discount)
          );
        }
        subtotaln += Number(subtotalPrice);
      } else if (type == "wholesale") {
        subtotalPrice = formatPrice(
          orderFood?.qty * (orderFood?.wholesale_price - discount)
        );
        subtotaln += Number(subtotalPrice);
      } else {
        subtotalPrice = formatPrice(
          orderFood?.qty * (orderFood?.retails_price - discount)
        );
        subtotaln += Number(subtotalPrice);
      }

      //discountTotal += formatPrice(orderFood.current_stock * discount);
    });

    setSubTotalNew(subtotaln);
    //const vats = formatPrice(0.15 * subtotal);
    //const taxes = formatPrice(0.1 * subtotal);
    let vats = vat && vat > 0 ? (vat / 100) * subtotaln : 0;
    let taxes = tax && tax > 0 ? (tax / 100) * subtotaln : 0;
    //const vats = 0;
    //const taxes = 0;
    if (type === "purchase") {
      vats = 0;
      taxes = 0;
    }
    const grandTotalnew = formatPrice(subtotaln + Number(vats) + Number(taxes));
    setGrandTotal(grandTotalnew);
    setGrandTotalDiscounted(
      formatPrice(
        Number(grandTotalnew) +
          Number(deliveryAmount) -
          Number(totalMoreDiscount)
      )
    );
    // setGrandTotalDiscounted(formatPrice(Number(grandTotalnew) + Number(deliveryAmount)));
    // setPaidAmount(formatPrice(Number(grandTotalnew) - Number(totalMoreDiscount) - Number(due)));
    setDue(
      Number(grandTotalnew) +
        Number(deliveryAmount) -
        Number(paidAmount) -
        Number(totalMoreDiscount)
    );
  }, [orderList, totalMoreDiscount, paidAmount, deliveryAmount]);

  useEffect(() => {
    setDue(Number(grandTotalAfterDiscount) - paidAmount);
  }, [paidAmount]);

  const notify = (type, msg) => {
    if (type == "success") {
      toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
    }
    if (type == "error") {
      toast.error(msg, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
    }
  };

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH
    ) {
      setIsMobile(true);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const handleResize = () => {
    if (
      typeof window !== "undefined" &&
      document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const [showNavMenu, setShowNavMenu] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const [searchFilter, setSearchFilter] = useState({
    keyword: "",
    categoryId: false,
    subCategoryId: false,
    unitId: false,
    brandId: false,
    stockIn: false,
    stockOut: false,
    startDate: 0,
    endDate: new Date(),
  });

  //

  const handleButtonClick = () => {
    setShowNavMenu((prevShowNavMenu) => !prevShowNavMenu);
  };
  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowNavMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleChangePaidAmount = (value) => {
    //if value is number then set the value
    if (!isNaN(value)) {
      // if (value <= Number(grandTotalAfterDiscount)) {

      if (value[0] == "0") {
        value = String(value).slice(1, value?.length);
        setPaidAmount(value);
      } else {
        if (value > Number(grandTotalAfterDiscount)) {
          return;
        } else {
          setPaidAmount(value);
        }
      }
      setDue(Number(grandTotalAfterDiscount) - value);
      // }
      // else {
      //   return;
      // }
    } else {
      return;
    }
  };
  const handleChangeDeliveryAmount = (value) => {
    //if value is number then set the value
    if (!orderList.length) {
      showModalWarning("Please Select A product");
      return;
    }
    if (!/[0-9.]/.test(String(value).slice(-1))) {
      setDeliveryAmount(0);
      return;
    }
    const parseFlat = parseFloat(value);
    if (!isNaN(parseFlat) && parseFlat) {
      // if (value <= Number(grandTotalAfterDiscount)) {

      if (value[0] == "0") {
        value = String(value).slice(1, value?.length);
        setDeliveryAmount(value);
      } else {
        setDeliveryAmount(value);
      }
      // setGrandTotalDiscounted(Number(grandTotalAfterDiscount) + parseFloat(value));
      // }
      // else {
      //   return;
      // }
    } else {
      setDeliveryAmount(0);
    }
  };
  // useEffect(()=>{
  //   handleChangePaidAmount(paidAmount)
  // },[paidAmount])

  const handleChangeDiscount = (value) => {
    // if not integer then return
    if (isNaN(value)) {
      setMoreDiscount();
      return;
    }
    if (selectedDiscountType === "Percentage" || selectedDiscountType === "2") {
      // Check if the discount percentage is 100%
      if (value > 100) {
        // Set the discount equal to the grand total
        setTotalMoreDiscount(0);
        setMoreDiscount(0);
      } else {
        // Apply other percentage discount
        setTotalMoreDiscount(formatPrice(Number(grandTotal) * (value / 100)));
        setMoreDiscount(value);
      }
    } else {
      // If discount type is not percentage, set the discount directly
      //but discount should not be more than grand total
      if (value > Number(grandTotal)) {
        setTotalMoreDiscount(0);
        setMoreDiscount(0);
      } else {
        setTotalMoreDiscount(value);
        setMoreDiscount(value);
      }
    }
  };

  const handleChangeDiscountType = (e) => {
    const { name, value } = e.target;
    setSelectedDiscountType(value);
  };
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const navigate = useNavigate();

  const [money, setMoney] = useState("");

  let vats = 0;
  let taxes = 0;
  let discountTotal = 0;
  //let grandTotal = 0;
  const discountType = ["Fixed", "Percentage"];

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedValue, setSelectedValue] = useState("A4");
  const [firstTime, setfirstTime] = useState(true);
  const perPage = 15;

  const [prevSearchKeyword, setPrevSearchKeyword] = useState("");

  const getTopSaleProductlist = (page) => {
    if (
      searchFilter.keyword ||
      searchFilter.brandId ||
      searchFilter.categoryId ||
      searchFilter.subCategoryId ||
      searchFilter.unitId ||
      searchFilter.stockIn ||
      searchFilter.stockOut
    ) {
      // //setCurrentPage(1);
      // if (firstTime) {
      //   //loadingCallback(true);
      //   setfirstTime(false);
      // }
      // getFilteredProductlist(page, searchKeyword);
      //
      getFilteredProductlist2(page);
    } else {
      if (firstTime) {
        setLoader(true);
        //loadingCallback(true);
        setfirstTime(false);
      }
      //
      loadingCallback(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}top-sales?page=${page}&limit=${perPage}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setLoader(false);
          loadingCallback(true);
          if (response.data.success) {
            loadingCallback(false);
            setFilterItem(response?.data?.data?.data);
            setFilteredProducts(response?.data?.data?.data);
            const produ = response?.data?.data?.data;
            produ?.map((valu) => {});
            setPagination(response?.data?.data);
            setSelectedFood(response?.data?.data?.data[0]);
            if (response?.data?.data?.data[0]?.image_path) {
              setSelectedFoodImg(response?.data?.data?.data[0]?.image_path);
            }
          }
        })
        .catch((error) => {
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    }
  };
  useEffect(() => {
    // Check if the search keyword has changed
    const isSearchKeywordChanged = searchKeyword !== prevSearchKeyword;

    // Update the previous search keyword
    setPrevSearchKeyword(searchKeyword);

    // If it's a new search keyword, set the page to 1
    if (isSearchKeywordChanged) {
      setCurrentPage(1);
      setfirstTime(true);
    } else {
      // It's the same search keyword, proceed with your logic
      if (firstTime) {
        setfirstTime(false);
      }
      getTopSaleProductlist(currentPage, searchKeyword);
    }
  }, [currentPage, searchKeyword, prevSearchKeyword, firstTime]);

  useEffect(() => {
    // getProductData();
    const updateProduct = filterItem.filter((curElement) => {
      return curElement?.category_id === category;
    });
    //setFilteredProducts(updateProduct);
  }, [category, filterItem]);

  const [selectedPaymentAccountList, setSelectedPaymentAccountList] = useState(
    []
  );
  const [filteredAccountList, setFilteredAccountList] = useState({});

  const getPayment = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}payment-type-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setPaymentList(response?.data?.data?.data);
        }
      })
      .catch((error) => {
        navigate("/login");
      });
  };

  useEffect(() => {
    getPayment();
    getPaymentAccountList();
    getWarranty();
    getDelivery();
    getDeliveryAccountList();
  }, [currentPage]);

  const getPaymentAccountList = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}payment-type-category-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response?.data.success) {
          loadingCallback(false);
          setPaymentAccountList(response?.data?.data?.data);
        }
      })
      .catch((error) => {
        navigate("/login");
      });
  };

  const handleSelectChange = (event) => {
    setChange(true);
    setSelectedValue(event.target.value);
    setPaperSize(event.target.value);
  };

  const getFilteredProductlist2 = (page) => {
    const data = {
      keyword: searchFilter.keyword,
      categoryId: searchFilter.categoryId,
      subCategoryId: searchFilter.subCategoryId,
      unitId: searchFilter.unitId,
      brandId: searchFilter.brandId,
      stockIn: searchFilter.stockIn,
      stockOut: searchFilter.stockOut,
    };
    // check if data.keyword string length is greater than 2
    if (
      data.brandId || data.unitId
        ? data.keyword.length >= 2
        : data.keyword.length >= 3
    ) {
      setLoader(true);
      const token = localStorage.getItem("token");
      axios
        .post(
          `${process.env.REACT_APP_API_URL}search-product?page=${page}&limit=${perPage}`,
          // { keyword: searchKeyword },
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          //loadingCallback(false);
          setLoader(false);

          if (response.status === 200) {
            //setCategoryData(response.data.data.data);
            setFilterItem(response?.data?.data?.data);
            setFilteredProducts(response?.data?.data?.data);
            setPagination(response?.data?.data);
            setSelectedFood(response?.data?.data?.data[0]);
            if (response?.data?.data?.data[0]?.image_path) {
              setSelectedFoodImg(response?.data?.data?.data[0]?.image_path);
            }
          } else {
          }
        })
        .catch((error) => {
          loadingCallback(false);
          //setLoader(false)

          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);
            navigate("/login");
          }
        });
    }
  };

  useEffect(() => {
    // getFilteredProductlist2(currentPage);
    getTopSaleProductlist(currentPage);
  }, [searchFilter]);
  const showModalWarning = (msg, data = null) => {
    Swal.fire({
      title: msg,
      text: "",
      icon: "warning",
    });
  };

  const handleSelectedFood = (newItem, order = "increment") => {
    //
    const item = { ...newItem };
    // if(type != 'purchase' && (item?.stock_unit_no)===0 && isImeis ){
    //
    //   showModalWarning('This product is stockout!');
    //   return;
    // }
    if (
      item?.product_type != 'service' && type != "purchase" &&
      Number.parseInt(item?.current_stock ? item?.current_stock : 0) <= 0
    ) {
      showModalWarning("This product is stockout!");
      return;
    }

    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.id === item?.id
    );

    if (order == "decrement") {
      // Item already exists, update its current_stock
      const updatedOrderList = [...orderList];
      const parseQty = parseFloat(updatedOrderList[existingIndex].qty);
      if (parseQty <= 1) {
        handleDelete(existingIndex);
      } else {
        const initialVariationData = Array.from(
          { length: Number.parseInt(parseQty - 1) },
          () => ({
            ...variationForm,
            product_id: item.id,
            purchase_price: item.purchase_price,
            wholesale_price: item.wholesale_price,
            retails_price: item.retails_price,
          })
        );
        updatedOrderList[existingIndex].variantsSum = 0;
        updatedOrderList[existingIndex].variants = initialVariationData;
        updatedOrderList[existingIndex].qty = parseQty - 1;
        setOrderList(updatedOrderList);
      }
    } else {
      //const current_stock = item.current_stock;
      if (existingIndex !== -1) {
        // Item already exists, update its current_stock
        const updatedOrderList = [...orderList];
        const parseQty = parseFloat(updatedOrderList[existingIndex].qty);
        if (item?.product_type != 'service' && type != "purchase" && item.current_stock < parseQty + 1) {
          showModalWarning("This product is stockout!", item);
          return;
        } else {
          const initialVariationData = Array.from(
            { length: Number.parseInt(parseQty + 1) },
            () => ({
              ...variationForm,
              product_id: item.id,
              purchase_price: item.purchase_price,
              wholesale_price: item.wholesale_price,
              retails_price: item.retails_price,
            })
          );
          updatedOrderList[existingIndex].variantsSum = 0;
          updatedOrderList[existingIndex].variants = initialVariationData;
          updatedOrderList[existingIndex].qty = parseQty + 1;
          setOrderList(updatedOrderList);
        }
      } else {
        item.qty = 1;
        // Item doesn't exist, add it to the orderList with current_stock 1
        const initialVariationData = Array.from(
          { length: Number.parseInt(1) },
          () => ({
            ...variationForm,
            product_id: item.id,
            purchase_price: item.purchase_price,
            wholesale_price: item.wholesale_price,
            retails_price: item.retails_price,
          })
        );
        item.variants = initialVariationData;
        item.variantsSum = 0;
        let newOrderArr = [...orderList, item];
        setOrderList(newOrderArr);

        const value = {
          id: item?.id,
          warranty: [
            {
              id: "",
              product_id: item?.id,
              warranty_id: "",
              warranties_count: item.warranties_count
                ? item.warranties_count
                : "",
              name: item.warrenty ? item.warrenty : "",
            },
          ],
        };

        // if (item?.warranties_count !== null && item?.warrenty !== null) {
        if (
          selectedProductWarrantyList &&
          selectedProductWarrantyList?.length
        ) {
          const newWarrantyList = [...selectedProductWarrantyList, value];
          setSelectedProductWarrantyList(newWarrantyList);
        } else {
          setSelectedProductWarrantyList([value]);
        }
        //
        //   setSelectedProductWarrantyList([value]);
        // }
        // else{
        //
        // }
      }
      //item.current_stock -= 1;
      setSelectedFood(item);
      if (item?.image_path) {
        setSelectedFoodImg(item.image_path);
      }
    }
  };

  const handleSelectedFoodForImei = (newItem, order = "increment") => {
    //
    const item = { ...newItem };

    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.imei_id === item?.imei_id
    );

    if (order == "decrement") {
      // Item already exists, update its current_stock
      const updatedOrderList = [...orderList];
      const parseQty = parseFloat(updatedOrderList[existingIndex].qty);
      if (parseQty <= 1) {
        handleDelete(existingIndex);
      } else {
        const initialVariationData = Array.from(
          { length: Number.parseInt(parseQty - 1) },
          () => ({
            ...variationForm,
            product_id: item.id,
            purchase_price: item.purchase_price,
            wholesale_price: item.wholesale_price,
            retails_price: item.retails_price,
          })
        );
        updatedOrderList[existingIndex].variantsSum = 0;
        updatedOrderList[existingIndex].variants = initialVariationData;
        updatedOrderList[existingIndex].qty = parseQty - 1;
        setOrderList(updatedOrderList);
      }
    } else {
      //const current_stock = item.current_stock;
      if (existingIndex !== -1) {
        // Item already exists, update its current_stock
        const updatedOrderList = [...orderList];
        const parseQty = parseFloat(updatedOrderList[existingIndex].qty);
        if (item?.product_type != 'service' && type != "purchase" && item.stock_unit_no < parseQty + 1) {
          showModalWarning("This product is stockout!", item);
          return;
        } else {
          const initialVariationData = Array.from(
            { length: Number.parseInt(parseQty + 1) },
            () => ({
              ...variationForm,
              product_id: item.id,
              purchase_price: item.purchase_price,
              wholesale_price: item.wholesale_price,
              retails_price: item.retails_price,
            })
          );
          updatedOrderList[existingIndex].variantsSum = 0;
          updatedOrderList[existingIndex].variants = initialVariationData;
          updatedOrderList[existingIndex].qty = parseQty + 1;
          setOrderList(updatedOrderList);
        }
      } else {
        item.qty = 1;
        // Item doesn't exist, add it to the orderList with current_stock 1
        const initialVariationData = Array.from(
          { length: Number.parseInt(1) },
          () => ({
            ...variationForm,
            product_id: item.id,
            purchase_price: item.purchase_price,
            wholesale_price: item.wholesale_price,
            retails_price: item.retails_price,
          })
        );
        item.variants = initialVariationData;
        item.variantsSum = 0;
        let newOrderArr = [...orderList, item];
        setOrderList(newOrderArr);

        const value = {
          id: item?.id,
          warranty: [
            {
              id: "",
              product_id: item?.id,
              warranty_id: "",
              warranties_count: item.warranties_count
                ? item.warranties_count
                : "",
              name: item.warrenty ? item.warrenty : "",
            },
          ],
        };

        // if (item?.warranties_count !== null && item?.warrenty !== null) {
        if (
          selectedProductWarrantyList &&
          selectedProductWarrantyList?.length
        ) {
          const newWarrantyList = [...selectedProductWarrantyList, value];
          setSelectedProductWarrantyList(newWarrantyList);
        } else {
          setSelectedProductWarrantyList([value]);
        }
        //
        //   setSelectedProductWarrantyList([value]);
        // }
        // else{
        //
        // }
      }
      //item.current_stock -= 1;
      setSelectedFood(item);
      if (item?.image_path) {
        setSelectedFoodImg(item.image_path);
      }
    }
  };
  const [selectedFoodImg, setSelectedFoodImg] = useState(null);
  const handleSelectedFoodImage = (imgPath = "") => {
    setSelectedFoodImg(imgPath);
  };

  const handleSelectedFoodQtyHoverMove = (item, e) => {
    if (e.target.value.slice(-1) == ".") {
      const existingIndex = orderList.findIndex(
        (orderItem) => orderItem?.id === item?.id
      );
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].qty = 0;
      setOrderList(updatedOrderList);
    }
  };
  const variationForm = {
    product_id: "",
    name: "",
    serial: "",
    purchase_price: 0,
    wholesale_price: 0,
    retails_price: 0,
    discount_type: 0,
    quantity: 1,
  };

  const handleSelectedFoodQty = (item, e) => {
    const qty = e.target.value.startsWith("x")
      ? e.target.value.slice(1)
      : e.target.value;
    const parsedQty = qty;
    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.id === item.id
    );
    if (qty[0] == "0" && qty.length == 2 && qty[1] != ".") {
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].qty = parseFloat(qty);

      const initialVariationData = Array.from(
        { length: Number.parseInt(qty) },
        () => ({
          ...variationForm,
          product_id: item.id,
          purchase_price: item.purchase_price,
          wholesale_price: item.wholesale_price,
          retails_price: item.retails_price,
        })
      );
      // updatedOrderList[existingIndex].qty = qty;

      updatedOrderList[existingIndex].variants = initialVariationData;
      //
      setOrderList(updatedOrderList);
    } else if (qty && existingIndex !== -1 && !isNaN(qty)) {
      const existingProductIndex = filteredProducts.filter(
        (filteredItem) => filteredItem?.id === item?.id
      );
      // Item already exists, update its current_stock
      const updatedOrderList = [...orderList];
      // if (type != 'purchase' && existingProductIndex && existingProductIndex[0] && existingProductIndex[0].current_stock < qty) {
      if (
        item?.product_type != 'service' &&
        type != "purchase" &&
        existingProductIndex &&
        existingProductIndex[0] &&
        existingProductIndex[0].current_stock < qty
      ) {
        showModalWarning("This product is stockout!", item);
        return;
      } else {
        const initialVariationData = Array.from(
          { length: Number.parseInt(qty) },
          () => ({
            ...variationForm,
            product_id: item.id,
            purchase_price: item.purchase_price,
            wholesale_price: item.wholesale_price,
            retails_price: item.retails_price,
          })
        );
        updatedOrderList[existingIndex].qty = parseInt(qty);
        updatedOrderList[existingIndex].variants = initialVariationData;

        setOrderList(updatedOrderList);
      }
    } else {
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].qty = 0;
      updatedOrderList[existingIndex].variants = [];
      setOrderList(updatedOrderList);
    }
  };
  //

  const handleSelectedFoodPrice = (item, price, idx) => {
    const newPriceStr = String(price);
    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.id === item?.id
    );

    const newPrice = newPriceStr
      ? parseFloat(newPriceStr) / parseInt(item.qty)
      : 0;
    if (price && newPrice && existingIndex !== -1 && !isNaN(newPrice)) {
      const updatedOrderList = [...orderList];
      if (updatedOrderList[idx].variantsSum) {
        updatedOrderList[idx].variantsSum = 0;
      }
      // Only update when there is a valid number input
      updatedOrderList[idx].purchase_price = !isNaN(newPrice) ? newPrice : 0;

      setOrderList(updatedOrderList);
    } else {
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].purchase_price = 0;
      setOrderList(updatedOrderList);
      setSubtotalPurchasePrice(0);
    }
  };
  const handleSelectedFoodPriceBilling = (item, price, idx) => {
    const newPriceStr = String(price);
    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.id === item?.id
    );


    const newPrice = parseFloat(newPriceStr) / parseInt(item.qty);
    if (price && newPrice && existingIndex !== -1 && !isNaN(newPrice)) {
      const updatedOrderList = [...orderList];
      if (updatedOrderList[idx].variantsSum) {
        updatedOrderList[idx].variantsSum = 0;
      }
      updatedOrderList[idx].retails_price = newPrice + item.discount;
      setOrderList(updatedOrderList);
    } else {
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].retails_price = 0 + item.discount;
      setOrderList(updatedOrderList);
    }
  };

  const [paymentData, setPaymentData] = useState([]);

  const handleDeliveryClicks = () => {
    if (!orderList.length) {
      showModalWarning("Please Select A product");
      return;
    }
  };

  const handleClicks = (data) => {
    if (!orderList.length) {
      showModalWarning("Please Select A product");
      return;
    }
    // else{
    setShowPaymentModal(!showPaymentModal);
    setPaidAmount(0);
    let val = paymentList.find((items) => {
      return items?.type_name == data?.type_name;
    });
    setFilteredAccountList(val.payment_type_category);
    if (val) {
      setFormData({ ...formData, payment_id: data?.id });
      setMoney(data?.type_name);
    }
    if (showPaymentModal) {
      setPaymentData((prevPaymentData) => [...prevPaymentData, data]);

      // setSelectedPaymentAccountList([]);
    } else {
      setPaymentData([data]);
    }
    // }
  };

  const handlePurchesClicks = (active) => {
    if (active === "cash" || active === "card" || active === "qr") {
      return setMoney(active);
    }
  };

  const handleDelete = async (index) => {

    const newImeis = imeis.filter((e) => e.product_id !== orderList[index].id);
    setImeis(newImeis);
    // }

    const newOrderList = [...orderList];
    newOrderList.splice(index, 1); // Remove the item from the copied array
    setOrderList(newOrderList);

    const newWarrantyList = [...selectedProductWarrantyList];
    newWarrantyList.splice(index, 1);
    setSelectedProductWarrantyList(newWarrantyList);
  };

  const numberOfProducts = Math.min(filteredProducts.length - 2, 5); // Set a maximum number of 7 products

  let settings = {};

  if (isMobile) {
    settings = {
      className: "sliderArea w-[300px]",
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1, // Adjust slidesToShow based on the number of products
      slidesToScroll: 1, // Adjust slidesToScroll as well
      variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  } else {
    settings = {
      //className: "sliderArea w-[920px]",
      className:
        "sliderArea md:max-w-[550px] lg:max-w-[600px] 2xl:max-w-[800px]",
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2, // Adjust slidesToShow based on the number of products
      slidesToScroll: 3, // Adjust slidesToScroll as well
      variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  }

  const [deliveryFormData, setDeliveryFormData] = useState({
    deliveryName: "",
    deliveryId: "",
    companyName: "",
    companyId: "",
    customerName: "",
    customerAddress: "",
    customerPhone: "",
    deliveryFee: "",
  });



  const [formData, setFormData] = useState({
    name: "",
    method_id: 0,
    account_id: 0,
    account_number: "",
    methodLabel: "",
    accountLabel: "",
    type_name: "",
  });

  // const [paymentMap, setPaymentMap] = useState([])
  const handleSubmitOrder = async (value, isSave = false) => {
    if (change === false) {
      setSelectedValue("A4");
      setPaperSize("A4");
    }
    //const { pay_mode, paid_amount, sub_total } = isSave ? values : values.target.elements;
    // {
    //         "product_id": 1,
    //         "qty": 1,
    //         "price": 1,
    //         "mode": 1,
    //         "size": 1,
    //         "sales_id": 1
    //     },
    // Transform orderList array
    let transformedProductArray = orderList.map((item) => ({
      product_id: item?.id,
      qty: item.qty,
      price:
        type === "purchase"
          ? item?.purchase_price
          : type === "wholesale"
          ? item.wholesale_price
          : item?.discount_type === "Percentage" || item?.discount_type === "2"
          ? item.retails_price - item?.retails_price * (item.discount / 100)
          : item?.retails_price - item?.discount,
      mode: 1,
      size: 1,
      sales_id: 1,
      imei_id: item?.imei_id ? item?.imei_id : null,
    }));
    let totalPrice = transformedProductArray.reduce((total, item) => {
      // Calculate the total price for each item
      const itemTotalPrice = item.qty * item.price;

      // Add the item's total price to the overall total
      return total + itemTotalPrice;
    }, 0);
    const vatsN = vat && vat > 0 ? (vat / 100) * totalPrice : 0;
    const taxN = tax && tax > 0 ? (tax / 100) * totalPrice : 0;
    const sub_total_c = totalPrice - Number(totalMoreDiscount) + vatsN + taxN;

    const paymentMap = selectedPaymentAccountList.map((item) => ({
      payment_type_id: item?.payment_type_id,
      payment_type_category_id: item?.payment_type_category_id,
      payment_amount: item?.Amount,
    }));

    const defaultWarrantyList = selectedProductWarrantyList?.flatMap((item) => {
      // const { id, warranty } = item;
      return item?.warranty?.map((warrantyItem) => ({
        product_id: item?.id,
        warranty_id: warrantyItem?.id,
        default_warranties_count: warrantyItem?.warranties_count,
      }));
    });

    if (!selectedPaymentAccountList.length) {
      showModalWarning("Select At-least one payment method!");
      return;
    }

    let variationsData = [];
    const uniqueProduct = new Set();
    if (type == "purchase") {
      variationsData = orderList.flatMap((item) => {
        return item.variants
          .filter((t) => t.serial.length)
          .map((t) => ({
            product_id: t.product_id,
            name: t.name,
            serial: t.serial,
            purchase_price: t.purchase_price,
            wholesale_price: t.wholesale_price,
            retails_price: t.retails_price,
            discount_type: 0,
            quantity: 1,
          }));
      });
      variationsData.map((item) => {
        if (!uniqueProduct.has(item.product_id)) {
          uniqueProduct.add(item.product_id);
        }
      });

      transformedProductArray = transformedProductArray.filter(
        (item) => !uniqueProduct.has(item.product_id)
      );
      totalPrice = transformedProductArray.reduce((total, item) => {
        const itemTotalPrice = item.qty * item.price;
        return total + itemTotalPrice;
      }, 0);
      totalPrice += variationsData.reduce((total, item) => {
        return total + Number(item.purchase_price);
      }, 0);
    }
    if (type == "billing") {
      const uniqueProductIds = new Set();
      /*       transformedProductArray = transformedProductArray.filter(item => {
        if (!uniqueProductIds.has(item.product_id)) {
          uniqueProductIds.add(item.product_id);
          return true;
        }
        return false;
      }); */

      transformedProductArray = transformedProductArray.filter((item) => {
        // If item has imei_id, always include it, even if product_id already exists
        if (item.imei_id) {
          return true;
        }

        // Otherwise, check if the product_id already exists in the Set
        if (!uniqueProductIds.has(item.product_id)) {
          uniqueProductIds.add(item.product_id);
          return true;
        }

        // If product_id exists and item does not have imei_id, filter it out
        return false;
      });
    }
    const transformImeis = imeis.map((e) => e.id);

    const data = {
      pay_mode: money,
      paid_amount: Number(paidAmount),
      //sub_total: sub_total_c,
      sub_total: totalPrice,
      vat:
        vat && vat > 0
          ? Number(formatNumber((vat / 100) * Number(totalPrice)))
          : 0,
      tax:
        tax && tax > 0
          ? Number(formatNumber((tax / 100) * Number(totalPrice)))
          : 0,
      discount: Number(totalMoreDiscount),
      product: transformedProductArray,
      delivery_method_id: newSalesBillingState.posDeliveryFormData?.deliveryId,
      delivery_info_id: newSalesBillingState.posDeliveryFormData?.companyId,
      delivery_customer_name:
        newSalesBillingState.posDeliveryFormData?.customerName,
      delivery_customer_address:
        newSalesBillingState.posDeliveryFormData?.customerAddress,
      delivery_customer_phone:
        newSalesBillingState.posDeliveryFormData?.customerPhone,
      delivery_fee: deliveryAmount,
      payment_method: paymentMap,
      warranty: defaultWarrantyList,
      variants: variationsData,
      imeis: transformImeis,
      created_at: transactionDate,
    };

    //
    if (type === "purchase") {
      data.vendor_id = vendor?.id;
      data.vendor_name = vendor.name;
      data.vat = 0;
      data.tax = 0;
      //data.sales_id = seller?.id ? seller?.id : currentUser.id;
    } else {
      if (type === "wholesale") {
        data.wholeseller_id = wholeSaler ? wholeSaler?.id : null;
        data.wholeseller_name = wholeSaler ? wholeSaler?.name : null;
      } else {
        data.customer_id = customer ? customer?.id : null;
        data.customer_name = customer ? customer?.name : null;
      }
      if (currentUser) {
        data.sales_id = seller?.id ? seller?.id : currentUser.id;
      } else {
        data.sales_id = seller?.id ? seller?.id : null;
      }
    }
    if (type == "billing" || type == "wholesale") {
      data.status = value;
    }
    if (type == "wholesale") {
      data.sales_type = "wholesale";
    }

    const url =
      type === "purchase"
        ? "save-purchase"
        : type === "booking"
        ? "save-advance-sales"
        : "save-sales";
    setDeliveryMode(deliveryFormData.deliveryName);
    loadingCallback(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}${url}`, data, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          // setInvoice(response.data.data);

          setOrderList([]);
          if (type === "purchase") {
            navigate(`/purchase-invoice/${response.data.data.invoice_id}`);
          } else if (type === "booking") {
            if (value) {
              navigate(`/advance-bookings/${response.data.data.invoice_id}`);
            } else {
              notify(
                "success",
                "Order submitted successfully.Go to booking list to update"
              );
            }
          } else {
            if (isSave === false && value === 1) {
              if (type === "wholesale") {
                navigate(`/wholesale-invoice/${response.data.data.invoice_id}`);
              } else {
                if (
                  type === "booking" &&
                  saveNoRedirect &&
                  saveNoRedirect === 1
                ) {
                  notify(
                    "success",
                    "Order submitted successfully.Go to booking list to update"
                  );
                } else {
                  if(isSmsChecked){
                    sendSMS(totalPrice, user?.phone, newSalesBillingState?.posDeliveryFormData?.customerName,
                      newSalesBillingState.posDeliveryFormData?.customerPhone, user.outlet_name, response.data.data.invoice_id );
                  }
                  navigate(`/invoice/${response.data.data.invoice_id}`);
                }
              }
            } else {
              setStatus(1);
              if (type === "wholesale") {
                setWholeSaler(null);
              } else {
                setCustomer(null);
              }
              setSeller(null);
              if (type === "booking") {
                // notify('success', 'Order submitted successfully.Go to booking list to update')
                FlashMessage(
                  "Order submitted successfully.Go to booking list to update",
                  "success"
                );
                // setSuccessFlashMessage("Order submitted successfully.Go to booking list to update");
              } else {
                if (type === "wholesale") {
                  // notify('success', 'Order submitted successfully.Go to hold wholesale invoice list to update')
                  FlashMessage(
                    "Order submitted successfully.Go to hold wholesale invoice list to update",
                    "success"
                  );
                  // setSuccessFlashMessage("Order submitted successfully.Go to hold wholesale invoice list to update");
                } else {
                  // notify('success', 'Order submitted successfully.Go to hold list to update')
                  FlashMessage(
                    "Order submitted successfully.Go to hold list to update",
                    "success"
                  );
                  window.location.reload();
                  // setSuccessFlashMessage("Order submitted successfully.Go to hold list to update");
                }
              }
            }
          }
        } else {
          if (response.data.message) {
            // setErrorFlashMessage('something went wrong!');
            FlashMessage("something went wrong!", "error");
          }
          if (response.data.errors) {
            // setErrorFlashMessage(response.data.errors?.customer_id[0]);
            FlashMessage(response.data.errors?.customer_id[0], "error");
          }
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  const sendSMS = (totalPrice, userPhone, customerName, customerPhhone, shopName, invoiceID ) => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}send-sms`,
        {
          amount: totalPrice,
          user_phone: userPhone,
          customer_name: customerName,
          customer_phone: customerPhhone,
          shop_name: shopName,
          invoice_id: invoiceID,
          date: new Date()
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data)
        if(res.data.success)
          return true;

      })
      .catch((error) => console.error(error));
  }

  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const shouldRenderPagination = pagination.total > perPage;
  const [showQuickProductAddForm, setShowQuickProductAddForm] = useState(false);
  const handleQuickProductFormClick = () => {
    setShowQuickProductAddForm(!showQuickProductAddForm);
  };

  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => {
    setShowModal(!showModal);
    getCountryList();
  };

  const [showModalWholeSaler, setShowModalWholeSaler] = useState(false);
  const handleOpenWholesaler = () =>
    setShowModalWholeSaler(!showModalWholeSaler);
  const [showModalSeller, setShowModalSeller] = useState(false);
  const handleOpenSeller = () => setShowModalSeller(!showModalSeller);
  const [showModalVendor, setShowModalVendor] = useState(false);
  const handleOpenVendor = () => setShowModalVendor(!showModalVendor);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const handleOpenPayment = () => setShowPaymentModal(!showPaymentModal);

  // Gneral Focus Hook
  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };

  const [inputRef, setInputFocus] = useFocus();
  const [filteredCustomers, setFilteredCustomers] = useState(
    state.customerList
  );
  //
  const [filteredWholeSaler, setFilteredWholeSaler] = useState(
    state.wholeSalerList
  );
  const eclipse = (str, length) => {
    return str && str.length > 0 && str.length > length
      ? str.substring(0, length) + "..."
      : str;
  };
  const modifiedFilteredProducts = [...filteredProducts];
  const filteredWithoutSelected = modifiedFilteredProducts.filter(
    (item) => item?.id !== selectedFood?.id
  );

  filteredWithoutSelected.unshift(selectedFood);
  useEffect(() => {
    getSellerListData();
  }, []);
  const [filteredSeller, setFilteredSeller] = useState([{ id: 1 }]);
  const [showSeller, setShowSeller] = useState(false);

  const getSellerListData = async (searchKeyword = "") => {
    //loadingCallback(true);
    setFilteredSeller([]);
    const page1 = 1;

    const options = [{ id: "", name: "Select" }];
    axios
      .get(`${process.env.REACT_APP_API_URL}employee`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          const sellers = response.data.data.data;
          // Append other options if there are sellers
          if (sellers.length > 0) {
            const sellerOptions = sellers.map((seller) => ({
              id: seller?.id,
              employee_type: seller.employee_type,
              name: seller.name,
              email: seller.email,
              country_code: seller.country_code,
              mobile_number: seller.mobile_number,
              address: seller.address,
              country: seller.country,
              district: seller.district,
              city: seller.city,
              zipcode: seller.zipcode,
              nid: seller.nid,
              joining_date: seller.joining_date,
              blood_group_id: seller.blood_group_id,
              emergency_contact_name: seller.emergency_contact_name,
              emergency_country_code: seller.emergency_country_code,
              emergency_mobile_numbers: seller.emergency_mobile_numbers,
              relation_with: seller.relation_with,
              status: seller.status,
            }));
            options.push(...sellerOptions);
            setShowSeller(true);
          } else {
            setSeller(currentUser ? currentUser?.id : null);
          }
        } else {
        }
        setFilteredSeller(options);
      })
      .catch((error) => {
        loadingCallback(false);

        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  const minWidth = 80; // Set a reasonable minimum width
  const inputStyle = {
    width: `${Math.max(paidAmount.length * 10 + 30, minWidth)}px`,
  };

  const [barcodeRead, setBarcodeRead] = useState("");

  const handleScan = (data) => {
    if (data) {
      //setBarcodeRead(data);
    }
  };

  const handleError = (error) => {
    setBarcodeRead(error);
  };

  const fetchProductData = (productId) => {
    //loadingCallback(true);
    // Make an API request to fetch product data by ID
    // Once data is retrieved, update the form fields with the data
    get("product-details/" + productId, token)
      .then((response) => {
        const productData = response.data; // Replace with your API response data

        loadingCallback(false);
        //setProductData(productData);
        handleSelectedFood(productData);
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };

  const [methodIndex, setMethodIndex] = useState(null);

  useEffect(() => {
    if (barcodeRead.length > 0) {
      fetchProductData(barcodeRead);
    }
  }, [barcodeRead]);

  // useEffect(() => {
  //   if (status===0) {
  //     handleSubmitOrder();
  //   }
  // }, [status]);

  const handleChangeSelect = (name, option) => {
    const { value, label } = option; //some error in this code.
    setDeliveryFormData({
      ...deliveryFormData,
      deliveryId: value,
      deliveryName: label,
    });
  };

  useEffect(() => {
    if (deliveryList?.length) {
      let selectInitial = deliveryList.find(
        (item) => (item?.type_name).toLowerCase() == "hand-to-hand"
      );

      setDeliveryFormData({
        ...deliveryFormData,
        deliveryId: selectInitial?.id,
        deliveryName: selectInitial?.type_name,
      });
    }
  }, [deliveryList]);

  const getDelivery = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}delivery-method-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setDeliveryList(response.data.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
      });
  };

  const getDeliveryAccountList = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}delivery-info-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setDeliveryAccountList(response.data.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
      });
  };

  const getWarranty = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}get-warranty-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setWarrantyList(response.data.data.data);
          updateWarrantyList(response.data.data);
          // setSelectedProductWarrantyList(response.data.data.data)
        }
      })
      .catch((error) => {
        navigate("/login");
      });
  };

  const [warrantyModal, setWarrantyModal] = useState(false);
  const [productVariation, setProductVariation] = useState(false);
  const [warrantyList, setWarrantyList] = useState([]);

  const [selectedItem, setSelectedItem] = useState({});
  const handleImeisSelect = (imei, selectedFood) => {
    const newItems = { ...selectedFood };
    newItems.checked = 1;
    //
    //newItems.retails_price = imei.sale_price;
    newItems.retails_price =
      imei?.currency_rate !== null
        ? Number.parseFloat(imei?.sale_price).toFixed(2)
        : Number.parseFloat(imei?.sale_price).toFixed(2);
    newItems.currency_code =
      imei?.currency_rate !== null
        ? imei?.base_currency_code
        : imei.purchase_currency_code;
    //newItems.purchase_price = imei.purchase_price;

    newItems.purchase_price =
      imei?.currency_rate !== null
        ? Number.parseFloat(imei?.purchase_price).toFixed(2)
        : Number.parseFloat(imei?.purchase_price).toFixed(2);
    newItems.imei_id = imei.id;
    const findImei = imeis.find((e) => e.id === imei.id);
    if (findImei) {
      handleSelectedFoodForImei(newItems, "decrement", 1);
      const newImeis = imeis.filter((e) => e.id !== imei.id);
      // const transformArray =  newImeis.map( e=>({ id: e.id, product_id: e.product_id}))
      setImeis(newImeis);
    } else {
      handleSelectedFoodForImei(newItems, "increment", 1);
      setImeis((e) => [...e, { id: imei.id, product_id: imei.product_id }]);
    }
  };

  const handleSelectedImeis = (item) => {
    setSelectedFood(item);
    if (item?.image_path) {
      setSelectedFoodImg(item.image_path);
    }
  };

  useEffect(() => {}, [imeis]);

  // Filter the IMEIs based on the search term
  const filteredImeis = selectedFood?.imeis.filter((imei) =>
    imei?.imei.includes(searchVarientItem)
  );
  const toggleImeiSelection = (imei) => {
    const isSelected = selectedImeis.includes(imei.id);
    const updatedSelections = isSelected
      ? selectedImeis.filter((id) => id !== imei.id)
      : [...selectedImeis, imei.id];

    setSelectedImeis(updatedSelections);
    handleImeisSelect(imei, selectedFood); // You might need to update this logic if you handle selection differently
  };

  const [showNestedModal, setShowNestedModal] = useState(true);

  return (
    <div className="">
      <Helmet>
        <title>Outlet Expense | New Sales Billing</title>
      </Helmet>
      <BarcodeReader onScan={handleScan} onError={handleError} />
      <ToastContainer />
      <div className="flex gap-x-[2%] flex-col md:flex-row">
        <div className="relative w-full md:w-[65%] p-3 box-border shadow-xs bg-[#fff] rounded-[15px] mb-4 md:!mb-0">
          <div className="pt-0 slider-area">
            <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4 w-full">
              <div className="relative w-full flex-1">
                {/* <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span> */}

                <InvoiceCustomerForm
                  className="col-sm-12"
                  formId="my_modal_2"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  setData={setCustomer}
                  // setCustomer={setCustomer}
                  setWarningMessage={setWarningMessage}
                  state={state}
                  // setShowModal={()=>{}}
                  filteredCustomers={state.customerList} // or any fallback, if necessary
                  setFilteredCustomers={updateFilteredCustomer} // from context
                  showNestedModal={showNestedModal}
                  setShowNestedModal={setShowNestedModal}
                  setNestedModalData={setCustomerList}
                  pageDesign="newSalesBilling"
                  notify={notify}
                />
              </div>

              <div className="flex-1 w-full flex col-sm-12">
                <div className="flex-1 flex items-center justify-center relative w-full rounded-[8px]">
                  <input
                    autoFocus
                    type="text"
                    // className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[17px] pr-[45px] pl-[21px] pb-[15px] placeholder:text-[#91919F]"
                    className="input input-bordered rounded-[8px] w-[100%] pt-[14px] pr-[45px] pl-[35px] pb-[12px] placeholder:text-[#A3A3A3] placeholder:text-[12px] "
                    placeholder="Item Name/Barcode/Item code"
                    // onChange={(e) => setSearchKeyword(e.target.value)}

                    onChange={(e) =>
                      // getFilteredProductlist2(1, e.target.value)
                      {
                        setSearchFilter({
                          ...searchFilter,
                          keyword: e.target.value,
                        });
                        setShowPopup(e.target.value.length > 0);
                      }
                    }
                  />

                  {showPopup && (
                    <div className="absolute top-[60px] w-full bg-white shadow-lg rounded-lg z-10 max-h-96 overflow-y-scroll">
                      <div className="p-3">
                        <p className="font-semibold">Search Result</p>
                        {modifiedFilteredProducts.length > 0 ? (
                          modifiedFilteredProducts.map((product) => (
                            <div
                              onClick={() => {
                                handleSelectedFood(product);
                              }}
                              key={product.id}
                              className="border-b py-2 flex items-center cursor-pointer"
                            >
                              <img
                                src={
                                  product?.image_path
                                    ? product?.image_path
                                    : shop_logo
                                } // Placeholder image
                                alt={product.name}
                                className="w-[40px] h-[40px] rounded"
                              />
                              <div className="ml-3">
                                <p className="font-semibold">
                                  {product.name} - {product?.sub_category?.name}
                                </p>
                                <p className="text-sm text-gray-500">
                                  Barcode: {product?.barcode} | Item Code:{" "}
                                  {product?.id} | Stock:{" "}
                                  {product?.current_stock}
                                </p>
                                <p className="text-sm text-gray-500">
                                  Price: {product?.purchase_price}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No results found.</p>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="absolute top-[37%] left-[10px] ">
                    <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                  </div>
                  {/* barcode icon */}
                  <div className="absolute top-[36%] right-[10px]">
                    <BiBarcodeReader />
                  </div>
                  {/* Filter icon and category codes are removed */}
                </div>

                <label
                  htmlFor="my_modal_product"
                  onClick={handleQuickProductFormClick}
                  className=" w-[55px] h-[56px] rounded-[10px] bg-[#407BFF] text-center ml-2 cursor-pointer flex items-center justify-center"
                >
                  <GoPlus className="text-white text-lg" />
                </label>
              </div>
              {/* </div> */}
            </div>

            <div className="flex justify-between md:flex-row flex-col px-0 md:px-4 mt-3">
              <div>
                <p className="text-[#2D3748] font-nunito text-sm font-bold mb-3">
                  Point Of Sale
                </p>
              </div>
            </div>
            {loading && (
              <div className="custom-loader-logo">
                {/* <BeatLoader color={"#123abc"} size={20} /> */}
                <img
                  className=""
                  src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
                  alt="logo"
                />
              </div>
            )}

            {/* Start Exp. */}

            {searchKeyword &&
            filteredProducts?.length > 0 &&
            filteredProducts?.length < 5 ? (
              <>
                <div className="searchList sliderArea">
                  {loader && (
                    <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
                      {" "}
                      <div className="custom-loader"></div>{" "}
                    </div>
                  )}

                  {!loader
                    ? filteredProducts?.map((item, index) =>
                        item?.imeis?.length > 0 ? (
                          <div
                            key={index}
                            // onClick={() => {handleSelectedFood(item)}}
                            onClick={
                              item?.imeis?.length
                                ? () => {
                                    handleSelectedImeis(item);
                                  }
                                : () => {
                                    handleSelectedFood(item);
                                  }
                            }
                            className="flex flex-col gap"
                          ></div>
                        ) : (
                          ""
                        )
                      )
                    : null}
                </div>
              </>
            ) : (!loader && searchKeyword && filteredProducts.length > 3) ||
              (!loader && filteredProducts.length > 3) ? (
              <div>
                <div className="parent-sliderArea mb-[40px] md:mb-[0px]"></div>
              </div>
            ) : (
              ""
            )}
          </div>

          {/* End */}

          <div className="">
            {/* Top Sale products list based on Search ----> The backup file is saved in another file named "backupSearchedTopSaleProducts.js" */}

            {/* Point of sale bottom div start */}
            <div className="">
              <div className="mt-[10px] mb-5 w-[100%]">
                {orderList && (
                  <OrderTable
                    orderList={orderList}
                    setOrderList={setOrderList}
                    type={type}
                    formatPrice={formatPrice}
                    subtotal={subtotal}
                    tax={tax}
                    handleSelectedFood={handleSelectedFood}
                    handleSelectedFoodQty={handleSelectedFoodQty}
                    handleSelectedFoodQtyHoverMove={
                      handleSelectedFoodQtyHoverMove
                    }

                  />
                )}
                <div className="sticky z-10 bottom-2 bg-white mt-2 space-y-3">
                  {orderList.length > 0 &&
                    (type === "booking" ||
                      type === "billing" ||
                      type === "wholesale") && (
                      <div className=" mt-5 grid grid-cols-3 gap-4 pt-2">
                        {/* Select Discount Type */}
                        <div className="w-full">
                          <select
                            name="discount_type"
                            onChange={handleChangeDiscountType}
                            className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px] py-2.5 text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                          >
                            <option disabled="">Select Discount Type</option>
                            {discountType.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Discount Input */}
                        <div className="w-full">
                          <input
                            type="text"
                            className="w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px] py-2.5 text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                            placeholder="Discount"
                            value={moreDiscount}
                            onChange={(e) =>
                              handleChangeDiscount(e.target.value)
                            }
                            name={"discount"}
                          />
                        </div>

                        {/* Transaction Date */}
                        <div className="w-full relative">
                          <div className="relative border-[#C1CFEF] py-2.5 border w-full flex-1 rounded-[10px]">
                            <DatePicker
                              selected={transactionDate}
                              onChange={(date) => setTransactionDate(date)}
                              dateFormat="dd/MM/yyyy"
                              className="w-full px-4 focus:outline-none"
                            />
                            <span className="bg-white absolute left-4 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                              Transaction Date
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                  {/*Start ---------------------- Newly added  POS calculation ---------------------- */}
                  <div className="flex w-full justify-between items-start">
                    <div className="w-full grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-3 overflow-auto px-auto">
                      <div className="flex flex-col space-y-2 justify-between">
                        <div>
                          <h2 className="text-[#737373] font-medium ">
                            Quantity
                          </h2>
                        </div>
                        <div>
                          <h1 className="text-[#0A0A0A] font-bold text-2xl">
                            {orderList?.length}
                          </h1>
                        </div>
                      </div>
                      <div className="flex flex-col space-y-2 justify-between">
                        <div>
                          <h1 className="text-[#737373] font-medium ">
                            Total Amount
                          </h1>
                        </div>
                        <div>
                          <h1 className="text-[#0A0A0A] font-bold text-2xl">
                            {formatPrice(subTotalNew)}{" "}
                            {/* <span className="text-[13px]">
                            {" "}
                            {selectedFood?.currency_code
                              ? selectedFood?.currency_code
                              : invoiceSetting?.currency_info?.code
                              ? invoiceSetting?.currency_info?.code
                              : "BDT"}
                          </span> */}
                          </h1>
                        </div>
                      </div>
                      {/* <div className="flex flex-col space-y-2 justify-between">
                        <div>
                          <h2 className="text-[#737373] font-medium ">
                            Total Discount
                          </h2>
                        </div>
                        <div>
                          <h1 className="text-[#0A0A0A] font-bold text-2xl">
                            {totalMoreDiscount}
                            <span className="text-[13px]">
                            {" "}
                            {selectedFood?.currency_code
                              ? selectedFood?.currency_code
                              : invoiceSetting?.currency_info?.code
                              ? invoiceSetting?.currency_info?.code
                              : "BDT"}
                          </span>
                          </h1>
                        </div>
                      </div> */}
                      <div className="flex flex-col space-y-2 justify-between">
                        <div>
                          <h2 className="text-[#737373] font-medium ">
                            Vat ({(type === "purchase" ? 0 : vat) + "%"})
                          </h2>
                        </div>
                        <div>
                          <h1 className="text-[#0A0A0A] font-bold text-2xl">
                            {type === "purchase"
                              ? 0
                              : formatPrice(
                                  vat && vat > 0
                                    ? (vat / 100) * Number(subTotalNew)
                                    : 0
                                )}

                            {/* <span className="text-[13px]">
                            {" "}
                            {selectedFood?.currency_code
                              ? selectedFood?.currency_code
                              : invoiceSetting?.currency_info?.code
                              ? invoiceSetting?.currency_info?.code
                              : "BDT"}
                          </span> */}
                          </h1>
                        </div>
                      </div>
{/*                       <div className="flex flex-col space-y-2 justify-between">
                        <div>
                          <h1 className="text-[#737373] font-medium ">
                            Delivery Charge
                          </h1>
                        </div>
                        <div>
                          {(type === "booking" ||
                            type === "billing" ||
                            type === "wholesale") && (
                            <h1 className="text-[#7F8098] font-nunito font-bold text-lg">
                              {(deliveryFormData?.deliveryName).toLowerCase() !=
                              "hand-to-hand" ? (
                                <input
                                  type="text"
                                  // disabled={ (deliveryFormData?.deliveryName).toLowerCase()=='hand-to-hand' ? true : false }
                                  className={`w-[80px] text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-2`}
                                  placeholder="0.00"
                                  value={deliveryAmount}
                                  style={inputStyle}
                                  onChange={(e) =>
                                    handleChangeDeliveryAmount(e.target.value)
                                  }
                                  name={"delivery_fee"}
                                />
                              ) : (
                                <span>{deliveryAmount}</span>
                              )}
                            </h1>
                          )}
                        </div>
                      </div> */}
                      <div className="flex flex-col space-y-2 justify-between">
                        <div>
                          <h1 className="text-[#737373] font-medium ">
                            Grand Total
                          </h1>
                        </div>
                        <div>
                          <p className="text-[#0A0A0A] font-bold text-2xl">
                            {Number.parseFloat(grandTotalAfterDiscount).toFixed(
                              2
                            )}{" "}
                            {/* <span className="text-[13px]">
                            {selectedFood?.currency_code
                              ? selectedFood?.currency_code
                              : invoiceSetting?.currency_info?.code
                              ? invoiceSetting?.currency_info?.code
                              : "BDT"}
                          </span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*End ---------------------- Newly added  POS calculation ---------------------- */}

                  {/* Start - code of different checklist (Send SMS to Customer, Delivery Method, Add Seller, Exchange) */}
                  <div className="flex items-center space-x-4">
                    {/* Checkbox for "Send SMS to Customer" */}
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-gray-600"
                        checked={isSmsChecked}
                        onChange={() => setIsSmsChecked(!isSmsChecked)}
                      />
                      <span className="text-[#525252] font-semibold text-sm font-nunito">
                        Send SMS to Customer
                      </span>
                    </label>

                    {/* Checkbox for "Delivery method" */}
                    {/* <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-gray-600"
                      checked={isDeliveryChecked}
                      onChange={() => setIsDeliveryChecked(!isDeliveryChecked)}
                    />
                    <span className="text-[#525252] font-semibold text-sm font-nunito">
                      Delivery method
                    </span>
                  </label> */}
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-gray-600"
                        checked={isDeliveryChecked}
                        onChange={() => {
                          // Check if the order list is empty
                          if (!orderList.length) {
                            showModalWarning("Please Select A product");
                            return;
                          }
                          setIsDeliveryChecked(!isDeliveryChecked);
                        }}
                      />
                      <span className="text-[#525252] font-semibold text-sm font-nunito">
                        Delivery method
                      </span>
                    </label>

                    {/* Checkbox for "Add seller" */}
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-gray-600"
                        checked={isSellerChecked}
                        // onChange={()=> setIsSellerChecked(!isSellerChecked)}
                        onChange={() => {
                          setIsSellerChecked(!isSellerChecked); // Toggle the checkbox
                          if (!isSellerChecked) {
                            handleOpenSeller(); // Call handleOpenSeller when the checkbox is checked
                          }
                        }}
                      />
                      <span className="text-[#525252] font-semibold text-sm font-nunito">
                        Add seller
                      </span>
                    </label>

                    {/* Disabled Checkbox for "Exchange" */}
                    <label className="flex items-center space-x-2 opacity-50">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-gray-600"
                        disabled
                      />
                      <span className="text-gray-500">Exchange</span>
                    </label>
                  </div>
                  {/* End -  code of different checklist (Send SMS to Customer, Delivery Method, Add Seller, Exchange) */}

                  {/* Start - code of different actions like (Discard, Hold, Payment) */}
                  {/* <div className="flex flex-col md:flex-row items-center space-y-3 md:space-y-0 md:space-x-4 justify-between w-full">
                    <button className=" w-full md:flex-1 text-white text-xl font-bold py-3 px-4 bg-[#FF3335] rounded-lg flex justify-center items-center gap-2">
                      <span>
                        {" "}
                        <RiDeleteBin5Fill />{" "}
                      </span>
                      Discard
                    </button>
                    <button className=" w-full md:flex-1 text-white text-xl font-bold py-3 px-4 bg-[#FF9900] rounded-lg flex justify-center items-center gap-2">
                      <span>
                        <FaPause />
                      </span>
                      Hold
                    </button>

                    <button
                      className={` w-full md:flex-1 text-white text-xl font-bold py-3 px-4 rounded-lg flex justify-center items-center gap-2
  ${orderList?.length > 0 ? "bg-[#29B170]" : "bg-gray-400 cursor-not-allowed"}`}
                      disabled={orderList?.length === 0}
                      onClick={() => setShowPaymentRightDiv(true)}
                    >
                      <img
                        src="https://i.ibb.co.com/31fHyTk/image.png"
                        alt="Icon"
                        className="h-6 w-6"
                      />
                      Payment
                    </button>
                  </div> */}
                  <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-3 justify-between w-full">
                    <button className="w-1/2 md:flex-1 text-white text-lg font-semibold py-2 px-3 bg-[#FF3335] rounded-md flex justify-center items-center gap-1.5">
                      <span>
                        <RiDeleteBin5Fill />
                      </span>
                      Discard
                    </button>

                    <button className="w-1/2 md:flex-1 text-white text-lg font-semibold py-2 px-3 bg-[#FF9900] rounded-md flex justify-center items-center gap-1.5">
                      <span>
                        <FaPause />
                      </span>
                      Hold
                    </button>

                    <button
                      className={`w-1/2 md:flex-1 text-white text-lg font-semibold py-2 px-3 rounded-md flex justify-center items-center gap-1.5
                        ${orderList?.length > 0 ? "bg-[#29B170]" : "bg-gray-400 cursor-not-allowed"}`}
                      disabled={orderList?.length === 0}
                      onClick={() => setShowPaymentRightDiv(true)}
                    >
                      <img
                        src="https://i.ibb.co.com/31fHyTk/image.png"
                        alt="Icon"
                        className="h-5 w-5"
                      />
                      Payment
                    </button>
                  </div>
                  {/* End - code of different actions like (Discard, Hold, Payment) */}
                </div>

                {/*Start - Old Code of Calculation */}
                {/* these code are in backupCalculationCode.js file */}
                {/*End - Old Code of Calculation */}

                {/*Start - Payment methods old code */}
                {/* The file in backup file */}
                {/*End - Payment methods old code */}
              </div>
            </div>
            {/* Point of Sale  bottom div end*/}
          </div>
          {/* ): null} */}
        </div>

        {/*  - right div */}
        {/* - Right Div */}
        {showPaymentRightDiv ? (
          // Show the payment div when `showPaymentRightDiv` is true
          <div className="w-full bg-white p-4 rounded-xl shadow-sm md:w-[35%]">
            {/* <div className="flex justify-start items-center gap-2 bg-white p-4 rounded-lg max-w-md w-full">
              <h2 className="text-center text-[#737373] text-base  font-bold font-nunito">
                To Pay:
              </h2>
              <h1 className="text-center text-base font-bold text-[#0A0A0A]">
                {Number.parseFloat(grandTotalAfterDiscount).toFixed(2)}
              </h1>
            </div> */}
            <h3 className="text-center text-[#737373] text-base  font-bold font-nunito mb-2">
                Payment Methods
              </h3>

            <div className="">
              {/* {(type === "billing" ||
                type === "booking" ||
                type === "wholesale") && (
                <div
                  className="flex items-center justify-center py-5 gap-3 px-3"
                  onClick={() => setMethodIndex(0)}
                >
                  <ItemSelectBox2
                    name="delivery_id"
                    options={deliveryList}
                    value={deliveryFormData.deliveryId}
                    onChange={handleChangeSelect.bind(this, "delivery_id")}
                    label={"Delivery Method"}
                    placeholder={deliveryFormData.deliveryName}
                    style={{ maxHeight: "300px", overflowY: "scroll" }}
                  />
                </div>
              )} */}

              {(type === "booking" ||
                type === "billing" ||
                type == "purchase" ||
                type == "wholesale") && (
                <>
                  <div className="flex justify-center items-center">
                    <div className="grid grid-cols-4 gap-2 w-full">
                      {paymentList.map((items, index) => (
                        <label
                          key={index}
                          htmlFor="my_modal_payment"
                          onClick={() => handleClicks(items)}
                          className={`${
                            money === items?.type_name && orderList.length > 0
                              ? " outline outline-[#407BFF]"
                              : "opacity-50  border-blue-500"
                          } cursor-pointer flex justify-center border rounded-[12px]  hover:shadow-md hover:transition-shadow duration-200`}
                        >
                          <div
                            className={` flex flex-col justify-center w-full items-center gap-y-1 p-1`}
                          >
                            <h2 className={`font-bold text-3xl`}>
                              {items.icon_letter}
                            </h2>
                            <p className="text-sm  text-center font-nunito font-bold w-[90%]">
                              {items.type_name == "cash"
                                ? "Cash"
                                : items.type_name}
                            </p>
                          </div>
                        </label>
                      ))}
                    </div>
                  </div>
                  {/* <div className="flex justify-between">
                    <button className="px-6 py-2 rounded-md bg-blue-100 text-blue-600">
                      Back
                    </button>
                    <button
                      className="px-6 py-2 rounded-md bg-gray-300 text-gray-600"
                      disabled
                    >
                      Pay Bill
                    </button>
                  </div> */}

                  {showPaymentModal && (
                    <>
                      <PaymentTypeForm
                        formId={"my_modal_payment"}
                        paymentData={paymentData}
                        paymentList={paymentList}
                        paymentAccountList={paymentAccountList}
                        setData={setPaymentData}
                        formData={formData}
                        setFormData={setFormData}
                        filteredAccountList={filteredAccountList}
                        setFilteredAccountList={setFilteredAccountList}
                        setPaidAmount={setPaidAmount}
                        loadingCallback={loadingCallback}
                        setSelectedAccountList={setSelectedPaymentAccountList}
                        navigate={navigate}
                        setShowModal={false}
                        isOpen={false}
                        title={"Payment Info"}
                        setWarningMessage={setWarningMessage}
                        type={type}
                        selectType={undefined}
                        postAccountTxn={undefined}
                        grandTotalAfterDiscount={grandTotalAfterDiscount}
                        disabledForSetShowModal={true}
                        autoSubmitOnAmountChange={true}
                      />
                    </>
                  )}
                </>
              )}

              {type === "wholesale" &&
              orderList &&
              orderList.length > 0 &&
              money != "" ? (
                <label
                  htmlFor="my_modal_wholesaler"
                  onClick={handleOpenWholesaler}
                  className={`mt-[22px] border-[#034AFF] border-[1px]  ${
                    wholeSaler
                      ? "bg-[#034AFF] text-white"
                      : "bg-white text-[#034AFF]"
                  } p-[6px] w-full font-nunito font-semibold text-lg text-center cursor-pointer rounded-[5px] capitalize`}
                >
                  Add Wholesaler
                </label>
              ) : null}
              {/* {(type === "booking" || type === "billing") &&
              orderList &&
              orderList.length > 0 &&
              money != "" ? (
                <label
                  htmlFor="my_modal_2"
                  onClick={handleOpen}
                  className={`mt-[22px] border-[#034AFF] border-[1px]  ${
                    customer
                      ? "bg-[#034AFF] text-white"
                      : "bg-white text-[#034AFF]"
                  } p-[6px] w-full font-nunito font-semibold text-lg text-center cursor-pointer rounded-[5px] capitalize`}
                >
                  Add Customer
                </label>
              ) : null} */}
              {/* {showSeller &&
              (type === "booking" || type === "billing") &&
              orderList &&
              orderList.length > 0 &&
              money != "" ? (
                <label
                  htmlFor="my_modal_seller"
                  onClick={handleOpenSeller}
                  className={`mt-[22px] border-[#034AFF] border-[1px]  ${
                    seller
                      ? "bg-[#034AFF] text-white"
                      : "bg-white text-[#034AFF]"
                  } p-[6px] w-full font-nunito font-semibold text-lg text-center cursor-pointer rounded-[5px] capitalize`}
                >
                  Add Seller
                </label>
              ) : null} */}
              {type === "purchase" &&
              orderList &&
              orderList.length > 0 &&
              money != "" ? (
                <label
                  htmlFor="my_modal_vendor"
                  onClick={handleOpenVendor}
                  className={`mt-[22px] border-[#034AFF] border-[1px] ${
                    vendor
                      ? "bg-[#034AFF] text-white"
                      : "bg-white text-[#034AFF]"
                  } p-[6px] w-full font-nunito font-semibold text-lg text-center cursor-pointer rounded-[5px] capitalize`}
                >
                  Add Vendor
                </label>
              ) : null}
              {(type === "billing" ||
                vendor ||
                customer ||
                (type === "booking" && (vendor || customer)) ||
                (type === "wholesale" && wholeSaler)) &&
              orderList &&
              orderList.length > 0 &&
              money !== "" ? (
                <FormPayment
                  formId="PaymentForm"
                  status={status}
                  deliveryAccountList={deliveryAccountList}
                  setStatus={setStatus}
                  subtotal={subTotalNew}
                  deliveryFormData={newSalesBillingState.posDeliveryFormData}
                  setDeliveryFormData={setDeliveryFormData}
                  invoice={invoice}
                  vats={formatPrice(
                    vat && vat > 0 ? (vat / 100) * Number(subTotalNew) : 0
                  )}
                  type={type}
                  taxes={formatPrice(
                    tax && tax > 0 ? (tax / 100) * Number(subTotalNew) : 0
                  )}
                  customer={customer}
                  seller={seller}
                  wholeSaler={wholeSaler}
                  vendor={vendor}
                  grandTotal={grandTotalAfterDiscount}
                  currentUser={currentUser ? currentUser : null}
                  product={orderList ? orderList : null}
                  discount={totalMoreDiscount}
                  onOrderSubmit={handleSubmitOrder}
                  setInvoice={setInvoice}
                  setWarningMessage={setWarningMessage}
                  selectedValue={selectedValue}
                  handleSelectChange={handleSelectChange}
                  setIsSave={setSaveNoRedirect}
                />
              ) : null}
            </div>
          </div>
        ) : isDeliveryChecked ? (
          // Show the delivery method form when `isDeliveryChecked` is true

          <DeliveryMethod
            deliveryList={deliveryList}
            deliveryFormData={deliveryFormData}
            setDeliveryFormData={setDeliveryFormData}
            setDeliveryAmount={setDeliveryAmount}
            deliveryAmount={deliveryAmount}
            setShowPaymentRightDiv={setShowPaymentRightDiv}
          />
        ) : (
          // By default, show the TopSaleProducts
          <TopSaleProducts
            handleSelectedFood={handleSelectedFood}
            buttonRef={buttonRef}
            handleButtonClick={handleButtonClick}
            showNavMenu={showNavMenu}
            menuRef={menuRef}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
          />
        )}
      </div>

      {/* All modal */}
      {warningMessage && (
        <div className="warning-message">something wrong!</div>
      )}
      {flashMessage && (
        <div
          className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
            flashMessage.type
          )}`}
        >
          {flashMessage.text}
        </div>
      )}

      {warrantyModal ? (
        <Modal
          state={state}
          isOpen={warrantyModal}
          setOpen={setWarrantyModal}
          filteredProducts={filteredProducts}
          setWarningMessage={setWarningMessage}
          selectedProductWarrantyList={selectedProductWarrantyList}
          setSelectedProductWarrantyList={setSelectedProductWarrantyList}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          orderList={orderList}
          title="Warranty Info"
          formId="my_modal_warranty"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="warranty"
        />
      ) : null}

      {productVariation && selectedItem.qty >= 1 ? (
        <Modal
          state={state}
          isOpen={productVariation}
          setOpen={setProductVariation}
          // variationData={variationData}
          // seteVariationData={seteVariationData}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          orderList={orderList}
          setOrderList={setOrderList}
          title={selectedItem.name}
          formId="my_modal_productVariation"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="productVariation"
        />
      ) : null}


      {/* Modal Wholesaler  */}
      {showModalWholeSaler ? (
        <Modal
          isOpen={showModalWholeSaler}
          setOpen={setShowModalWholeSaler}
          setData={setWholeSaler}
          setWarningMessage={setWarningMessage}
          title="Wholesaler Info"
          formId="my_modal_wholesaler"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="wholesaler"
          filteredWholeSaler={filteredWholeSaler}
          setFilteredWholeSaler={updateFilteredWholeSaler}
        />
      ) : null}
      {/* Modal One  */}
      {showModal ? (
        <Modal
          notify={notify}
          isOpen={showModal}
          setOpen={setShowModal}
          setData={setCustomer}
          setWarningMessage={setWarningMessage}
          title="Customer Info"
          formId="my_modal_2"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="customer"
          filteredCustomers={filteredCustomers}
          setFilteredCustomers={updateFilteredCustomer}
        />
      ) : null}
      {/* Modal Two  */}
      {showModalSeller ? (
        <Modal
          isOpen={showModalSeller}
          setOpen={setShowModalSeller}
          setData={setSeller}
          setWarningMessage={setWarningMessage}
          title="Seller Info"
          formId="my_modal_seller"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="seller"
          filteredSeller={filteredSeller}
          setFilteredSeller={setFilteredSeller}
        />
      ) : null}
      {showModalVendor ? (
        <Modal
          isOpen={showModalVendor}
          setOpen={setShowModalVendor}
          setData={setVendor}
          setWarningMessage={setWarningMessage}
          title="Vendor Info"
          formId="my_modal_vendor"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="vendor"
        />
      ) : null}
      {showQuickProductAddForm ? (
        <Modal
          isOpen={showQuickProductAddForm}
          setOpen={setShowQuickProductAddForm}
          setData={setVendor}
          setWarningMessage={setWarningMessage}
          title="Quick Entry"
          formId="my_modal_product"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="product"
          saleType={type}
          updateBrandList={updateBrandList}
          getTopSaleProductlist={getTopSaleProductlist}
        />
      ) : null}
    </div>
  );
};

export default NewSalesBilling;
