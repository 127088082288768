import React, { useContext, useEffect, useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { TfiMenuAlt } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import "./index.css";
//import Categoryss from "../../components/settings/Settings_Pages/Category";
import axios from "axios";
import Resizer from "react-image-file-resizer";
import { BeatLoader } from "react-spinners";
import { useFlashMessage } from "../../context/FlashMessageContext";
import { AppContext } from "../../context/context.js";
import ImageUploader2 from "../../forms/ImageUploader2";
import InvoiceSettingForm from "../../forms/InvoiceSettingForm";
import { get } from "../../services/apiService";

import {
  uploadFile,
  uploadFileMultipleInputVariants,
  validateData,
} from "../../utils/Common";
import SelectBox from "../../forms/Select";
import { logDOM } from "@testing-library/react";
import { set } from "react-hook-form";
import Swal from "sweetalert2";
import { FlashMessage } from "../../utils/FlashMessage.js";

import FileResizer from "react-image-file-resizer";

export const useMountEffect = (fun) => useEffect(fun, []);

const SubNav = ({ pageName, Breadcrumb, page }) => {
  const {
    state,
    isLoading,
    updateCategoryList,
    updateSingleCategoryList,
    updateSubCategoryList,
    updateBrandList,
    updateUnitList,
    updateInvoiceSetting,
    updatePaymentList,
    updateDeliveryList,
    updateWarrantyList,
  } = useContext(AppContext);

  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage();
  const [isValid, setIsValid] = useState(true);

  const [error, setError] = useState("");
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const [modalOne, setModalOne] = useState(true);
  const [modalTwo, setModalTwo] = useState(false);
  const [modalThree, setModalThree] = useState(false);

  const [modalUnit, setModalUnit] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [modalDelivery, setModalDelivery] = useState(false);
  const [modalWarranty, setModalWarranty] = useState(false);

  const [modalInvoice, setModalInvoice] = useState(false);
  const [modalDiscounts, setModalDiscounts] = useState(false);

  const parts = pathname.split("/");
  const [disabel, setDisable] = useState(false);
  const [category, setCategory] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const token = localStorage.getItem("token");

  //const [open, setOpen] = React.useState(false);
  //const handleOpen = () => setShowModal(!open);
  const [showModal, setShowModal] = React.useState(false);
  //const handleOpen = () => setShowModal(!showModal);

  const handleOpen = () => {
    resetFormError();
    setShowModal(!showModal);
    if (!showModal) {
      setName("");
      setCategoryId("");
      setDescription("");
      setCategoryImage(null);
      setFile("");
      setBrandfile("");
      setUploadDetails([]);
      setDeliveryIcon("");
      setDeliveryName("");
      setPaymentName("");
      setPaymentIcon("");
      setWarranty("");
    }
  };

  // Gneral Focus Hook
  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };

  const [inputRef, setInputFocus] = useFocus();
  const [invoiceSettingData, setInvoiceSettingData] = useState(
    state.invoiceSetting
  );
  const invoiceSetting = invoiceSettingData
    ? invoiceSettingData
    : {
        shop_name: "",
        shop_logo: "",
        shop_address: "",
        sign_authority: "",
        name_authority: "",
        currency_id: "",
        category_id: "",
        watermark_text: "",
        watermark_type: "",
      };

  useMountEffect(setInputFocus);

  //console.log(parts);
  let firstPart, secondPart;
  let checker;
  if (parts[2]) {
    [firstPart, secondPart] = parts[2].split("-");
    checker = firstPart;
    //setShowModal(true);
  } else if (parts[1] && parts[1] === "settings") {
    checker = "invoice";
  }

  const getInvoiceSetting = () => {
    loadingCallback(true);
    get("get-invoice-settings", token)
      .then((response) => {
        if (response.success) {
          setInvoiceSettingData(response.data);
          updateInvoiceSetting(response.data);
          loadingCallback(false);
        }
        if (response?.data?.shop_logo) {
          setFile(response?.data?.shop_logo);
        }
        loadingCallback(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setModalOne(false);
    setModalTwo(false);
    setModalThree(false);
    setModalUnit(false);
    setModalPayment(false);
    setModalDelivery(false);
    setModalDiscounts(false);
    setModalInvoice(false);
    setModalWarranty(false);
    if (checker === "category") {
      setModalOne(true);
      //getCategory();
      getSingleCategory();
    } else if (checker === "sub") {
      if (!state.categoryList.length) {
        getCategory();
      } else {
        setCategory(state.categoryList);
        updateCategoryList(state.categoryList);
      }
      setModalTwo(true);
    } else if (checker === "brands") {
      setModalThree(true);
    } else if (checker === "units") {
      setModalUnit(true);
    } else if (checker === "discounts") {
      setModalDiscounts(true);
    } else if (checker === "payment") {
      setModalPayment(true);
    } else if (checker === "delivery") {
      setModalDelivery(true);
    } else if (checker === "warranty") {
      setModalWarranty(true);
    } else if (checker === "invoice") {
      setModalInvoice(true);
      //getCategory();
      getCurrency();
    } else {
      setModalOne(false);
      setModalTwo(false);
      setModalThree(false);
      setModalUnit(false);
      setModalDiscounts(false);
      setModalInvoice(false);
      setModalPayment(false);
      setModalDelivery(false);
      setModalWarranty(false);
    }
  }, [firstPart]);

  const getCurrency = () => {
    get("currency", token)
      .then((response) => {
        console.log(response.data);

        if (response.success) {
          setCurrency(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Category save

  const [categoryImage, setCategoryImage] = useState(null);

  // Convert Base64 to Blob
  const base64ToBlobCategoryImage = (base64, mime) => {
    const byteString = atob(base64.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mime });
  };

  // Resize the uploaded file
  const resizeCategoryImage = (file) =>
    new Promise((resolve) => {
      const width = 500; // Desired width for category image
      const height = 500; // Desired height for category image

      FileResizer.imageFileResizer(
        file,
        width,
        height,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  // Handle Image Upload
  const handleCategoryImageChange = async (e) => {
    const file = e.target.files[0];
    const allowedExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
    ];

    if (!allowedExtensions.includes(file?.type)) {
      alert("Please upload a valid image file (JPEG, PNG, GIF).");
      return;
    }

    try {
      const resizedBase64 = await resizeCategoryImage(file);
      const blobFile = base64ToBlobCategoryImage(resizedBase64, file?.type);
      setCategoryImage(blobFile); // Set the resized and converted image file
    } catch (error) {
      console.error("Error processing category image:", error);
    }
  };

  const handleSubmitCategory = async (e) => {
    e.preventDefault();

    // Basic form validation
    if (!name) {
      setInputErrors({ ...inputErors, name: "Category name is required." });
      return;
    }
    if (!description) {
      setInputErrors({
        ...inputErors,
        description: "Category description is required.",
      });
      return;
    }

    try {
      loadingCallback(true);

      // Prepare file data for upload
      let imageUploadResponse = null;
      if (categoryImage) {
        imageUploadResponse = await uploadFileMultipleInputVariants(
          [{ file: categoryImage, input_name: "image_url" }],
          "image_url"
        );
      }

      const imageUrl = imageUploadResponse?.path || ""; // Use the uploaded image path

      // Prepare payload
      const payload = {
        name: name,
        description: description,
        image_url: imageUrl, // Add the uploaded image URL
      };

      // Save category data
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}save-category`,
        payload,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      loadingCallback(false);

      if (response.data.success) {
        Swal.fire(
          "Success",
          "Category has been saved successfully!",
          "success"
        );
        // Reset form
        // Reset form and trigger required functions
        setName("");
        setDescription("");
        setCategoryImage(null);
        getCategory();
        getSingleCategory();
        showModal(false);
      } else {
        Swal.fire(
          "Error",
          "Failed to save category. Please try again.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error saving category:", error);
      loadingCallback(false);
    }
  };

  const getCategory = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}get-all-category`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          setCategory(response.data.data);
          updateCategoryList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };
  const getSingleCategory = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}category?page=1&limit=10`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          //setCategory(response.data.data);
          updateSingleCategoryList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  function handleSubmitSubcategory(e) {
    e.preventDefault();
    if (validateForm("Sub Category")) {
      // Validate the category selection
      if (categoryId === "") {
        setError("Please select a category.");
        return; // Do not proceed with the form submission if there's an error
      } else {
        setError("");
      }
      loadingCallback(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}save-sub-category`,
          { category_id: categoryId, name: name, description: description },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          //console.log(response.data.success);
          //loadingCallback(false);
          if (response.data.success) {
            setName("");
            setDescription("");
            setShowModal(false);
            // window.location.reload();
            //navigate('/settings/category');
            getSubCategory();
          } else {
            loadingCallback(false);
            setName("");
            setDescription("");
          }
        })
        .catch((error) => {
          console.log(error);
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    }
  }

  const getSubCategory = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}sub-category`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          updateSubCategoryList(response.data.data);
        }
        loadingCallback(false);
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };
  const getBrand = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}brands?page=1&limit=200`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        //console.log(response.data.data.data)

        if (response.data.success) {
          updateBrandList(response.data.data);
        }
        loadingCallback(false);
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  const getUnits = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}units`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        //console.log(response.data.data.data)

        if (response.data.success) {
          updateUnitList(response.data.data);
        }
        loadingCallback(false);
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  function handleSubmitUnit(e) {
    e.preventDefault();
    if (validateForm("Unit")) {
      loadingCallback(true);

      axios
        .post(
          `${process.env.REACT_APP_API_URL}save-unit`,
          { name: name, description: description },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          //console.log(response.data.success);

          if (response.data.success) {
            setName("");
            setDescription("");
            setShowModal(false);
            getUnits();
            // window.location.reload();
            //navigate('/settings/category');
          } else {
            setName("");
            setDescription("");
            loadingCallback(false);
          }
        })
        .catch((error) => {
          console.log(error);
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    }
  }

  // const [paymentName, setPaymentName] = useState("");
  // const [paymentIcon, setPaymentIcon] = useState("");

  // const [paymentMethodImage, setPaymentMethodImage] = useState(null);
  // const handleImageChangePaymentMethod = (files) => {
  //   if (files.length > 0) {
  //     setPaymentMethodImage(files[0]); // Save the file object for submission
  //   }
  // };

  // const handleSubmitPayment = (e) =>{
  //   e.preventDefault();

  //   setInputErrors({...inputErors,  paymentName: (paymentName==''), paymentIcon: (paymentIcon=='')})
  //   if(paymentName){
  //     loadingCallback(true);
  //     axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}payment-type-save`,
  //       { type_name: paymentName, icon_letter: paymentIcon },
  //       {
  //         headers: {
  //           authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       loadingCallback(false);
  //       if(response?.data?.success) {
  //         showModalWarning("Method added successfully!", 'success')
  //         setPaymentIcon('');
  //         setPaymentName('');
  //         setShowModal(false);
  //         getPayment();
  //       }
  //       else if(response?.data?.errors){
  //         console.log(response.data.errors);
  //         response.data.errors?.type_name  ?
  //         showModalWarning(response.data.errors?.type_name) :
  //         showModalWarning(response.data.errors?.icon_letter)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       loadingCallback(false);
  //       if (error?.response?.status === 401) {
  //         localStorage.clear();
  //         localStorage.setItem('token', false);
  //         localStorage.setItem('isPinVerify', false);
  //         navigate('/login');
  //       }
  //     });
  //   }
  // }

  // const handleSubmitPayment = (e) => {
  //   e.preventDefault();

  //   // Set input errors for validation
  //   setInputErrors({
  //     ...inputErors,
  //     paymentName: paymentName === "",
  //     paymentIcon: paymentIcon === "",
  //     iconImage: paymentMethodImage === null, // Ensure image is uploaded
  //   });

  //   // Validate that all fields are filled before submitting
  //   if (paymentName && paymentIcon && paymentMethodImage) {
  //     loadingCallback(true);

  //     // Create a new FormData object to handle both text and file data
  //     const formData = new FormData();
  //     formData.append("type_name", paymentName);
  //     formData.append("icon_letter", paymentIcon);
  //     formData.append("icon_image", paymentMethodImage); // Append the image file

  //     // Log the FormData for testing (you can remove it after)
  //     console.log("Form Data being sent:", formData);

  //     console.log("FormData Contents:");
  //     for (const pair of formData.entries()) {
  //       console.log(`${pair[0]}:`, pair[1]); // Log key and value
  //     }

  //     //   return

  //     // Send the request
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_API_URL}payment-type-save`,
  //         formData, // Send FormData instead of JSON
  //         {
  //           headers: {
  //             authorization: `Bearer ${token}`,
  //             "Content-Type": "multipart/form-data", // Use 'multipart/form-data' for file uploads
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         loadingCallback(false);
  //         if (response?.data?.success) {
  //           showModalWarning("Method added successfully!", "success");
  //           setPaymentIcon("");
  //           setPaymentName("");
  //           setPaymentMethodImage(null); // Clear the uploaded image
  //           setShowModal(false);
  //           getPayment();
  //         } else if (response?.data?.errors) {
  //           console.log(response.data.errors);
  //           response.data.errors?.type_name
  //             ? showModalWarning(response.data.errors?.type_name)
  //             : showModalWarning(response.data.errors?.icon_letter);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         loadingCallback(false);
  //         if (error?.response?.status === 401) {
  //           localStorage.clear();
  //           localStorage.setItem("token", false);
  //           localStorage.setItem("isPinVerify", false);
  //           navigate("/login");
  //         }
  //       });
  //   }
  // };

  // Payment Add Form
  const [paymentName, setPaymentName] = useState("");
  const [paymentIcon, setPaymentIcon] = useState("");
  const [paymentMethodImage, setPaymentMethodImage] = useState(null);

  const handleImageChangePaymentMethod = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      setInputErrors({ ...inputErors, iconImage: true });
      return;
    }

    // Allowed image types
    const allowedExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
    ];
    if (!allowedExtensions.includes(file?.type)) {
      alert("Please upload a valid image file (JPEG, PNG, GIF).");
      return;
    }

    // Resize the image
    try {
      const resizedImage = await resizeFile(file, "payment_method");
      const blobImage = base64ToBlobCategoryImage(resizedImage, file?.type); // Convert base64 to Blob
      setPaymentMethodImage(blobImage); // Save the Blob file to state
    } catch (error) {
      console.error("Error resizing or processing the image:", error);
    }
  };

  const handleSubmitPayment = async (e) => {
    e.preventDefault();

    // Validation
    setInputErrors({
      ...inputErors,
      paymentName: paymentName === "",
      paymentIcon: paymentIcon === "",
      iconImage: paymentMethodImage === null,
    });

    if (!paymentName || !paymentIcon || !paymentMethodImage) {
      return;
    }

    loadingCallback(true);

    try {
      // Upload the image
      const uploadedImage = await uploadFileMultipleInputVariants(
        [{ file: paymentMethodImage, input_name: "icon_image" }],
        "icon_image"
      );

      if (!uploadedImage) {
        throw new Error("Image upload failed.");
      }

      // Prepare payload
      const payload = {
        type_name: paymentName,
        icon_letter: paymentIcon,
        icon_image: uploadedImage.path,
      };

      // Submit form
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}payment-type-save`,
        payload,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.success) {
        showModalWarning("Payment method added successfully!", "success");
        setPaymentName("");
        setPaymentIcon("");
        setPaymentMethodImage(null);
        setShowModal(false);
        getPayment(); // Refresh payment list
      } else if (response?.data?.errors) {
        showModalWarning(
          response.data.errors?.type_name || response.data.errors?.icon_letter
        );
      }
    } catch (error) {
      console.error("Error submitting payment method:", error);
      if (error?.response?.status === 401) {
        localStorage.clear();
        localStorage.setItem("token", false);
        localStorage.setItem("isPinVerify", false);
        navigate("/login");
      }
    } finally {
      loadingCallback(false);
    }
  };

  const [deliveryName, setDeliveryName] = useState("");
  const [deliveryIcon, setDeliveryIcon] = useState("");

  const handleSubmitDelivery = (e) => {
    e.preventDefault();

    setInputErrors({
      ...inputErors,
      deliveryName: deliveryName == "",
      deliveryIcon: deliveryIcon == "",
    });
    if (deliveryName) {
      loadingCallback(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}delivery-method-save`,
          { type_name: deliveryName, icon_letter: deliveryIcon },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          loadingCallback(false);
          if (response?.data?.success) {
            showModalWarning("Method added successfully!", "success");
            setDeliveryIcon("");
            setDeliveryName("");
            setShowModal(false);
            getDelivery();
          } else if (response?.data?.errors) {
            response.data.errors?.type_name
              ? showModalWarning(response.data.errors?.type_name)
              : showModalWarning(response.data.errors?.icon_letter);
          }
        })
        .catch((error) => {
          console.log(error);
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            localStorage.setItem("isPinVerify", false);
            navigate("/login");
          }
        });
    }
  };
  const showModalWarning = (msg, icon = "warning", data = null) => {
    Swal.fire({
      title: msg,
      text: "",
      icon: icon,
    });
  };
  const perPage = 20;
  const getPayment = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}payment-type-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          updatePaymentList(response.data.data);
          console.log("============");
          console.log(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDelivery = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}delivery-method-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          updateDeliveryList(response.data.data);
          console.log("============");
          console.log(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleSubmitInvoiceSetting(values, invoiceSetting) {
    //e.preventDefault();
    loadingCallback(true);
    // console.log("============");
    // const { shop_logo, ...data } = values;
    // console.log(data);

    //const data = values.invoiceSetting[0];
    //setShowModal(false);

    uploadFile(
      uploadedFiles,
      (shopLogo) => {
        // Success callback
        invoiceSettingDataSave(shopLogo, values, invoiceSetting);
      },
      (error) => {
        // Error callback
        console.log(error);
        loadingCallback(false);
      },
      (progressDetails) => {
        // Progress callback, update uploadDetails here
        setUploadDetails(progressDetails);
      }
    );
  }

  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadDetails, setUploadDetails] = useState([]);

  const invoiceSettingDataSave = (brandLogo, values, invoiceSetting) => {
    const { shop_logo, ...data } = values;
    data.shop_logo =
      brandLogo.length > 0
        ? brandLogo[0]
        : invoiceSetting && invoiceSetting.shop_logo
        ? invoiceSetting.shop_logo
        : null;
    axios
      .post(`${process.env.REACT_APP_API_URL}save-invoice-settings`, data, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        //console.log(response.data.success);

        if (response.data.success) {
          getInvoiceSetting();
          // setName("");
          // setDescription("");
          setFile(data.shop_logo);
          setShowModal(false);
          // window.location.reload();
          //navigate('/settings/category');
          //getBrand();
        } else {
          loadingCallback(false);
          setName("");
          setDescription("");
          setFile("");
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  const brandDataSave = (brandLogo) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}save-brand`,
        {
          image_path: brandLogo.length > 0 ? brandLogo[0] : "",
          name: name,
          description: description,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        //console.log(response.data.success);

        if (response.data.success) {
          setName("");
          setDescription("");
          setFile("");
          setShowModal(false);
          // window.location.reload();
          //navigate('/settings/category');
          getBrand();
        } else {
          loadingCallback(false);
          //setName("");
          //setDescription("");
          //setFile("");
          setInputErrors({ ...inputErors, name: response.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  const [inputErors, setInputErrors] = useState({
    name: "sumon",
    paymentName: false,
    paymentIcon: false,
    deliveryName: false,
    deliveryIcon: false,
    categoryId: 0,
    error1: false,
    error2: false,
  });

  function handleSubmitBrand(e) {
    e.preventDefault();

    if (validateForm("Brand")) {
      loadingCallback(true);
      uploadFile(
        uploadedFiles,
        (brandLogo) => {
          // Success callback
          brandDataSave(brandLogo);
        },
        (error) => {
          // Error callback
          console.log(error);
          loadingCallback(false);
        },
        (progressDetails) => {
          // Progress callback, update uploadDetails here
          setUploadDetails(progressDetails);
        }
      );
    } else {
      console.log("Form validation failed.");
    }
  }

  const validateForm = (inputName = "Brand") => {
    let valid = true;
    const newInputErrors = { ...inputErors };
    if (validateData(name, inputName)) {
      newInputErrors.name = validateData(name, inputName);
      valid = false;
    }
    if (inputName === "Sub Category") {
      if (categoryId === "" || categoryId === 0) {
        newInputErrors.categoryId = `${inputName} is required`;
        valid = false;
      }
    }

    setInputErrors(newInputErrors);
    return valid;
  };

  const resetFormError = () => {
    setInputErrors({
      name: "",
      error1: false,
      error2: false,
    });
  };

  const [file, setFile] = useState(state?.invoiceSetting?.shop_logo);
  const [brandfile, setBrandfile] = useState();

  const handleChange = ({ target: { files } }) => {
    let tempUploadDetails = [...uploadDetails];

    for (let i = 0; i < files.length; i++) {
      let size = files[i].size / 1024;
      let sizeName = "";

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + "MB";
      } else {
        sizeName = size.toFixed(2) + "KB";
      }

      tempUploadDetails.push({
        name: files[i].name,
        size: sizeName,
        percentage: 0,
      });
    }

    setUploadDetails(tempUploadDetails);
    setUploadedFiles(files);

    setBrandfile(URL.createObjectURL(files[0]));
  };
  const handleBrandLogoChange = ({ target: { files } }) => {
    let tempUploadDetails = [...uploadDetails];

    for (let i = 0; i < files.length; i++) {
      let size = files[i].size / 1024;
      let sizeName = "";

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + "MB";
      } else {
        sizeName = size.toFixed(2) + "KB";
      }

      tempUploadDetails.push({
        name: files[i].name,
        size: sizeName,
        percentage: 0,
      });
    }

    setUploadDetails(tempUploadDetails);
    setUploadedFiles(files);

    setBrandfile(URL.createObjectURL(files[0]));
  };

  const GotoProductslist = () => {
    navigate("/products");
  };
  const handleSelectChange = (name, option) => {
    const { value, label } = option;
    const selectedValue = value;

    if (name === "category_id") {
      setCategoryId(selectedValue);
      setInputErrors({ ...inputErors, categoryId: "" });
    } else {
      setCategoryId("");
      setInputErrors({ ...inputErors, categoryId: "" });
    }
    // Check if the selected value is the default (disabled) option
    if (selectedValue === "") {
      setError("Please select a " + name + ".");
      setCategoryId("");
    } else {
      setError("");
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  const handleImageChange = async (files, name) => {
    let tempUploadDetails = [...uploadDetails];
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    for (let i = 0; i < files.length; i++) {
      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        // setErrorFlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        FlashMessage(
          `Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`,
          "error"
        );
        console.error(
          `Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`
        );
        // Handle invalid file type (display a message, set an error state, etc.)
        setIsValid(false);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = "";

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + "MB";
      } else {
        sizeName = size.toFixed(2) + "KB";
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(",")[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob], files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      tempUploadDetails.push({
        name: files[i].name,
        size: sizeName,
        percentage: 0,
        file: file,
        input_name: name,
      });
    }
    //console.log("============");
    //console.log(tempUploadDetails);
    setFile(URL.createObjectURL(files[0]));
    setUploadedFiles(files);
    setUploadDetails(tempUploadDetails);
  };

  //

  const [warranty, setWarranty] = useState({
    warrantyDays: "",
    warrantyConditon: "",
  });
  const handleSubmitWarrenty = (e) => {
    e.preventDefault();
    setInputErrors({ ...inputErors, error1: warranty.warrantyDays != "" });
    console.log(inputErors);
    if (warranty.warrantyDays) {
      loadingCallback(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}save-warranty`,
          {
            name: warranty.warrantyConditon,
            warranties_count: warranty.warrantyDays,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          loadingCallback(false);
          if (response?.data?.success) {
            showModalWarning(
              "Warranty Condition added successfully!",
              "success"
            );
            setShowModal(false);
            getWarranty();
          } else if (response?.data?.errors) {
            response.data.errors?.name
              ? showModalWarning(response.data.errors?.name)
              : showModalWarning(response.data.errors?.warranties_count);
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            localStorage.setItem("isPinVerify", false);
            navigate("/login");
          }
        });
      console.log(warranty);
    }
  };

  const getWarranty = (page) => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}get-warranty-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          updateWarrantyList(response.data.data);
          console.log("============");
          console.log(response.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };

  return (
    <div>
      <div className="flex justify-between flex-col md:flex-row  items-center mb-5">
        <div>
          <p className="font-nunito font-normal text-xs text-[#A0AEC0]">
            {Breadcrumb} {page}
          </p>
          <h4 className="font-nunito font-bold text-sm text-[#2D3748] mt-1">
            {pageName}
          </h4>
        </div>
        {loading && (
          <div className="custom-loader-logo">
            {/* <BeatLoader color={"#123abc"} size={20} /> */}
            <img
              className=""
              src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
              alt="logo"
            />
          </div>
        )}
        <div className="flex items-center justify-between gap-x-2.5">
          {modalOne && (
            <label
              htmlFor="my_modal_2"
              onClick={handleOpen}
              className=" bg-[#407BFF] text-[#FFF] text-[11px] font-nunito font-bold py-2 px-6 cursor-pointer rounded-[5px] uppercase"
            >
              Add Category
            </label>
          )}
          {modalTwo && (
            <label
              htmlFor="my_modal_6"
              onClick={handleOpen}
              className="bg-[#407BFF] text-[#FFF] text-[11px] font-nunito font-bold py-2 px-6 cursor-pointer  rounded-[5px] uppercase"
            >
              Add Sub Category
            </label>
          )}
          {modalThree && (
            <label
              htmlFor="my_modal_3"
              onClick={handleOpen}
              className="bg-[#407BFF] text-[#FFF] text-[11px] font-nunito font-bold py-2 px-6 cursor-pointer rounded-[5px] uppercase"
            >
              Add Brand
            </label>
          )}
          {modalUnit && (
            <label
              htmlFor="modal_Unit"
              onClick={handleOpen}
              className="bg-[#407BFF] text-[#FFF] text-[11px] font-nunito font-bold py-2 px-6 cursor-pointer rounded-[5px] uppercase"
            >
              Add Unit
            </label>
          )}
          {modalPayment && (
            <label
              htmlFor="modal_payment"
              onClick={handleOpen}
              className="bg-[#407BFF] text-[#FFF] text-[11px] font-nunito font-bold py-2 px-6 cursor-pointer rounded-[5px] uppercase"
            >
              Add Payment
            </label>
          )}
          {modalDelivery && (
            <label
              htmlFor="modal_delivery"
              onClick={handleOpen}
              className="bg-[#407BFF] text-[#FFF] text-[11px] font-nunito font-bold py-2 px-6 cursor-pointer rounded-[5px] uppercase"
            >
              Add Delivery
            </label>
          )}
          {modalWarranty && (
            <label
              htmlFor="modal_warranty"
              onClick={handleOpen}
              className="bg-[#407BFF] text-[#FFF] text-[11px] font-nunito font-bold py-2 px-6 cursor-pointer rounded-[5px] uppercase"
            >
              Add Warranty
            </label>
          )}
          {/* {modalInvoice && (
                        <label
                            htmlFor='modal_Invoice' onClick={handleOpen}
                            className='bg-[#407BFF] text-[#FFF] text-[11px] font-nunito font-bold py-2 px-6 cursor-pointer rounded-[5px] capitalize'
                        >
                            Invoice Setting
                        </label>
                    )} */}
          {/* {modalDiscounts && (
                        <label
                            htmlFor='modal_Discounts' onClick={handleOpen}
                            className='bg-[#407BFF] text-[#FFF] text-[11px] font-nunito font-bold py-2 px-6 cursor-pointer rounded-[5px] capitalize'
                        >
                            Add Discounts
                        </label>
                    )} */}
          <button
            className="bg-[#407BFF] rounded-[5px] text-[#FFF] text-[11px] font-nunito font-bold py-2 px-6 flex items-center gap-2 uppercase"
            onClick={GotoProductslist}
          >
            <TfiMenuAlt className="text-xs"></TfiMenuAlt>{" "}
            <span>product list</span>
          </button>
        </div>
      </div>

      {/* Modal One  */}
      {showModal ? (
        <>
          <div>
            {/* The button to open modal */}
            {/* Put this part before </body> tag */}
            <input type="checkbox" id="my_modal_2" className="modal-toggle" />
            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Add New Category
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="my_modal_2"
                      className="cursor-pointer"
                      onClick={handleOpen}
                    >
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <form
                    method="post"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitCategory}
                    encType="multipart/form-data"
                  >
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setInputErrors({ ...inputErors, name: null });
                      }}
                      placeholder="Category Name"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />
                    {inputErors.name && (
                      <p className="error-message text-red-500 mb-3 pl-1">
                        {inputErors.name}
                      </p>
                    )}
                    <textarea
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="textarea textarea-bordered w-full mb-[15px] resize-none rounded-2xl bg-white"
                      placeholder="Category Description..."
                    ></textarea>
                    <div className="flex flex-col w-full mb-[15px]">
                      <label
                        htmlFor="categoryImage"
                        className="text-sm font-medium text-gray-700 mb-2 cursor-pointer"
                      >
                        Upload Category Image
                      </label>
                      <input
                        type="file"
                        id="categoryImage"
                        accept="image/*"
                        className="input input-bordered w-full rounded-2xl bg-white px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        onChange={handleCategoryImageChange}
                      />
                    </div>

                    <button
                      type="submit"
                      className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-nunito font-semibold text-lg"
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {/* Modal One  */}

      {/* Modal Two  */}

      {showModal ? (
        <>
          <div className="">
            {/* The button to open modal */}

            {/* Put this part before </body> tag */}
            <input type="checkbox" id="my_modal_6" className="modal-toggle" />

            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Add New Subcategory
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="my_modal_6"
                      className="cursor-pointer"
                      onClick={handleOpen}
                    >
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitSubcategory}
                  >
                    <SelectBox
                      name="category_id"
                      options={category}
                      value={categoryId}
                      //onChange={(e) => handleSelectChange('category', 'Category', e)}
                      onChange={handleSelectChange.bind(this, "category_id")}
                      //style={{ maxHeight: '300px', overflowY: 'scroll' }}
                      // error={formErrors.category_id}
                      // setFormErrors={setFormErrors}
                      placeholder="Select Category"
                      className="px-[0] select w-full rounded-2xl mb-[15px] bg-white"
                    />

                    {/* <select
                                        name="category_id"
                                        onChange={(e) => handleSelectChange('category', 'Category', e)}
                                        //onChange={handleSelectChange}
                                        className='select select-bordered w-full rounded-2xl mb-[15px] bg-white'
                                        required
                                        >
                                        <option disabled selected>
                                            Select Category
                                        </option>
                                        {category.map((item,index)=><option key={index} value={item.id}>{item.name}</option>) }
                                    </select> */}
                    {inputErors.categoryId ? (
                      <p className="error-message text-red-500 mb-3 pl-1">
                        {inputErors.categoryId}
                      </p>
                    ) : null}
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        const val = e.target.value;
                        setName(val);
                        setInputErrors({ ...inputErors, name: null });
                      }}
                      placeholder="Subcategory Name"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />
                    {inputErors.name ? (
                      <p className="error-message text-red-500 mb-3 pl-1">
                        {inputErors.name}
                      </p>
                    ) : null}
                    <textarea
                      name="description"
                      value={description}
                      onChange={(e) => {
                        const val = e.target.value;
                        setDescription(val);
                      }}
                      className="textarea textarea-bordered w-full mb-[15px] resize-none rounded-2xl bg-white"
                      placeholder="Subcategory Description..."
                    ></textarea>
                    <div className="button-disabled">
                      <button
                        htmlFor="my_modal_6"
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {""}
                        Save{""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showModal ? (
        <>
          <div>
            {/* The button to open modal */}

            {/* Put this part before </body> tag */}
            <input type="checkbox" id="my_modal_3" className="modal-toggle" />

            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Add New Brand
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="my_modal_3"
                      className="cursor-pointer"
                      onClick={handleOpen}
                    >
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <form
                    method="post"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitBrand}
                    encType="multipart/form-data"
                  >
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        const val = e.target.value;
                        setName(val);
                        setInputErrors({ ...inputErors, name: null });
                      }}
                      // required
                      placeholder="Brand Name"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />
                    {inputErors.name ? (
                      <p className="error-message text-red-500 mb-3 pl-1">
                        {inputErors.name}
                      </p>
                    ) : null}
                    <div className="flex justify-between flex-col border border-opacity-[0.2px] w-full mb-[15px] rounded-2xl">
                      <h4 className="pl-4 py-3">Brand's Logo</h4>
                      <ImageUploader2
                        name={"file-input"}
                        isBig={true}
                        //onChange={(files) => handleImageChange("image_path", files)}
                        onChange={handleImageChange}
                        image={brandfile ? brandfile : null}
                      />
                    </div>
                    <textarea
                      name="description"
                      value={description}
                      onChange={(e) => {
                        const val = e.target.value;
                        setDescription(val);
                      }}
                      className="textarea textarea-bordered w-full mb-[15px] resize-none rounded-2xl bg-white"
                      placeholder="Brand Description..."
                    ></textarea>

                    <div className="button-disabled">
                      <button
                        htmlFor="my_modal_3"
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {""}
                        Save{""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* Modal Unit  */}

      {showModal ? (
        <>
          <div>
            {/* The button to open modal */}
            {/* Put this part before </body> tag */}
            <input type="checkbox" id="modal_Unit" className="modal-toggle" />
            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Add Unit
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="modal_Unit"
                      className="cursor-pointer"
                      onClick={handleOpen}
                    >
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitUnit}
                  >
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        const val = e.target.value;
                        setName(val);
                        setInputErrors({ ...inputErors, name: null });
                      }}
                      ref={inputRef}
                      placeholder="Unit Name"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />
                    {inputErors.name ? (
                      <p className="error-message text-red-500 mb-3 pl-1">
                        {inputErors.name}
                      </p>
                    ) : null}
                    <textarea
                      name="description"
                      value={description}
                      onChange={(e) => {
                        const val = e.target.value;
                        setDescription(val);
                      }}
                      className="textarea textarea-bordered w-full mb-[15px] resize-none rounded-2xl bg-white"
                      placeholder="Unit Description..."
                    ></textarea>

                    {/* {description =="" && (
                                    <div className="warning-message">
                                        This field will not be empty.
                                    </div>
                                    )} */}
                    <div className="button-disabled">
                      <button
                        htmlFor="modal_Unit"
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {""}
                        Save{""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* Modal Invoice  */}

      {showModal ? (
        <>
          <div>
            {/* The button to open modal */}
            {/* Put this part before </body> tag */}
            <input
              type="checkbox"
              id="modal_Invoice"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between flex-col md:flex-row px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Invoice Settings
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="modal_Unit"
                      className="cursor-pointer"
                      onClick={handleOpen}
                    >
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <InvoiceSettingForm
                    initialValues={{ invoiceSetting }}
                    onSubmit={(values) => {
                      //const { ...updateValues } = values;
                      handleSubmitInvoiceSetting(values, invoiceSetting);
                    }}
                    handleChange={handleChange}
                    name={name}
                    file={file}
                    inputRef={inputRef}
                    currency={currency}
                    category={category}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* Modal Discounts  */}

      {showModal ? (
        <>
          <div>
            {/* The button to open modal */}
            {/* Put this part before </body> tag */}
            <input
              type="checkbox"
              id="modal_Discounts"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Add Discounts
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="modal_Discounts"
                      className="cursor-pointer"
                      onClick={handleOpen}
                    >
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitUnit}
                  >
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        const val = e.target.value;
                        setName(val);
                        setInputErrors({ ...inputErors, name: null });
                      }}
                      ref={inputRef}
                      placeholder="Discount Name"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />
                    {name == "" ? (
                      <div className="warning-message">
                        This field can not be empty.
                      </div>
                    ) : name != "" && inputErors.name ? (
                      <p className="error-message text-red-500 mb-3 pl-1">
                        {inputErors.name}
                      </p>
                    ) : null}
                    <textarea
                      name="description"
                      value={description}
                      onChange={(e) => {
                        const val = e.target.value;
                        setDescription(val);
                      }}
                      className="textarea textarea-bordered w-full mb-[15px] resize-none rounded-2xl bg-white"
                      placeholder="Discount Description..."
                    ></textarea>

                    {description == "" && (
                      <div className="warning-message">
                        This field can not be empty.
                      </div>
                    )}
                    <div className="button-disabled">
                      <button
                        htmlFor="modal_Discounts"
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {""}
                        Save{""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showModal ? (
        <>
          <div>
            {/* The button to open modal */}
            {/* Put this part before </body> tag */}
            <input
              type="checkbox"
              id="modal_payment"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Payment Method Name
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="modal_payment"
                      className="cursor-pointer"
                      onClick={handleOpen}
                    >
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <form
                    method="post"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitPayment}
                    encType="multipart/form-data"
                  >
                    <input
                      type="text"
                      name="name"
                      value={paymentName}
                      onChange={(e) => {
                        setPaymentName(e.target.value);
                        setInputErrors({ ...inputErors, paymentName: false });
                      }}
                      placeholder="Add Payment Method Name"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />
                    {inputErors.paymentName && (
                      <p className="error-message text-red-500 mb-3 pl-1">
                        This field cannot be empty.
                      </p>
                    )}

                    <input
                      name="icon"
                      value={paymentIcon}
                      onChange={(e) => {
                        setPaymentIcon(e.target.value);
                        setInputErrors({ ...inputErors, paymentIcon: false });
                      }}
                      className="textarea textarea-bordered w-full mb-[15px] resize-none rounded-2xl bg-white"
                      placeholder="Put a single letter as symbol..."
                    />
                    {inputErors.paymentIcon && (
                      <p className="error-message text-red-500 mb-3 pl-1">
                        This field cannot be empty.
                      </p>
                    )}

                    <div className="flex flex-col w-full mb-[15px]">
                      <label
                        htmlFor="paymentMethodImage"
                        className="text-sm font-medium text-gray-700 mb-2 cursor-pointer"
                      >
                        Payment Method Logo
                      </label>
                      <input
                        type="file"
                        id="paymentMethodImage"
                        accept="image/*"
                        className="input input-bordered w-full rounded-2xl bg-white px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        onChange={handleImageChangePaymentMethod}
                      />
                      {inputErors.iconImage && (
                        <p className="error-message text-red-500 mt-2">
                          Please upload a valid image file.
                        </p>
                      )}
                    </div>

                    <div className="button-disabled">
                      <button
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showModal ? (
        <>
          <div>
            {/* The button to open modal */}
            {/* Put this part before </body> tag */}
            <input
              type="checkbox"
              id="modal_delivery"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Delivery Method Name
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="modal_delivery"
                      className="cursor-pointer"
                      onClick={handleOpen}
                    >
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitDelivery}
                  >
                    <input
                      type="text"
                      name="name"
                      value={deliveryName}
                      onChange={(e) => {
                        setDeliveryName(e.target.value);
                        setInputErrors({ ...inputErors, deliveryName: false });
                      }}
                      ref={inputRef}
                      placeholder="Add Delivery Method Name"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />
                    {inputErors.deliveryName ? (
                      <p className="error-message text-red-500 mb-3 pl-1">
                        This field cannot be empty.
                      </p>
                    ) : null}
                    <input
                      name="icon"
                      value={deliveryIcon}
                      onChange={(e) => {
                        const val = e.target.value;
                        setDeliveryIcon(val);
                        setInputErrors({ ...inputErors, deliveryIcon: false });
                      }}
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                      placeholder="Put a single letter as symbol..."
                    ></input>
                    {/* {inputErors.deliveryIcon
                                    ? <p className="error-message text-red-500 mb-3 pl-1">This field cannot be empty.</p>
                                    : null} */}
                    <div className="button-disabled">
                      <button
                        htmlFor="modal_delivery"
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {""}
                        Save{""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* Warranty info  */}
      {showModal ? (
        <>
          <div>
            {/* The button to open modal */}
            <input
              type="checkbox"
              id="modal_warranty"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Warranty Condition
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="modal_warranty"
                      className="cursor-pointer"
                      onClick={handleOpen}
                    >
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitWarrenty}
                  >
                    <input
                      type="number"
                      name="name"
                      // value={warranty.warrantyDays}
                      onChange={(e) => {
                        setWarranty({
                          ...warranty,
                          warrantyDays: e.target.value,
                        });
                        setInputErrors({ ...inputErors, error1: false });
                      }}
                      ref={inputRef}
                      placeholder="Warranty Days"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />
                    {inputErors.error1 ? (
                      <p className="error-message text-red-500 mb-3 pl-1">
                        This field cannot be empty.
                      </p>
                    ) : null}
                    <input
                      name="icon"
                      // value={warranty.warrantyConditon}
                      onChange={(e) => {
                        setWarranty({
                          ...warranty,
                          warrantyConditon: e.target.value,
                        });
                        // setInputErrors({ ...inputErors, error2: false });
                      }}
                      className="textarea textarea-bordered w-full mb-[15px] resize-none rounded-2xl bg-white"
                      placeholder="Condition Details"
                    ></input>
                    {/* {inputErors.error2
                                    ? <p className="error-message text-red-500 mb-3 pl-1">This field cannot be empty.</p>
                                    : null} */}
                    <div className="button-disabled">
                      <button
                        htmlFor="modal_warranty"
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SubNav;
