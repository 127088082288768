


import React, { useEffect, useRef, useState } from "react";
import ContentLayout from "../../layout/ContentLayout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectBox from "../../forms/Select";
import axios from "axios";
import Swal from "sweetalert2";
import { AiOutlineSearch } from "react-icons/ai";
import pdfIcon from "../../assets/images/pdf-Icon.png";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import MonthlyPurchaseReportPDFDocument from "./MonthlyPurchaseReportPDFDocument";

const MonthlyPurchaseReportExpand = () => {
  const [activeTab, setActiveTab] = useState("daily");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [toggle, setToggle] = useState(true);
  const [reports, setReports] = useState([]);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [grandTotalCredit, setGrandTotalCredit] = useState(0);
  const [dayCount, setDayCount] = useState(0)
  const [closingBalance, setClosingBalance] = useState(0);
  const [selected, setSelected] = useState("");
  const [formData, setFormData] = useState({
    start_date: startDate,
    end_date: endDate,
    // due: "customer" || selected,
  });
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const generatePdf = async () => {
    // Format the dates
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    const doc = (
      <MonthlyPurchaseReportPDFDocument
        reports={reports}
        user={user}
        startDate={formattedStartDate}
        endDate={formattedEndDate}
        dayCount={dayCount}
        grandTotal={grandTotal}
      />
    );

    // Generate the PDF as a blob
    const pdfBlob = await pdf(doc).toBlob();

    // Create a URL for the blob and open it in a new tab
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
  };

  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setFormData((prevFormData) => ({ ...prevFormData, start_date: date }));
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    setFormData((prevFormData) => ({ ...prevFormData, end_date: date }));
  };

  // ledger report api
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const fetchReportData = (interval = activeTab, useDateRange = false) => {
    loadingCallback(true);

    const requestData = useDateRange
      ? { start_date: startDate, end_date: endDate }
      : { interval };

    axios
      .post(
        `https://www.outletexpense.xyz/api/purchase-summary-report`,
        requestData,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        loadingCallback(false);
        if (res.data.success) {
          setDayCount(res?.data?.days_count)
          setGrandTotal(res?.data?.grand_total)
          setReports(res.data.data);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        console.error("Error fetching report data:", error);
      });
  };

  useEffect(() => {
    // Call API with interval on initial load and whenever `activeTab` changes
    fetchReportData(activeTab);
  }, [activeTab]);

  return (
    <>
      <ContentLayout>
        {/* tabs */}
        <div className="flex flex-row gap-[1%] pt-32 md:pt-0 pb-3 md:!pb-0 fixed md:static bg-[#EBF1FF] md:bg-transparent md:translate-x-0 top-0 left-0 pl-[30px] md:pl-0 w-full z-[9998]">
          <div
            // onClick={() => handleTabClick("daily")}
            onClick={() => setActiveTab("daily")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${
              activeTab === "daily"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Daily
          </div>
          <div
            // onClick={() => handleTabClick("weekly")}
            onClick={() => setActiveTab("weekly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${
              activeTab === "weekly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Weekly
          </div>
          <div
            // onClick={() => handleTabClick("monthly")}
            onClick={() => setActiveTab("monthly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${
              activeTab === "monthly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Monthly
          </div>
          <div
            // onClick={() => handleTabClick("yearly")}
            onClick={() => setActiveTab("yearly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${
              activeTab === "yearly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Yearly
          </div>
        </div>
        {/* datepicker field */}
        <div className="bg-white rounded-3xl mt-3 font-nunito p-[26px] grid grid-cols-1 gap-y-4 md:gap-y-0 md:gap-x-4 md:grid-cols-12 justify-between items-center">
          {/* card */}
          <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] w-full col-span-3 items-stretch bg-[#FF7A00] text-white flex justify-between py-7 px-3 rounded-2xl">
            <div className="space-y-1">
              <p className="font-semibold whitespace-nowrap text-xl">
                <span>{grandTotal || 0}</span>{" "}
                <sub className="text-[9px] font-extralight ml-1">BDT</sub>
              </p>
              <p className="text-xs font-normal">Purchase Day Counting Report</p>
            </div>
          </div>
          {/* date pickers */}
          <div className="col-span-7">
            <div className="flex justify-between w-full  items-center mt-[12px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
              <div className="relative border-[#C1CFEF] py-2.5 border w-full flex-1 rounded-xl">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="dd/MM/yyyy"
                  className="w-full px-2 focus:outline-none z-20"
                />
                <span className="bg-white absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                  Start Date
                </span>
              </div>
              <div className="relative border-[#C1CFEF] py-2.5  border w-full flex-1 rounded-xl">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  dateFormat="dd/MM/yyyy"
                  className="w-full px-2 focus:outline-none z-20"
                />
                <span className="bg-white absolute left-2 px-1 font-semibold text-xs top-0 text-[#102048] translate-y-[-50%]">
                  End Date
                </span>
              </div>
            </div>
          </div>
          <button
            onClick={() => fetchReportData(null, true)}
            className=" bg-[#034AFF] cursor-pointer col-span-2 font-nunito text-white rounded-xl px-3 xl:px-5 py-2 text-md"
          >
            Report
          </button>
        </div>

        {/* table */}
        <div>
          <div className="bg-white rounded-3xl font-nunito mt-4 p-[26px]">
            <div className="flex justify-between items-center">
              {/* section title & switch,search & pdf button*/}
              <div className="flex gap-3 items-center">
                {/* section title */}
                {toggle ? (
                  <h2 className="text-xl font-semibold">
                    Monthly Purchase Day Counting Report
                  </h2>
                ) : (
                  <h2 className="text-xl font-semibold">
                    Ledger Status Report
                  </h2>
                )}
              </div>
              {/* switch,search & pdf button */}
              <div className="flex gap-3 items-center">
                <div className="flex relative w-[95%]">
                  <input
                    autoFocus
                    type="text"
                    className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[45px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                    placeholder="Search By Particulars"
                  />
                  <div className="absolute top-[17px] left-[10px]">
                    <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                  </div>

                  <div className="absolute top-[17px]  right-[10px]">
                    <div
                      className=" relative flex flex-col items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z"
                          fill="#102048"
                        />
                        <path
                          d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z"
                          fill="#102048"
                        />
                        <path
                          d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z"
                          fill="#102048"
                        />
                        <path
                          d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z"
                          fill="#102048"
                        />
                        <path
                          d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z"
                          fill="#102048"
                        />
                        <path
                          d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z"
                          fill="#102048"
                        />
                        <path
                          d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z"
                          fill="#102048"
                        />
                        <path
                          d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z"
                          fill="#102048"
                        />
                        <path
                          d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z"
                          fill="#102048"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  onClick={generatePdf}
                  className="bg-[#EBF1FF] rounded-full w-[34px] h-[34px] flex items-center justify-center cursor-pointer transform hover:scale-110 duration-200"
                >
                  <img className="w-[16px]" src={pdfIcon} alt="pdfIcon" />
                </div>
              </div>
            </div>

            {toggle ? (
              <div>
              <div className=" grid grid-cols-12 text-left text-[14px] mt-3 py-[8px] font-nunito font-bold text-[#000232]  border-[2px] border-black px-3">
                <div className="text-left col-span-1 mr-4">Sl.</div>
                <div className="text-left col-span-2 mr-4">Transaction Date</div>
                <div className="text-left col-span-2 mr-4">Voucher Number</div>
                <div className="text-left col-span-2 mr-4">Vendor Name</div>
                <div className="text-left col-span-2 mr-4">Product Name</div>
                {/* <div className="text-center col-span-1 mr-4">Qty</div> */}
                <div className="text-left col-span-2 mr-4">IMEI</div>
                <div className="flex flex-col justify-center items-end col-span-1 mr-4">
                  <span className="text-right">Purchase Amount</span>
                </div>
              </div>

              {loading ? (
                <div className="custom-loader-logo">
                  <img
                    className=""
                    src={`${
                      process.env.REACT_APP_ROOT_URL + "/smallLogo.png"
                    }`}
                    alt="logo"
                  />
                </div>
              ) : (
                <>
                  {reports.map((report, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-12 items-center justify-start font-nunito font-medium text-[14px] text-[#000232] border-[1px] border-solid border-[#E7EBF4] py-2 px-3"
                    >
                      <div className="col-span-1">{index + 1}</div>
                      <div className=" col-span-2">
                        <div className="font-medium text-[#000232]">
                          {report?.date}
                        </div>
                      </div>
                      <div className="text-start col-span-2 mr-4">
                        {report?.invoice_id}
                      </div>
                      <div className="text-start col-span-2 mr-4">
                        {report?.vendor_name || "N/A"}
                      </div>
                      <div className="text-left col-span-2 mr-4">
                        {report?.product_name}
                      </div>
                      {/* <div className="text-center col-span-1 mr-4">
                        {report?.qty}
                      </div> */}
                      <div className="text-left col-span-2 mr-4">
                        {report?.imei}
                      </div>
                      <div className="text-right col-span-1 mr-4">
                        {report?.price}
                      </div>
                    </div>
                  ))}

                  <div className="grid grid-cols-12 font-nunito font-bold text-[14px] text-[#000232] border-t-[2px] border-solid border-[#000] py-2 px-3 bg-gray-200">
                    <div className="col-span-1"></div>
                    <div className="col-span-2">{dayCount} Days</div>
                    <div className="col-span-7">
                      Grand Total
                    </div>
                    <div className="text-right col-span-2">
                      {grandTotal ? grandTotal : 0}
                    </div>
                  </div>
                </>
              )}
            </div>
            ) : (
              <div>
                <div className="grid text-center text-[14px] mt-8 py-[8px] font-nunito font-bold text-[#000232] grid-cols-5 border-b-[1px] border-[#E7EBF4]">
                  <div className="text-left whitespace-nowrap">
                    <p className="text-[14px] font-bold justify-start items-center flex">
                      <input
                        className="me-1"
                        type="checkbox"
                      />{" "}
                      Select All{" "}
                    </p>
                  </div>
                  <div className="">Transaction Status</div>
                  <div>Transaction Type</div>
                  <div>Transaction Id</div>
                  <div className="text-center">Amount</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default MonthlyPurchaseReportExpand;
