import React from 'react';
import {
  formatPhoneNumber,
  numberToWords,
  formatPrice,
  invoiceCalculate,
} from "../../../utils/Common";
import QRCode from "react-qr-code";

const PdfHtmlContentPad = ({invoice, invoiceSetting, invoiceImeis, inputs, formattedDateTime, totalPricePrintPad, totalItemsPrintPad, styles, totalPrice, totalQty}) => {
  return (
    <div className="hidden">
        <div id="pdfConentAreasPad" className="pdfConentAreasPad">
          <div
            id="invoice-detail-page"
            className="invoice-detail-page font-nunito"
          >
            {/* invoice header */}

            {/* customer info and  + QR*/}
            <div className="flex items-center mt-[10px] justify-between">
              <div className="text-black space-y-[2px] md:space-y-[6px] font-nunito text-xs  lg:text-base">

                <p>
                  {" "}
                  <span className="font-semibold">Bill to,</span> <br />{" "}
                  <span className="font-semibold">Customer Name </span> :{" "}
                  {invoice.customer_name}{" "}
                </p>
                <p>
                  <span className="font-semibold">Customer Phone</span> :{" "}
                  {invoice.country_code}
                  {invoice.customer_phone}
                </p>
                <p>
                  <span className="font-semibold"> Customer ID </span> :{" "}
                  {invoice.customer_id}
                </p>
              </div>
              {/* <div className="w-[45px] sm:w-[65px] md:w-[100px]"> */}
              <div className="flex flex-col justify-start items-start text-black space-y-[2px] md:space-y-[4px] font-nunito text-xs  lg:text-base">
                {/* <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice.invoice_id} size={100} /> */}
                <p>
                  {" "}
                  <span className="font-semibold">Invoice No</span> :{" "}
                  {invoice.invoice_id}{" "}
                </p>
                <p>
                  <span className="font-semibold">Invoice Date</span> :{" "}
                  {formattedDateTime}
                </p>
              </div>
            </div>

            <div className="mx-auto md:w-full md:max-w-full overflow-x-auto mb-0 mt-3">
              <table className="table ">
                <thead id="pdfContentTHeadPad" className="pb-3">
                  <tr className={`leading-normal text-sm lg:text-base font-nunito tracking-wide border-b-4 border-t-4 `}
                  style={{borderColor : invoiceSetting?.dummy_text ? invoiceSetting?.dummy_text : '#93C5FD'}}
                  >
                    <th
                      className="font-nunito text-left"
                      style={{ padding: "3px 5px" }}
                    >
                      #
                    </th>
                    <th
                      className="font-nunito text-start"
                      style={{ padding: "3px 5px" }}
                    >
                      Item Name
                    </th>
                    <th
                      className="font-nunito text-center"
                      style={{ padding: "3px 5px" }}
                    >
                      Warranty
                    </th>
                    <th
                      className="font-nunito text-center"
                      style={{ padding: "3px 5px" }}
                    >
                      Quantity
                    </th>
                    <th
                      className="font-nunito text-right"
                      style={{ padding: "3px 5px" }}
                    >
                      Price
                    </th>
                    <th
                      className="font-nunito text-right"
                      style={{ padding: "3px 5px" }}
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody className="mt-2">
                  {(() => {
                    let rowCounter = 0; // Counter for serial numbers
                    const processedProductIds = new Set(); // Track processed product IDs to avoid duplicates

                    return invoice?.sales_details?.map((item) => {
                      const findImeis = invoiceImeis.filter(
                        (imei) => imei.product_id === item.product_id
                      );

                      // If product_id has been processed, skip rendering it again
                      if (processedProductIds.has(item.product_id)) {
                        return null;
                      }

                      processedProductIds.add(item.product_id); // Mark product_id as processed

                      let subtotalPrice = 0;
                      if (findImeis.length > 0) {
                        // Calculate total price for IMEIs
                        const qty = findImeis.length;
                        totalItemsPrintPad += qty;
                        subtotalPrice = findImeis.reduce(
                          (acc, prod) => acc + prod.sale_price,
                          0
                        );
                        totalPricePrintPad += subtotalPrice;
                      } else {
                        // For items without IMEIs
                        subtotalPrice = (item.qty * item.price);
                        totalQty += Number(item.qty);
                        totalItemsPrintPad += 1;
                        totalPricePrintPad += Number(subtotalPrice);
                      }

                      // Render IMEI rows for products with IMEIs, otherwise render the standard product row
                      return findImeis.length > 0 ? (
                        findImeis.map((imei, index) => (
                          <tr
                            key={imei.id}
                            className="font-nunito border-[#E7EBF4] font-normal text-sm lg:text-base relative pt-3 pb-3"
                          >
                            <td
                              className="text-left"
                              style={{ padding: "10px" }}
                            >
                              {++rowCounter}
                            </td>
                            <td className="" style={{ padding: "10px" }}>
                              {imei.product_name} {imei.optional_name}
                              <br />
                              <span className="text-[10px]">{imei.imei}</span>
                            </td>
                            <td
                              className="text-center "
                              style={{ padding: "10px" }}
                            >
                              {invoice.defaultwarranties &&
                                invoice.defaultwarranties.map(
                                  (warrantyItem) =>
                                    warrantyItem.invoice_id ===
                                      invoice.invoice_id &&
                                    warrantyItem.product_id ===
                                      item.product_id &&
                                    (warrantyItem.warranty_id == null ? (
                                      <h1 className="">
                                        {warrantyItem.default_warranties_count ||
                                          ""}{" "}
                                        {item.product_info.warrenty || ""}
                                      </h1>
                                    ) : (
                                      <h1 className="">
                                        {warrantyItem.warranty
                                          ?.warranties_count || ""}{" "}
                                        {warrantyItem.warranty?.name || ""}
                                      </h1>
                                    ))
                                )}
                            </td>
                            <td
                              className="text-center "
                              style={{ padding: "10px" }}
                            >
                              1
                            </td>
                            <td
                              className="text-right"
                              style={{ padding: "10px" }}
                            >
                              {imei.sale_price}
                            </td>
                            <td
                              className="text-right font-semibold md:font-semibold "
                              style={{
                                padding: "6px 5px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              {imei.sale_price}
                              {invoiceSetting?.currency_info?.code || " BDT"}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr
                          key={item.id}
                          className="font-nunito border-[#E7EBF4] font-normal text-sm lg:text-base relative"
                        >
                          <td className="text-left" style={{ padding: "10px" }}>
                            {++rowCounter}
                          </td>
                          <td className="" style={{ padding: "10px" }}>
                            {item.product_info.name}
                            <br />
                            <span className="text-[10px]">
                              {item.product_info.serial}
                            </span>
                          </td>
                          <td
                            className="text-center "
                            style={{ padding: "10px" }}
                          >
                            {invoice.defaultwarranties &&
                              invoice.defaultwarranties.map(
                                (warrantyItem) =>
                                  warrantyItem.invoice_id ===
                                    invoice.invoice_id &&
                                  warrantyItem.product_id === item.product_id &&
                                  (warrantyItem.warranty_id == null ? (
                                    <h1 className="">
                                      {warrantyItem.default_warranties_count ||
                                        ""}{" "}
                                      {item.product_info.warrenty || ""}
                                    </h1>
                                  ) : (
                                    <h1 className="">
                                      {warrantyItem.warranty
                                        ?.warranties_count || ""}{" "}
                                      {warrantyItem.warranty?.name || ""}
                                    </h1>
                                  ))
                              )}
                          </td>
                          <td
                            className="text-center "
                            style={{ padding: "10px" }}
                          >
                            {item.qty}
                          </td>
                          <td
                            className="text-right "
                            style={{ padding: "10px" }}
                          >
                            {item.price}
                          </td>
                          <td
                            className="text-right font-semibold md:font-semibold "
                            style={{ padding: "10px" }}
                          >
                            {subtotalPrice}
                            {invoiceSetting?.currency_info?.code || " BDT"}
                          </td>
                        </tr>
                      );
                    });
                  })()}
                </tbody>
              </table>
              <div className={`border-b-4 mt-3 mb-3`} style={{borderColor : invoiceSetting?.dummy_text ? invoiceSetting?.dummy_text : '#93C5FD'}}></div>
            </div>
            <div className="w-full flex justify-between pt-0 ml-auto ">
              {/* disclaimer */}
              {/* payment method */}
              <div style={styles.paymentMethodPrintDiv}>


                  {/* Here added the code by Saif */}
                <div>
                  {invoice?.multiple_payment?.length > 0 ? (
                    <>
                      {(() => {
                        const grandTotal = parseFloat(
                          (
                            Number(totalPrice) +
                            Number(invoice?.vat || 0) +
                            Number(invoice?.tax || 0) +
                            Number(invoice?.delivery_fee || 0)
                          ).toFixed(2)
                        );
                        const discount = Number(invoice?.discount || 0);
                        const paidAmount = Number(
                          invoice.paid_amount && invoice.paid_amount !== 0
                            ? invoice.paid_amount
                            : 0
                        );
                        const dueAmount = grandTotal - paidAmount;

                        // Debugging values
                        console.log("grandTotal:", grandTotal);
                        console.log("discount:", discount);
                        console.log("paidAmount:", paidAmount);
                        console.log("dueAmount:", dueAmount);

                        const remainingAmount = dueAmount - paidAmount;
                        console.log("remainingAmount:", remainingAmount);

                        const isFullyPaid = dueAmount === 0;
                        const isFullyDue = paidAmount === 0;
                        const isPartiallyPaid = paidAmount > 0;
                        console.log("isFullyPaid:", isFullyPaid);
                        console.log("isFullyDue:", isFullyDue);
                        console.log("isPartiallyPaid:", isPartiallyPaid);

                        return (
                          <>
                            {isFullyDue ? (
                              <span style={{ letterSpacing: "0.08em" }}>
                                The amount is fully due
                              </span>
                            ) : (
                              <>
                                {isFullyPaid ? (
                                  <span style={{ letterSpacing: "0.08em" }}>
                                    Paid by{" "}
                                    {invoice?.multiple_payment
                                      ?.map(
                                        (payment) =>
                                          payment.payment_type.type_name
                                      )
                                      .join(", ")
                                      .replace(/, ([^,]*)$/, " and $1")}{" "}
                                  </span>
                                ) : (
                                  <span style={{ letterSpacing: "0.08em" }}>
                                    Paid by{" "}
                                    {invoice?.multiple_payment
                                      ?.map(
                                        (payment) =>
                                          payment.payment_type.type_name
                                      )
                                      .join(", ")
                                      .replace(/, ([^,]*)$/, " and $1")}{" "}
                                    {isPartiallyPaid ? "Partially" : ""}
                                  </span>
                                )}
                              </>
                            )}
                          </>
                        );
                      })()}
                    </>
                  ) : (
                    <span>No payment information available</span>
                  )}
                </div>
              </div>
              {/* invoice sumary */}
              <div className="w-1/3 text-xs font-semibold pl-12">
                <div className="space-y-[2px] mb-2">
                  {/* <p className="flex justify-between">
                  <span>Total Items</span>
                  <span>{totalItemsPrintPad}</span>
                </p> */}
                  <p className="flex justify-between">
                    <span>Sub Total</span>
                    <span>
                      {formatPrice(totalPricePrintPad)}
                      {/* {totalPrice} */}
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                  <p className="flex justify-between">
                    <span>Discount</span>
                    <span>
                      {formatPrice(invoice.discount ? invoice.discount : 0)}
                      {/* {invoice.discount ? invoice.discount : 0} */}
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>

                </div>
                {/* <hr></hr> */}
                <div
                  style={{ borderTop: "0.5px solid #D3D3D3", margin: "10px 0" }}
                ></div>

                <div className="my-2">
                  <p className="flex justify-between">
                    <span>Grand Total</span>
                    <span>
                      {/* {formatPrice(invoiceCalculate(invoice).grandTotal)} */}
                      {
                        // Ensure values are valid numbers and calculate before toFixed
                        Number.parseFloat(
                          (
                            (Number(totalPricePrintPad) || 0) +
                            (Number(invoice?.vat) || 0) +
                            (Number(invoice?.tax) || 0) +
                            (Number(invoice?.delivery_fee) || 0) -
                            (Number(invoice?.discount) || 0)
                          ).toFixed(2)
                        ).toFixed(2)
                      }
                      {/* {invoice.sub_total} */}
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                  <p className="flex justify-between">
                    <span>Paid Amount</span>
                    <span>
                      {/* {formatPrice(invoice.paid_amount)} */}
                      {formatPrice(invoice.paid_amount)}
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                </div>
                {/* <hr></hr> */}
                <div
                  style={{ borderTop: "0.5px solid #D3D3D3", margin: "10px 0" }}
                ></div>
                <p className="flex justify-between mt-2">
                  <span>Due Amount</span>
                  <span>
                    {/* {Number.parseFloat(invoiceCalculate(invoice).dueAmount).toFixed(2)} */}
                    {Number.parseFloat(
                      Number(totalPricePrintPad) +
                        Number(invoice?.vat) +
                        Number(invoice?.tax) +
                        Number(invoice?.delivery_fee) -
                        Number(invoice?.discount) -
                        Number(
                          invoice.paid_amount && invoice.paid_amount != ""
                            ? formatPrice(invoice.paid_amount)
                            : formatPrice(0)
                        )
                    ).toFixed(2)}
                    {/* {invoice.sub_total - invoice.paid_amount} */}
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : " BDT"}
                  </span>
                </p>
              </div>
            </div>
            {/* amount in words */}
            <p className="text-gray-900 mt-4 font-nunito text-xs  lg:text-base font-semibold leading-normal text-center">
              <span style={{ color: "#A0AEC0" }}>In Word:</span>{" "}
              {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))}{" "}
              taka only
            </p>
            {/* <div style={{ ...styles.div }}>
              <p className="text-white font-nunito text-xs font-bold">
                You saved {formatPrice(invoice.discount ? invoice.discount : 0)}
                {invoiceSetting?.currency_info?.code
                  ? invoiceSetting?.currency_info?.code
                  : " BDT"}{" "}
                in this purchase{" "}
              </p>
            </div> */}
            <div style={invoiceSetting?.dummy_text ? { backgroundColor: invoiceSetting.dummy_text,color: "white",
            WebkitPrintColorAdjust: "exact",
            padding: "10px 10px 10px 10px",
            textAlign: "center",
            margin: "13px 0px 10px 0px" } : { ...styles.div }}>
            <p className="text-white font-nunito text-sm font-bold">
              You saved {formatPrice(invoice.discount ? invoice.discount : 0)}
              {/* You saved {invoice.discount ? invoice.discount : 0} */}
              {invoiceSetting?.currency_info?.code
                ? invoiceSetting?.currency_info?.code
                : " BDT"}{" "}
              in this purchase{" "}
            </p>
          </div>
            {/* web address qr code */}
            <QRCode
              className="w-[50px] object-contain p-0 mt-2"
              value={invoice?.user_info?.web_address || invoice?.user_info?.invoice_settings?.web_address
                || 'No Web Address set to the QR code'}
              size={50}
            />

            {/* Terms and Conditions Section */} {/* Signature Section */}
            <div className="flex space-x-5 text-[10px] lg:text-sm mt-2">
              <div className=" pt-2 w-1/2 ">
                <h3 className="text-[10px] font-semibold text-gray-800 mb-2">
                  Terms and Conditions
                </h3>
                <ul className="list-disc list-inside text-gray-700 text-[10px]">
                  {/* <li>All prices include taxes. Payment must be made in full at the time of purchase via accepted payment methods.</li>
                  <li>Disputes will be resolved under the laws of Bangladesh.</li>
                  <li>Defective or incorrect items will be replaced or refunded at no additional cost.</li> */}
                  {inputs.map((item, index) => (
                    <li key={item.id || index}>{item.description}</li>
                  ))}
                </ul>
              </div>
              {/* signature */}
              <div className="p-4 text-xs flex flex-col items-end w-1/2 ">

                <div className="flex justify-end items-center">
                  <div className={`flex flex-col items-center border`}style={{borderColor : invoiceSetting?.dummy_text ? invoiceSetting?.dummy_text : '#93C5FD'}}>
                    <hr className="w-full" />
                    <span>Authorized Signature</span>
                  </div>
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="text-center font-nunito mt-2 text-xs md:text-sm lg:text-base">
              <h2 className="text-orange-500  text-xs font-semibold ">
                Thanks for purchase
              </h2>
              {/* <p className="text-black mt-2 text-xs">
              A Product of {invoice?.user_info?.invoice_settings?.shop_name}{" "}
              </p> */}
            </div>
          </div>
        </div>
      </div>
  );
};

const styles = {
  div: {
    backgroundColor: "#407BFF",
    color: "white",
    WebkitPrintColorAdjust: "exact",
    padding: "10px 10px 10px 10px",
    textAlign: "center",
    margin: "13px 0px 10px 0px",
  },
  paymentMethodDiv: {
    transform: "rotate(-45deg)",
    padding: "10px",
    textAlign: "center",
    // backgroundColor: '#407BFF',
    color: "black",
    // WebkitPrintColorAdjust: 'exact',
    margin: "30px 30px 20px 180px",
    maxWidth: "120px",
    wordWrap: "break-word",
    overflow: "hidden",
    fontSize: "13px",

    // border: '2px solid #407BFF',
    // borderRadius: '50%',
    // width: '180px',
    // height: '120px',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  paymentMethodPrintDiv: {
    transform: "rotate(-45deg)",
    padding: "10px",
    textAlign: "center",
    // backgroundColor: '#407BFF',
    color: "black",
    // WebkitPrintColorAdjust: 'exact',
    margin: "30px 30px 20px 180px",
    maxWidth: "120px",
    wordWrap: "break-word",
    overflow: "hidden",
    fontSize: "10px",

    // border: '2px solid #407BFF',
    // borderRadius: '50%',
    // width: '120px',
    // height: '120px',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
};

export default PdfHtmlContentPad;