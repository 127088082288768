// import React from "react";
// import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// // Define styles
// const styles = StyleSheet.create({
//   page: {
//     paddingVertical: 20,
//     paddingHorizontal: 20,
//     position: "relative",
//   },
//   header: {
//     textAlign: "center",
//     marginBottom: 10,
//   },
//   title: {
//     fontSize: 16,
//     fontWeight: "bold",
//   },
//   shopInfo: {
//     fontSize: 10,
//     marginBottom: 2
//   },
//   reportTitle: {
//     fontSize: 10,
//     fontWeight: "bold",
//     marginTop: 2,
//   },
//   thickDivider: {
//     borderBottomWidth: 2,
//     borderBottomColor: "#000",
//     marginVertical: 0,
//     width: "100%",
//   },
//   divider: {
//     borderBottomWidth: 1,
//     borderBottomColor: "#000",
//     marginVertical: 1,
//   },
//   table: {
//     display: "table",
//     width: "100%",
//     marginTop: 5, // Add margin to the top of the table
//   },
//   tableRow: {
//     flexDirection: "row",
//   },
//   tableColHeader: {
//     flex: 1,
//     paddingVertical: 5,
//     paddingLeft: 2,
//     textAlign: "left", // Left align headers
//     fontSize: 10,
//     fontWeight: "bold",
//     backgroundColor: "#E7EBF4",
//     // border: "1px solid #000",
//   },
//   tableCol: {
//     flex: 1,
//     paddingVertical: 5,
//     paddingLeft: 2,
//     textAlign: "left", // Left-align cell values
//     fontSize: 10,
//     borderBottom: "1px solid #000",
//   },
//   tableColLeft: {
//     textAlign: "left",
//   },
//   tableColSerialNo: {
//     flex: 0.2, // Smaller flex value for Serial No column
//     // border: "1px solid #000",
//     textAlign: "left",
//   },
//   tableRowAlternate: {
//     backgroundColor: "#F9F9F9",
//   },
//   totalRow: {
//     backgroundColor: "#E7EBF4",
//     fontWeight: "bold",
//   },
//   footer: {
//     position: "absolute",
//     bottom: 15,
//     left: 0,
//     right: 0,
//     flexDirection: "row",
//     justifyContent: "space-between",
//     fontSize: 10,
//     paddingHorizontal: 20,
//   },
// });

// // PDF document component
// const CashBookReport = ({
//   reports,
//   user,
//   dateTo,
//   dateFrom,
//   openingBalance,
//   currentTotalDebit,
//   currentTotalCredit,
//   closingBalance,
//   paymentTypeName
// }) => (
//   <Document>
//     <Page style={styles.page} size="A4" orientation="landscape">
//       {/* Header */}
//       {console.log(user)}
//       <View style={styles.header}>
//         <Text style={styles.title}>{user?.invoice_settings?.shop_name || "Shop Name"}</Text>
//         <Text style={styles.shopInfo}>{user?.address || "Address"}</Text>
//         <Text style={styles.shopInfo}>Mobile: {user?.phone || "N/A"}</Text>
//         <View style={styles.thickDivider} />
//         <View style={styles.divider} />
//         <View style={styles.thickDivider} />

//         <Text style={styles.reportTitle}>
//           Cash Book Detail Report ({paymentTypeName})
//         </Text>
//         <Text style={styles.reportTitle}>
//         ({dateFrom} to {dateTo})
//         </Text>

//       </View>

//       {/* Table */}
//       <View style={styles.table}>
//         {/* Table Header */}
//         <View style={styles.tableRow}>
//           <Text style={[styles.tableColHeader, styles.tableColSerialNo]}>
//             Sl.
//           </Text>
//           <Text style={styles.tableColHeader}>Transaction Date</Text>
//           <Text style={styles.tableColHeader}>Particulars</Text>
//           <Text style={styles.tableColHeader}>Vch Types</Text>
//           <Text style={styles.tableColHeader}>Vch Number</Text>
//           <Text style={styles.tableColHeader}>Debit (in BDT)</Text>
//           <Text style={styles.tableColHeader}>Credit (in BDT)</Text>
//         </View>

//         {/* Table Body */}
//         <View style={styles.tableRow}>
//           <Text style={[styles.tableCol, styles.tableColSerialNo]}>1</Text>
//           <Text style={styles.tableCol}>{dateFrom}</Text>
//           <Text style={styles.tableCol}>Opening Balance</Text>
//           <Text style={styles.tableCol}></Text>
//           <Text style={styles.tableCol}></Text>
//           <Text style={styles.tableCol}>0</Text>
//           <Text style={styles.tableCol}>{openingBalance || 0}</Text>
//         </View>
//         {reports.map((report, index) => (
//           <View
//             key={index}
//             style={[
//               styles.tableRow,
//               index % 2 === 0 && styles.tableRowAlternate,
//             ]}
//           >
//             <Text style={[styles.tableCol, styles.tableColSerialNo]}>
//               {index + 2}
//             </Text>
//             <Text style={styles.tableCol}>{report?.date || "N/A"}</Text>
//             <Text style={styles.tableCol}>{report?.particulars || "N/A"}</Text>
//             <Text style={styles.tableCol}>{report?.type || "N/A"}</Text>
//             <Text style={styles.tableCol}>{report?.invoice_id || "N/A"}</Text>
//             <Text style={styles.tableCol}>
//               {report?.debit ? report?.debit : 0}
//             </Text>
//             <Text style={styles.tableCol}>
//               {report?.credit ? report?.credit : 0}
//             </Text>
//           </View>
//         ))}

//         {/* Totals */}
//         <View style={[styles.tableRow, styles.totalRow]}>
//           <Text style={[styles.tableCol, styles.tableColSerialNo]}></Text>
//           <Text style={styles.tableCol}></Text>
//           <Text style={styles.tableCol}></Text>
//           <Text style={styles.tableCol}></Text>
//           <Text style={styles.tableCol}>Current Total</Text>
//           <Text style={styles.tableCol}>{currentTotalDebit || 0}</Text>
//           <Text style={styles.tableCol}>{currentTotalCredit || 0}</Text>
//         </View>
//         <View style={[styles.tableRow, styles.totalRow]}>
//           <Text style={[styles.tableCol, styles.tableColSerialNo]}></Text>
//           <Text style={styles.tableCol}></Text>
//           <Text style={styles.tableCol}></Text>
//           <Text style={styles.tableCol}></Text>
//           <Text style={styles.tableCol}>Closing Balance</Text>
//           <Text style={styles.tableCol}></Text>
//           <Text style={styles.tableCol}>{closingBalance || 0}</Text>
//         </View>
//       </View>

//       {/* Footer */}
//       <View style={styles.footer}>
//         <Text>Prepared by</Text>
//         <Text>Checked/Recommended by</Text>
//         <Text>Authorized by</Text>
//       </View>
//     </Page>
//   </Document>
// );

// export default CashBookReport;





import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  page: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    position: "relative",
  },
  header: {
    textAlign: "center",
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  shopInfo: {
    fontSize: 10,
    marginBottom: 2,
  },
  reportTitle: {
    fontSize: 10,
    fontWeight: "bold",
    marginTop: 2,
  },
  thickDivider: {
    borderBottomWidth: 2,
    borderBottomColor: "#000",
    marginVertical: 0,
    width: "100%",
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    marginVertical: 1,
  },
  table: {
    display: "table",
    width: "100%",
    marginTop: 5, // Add margin to the top of the table
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    flex: 1,
    paddingVertical: 5,
    paddingLeft: 2,
    fontSize: 10,
    fontWeight: "bold",
    backgroundColor: "#E7EBF4",
  },
  tableColHeaderDebit: {
    textAlign: "center", // Center-align Debit column header
  },
  tableColHeaderCredit: {
    textAlign: "right", // Right-align Credit column header
    paddingRight: 5, // Add some padding for the right alignment
  },
  tableCol: {
    flex: 1,
    paddingVertical: 5,
    paddingLeft: 2,
    textAlign: "left", // Default alignment
    fontSize: 10,
    borderBottom: "1px solid #000",
  },
  tableColSerialNo: {
    flex: 0.2, // Smaller flex value for Serial No column
    textAlign: "left",
  },
  tableColDebit: {
    textAlign: "center", // Center-align Debit column values
  },
  tableColCredit: {
    textAlign: "right", // Right-align Credit column values
    paddingRight: 5,
  },
  tableRowAlternate: {
    backgroundColor: "#F9F9F9",
  },
  totalRow: {
    backgroundColor: "#E7EBF4",
    fontWeight: "bold",
  },
  footer: {
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 10,
    paddingHorizontal: 20,
  },
});

// PDF document component
const CashBookReport = ({
  reports,
  user,
  dateTo,
  dateFrom,
  openingBalance,
  currentTotalDebit,
  currentTotalCredit,
  closingBalance,
  paymentTypeName,
}) => (
  <Document>
    <Page style={styles.page} size="A4" orientation="landscape">
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>{user?.invoice_settings?.shop_name || "Shop Name"}</Text>
        <Text style={styles.shopInfo}>{user?.address || "Address"}</Text>
        <Text style={styles.shopInfo}>Mobile: {user?.phone || "N/A"}</Text>
        <View style={styles.thickDivider} />
        <View style={styles.divider} />
        <View style={styles.thickDivider} />

        <Text style={styles.reportTitle}>
          Cash Book Detail Report ({paymentTypeName})
        </Text>
        <Text style={styles.reportTitle}>
          ({dateFrom} to {dateTo})
        </Text>
      </View>

      {/* Table */}
      <View style={styles.table}>
        {/* Table Header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableColHeader, styles.tableColSerialNo]}>Sl.</Text>
          <Text style={styles.tableColHeader}>Transaction Date</Text>
          <Text style={styles.tableColHeader}>Particulars</Text>
          <Text style={styles.tableColHeader}>Vch Types</Text>
          <Text style={styles.tableColHeader}>Vch Number</Text>
          <Text style={[styles.tableColHeader, styles.tableColHeaderDebit]}>Debit (in BDT)</Text>
          <Text style={[styles.tableColHeader, styles.tableColHeaderCredit]}>Credit (in BDT)</Text>
        </View>

        {/* Table Body */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCol, styles.tableColSerialNo]}>1</Text>
          <Text style={styles.tableCol}>{dateFrom}</Text>
          <Text style={styles.tableCol}>Opening Balance</Text>
          <Text style={styles.tableCol}></Text>
          <Text style={styles.tableCol}></Text>
          <Text style={[styles.tableCol, styles.tableColDebit]}>0</Text>
          <Text style={[styles.tableCol, styles.tableColCredit]}>
            {openingBalance || 0}
          </Text>
        </View>
        {reports.map((report, index) => (
          <View
            key={index}
            style={[
              styles.tableRow,
              index % 2 === 0 && styles.tableRowAlternate,
            ]}
          >
            <Text style={[styles.tableCol, styles.tableColSerialNo]}>{index + 2}</Text>
            <Text style={styles.tableCol}>{report?.date || "N/A"}</Text>
            <Text style={styles.tableCol}>{report?.particulars || "N/A"}</Text>
            <Text style={styles.tableCol}>{report?.type || "N/A"}</Text>
            <Text style={styles.tableCol}>{report?.invoice_id || "N/A"}</Text>
            <Text style={[styles.tableCol, styles.tableColDebit]}>
              {report?.debit ? report?.debit : 0}
            </Text>
            <Text style={[styles.tableCol, styles.tableColCredit]}>
              {report?.credit ? report?.credit : 0}
            </Text>
          </View>
        ))}

        {/* Totals */}
        <View style={[styles.tableRow, styles.totalRow]}>
          <Text style={[styles.tableCol, styles.tableColSerialNo]}></Text>
          <Text style={styles.tableCol}></Text>
          <Text style={styles.tableCol}></Text>
          <Text style={styles.tableCol}></Text>
          <Text style={styles.tableCol}>Current Total</Text>
          <Text style={[styles.tableCol, styles.tableColDebit]}>
            {currentTotalDebit || 0}
          </Text>
          <Text style={[styles.tableCol, styles.tableColCredit]}>
            {currentTotalCredit || 0}
          </Text>
        </View>
        <View style={[styles.tableRow, styles.totalRow]}>
          <Text style={[styles.tableCol, styles.tableColSerialNo]}></Text>
          <Text style={styles.tableCol}></Text>
          <Text style={styles.tableCol}></Text>
          <Text style={styles.tableCol}></Text>
          <Text style={styles.tableCol}>Closing Balance</Text>
          <Text style={[styles.tableCol, styles.tableColDebit]}></Text>
          <Text style={[styles.tableCol, styles.tableColCredit]}>
            {closingBalance || 0}
          </Text>
        </View>
      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <Text>Prepared by</Text>
        <Text>Checked/Recommended by</Text>
        <Text>Authorized by</Text>
      </View>
    </Page>
  </Document>
);

export default CashBookReport;
